import React from "react";
import { Grid, Paper, Typography, Theme } from "@mui/material";
import PageHeader from "./PageHeader.js";
import theme from "../theme";
import { ShiSpinnerIcon } from "../components/ShiSpinnerIcon";
const css = (theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  fade: {
    opacity: 0.5,
    width: "75%",
    display: "flex",
    minHeight: theme.spacing(40),
  },
  fifty: {
    width: "60%",
  },
});

export default function LoadingOrError({
  loading = false,
  error = false,
  message = "",
  header = "",
  subheader = "",
  icon,
  headerComponent = undefined,
  suggestions = "",
}: {
  loading?: boolean;
  error?: boolean;
  message?: string;
  header?: string | string[];
  subheader?: string;
  icon?: any;
  headerComponent?: any;
  suggestions?: string;
}) {
  const styles = css(theme);

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 50,
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <ShiSpinnerIcon />
        </div>
      )}
      {header && (
        <PageHeader
          header={header}
          subheader={subheader}
          icon={icon}
          headerComponents={headerComponent}
        />
      )}
      <Grid
        container
        alignItems={loading ? "center" : "start"}
        justifyContent="center"
        sx={{ height: "80%", width: "100%" }}
      >
        {!loading && error && (
          <Paper sx={{ width: "100%" }}>
            <Typography component="div" sx={[styles.root, styles.fade]}>
              <div style={styles.grow}>
                <Typography component="div" variant="h4" gutterBottom>
                  {message}
                </Typography>
                {suggestions && (
                  <Typography
                    component="div"
                    variant="subtitle1"
                    sx={styles.fifty}
                  >
                    {suggestions}
                  </Typography>
                )}
              </div>
            </Typography>
          </Paper>
        )}
      </Grid>
    </div>
  );
}
