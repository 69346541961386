export const parseParams = (querystring) => {
  const obj = {};

  if (!querystring.startsWith("?")) {
    return obj;
  }

  const params = new URLSearchParams(querystring);

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};
