import React, { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { constructUrl } from "../utils/constructUrl";

interface LabelLinkProps {
  label: string;
  baseUrl: string;
  queryParams?: Record<string, string>;
  hashes?: string[];
  state?: unknown;
  css?: CSSProperties;
}

const LabelLink = ({
  label,
  baseUrl,
  queryParams = {},
  hashes = [],
  state = "",
  css,
}: LabelLinkProps) => {
  const path = constructUrl(baseUrl, queryParams, hashes);

  return (
    <Link component={RouterLink} state={state} to={path} sx={css}>
      {label}
    </Link>
  );
};

export default LabelLink;
