const shiAccountExecutiveKey = "SHI Account Executive";
const shiAccountExecutiveEmailKey = "SHI Account Executive Email";

export const getAccountExecutive = (udfJson) => {
  const udf = JSON.parse(udfJson);
  const accountExecutiveName = udf.find((k) => {
    return k.Name === shiAccountExecutiveKey;
  });
  const accountExecutiveEmail = udf.find((k) => {
    return k.Name === shiAccountExecutiveEmailKey;
  });

  if (accountExecutiveName && accountExecutiveEmail) {
    return {
      name: accountExecutiveName.Value,
      email: accountExecutiveEmail.Value,
    };
  }
  return false;
};

export const getInitials = (name) => {
  if (!name) return "";
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials.toUpperCase();
};
