export const GET_MY_TICKETS_INITIAL = "GET_MY_TICKETS_INITIAL";
export const GET_MY_TICKETS_INITIAL_SUCCESS = "GET_MY_TICKETS_INITIAL_SUCCESS";
export const GET_MY_TICKETS_INITIAL_ERROR = "GET_MY_TICKETS_INITIAL_ERROR";
export const SCHEDULE_TICKET_POLL = "SCHEDULE_TICKET_POLL";
export const RESET_CREATE_TICKET = "RESET_CREATE_TICKET";
export const CREATE_BACKUP_TICKET = "CREATE_BACKUP_TICKET";
export const CREATE_BACKUP_TICKET_COMPLETE = "CREATE_BACKUP_TICKET_COMPLETE";
export const CREATE_BACKUP_TICKET_ERROR = "CREATE_BACKUP_TICKET_ERROR";
export const CREATE_TICKET_NOTE = "CREATE_TICKET_NOTE";
export const CREATE_TICKET_NOTE_COMPLETE = "CREATE_TICKET_NOTE_COMPLETE";
export const CREATE_TICKET_NOTE_ERROR = "CREATE_TICKET_NOTE_ERROR";
export const DOWNLOAD_TICKET_ATTACHMENT = "DOWNLOAD_TICKET_ATTACHMENT";
export const DOWNLOAD_TICKET_ATTACHMENT_COMPLETE =
  "DOWNLOAD_TICKET_ATTACHMENT_COMPLETE";
export const DOWNLOAD_TICKET_ATTACHMENT_ERROR =
  "DOWNLOAD_TICKET_ATTACHMENT_ERROR";
export const GET_TICKET_ATTACHMENT_INFO = "GET_TICKET_ATTACHMENT_INFO";
export const GET_TICKET_ATTACHMENT_INFO_COMPLETE =
  "GET_TICKET_ATTACHMENT_INFO_COMPLETE";
export const GET_TICKET_ATTACHMENT_INFO_ERROR =
  "GET_TICKET_ATTACHMENT_INFO_ERROR";
export const RESET_TICKET_NOTE_SUBMITTED = "RESET_TICKET_NOTE_SUBMITTED";

export function resetTicketNoteSubmitted() {
  return (dispatch) => {
    dispatch({
      type: RESET_TICKET_NOTE_SUBMITTED,
    });
  };
}

export function resetTicketSubmission() {
  return (dispatch) => {
    dispatch({
      type: RESET_CREATE_TICKET,
    });
  };
}

export function getMyTicketsInitial() {
  return (dispatch) => {
    dispatch({
      type: GET_MY_TICKETS_INITIAL,
    });
  };
}

export function createTicketNote(ticketNote) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TICKET_NOTE,
      payload: {
        data: ticketNote,
      },
    });
  };
}

export function createBackupTicket(ticket) {
  const updatedTicket = {
    description: ticket.description,
    priority: ticket.priority,
    title: ticket.title,
    ticketAttachmentGuids: ticket.ticketAttachmentGuids,
    ticketCategory: parseInt(ticket.category),
    issueType: parseInt(ticket.issueType),
    subIssueType: parseInt(ticket.subIssueType),
    contractId: parseInt(ticket.contract),
    installedProductId: parseInt(ticket.configurationItemId),
    accountId: ticket.accountId,
    contactId: ticket.contactId,
    configurationPageUrl: ticket.configurationPageUrl,
  };
  return (dispatch) => {
    dispatch({
      type: CREATE_BACKUP_TICKET,
      payload: {
        data: updatedTicket,
      },
    });
  };
}

export function downloadAttachment(guid) {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_TICKET_ATTACHMENT,
      payload: {
        data: guid,
      },
    });
  };
}

export function getTicketAttachments(id) {
  return (dispatch) => {
    dispatch({
      type: GET_TICKET_ATTACHMENT_INFO,
      payload: {
        data: id,
      },
    });
  };
}
