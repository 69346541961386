import { ReactNode } from "react";
import { Skeleton } from "@mui/material";

const skeletonStyle = {
  color: "rgba(0,0,0,0)",
  userSelect: "none",
  pointerEvents: "none",
};

export function SkeletonWrapper({
  children,
  loading,
  error,
}: {
  children: ReactNode;
  loading?: boolean;
  error?: boolean;
}) {
  if (loading || error) {
    return (
      <Skeleton sx={skeletonStyle} animation={error ? false : "pulse"}>
        {children}
      </Skeleton>
    );
  }

  return <>{children}</>;
}

export function loading(
  children: ReactNode,
  loading?: boolean,
  error?: boolean
) {
  return (
    <SkeletonWrapper loading={loading} error={error}>
      {children}
    </SkeletonWrapper>
  );
}
