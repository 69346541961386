import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { Theme, alpha } from "@mui/material";

const StyledDataGrid = styled(DataGridPro, {
  shouldForwardProp: (prop) => prop !== "customTheme",
})<{ autoHeight?: boolean; customTheme?: any; adjustRowHeight?: boolean }>(
  ({ theme, autoHeight, customTheme, adjustRowHeight }) => {
    return {
      ...standardDataGridTheme(theme),
      ...(autoHeight && stickyHeader()),
      ...(!adjustRowHeight && rowHeight(theme)),
      ...customTheme,
    };
  }
);

const rowHeight = (theme: Theme) => ({
  "& .MuiDataGrid-cell": {
    padding: theme.spacing(0, 1.5),
  },
});

const stickyHeader = () => ({
  "& .MuiDataGrid-main": {
    overflow: "unset",
  },
});

const standardDataGridTheme = (theme: Theme) => ({
  borderRadius: 0,
  border: "1px solid" + theme.palette.table.border,
  backgroundColor: theme.palette.background.paper,
  position: "relative",

  "& .MuiDataGrid-columnHeaders,& .MuiDataGrid-pinnedColumnHeaders": {
    borderRadius: 0,
    backgroundColor: theme.palette.table.header,
    color: theme.palette.table.contrastText,
    "& .MuiDataGrid-columnSeparator": {
      color: theme.palette.table.header,
    },
    "& .MuiButtonBase-root": {
      color: theme.palette.table.contrastText,
    },
  },

  "& .MuiDataGrid-columnHeader": {
    "&:focus": {
      outline: "1px solid" + theme.palette.table.header,
    },
    "&:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-cell--pinnedRight, & .MuiDataGrid-filler--pinnedLeft, & .MuiDataGrid-filler--pinnedRight":
    {
      backgroundColor: theme.palette.background.paper,
    },
  "& .MuiDataGrid-cell": {
    padding: theme.spacing(1.2, 1.5),
    display: "flex",
    alignItems: "center",
  },
  "& .MuiDataGrid-cell a": {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  "& .MuiTablePagination-actions .MuiButtonBase-root": {
    color: theme.palette.secondary.main,
  },
  "& .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled": {
    color: alpha(theme.palette.text.disabled, 0.5),
  },
  "& .MuiDataGrid-container--bottom [role=row]": {
    boxShadow: "none",
    background: theme.palette.background.paper,
    fontWeight: 600,
  },
  "& .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
    {
      outline: "none",
    },
  "& .alterColumn-cell": {
    background: theme.palette.background.dark,
  },
  "& .MuiDataGrid-columnHeader:has(+ :not(.MuiDataGrid-columnHeader)):not(.MuiDataGrid-columnHeader--pinnedLeft) .MuiDataGrid-columnHeaderDraggableContainer":
    {
      flexDirection: "row-reverse",
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      },
    },
  "& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell:focus-within": {
    outlineWidth: 0.5,
    outlineColor: theme.palette.text.primary,
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-cell:last-child": {
      justifyContent: "flex-end",
      textAlign: "right",
      "& div": {
        textAlign: "right",
        justifyContent: "flex-end",
        display: "flex",
      },
    },
    "& .MuiDataGrid-cell:has(+ .MuiDataGrid-cell:not(.MuiDataGrid-cell--textLeft):not(.MuiDataGrid-cell--textRight):empty)":
      {
        textAlign: "right",
        justifyContent: "flex-end",
        "& div": {
          textAlign: "right",
          justifyContent: "flex-end",
        },
      },
    "& .MuiDataGrid-cell:has(+ .MuiDataGrid-scrollbarFiller)": {
      textAlign: "right",
      justifyContent: "flex-end",
      "& div": {
        textAlign: "right",
        justifyContent: "flex-end",
      },
    },
  },
});
export default StyledDataGrid;
