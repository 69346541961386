import { SvgIcon, Typography } from "@mui/material";
import React from "react";
import ReactComponent from "../assets/shi-square-logo.svg?react";

export function ShiSpinnerIcon({
  loadingText = "Loading...",
}: {
  loadingText?: string;
}) {
  return (
    <>
      <div>
        <SvgIcon
          style={{
            fontSize: "24px",
            animation: "spin 1.2s linear infinite",
          }}
          component={ReactComponent}
          inheritViewBox
        />
      </div>
      <div>
        <Typography variant={"h6"} style={{ fontWeight: 400 }}>
          {loadingText}
        </Typography>
      </div>
    </>
  );
}
