import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

export default function PasswordlessLogin({ targetPath }) {
  const { loginWithRedirect } = useAuth0();
  const { emailAddress } = useParams();

  loginWithRedirect({
    appState: {
      returnTo: targetPath,
    },
    authorizationParams: {
      shi_com_email: emailAddress,
    },
  }).then();
  return null;
}
