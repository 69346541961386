import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import {
  PageLayoutWidgetDto,
  ServiceRequestClient,
  ServiceRequestQueryType,
  SortOrder,
} from "@lib/ShiOneClient";
import {
  chipIt,
  getRequestStatusColor,
  linkToServiceRequest,
  standardizeDateFormat,
  useApi,
} from "../../utils";
import RequestsWidget from "@widgets/support/RequestsWidget";
import Box from "@mui/material/Box";
import {
  asDto,
  SkeletonWrapper,
  WidgetStylesType,
} from "shared-ui";
import { SortingOrder } from "../../../../components/mui-data-grid/constants";

const placeholderData = {
  totalCount: 5,
  serviceRequestDtos: [
    {
      id: 1,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 2,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 3,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 4,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 5,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
  ],
};

function getColumnDefs(
  loading: boolean,
  error: boolean,
  styles: WidgetStylesType
) {
  return {
    sortedColumns: [
      { field: "lastModifiedDate", sort: SortingOrder.Descending },
    ],
    columnDefinitions: [
      {
        id: "title",
        headerName: "Title",
        sortable: true,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <Box sx={styles.twoLineHeightCell}>
                {linkToServiceRequest(
                  params,
                  "/shi-internal/sample-dashboard-2"
                )}
              </Box>
            </SkeletonWrapper>
          );
        },
      },
      {
        id: "status",
        headerName: "Status",
        sortable: true,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              {chipIt(params, {
                backgroundColor: getRequestStatusColor(params.value),
                color: "white",
                borderColor: getRequestStatusColor(params.value),
              })}
            </SkeletonWrapper>
          );
        },
      },
      {
        id: "lastModifiedDate",
        headerName: "Last Updated",
        sortable: true,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <span>{standardizeDateFormat(params)}</span>
            </SkeletonWrapper>
          );
        },
      },
    ],
  };
}

const maxNumberOfRowsToFetch = 50;

const query = {
  query: {
    sortBy: "LastModifiedDate",
    sortOrder: SortOrder.Desc,
    termFilters: { ActiveOrComplete: "Active" },
    dateFilters: {},
    searchTerm: "",
    resultsFrom: 0,
    pageSize: maxNumberOfRowsToFetch,
  },
  serviceRequestQueryType: ServiceRequestQueryType.PrimaryContactRequests,
};

function useGetElasticServiceRequests() {
  const api = useApi(ServiceRequestClient);

  return useQuery(
    [`my-requests-widget`],
    // @ts-ignore
    () => api.searchServiceRequests(query),
    {
      placeholderData: asDto(placeholderData),
    }
  );
}

export default function MyRequestsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  return (
    <RequestsWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetElasticServiceRequests}
      columnDefinitionsFunction={getColumnDefs}
    />
  );
}
