import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ms from "ms";
import { useContext } from "react";
import { PbiContext } from "../components/PbiContext";
import { BookmarkDto, BookmarksDto } from "../../../../../customer-experience/src/lib/ShiOneClient";

export const cacheKey = "PowerBiBookmarks";
export const useGetBookmarks = (reportId: string) => {
  const { pbiBookmarksProps } = useContext(PbiContext);
  const { getBookmarks } = pbiBookmarksProps;
  return useQuery<BookmarksDto, Error>({
    queryKey: [cacheKey, reportId],
    queryFn: () => getBookmarks(reportId),
    staleTime: ms("10m"),
  });
};

export const useAddBookmark = () => {
  const { pbiBookmarksProps } = useContext(PbiContext);
  const { postBookmarks } = pbiBookmarksProps;

  const queryClient = useQueryClient();
  return useMutation<BookmarkDto, Error, BookmarkDto>({
    mutationFn: postBookmarks,
    onSuccess: (savedBookmark, newBookmark) => {
      queryClient.invalidateQueries([cacheKey, newBookmark.reportId]).then();
    },
  });
};

export const useUpdateBookmark = () => {
  const { pbiBookmarksProps } = useContext(PbiContext);
  const { putBookmarks } = pbiBookmarksProps;

  const queryClient = useQueryClient();
  return useMutation<void, Error, BookmarkDto>({
    mutationFn: putBookmarks,
    onSuccess: (rtnFn, updatedBookmark) => {
      queryClient
        .invalidateQueries([cacheKey, updatedBookmark.reportId])
        .then();
    },
  });
};

export const useDeleteBookmark = () => {
  const { pbiBookmarksProps } = useContext(PbiContext);
  const { deleteBookmarks } = pbiBookmarksProps;

  const queryClient = useQueryClient();
  return useMutation<void, Error, BookmarkDto>({
    mutationFn: deleteBookmarks,
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKey]).then();
    },
  });
};
