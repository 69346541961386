import React from "react";
import { useApi } from "../../utils";
import {
  DateFilterOption,
  ServiceRequestVisualsTypes,
  SupportRequestWidgetsClient,
} from "@lib/ShiOneClient";

import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { isLoading } from "@widgets/utils/widgetFunctions";
import { SupportRequestsWidgetHeaderAction } from "@widgets/utils/support/SupportRequestsWidgetHeaderAction";
import { Box, Button, Container } from "@mui/material";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { transformPieChartDtoWithLabelLink } from "shared-ui/src/utils/transformPieChartDto";
import { asDto, BaseWidgetProps, useSummaryData } from "shared-ui";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";

const filterKey = "open-support-requests-widget-filter";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    totalLabel: "Total Open Requests",
    totalCount: 0,
    slices: [],
  },
};

function useGetOpenSupportRequestsData(visualKey: ServiceRequestVisualsTypes) {
  const api = useApi(SupportRequestWidgetsClient);

  return useSummaryData({
    queryKey: [`open-support-requests-widget-${visualKey}`],
    apiFunction: () =>
      api.getSupportRequestsData(visualKey, DateFilterOption.None, true),
    transformFunction: (rawData) =>
      transformPieChartDtoWithLabelLink(
        rawData?.data,
        "Open Requests",
        prebuiltViewNames.openRequests,
        visualKey,
        undefined,
        ServiceRequestsPageTabs.allRequests
      ),
    placeholderData: asDto(placeholderData),
  });
}

function CardActions() {
  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          href={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.openRequests}#${ServiceRequestsPageTabs.allRequests}`
          )}
        >
          View Open Requests
        </Button>
      </Box>
    </Container>
  );
}

export default function OpenRequestsWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const initialVisualFilter = ServiceRequestVisualsTypes.ServiceRequestType;

  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      pageLayoutWidget.widgetId!,
      filterKey,
      initialVisualFilter
    );

  const responseFunc = useGetOpenSupportRequestsData(currentFilterValue);

  const isLoadingData = isLoading([
    isLoadingFilterUserConfig,
    responseFunc.isPlaceholderData,
  ]);

  return (
    <SummaryWidget
      headerAction={
        <SupportRequestsWidgetHeaderAction
          filterBy={currentFilterValue}
          setFilterBy={setFilter}
          loading={isLoadingData}
          filterKey={filterKey}
        />
      }
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => responseFunc}
      leftLabel={"Open Requests"}
      cardActions={<CardActions />}
    />
  );
}
