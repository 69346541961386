import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import { Page } from "powerbi-client";
import { usePowerBiReportPagesMenu } from "../hooks/usePowerBiReportPagesMenu";

export default function PowerBiReportPagesMenu({
  reportPages,
  collapsed,
}: {
  reportPages: Page[];
  collapsed: boolean;
}) {
  const { currentActivePage, handlePageClick } = usePowerBiReportPagesMenu();

  return (
    <Box>
      <List component="div" disablePadding>
        {reportPages?.map((page) => {
          const isActiveReport = currentActivePage?.name === page.name;
          return (
            <ListItemButton
              key={page.name}
              sx={[
                !collapsed
                  ? { pl: 6, justifyContent: "center" }
                  : { justifyContent: "center" },
                isActiveReport && { backgroundColor: "#f0f0f0" },
              ]}
              onClick={
                !isActiveReport ? () => handlePageClick(page) : undefined
              }
            >
              {!collapsed ? (
                <ListItemText
                  primary={page.displayName}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              ) : (
                <Tooltip title={page.displayName} placement="right-start">
                  <ArrowRightIcon fontSize="small" />
                </Tooltip>
              )}
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
