import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, AppDispatch } from "store";
import {
  ProjectListResponse,
  ProjectState,
  ProjectIndividualResponse,
} from "modules/rtk/project/projectTypes";

import { getProjects, getProjectById } from "api/projectStore";
import GlobalConstants from "@constants";

const initialState: ProjectState = {
  projectListFetchStatus: GlobalConstants.fetchStatus.idle,
  projectList: {
    errorMessage: "",
    projects: [],
    responseStatus: 0,
  },
  individualProjects: [],
};

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    receivingProjects(state) {
      state.projectListFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveProjects(state, action: PayloadAction<ProjectListResponse>) {
      state.projectList = action.payload;
      state.projectListFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveProject(state, action: PayloadAction<ProjectIndividualResponse>) {
      const existingProjectIndex = state.individualProjects.findIndex(
        (p) => p.projectId === action.payload.projectId
      );
      if (existingProjectIndex > -1) {
        state.individualProjects[existingProjectIndex] = action.payload;
      } else {
        state.individualProjects.push(action.payload);
      }
    },
  },
});

export const loadProjects = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(projectSlice.actions.receivingProjects());
  const projects = await getProjects();
  dispatch(projectSlice.actions.receiveProjects(projects));
};

export const loadProject =
  (projectId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    const pir: ProjectIndividualResponse = {
      fetchingStatus: GlobalConstants.fetchStatus.loading,
      projectId: projectId,
    };
    dispatch(projectSlice.actions.receiveProject(pir));
    const project = await getProjectById(projectId);
    project.fetchingStatus = GlobalConstants.fetchStatus.complete;
    dispatch(projectSlice.actions.receiveProject(project));
  };

export default projectSlice.reducer;
