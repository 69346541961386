import React, { useMemo } from "react";
import { useApi } from "../../utils";
import {
  AssetOverviewWidgetClient,
  AssetOverviewWidgetResponse,
  AssetWidgetBaseRecord,
} from "@lib/ShiOneClient";

import { useQuery } from "@tanstack/react-query";
import PercentageLine from "../../components/PercentageLine";
import SingleDataWithLabels from "../../components/SingleDataWithLabels";
import { Button, Divider } from "@mui/material";
import {
  assetTypesColors,
  categoryColors,
  contractCoverageWidgetColors,
  lifecycleStateColors,
} from "@features/assets-feature/utils/assetsColors";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import {
  BaseWidgetProps,
  graphColors,
  Summary,
  WidgetWrapper,
} from "shared-ui";
import LabelLink from "shared-ui/src/components/LabelLink";
import { shiOneUrl } from "@constants";

const assetInventoryLink = AppendIfPrefixExists(
  shiOneUrl.assets.assetInventory
);

const useGetAssetOverview = () => {
  const api = useApi(AssetOverviewWidgetClient);

  return useQuery<AssetOverviewWidgetResponse>({
    queryKey: ["asset-overview-widget"],
    queryFn: () => api.getAssetsOverviewWidgetData(),
  });
};

function CardActions() {
  return <Button href={assetInventoryLink}>View Asset Inventory</Button>;
}

export default function AssetsOverviewWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const containerStyle = { padding: "16px" };
  const { data, isLoading } = useGetAssetOverview();

  const totalAssetsCount: number = useMemo(() => {
    return data?.totalProducts ?? 0;
  }, [data?.productTypes]);

  // @ts-ignore
  const formattedAssetTypes: Summary[] = data?.productTypes?.map(
    (item: AssetWidgetBaseRecord, index: number) => ({
      name: "AssetTypes",
      data: item.assetsCount ?? 0,
      label: item.category ? item.category : "Unknown",
      customLabel: (
        <LabelLink
          label={item.category ? item.category : "Unknown"}
          baseUrl={assetInventoryLink}
          css={{ fontWeight: "normal" }}
          state={{ assetType: item.category, includeChildren: true }}
        />
      ),
      color:
        assetTypesColors[
          item.category ? item.category?.toLowerCase() : "unknown"
        ] ?? graphColors[index + 1],
    })
  );

  // @ts-ignore
  const formattedAssetCategories: Summary[] = data?.productCategories?.map(
    (item: AssetWidgetBaseRecord) => ({
      name: "AssetCategories",
      data: item.assetsCount ?? 0,
      label: item.category ? item.category : "Unknown",
      customLabel: (
        <LabelLink
          label={item.category ? item.category : "Unknown"}
          baseUrl={assetInventoryLink}
          css={{ fontWeight: "normal" }}
          state={{ shiCategory: item.category, includeChildren: true }}
        />
      ),
      color:
        categoryColors[
          item.category ? item.category?.toLowerCase() : "unknown"
        ],
    })
  );

  // @ts-ignore
  const formattedLifecycle: Summary[] = useMemo(() => {
    const sortOrder: { [key: string]: number } = {
      "General Availability": 1,
      "End of Life": 2,
      "End of Sale": 3,
      "End of Software Maintenance": 4,
      "End of Support": 5,
      Unknown: 6,
    };

    return data?.lifecycleStates
      ?.sort(
        (a: AssetWidgetBaseRecord, b: AssetWidgetBaseRecord) =>
          sortOrder[a.category ?? ""] - sortOrder[b.category ?? ""]
      )
      .map((item: AssetWidgetBaseRecord) => ({
        name: "lifecycleStates",
        data: item.assetsCount ?? 0,
        label: item.category ? item.category : "Unknown",
        customLabel: (
          <LabelLink
            label={item.category ? item.category : "Unknown"}
            baseUrl={assetInventoryLink}
            css={{ fontWeight: "normal" }}
            state={{ shiLifecycleState: item.category, includeChildren: true }}
          />
        ),
        color:
          lifecycleStateColors[
            item.category ? item.category?.toLowerCase() : "unknown"
          ],
      }));
  }, [data?.lifecycleStates]);

  const formattedContractCoverage: Summary[] =
    data?.contractCoverage?.map(
      (item: AssetWidgetBaseRecord, index: number) => ({
        name: "contractCoverage",
        data: item.assetsCount ?? 0,
        label: item.category ? item.category : "Unknown",
        customLabel: (
          <LabelLink
            label={item.category ? item.category : "Unknown"}
            baseUrl={assetInventoryLink}
            css={{ fontWeight: "normal" }}
            state={{ contractCoverage: index, includeChildren: true }}
          />
        ),
        color: contractCoverageWidgetColors[index],
      })
    ) ?? ([] as Summary[]);

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      cardHeaderDivider
      noPadding={true}
      cardActions={<CardActions />}
    >
      <>
        <SingleDataWithLabels
          data={totalAssetsCount}
          label={"Total Assets"}
          loading={isLoading}
          containerStyle={containerStyle}
        />
        <Divider />

        <PercentageLine
          visualParts={formattedAssetTypes ?? []}
          loading={isLoading}
          leftLabel={"Types"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
        <PercentageLine
          visualParts={formattedAssetCategories ?? []}
          loading={isLoading}
          leftLabel={"Categories"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
        <PercentageLine
          visualParts={formattedLifecycle ?? []}
          loading={isLoading}
          leftLabel={"Lifecycle States"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
        <PercentageLine
          visualParts={formattedContractCoverage ?? []}
          loading={isLoading}
          leftLabel={"Contract Coverage"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
      </>
    </WidgetWrapper>
  );
}
