import { useApi } from "../../../utils";
import { ProjectClient, ProjectIndividualResponse } from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";

const useGetProjectById = (projectId: number) => {
  const isValidProjectId = !isNaN(projectId);
  const api = useApi(ProjectClient);

  const loadProject = async () => {
    return await api.getProjectById(projectId);
  };

  return useQuery<ProjectIndividualResponse, Error>({
    queryKey: [`project-details-${projectId}`],
    queryFn: loadProject,
    enabled: isValidProjectId,
  });
};

export default useGetProjectById;
