import { Icon } from "@mui/material";
import React from "react";
import { NavItemType } from "../../NavigationTypes";
import { ciscoItemsToShowType } from "../../NavItemsToShowTypes";

export function CiscoMenu(
  ciscoItemsToShow: ciscoItemsToShowType,
  assetsV2Enabled: boolean
): NavItemType {
  return {
    id: "cisco",
    type: "Group",
    displayName: "Cisco",
    icon: <Icon className={"fas fa-network-wired"} />,
    visible: !assetsV2Enabled && ciscoItemsToShow.showCisco,
    children: [
      {
        id: "ciscoOverviewReport",
        type: "Link",
        displayName: "Overview",
        path: "/cisco/overview",
        visible: true,
      },
      {
        id: "ciscoFullInventoryReport",
        type: "Link",
        displayName: "Full Inventory",
        path: "/cisco/full-inventory",
        visible: true,
      },
      {
        id: "ciscoLifecycleReport",
        type: "Link",
        displayName: "Life Cycle",
        path: "/cisco/lifecycle-report",
        visible: true,
      },
      {
        id: "ciscoContracts",
        type: "Link",
        displayName: "Active Contracts",
        path: "/cisco/contracts",
        visible: true,
      },
      {
        id: "ciscoSiteLocationReport",
        type: "Link",
        displayName: "Site Location",
        path: "/cisco/site-location-report",
        visible: true,
      },
    ],
  };
}
