import React, { useEffect, useState } from "react";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountNotifications,
  selectAccountNotifications,
} from "../modules/rtk/accountAdmin/accountAdminSlice";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WarningIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Report";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SignOutMenuItem from "./SignOutMenuItem";
import CloseIcon from "@mui/icons-material/Close";
import ForwardNavLink from "./ForwardRefNavLink";
import GlobalConstants from "@constants";
import { useNavHamburger } from "../containers/useNavHamburger";
import { useFeatureFlag } from "shared-ui/src/utils/FeatureFlagHelper";
import SwitchThemeButton from "shared-ui/src/components/SwitchThemeButton";
import { useThemeMode } from "shared-ui/src/theme/ThemeModeProviderContext";
import AccountImpersonation from "../v2/src/components/AccountImpersonation";
import {
  clearImpersonate,
  isImpersonatingAccount,
  isImpersonatingUser,
} from "shared-ui/src/utils/clientmanagement";
import ShiOneLogo from "shared-ui/src/components/ShiOneLogo";

const css = (theme) => ({
  root: {
    height: "50px",
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 1, 0, 0.5),
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    gap: theme.spacing(3),
    alignItems: "center",
  },
  menu: {
    "& > .MuiList-root": {
      padding: "0 !important",
    },
  },
  menuButton: {
    color: theme.palette.text.primary,
    display: "flex",
    gap: theme.spacing(2),
  },
  menuHeader: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(1.5),
    borderBottom: "solid 1px " + theme.palette.border,
    "& > .MuiButtonBase-root": {
      marginLeft: theme.spacing(1),
      "&:first-child": {
        marginLeft: 0,
      },
    },
  },
  menuSmall: {
    minWidth: 250,
  },
  menuLarge: {
    minWidth: 500,
  },
  info: {
    flex: "1 auto",
    flexWrap: "wrap",
    overflow: "hidden",
    padding: "0 !important",
    marginLeft: theme.spacing(1),
  },
  menuIcon: {
    minWidth: theme.spacing(4),
  },
  menuBadge: {
    marginRight: theme.spacing(1),
  },
  menuAction: {
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(1),
  },

  notifFeature: {
    color: theme.palette.warning.main,
  },
  notifWarning: {
    color: theme.palette.error.main,
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuSize: {
    "& .MuiPaper-root": {
      maxWidth: 500,
    },
  },
  whiteSpace: {
    whiteSpace: "normal",
  },
  action: {
    borderRight: ".5px solid" + theme.palette.border,
    width: "245px",
    [theme.breakpoints.down("lg")]: {
      borderRight: "none !important",
    },
  },
  serviceMenuItem: {
    color: theme.palette.text.primary,
    fontWeight: "400",
    fontSize: "0.875rem",
    letterSpacing: "0.01071em",
    lineHeight: "1.5",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  closeIcon: {
    height: "40px",
    width: "40px",
    margin: "auto 0",
    color: theme.palette.secondary,
  },
});

export default function Header() {
  const theme = useTheme();
  const classes = css(theme);
  const { handleHamburgerClick } = useNavHamburger();
  const { userProfileResponse, portalRole } = useSelector(
    (state) => state.profile
  );
  const shiProfile = userProfileResponse.shiProfile;
  const accountNotifications = useSelector(selectAccountNotifications);

  const { enabled: themeSwitcherEnabled } = useFeatureFlag(
    "shione.enable-v2-theme-switcher"
  );
  const contact = userProfileResponse.userProfile.contact;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openUserProfileMenu, setOpenUserProfileMenu] = useState(false);
  const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  const [numOfNotifications, setNumOfNotifications] = useState(0);

  const open = Boolean(anchorEl);
  const isLocalShi =
    userProfileResponse.userProfile?.contact?.accountID === 676;
  const isShiUser = isLocalShi || userProfileResponse.shiProfile;

  useEffect(() => {
    dispatch(getAccountNotifications());
  }, []);

  useEffect(() => {
    if (accountNotifications) {
      const isLocalShi =
        userProfileResponse.userProfile.contact.accountID ===
        GlobalConstants.shiAutotaskAccountId;
      const isShi = isLocalShi || shiProfile;

      let newNumOfNotifications = 0;
      Object.entries(accountNotifications).forEach((v) => {
        if (v[0] === "teamsEnabled" && !v[1] && !isShi) {
          newNumOfNotifications++;
        } else if (v[0] !== "teamsEnabled" && v[1] > 0) {
          newNumOfNotifications++;
        }
      });
      if (numOfNotifications !== newNumOfNotifications) {
        setNumOfNotifications(newNumOfNotifications);
      }
    }
  }, [accountNotifications]);

  useEffect(() => {
    dispatch(getAccountNotifications());
  }, []);

  const showAdminNotifications = () => {
    if (portalRole === "admin") {
      return (
        <>
          <IconButton
            disabled={numOfNotifications === 0}
            size="small"
            aria-controls="notificationsMenu"
            aria-haspopup="true"
            onClick={handleClick}
            data-telemetry-name="Show Notifications Menu"
          >
            <Badge
              badgeContent={numOfNotifications}
              color="secondary"
              overlap="rectangular"
              data-telemetry-name="Show Notifications Menu"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            id="notificationsMenu"
            data-telemetry-name="Hide Notifications Menu"
            anchorEl={anchorEl}
            open={openNotificationsMenu && open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <MenuList>
              {unassignedCloudAccounts()}
              {usersNeedSecurityGroup()}
              {usersAwaitApproval()}
            </MenuList>
          </Popover>
        </>
      );
    }
    return null;
  };

  const usersAwaitApproval = () => {
    if (accountNotifications?.usersAwaitingApproval > 0) {
      return (
        <MenuItem
          component={ForwardNavLink}
          to={AppendIfPrefixExists("/settings/user-management")}
        >
          <ListItemIcon sx={[classes.menuIcon, classes.notifFeature]}>
            <WarningIcon />
          </ListItemIcon>
          <div style={classes.info}>
            <Typography component="div" variant="body2" sx={classes.whiteSpace}>
              There are new users that are awaiting for approval
            </Typography>
            <Typography component="div" noWrap variant="caption">
              {accountNotifications?.usersAwaitingApproval} users
            </Typography>
          </div>
        </MenuItem>
      );
    }
    return null;
  };

  const handleClick = (event) => {
    const { currentTarget } = event;
    const name = currentTarget.getAttribute("aria-controls")?.toLowerCase();

    switch (name) {
      case "notificationsmenu":
        setAnchorEl(currentTarget);
        setOpenNotificationsMenu(true);
        break;
      case "userprofilemenu":
        setAnchorEl(currentTarget);
        setOpenUserProfileMenu(true);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpenNotificationsMenu(false);
    setOpenUserProfileMenu(false);
    setAnchorEl(null);
  };

  // NOTE: Nowhere in the webapi or in the frontend besides here can I find any objects with 'usersWithOutGroups'
  const usersNeedSecurityGroup = () => {
    if (accountNotifications?.usersWithoutGroups > 0) {
      return (
        <MenuItem
          component={ForwardNavLink}
          to={AppendIfPrefixExists("/settings/team-management")}
        >
          <ListItemIcon sx={[classes.menuIcon, classes.notifFeature]}>
            <WarningIcon />
          </ListItemIcon>
          <div style={classes.info}>
            <Typography component="div" variant="body2" sx={classes.whiteSpace}>
              There are users that are not assigned to a Security Group
            </Typography>
            <Typography component="div" noWrap variant="caption">
              {accountNotifications?.usersWithoutGroups} users
            </Typography>
          </div>
        </MenuItem>
      );
    }
    return null;
  };

  const unassignedCloudAccounts = () => {
    if (
      accountNotifications?.contractsWithoutGroups > 0 ||
      accountNotifications?.cloudAccountsWithoutGroups > 0
    ) {
      return (
        <MenuItem
          component={ForwardNavLink}
          to={AppendIfPrefixExists("/settings/team-management")}
        >
          <ListItemIcon sx={[classes.menuIcon, classes.notifWarning]}>
            <ReportIcon />
          </ListItemIcon>
          <div style={classes.info}>
            <Typography component="div" variant="body2" sx={classes.whiteSpace}>
              There are unassigned contracts/cloud accounts
            </Typography>
            <Typography component="div" variant="caption">
              {accountNotifications?.contractsWithoutGroups > 0 && (
                <>
                  {accountNotifications?.contractsWithoutGroups} contracts
                  <br />
                </>
              )}
              {accountNotifications?.cloudAccountsWithoutGroups > 0 && (
                <>
                  {accountNotifications?.cloudAccountsWithoutGroups} cloud
                  accounts
                  <br />
                </>
              )}
            </Typography>
          </div>
        </MenuItem>
      );
    }
    return null;
  };

  return (
    <AppBar position={"fixed"} elevation={1} color="inherit">
      <Toolbar
        variant="dense"
        sx={classes.root}
        id="back-to-top-anchor"
        disableGutters
      >
        <div style={classes.container}>
          <Box sx={classes.action}>
            <IconButton
              id={"shiHamburgerButton"}
              onClick={handleHamburgerClick}
              data-telemetry-name="Main Menu Toggle"
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <ShiOneLogo />
          </Box>
          {!isImpersonatingUser() && (
            <div
              data-telemetry-name="Account Impersonation"
              style={{ display: "flex", width: "46%", gap: 8 }}
            >
              {isShiUser && import.meta.env.DIST_ENV !== "demo" && (
                <AccountImpersonation />
              )}
              {isImpersonatingAccount() && (
                <Tooltip title={"Return to SHI Portal"}>
                  <IconButton
                    sx={classes.closeIcon}
                    onClick={() => clearImpersonate()}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        <div style={classes.menuButton}>
          {themeSwitcherEnabled && (
            <SwitchThemeButton useThemeMode={useThemeMode} />
          )}
          {showAdminNotifications()}
          <IconButton
            size="small"
            aria-controls="userProfileMenu"
            aria-haspopup="true"
            onClick={handleClick}
            data-telemetry-name="Show User Profile Menu"
          >
            <Avatar data-telemetry-name="Show User Profile Menu">
              {contact.firstName.toUpperCase().charAt(0)}
              {contact.lastName.toUpperCase().charAt(0)}
            </Avatar>
          </IconButton>
          <Menu
            id="userProfileMenu"
            data-telemetry-name="Hide User Profile Menu"
            anchorEl={anchorEl}
            open={openUserProfileMenu && open}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            MenuListProps={{
              disablePadding: true,
            }}
          >
            <Box sx={[classes.menuHeader, classes.menuSmall]}>
              <Avatar sx={classes.avatar}>
                {contact.firstName.toUpperCase().charAt(0)}
                {contact.lastName.toUpperCase().charAt(0)}
              </Avatar>
              <div style={classes.info}>
                <Typography component="div" noWrap>
                  {contact.firstName} {contact.lastName}
                </Typography>
                <Typography component="div" noWrap variant="caption">
                  {contact.eMailAddress}
                </Typography>
              </div>
            </Box>
            <MenuList component="nav">
              <MenuItem
                component={ForwardNavLink}
                to={AppendIfPrefixExists("/userprofile")}
                name="user-profile-button"
              >
                <ListItemIcon sx={classes.menuIcon}>
                  <PersonIcon />
                </ListItemIcon>
                <span>My Profile</span>
              </MenuItem>
            </MenuList>
            <MenuList component="nav">
              <MenuItem
                component={ForwardNavLink}
                to={AppendIfPrefixExists("/organizationprofile")}
                name="user-profile-button"
              >
                <ListItemIcon sx={classes.menuIcon}>
                  <BusinessIcon />
                </ListItemIcon>
                <span>Organization Profile</span>
              </MenuItem>
            </MenuList>
            <SignOutMenuItem classes={classes} MenuItem={MenuItem} />
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
