import {
  AXAccountBillingUsersDTO,
  AXAccountDetailsDTO,
  AXBillingAddressDTO,
  ServiceRequestAccountLocationDTO,
  ServiceRequestCloudTypes,
  ServiceRequestContactDTO,
  ServiceRequestTemplateClient,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new ServiceRequestTemplateClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestContactsWidgetData(
  accountId: number
): Promise<ServiceRequestContactDTO[]> {
  return api.getAvailableContacts(accountId);
}

export async function getRestLocationsWidgetData(
  accountId: number
): Promise<ServiceRequestAccountLocationDTO[]> {
  return api.getAvailableAccountLocations(accountId);
}

export async function getRestAxAccountDetails(
  axAccountNumber: string
): Promise<AXAccountDetailsDTO> {
  return api.getShiAccountDetails(axAccountNumber);
}

export async function getRestAxAccountBillingAddressesByCloudType(
  cloudType: ServiceRequestCloudTypes,
  axAccountNumber: string
): Promise<AXBillingAddressDTO> {
  return api.getShiBillingAddressesByAxAccountNumberAndCloudType(
    axAccountNumber,
    cloudType
  );
}

export async function getRestAxAccountBillingAddresses(
  axAccountNumber: string
): Promise<AXBillingAddressDTO> {
  return api.getShiBillingAddressesByAxAccountNumber(axAccountNumber);
}

export async function getRestAllAxBillingContacts(
  axAccountNumber: string
): Promise<AXAccountBillingUsersDTO[]> {
  return api.getAllBillingUsersByAxAccountNumber(axAccountNumber);
}
