import React, { Fragment, useEffect, useState } from "react";
import Navigation from "../v2/src/components/Navigation";
import Loading from "../components/Loading";
import Header from "../components/Header";
import PropTypes from "prop-types";
import {
  clearImpersonate,
  getImpersonate,
  getImpersonateAccount,
  impersonateUser,
  isImpersonatingUser,
} from "shared-ui/src/utils/clientmanagement";
import AuthError from "./AuthError";
import GlobalConstants from "@constants";
import { isValidShiProfile } from "../functions/shiUserCheck";
import ReactRouterClassWrapper from "../components/ReactRouterClassWrapper";
import { Button, Chip, Icon, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMyUserProfile } from "../modules/rtk/profile/profileSlice";
import { getPicklist } from "../modules/picklist/actions/picklistActions";
import { useNavHamburger } from "./useNavHamburger";
import {
  getIntegrationsCollection,
  getIntegrationsDefinitions,
} from "../modules/rtk/integrationsCollector/integrationsCollectorSlice";
import { getMyDevices } from "../modules/rtk/device/deviceSlice";
import { getMyTeams } from "../modules/rtk/team/teamSlice";
import { getFSAs } from "../modules/rtk/contract/contractSlice";
import { loadProjects } from "../modules/rtk/project/projectSlice";
import { getAccounts } from "../modules/rtk/accountAdmin/accountAdminSlice";
import { getMonitoringAlerts } from "../modules/rtk/alert/alertSlice";
import {
  getMyAdditionalContactRequests,
  getMyServiceRequests,
} from "../modules/rtk/serviceRequest/serviceRequestSlice";
import { getKnowledgebase } from "../modules/knowledgebase/actions/knowledgebaseActions";
import {
  getAccountsOverview,
  getCloudAccountsOverview,
} from "../modules/rtk/clientManagement/clientManagementSlice";
import { AppendIfPrefixExists, SitePaths } from "../modules/sitePaths";
import {
  parseInvalidNewURLWithSitePrefix,
  parseNewURLWithSitePrefix,
} from "../functions/getCorrectURLBasedOnSitePrefix";
import { getSite } from "../modules/site/actions/siteActions";
import { getMyTicketsInitial } from "../modules/ticket/actions/ticketActions";
import shiColors from "shared-ui/src/theme/shiColors";
import MainContainer from "shared-ui/src/components/MainContainer";

const Content = (props) => {
  const { isOpenSideBar, isDesktop, children } = props;
  return (
    <MainContainer isDrawerOpen={isOpenSideBar && isDesktop}>
      {children}
    </MainContainer>
  );
};

const impersonationTimer = ({ userProfileResponse }) => {
  const { shiProfile } = userProfileResponse || {};

  const user = getImpersonate();
  const account = getImpersonateAccount();

  const isShi =
    isValidShiProfile(shiProfile) ||
    (userProfileResponse?.userProfile?.contact &&
      userProfileResponse?.userProfile?.contact?.accountID ===
        GlobalConstants.shiAutotaskAccountId);

  if (account && userProfileResponse?.userProfile?.contact?.accountID) {
    if (
      account &&
      userProfileResponse.userProfile.contact.accountID !== Number(account) &&
      isShi
    ) {
      window.location.href = window.location.origin;
    }
  } else if (user && userProfileResponse?.userProfile?.contract?.id) {
    if (Number(user) !== userProfileResponse.userProfile.contact.id && isShi) {
      window.location.href = window.location.origin;
    }
  } else if (
    user === null &&
    account === null &&
    isValidShiProfile(shiProfile)
  ) {
    clearImpersonate();
    window.location.href = window.location.origin;
  }
};

function getURLParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

const demoUserIds = {
  everything: 30691967,
  cloud: 30691968,
  asset: 30692540,
};

export default function Dashboard({ children }) {
  const dispatch = useDispatch();
  const [initialDataLoad, setInitialDataLoad] = useState(false);
  const paramValue = getURLParameter("demo");
  const { isDesktop, isOpenSideBar } = useNavHamburger();

  const { labelInfo, fetchingSite, fetchSiteComplete } = useSelector(
    (state) => state.site
  );

  const userProfileResponse = useSelector(
    (state) => state.profile.userProfileResponse
  );
  const profileFetchStatus = useSelector(
    (state) => state.profile.profileFetchStatus
  );

  const {
    fetchingTickets,
    fetchTicketsComplete,
    fetchTicketsError,
    checkingForDeletedTickets,
    checkingForDeletedTicketsComplete,
  } = useSelector((state) => state.ticket);

  const portalRole = useSelector((state) => state.profile.portalRole);
  const { sitePrefix, userProfile, shiProfile } = userProfileResponse;

  const { picklist, fetchingPicklist } = useSelector((state) => state.picklist);

  const userProfileLoaded =
    profileFetchStatus === GlobalConstants.fetchStatus.complete &&
    userProfileResponse
      ? Object.keys(userProfileResponse).length !== 0
      : false;
  const hasUserProfile =
    userProfileLoaded && userProfileResponse.responseStatus === 1;
  const noUserProfile =
    userProfileLoaded && userProfileResponse.responseStatus !== 1;
  const startInitialDataLoad = hasUserProfile && !initialDataLoad;

  useEffect(() => {
    if (hasUserProfile && import.meta.env.DIST_ENV === "demo") {
      const userId = demoUserIds[paramValue] || demoUserIds.everything;
      if (localStorage.getItem("impersonateUser") !== userId.toString()) {
        impersonateUser(userId, true);
      }
    }
  }, [paramValue, hasUserProfile]);

  useEffect(() => {
    dispatch(getMyUserProfile());
  }, []);

  useEffect(() => {
    if (startInitialDataLoad) {
      setInitialDataLoad(true);
      dispatch(getPicklist());
      dispatch(getIntegrationsCollection());
      dispatch(getIntegrationsDefinitions());
      dispatch(getMyDevices());
      dispatch(getMyTeams());
      dispatch(getFSAs());
      dispatch(getAccounts());
      dispatch(getMonitoringAlerts());
      dispatch(getMyServiceRequests());
      dispatch(getMyAdditionalContactRequests());
      dispatch(getKnowledgebase());
      dispatch(getAccountsOverview());
      dispatch(getCloudAccountsOverview());

      if (
        !fetchingTickets &&
        fetchTicketsComplete &&
        !fetchTicketsError &&
        !checkingForDeletedTickets &&
        !checkingForDeletedTicketsComplete
      ) {
        dispatch(getMyTicketsInitial());
      }
      const intervalId = setInterval(
        () => impersonationTimer(userProfileResponse),
        5000
      );
      return () => clearInterval(intervalId);
    } else if (noUserProfile) {
      clearImpersonate();
      window.location = AppendIfPrefixExists("/noprofile");
    }
  }, [startInitialDataLoad, noUserProfile]);

  useEffect(() => {
    const pathNames =
      window.location.pathname[0] === "/"
        ? window.location.pathname.substr(1).split("/")
        : window.location.pathname.split("/");
    const isBaseSite = SitePaths.includes(pathNames[0]);

    // do not need this unless we go back to subdomains...
    const url = new URL(window.location.href);
    // if we're not in a [customer path] already, let's check to see if we should be and redirect accordingly
    if (isBaseSite && sitePrefix) {
      window.location = parseNewURLWithSitePrefix(url, sitePrefix);
    }

    // if we're in a [customer path], but we're on the wrong prefix, let's inform the user of their error
    if (
      profileFetchStatus === "complete" &&
      pathNames[1] !== "noprofile" &&
      ((sitePrefix && !isBaseSite && sitePrefix !== pathNames[0]) ||
        (!sitePrefix && !isBaseSite))
    ) {
      const newUrl = parseInvalidNewURLWithSitePrefix(url, sitePrefix);
      if (window.location.href !== newUrl.toString()) {
        window.location = newUrl;
      }
    }

    if (sitePrefix && !fetchingSite && !fetchSiteComplete) {
      dispatch(
        getSite({
          prefix: sitePrefix,
        })
      );
    }
  }, [sitePrefix, fetchingSite, fetchSiteComplete]);

  useEffect(() => {
    if (portalRole === GlobalConstants.portalRole.admin) {
      dispatch(loadProjects());
    }
  }, [userProfileResponse]);

  const pathNames =
    window.location.pathname[0] === "/"
      ? window.location.pathname.substr(1).split("/")
      : window.location.pathname.split("/");

  if (sitePrefix && sitePrefix !== pathNames[0]) {
    return <Loading />;
  }

  if (
    !userProfile ||
    profileFetchStatus !== "complete" ||
    !picklist ||
    fetchingPicklist
  ) {
    if (profileFetchStatus === "error") {
      return (
        <ReactRouterClassWrapper>
          {(props) => <AuthError labelInfo={labelInfo} {...props} />}
        </ReactRouterClassWrapper>
      );
    }

    return <Loading />;
  }
  return (
    <div>
      {isImpersonatingUser() && import.meta.env.DIST_ENV !== "demo" && (
        <Fragment>
          <div className="header-alert">
            <span data-telemetry-name="Impersonation Account Name">
              {userProfile.givenName +
                " " +
                userProfile.surname +
                " (" +
                userProfile.accountName +
                ")"}
            </span>
            <Tooltip title="Return to SHI Portal">
              <Button
                sx={{
                  top: "-1px",
                  opacity: 0.66,
                  padding: "9px 8px 8px 8px",
                  position: "relative",
                  minWidth: 0,
                  boxShadow: "none",
                  borderRadius: 0,
                }}
                variant="contained"
                color="secondary"
                onClick={() => clearImpersonate()}
                size="small"
                data-telemetry-name="Return to SHI Portal"
              >
                <Icon className={"fas fa-undo"} fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        </Fragment>
      )}
      {isValidShiProfile(shiProfile) &&
        import.meta.env.DIST_ENV === "demo" && (
          <Fragment>
            <div
              style={{
                position: "fixed",
                zIndex: 9999999,
                left: "50%",
                transform: "translateX(-50%)",
                top: "7px",
              }}
            >
              <Chip
                sx={{
                  backgroundColor: shiColors.blue.DEFAULT,
                  color: shiColors.white,
                }}
                variant="extended"
                label={"Demo In Progress"}
              />
            </div>
          </Fragment>
        )}
      <Header />
      <Navigation />
      <Content isDesktop={isDesktop} isOpenSideBar={isOpenSideBar}>
        {children}
      </Content>
    </div>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node,
};
