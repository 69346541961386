import React, { useEffect } from "react";
import PowerBiBookmarks from "./PowerBiBookmarks";
import { ActiveReportStore, EmbeddedPowerBiStore } from "../../stores";
import { Button } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useAddBookmark, useDeleteBookmark, useGetBookmarks, useUpdateBookmark } from "../../hooks";
import { ReportDialog } from "../ReportDialog";
import { FiltersOperations, IFilter } from "powerbi-models";
import { BookmarkDto, BookmarkTypes } from "../../../../../../customer-experience/src/lib/ShiOneClient";

const ViewBookmarks = () => {
  const embeddedReport = ActiveReportStore((s) => s.activeEmbeddedReport);

  const ActiveReportFilterDate = ActiveReportStore(
    (s) => s.activeReportFilterDate
  );

  const ActiveReportParams = ActiveReportStore((s) => s.activeReportParams);

  const [defaultBookmark] = EmbeddedPowerBiStore((s) => [
    s.defaultBookmark,
    s.setDefaultBookmark,
  ]);

  // @ts-ignore
  const bookmarksManager = embeddedReport?.bookmarksManager;
  // @ts-ignore
  const reportId = embeddedReport?.config?.id;

  const { data: bookmarks } = useGetBookmarks(reportId);
  const addBookmarkMutation = useAddBookmark();
  const updateBookmarkMutation = useUpdateBookmark();
  const deleteBookmarkMutation = useDeleteBookmark();
  const [isVisible, setIsVisible] = React.useState(false);
  const [currentActiveBookmark, setCurrentActiveBookmark] =
    React.useState<BookmarkDto>();


  useEffect(() => {
    return () => setCurrentActiveBookmark(undefined);
  }, [embeddedReport]);

  const handleAddBookmark = async (name: string, type: BookmarkTypes) => {
    if (embeddedReport) {
      const capturedBookmark = await bookmarksManager?.capture({
        personalizeVisuals:
          ActiveReportParams?.bookmarkPersonalizedVisual ?? false,
        allPages: true,
      });

      // @ts-ignore
      const reportId =   embeddedReport.config.id;

      const bookmark  = new BookmarkDto( );
      bookmark.reportId = reportId
      bookmark.value = capturedBookmark?.state;
      bookmark.name = name;
      bookmark.bookmarkType = type;

      addBookmarkMutation.mutate(bookmark);
    }
  };

  const applyBookmark = async (activeBookmark: BookmarkDto) => {
    if (bookmarksManager && activeBookmark?.value) {
      await bookmarksManager.applyState(activeBookmark?.value);
      setCurrentActiveBookmark(activeBookmark);
    }
    if (ActiveReportFilterDate) {
      await embeddedReport?.updateFilters(FiltersOperations.Replace, [
        ActiveReportFilterDate.toJSON(),
      ] as IFilter[]);
    }
  };

  const handleDeleteBookmark = async (bookmark: BookmarkDto) => {
    deleteBookmarkMutation.mutate(bookmark);
    if (currentActiveBookmark?.bookmarkId === bookmark.bookmarkId && defaultBookmark) {
      const bookmark = new BookmarkDto({
        value: defaultBookmark.state.toString(),
      });
      await applyBookmark(bookmark);
    }
  };

  const handleUpdateBookmark = async (bookmark: BookmarkDto) => {
    const capturedBookmark = await bookmarksManager?.capture({
      personalizeVisuals:
        ActiveReportParams?.bookmarkPersonalizedVisual ?? false,
      allPages: true,
    });
    bookmark.value = capturedBookmark?.state;
    updateBookmarkMutation.mutate(bookmark)

    if (currentActiveBookmark?.bookmarkId === bookmark.bookmarkId) {
      const updatedBookmark = new BookmarkDto({
        value: bookmark.value,
        name:  bookmark.name
      });
      await applyBookmark(updatedBookmark);
    }
  };

  const handleApplyBookmark = async (bookmark: BookmarkDto) => {
    setIsVisible(false);
    await applyBookmark(bookmark);
  };

  const handleReset = async () => {
    if (!defaultBookmark) return;
    setIsVisible(false);
    const bookmark = new BookmarkDto({
      value: defaultBookmark.state.toString(),
    });
    await applyBookmark(bookmark);
  };

  const handleSetVisible = async () => {
    setIsVisible(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<Visibility />}
        onClick={handleSetVisible}
      >
        {currentActiveBookmark?.name ? currentActiveBookmark.name : "VIEWS"}
      </Button>
      <ReportDialog
        dialogTitle="Views"
        openDialog={isVisible}
        closeDialog={() => setIsVisible(false)}
      >
        <PowerBiBookmarks
          bookmarks={bookmarks}
          handleApplyBookmark={handleApplyBookmark}
          handleReset={handleReset}
          addBookmark={handleAddBookmark}
          deleteBookmark={handleDeleteBookmark}
          updateBookmark={handleUpdateBookmark}
        />
      </ReportDialog>
    </>
  );
};
export default ViewBookmarks;
