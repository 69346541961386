import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import {
  contractState,
  CustomerAccountContracts,
  CustomerAccountContractsFetchStatus,
} from "./contractTypes";
import {
  getRestAccountContracts,
  getRestCloudContracts,
  getRestContractById,
  getRestContractOverview,
  getRestFSAs,
  getRestSLAs,
} from "../../../api/contractStore";
import {
  AccountContractRequest,
  AccountContractResponse,
  CloudAccount,
  ContractDTO,
  ContractOverview,
  ContractSLA,
  FSACacheResponse,
} from "lib/ShiOneClient";
import GlobalConstants from "@constants";

const initialState: contractState = {
  contractFetchStatus: GlobalConstants.fetchStatus.idle,
  contractOverviewFetchStatus: GlobalConstants.fetchStatus.idle,
  contract: {} as ContractDTO,
  contractOverview: {} as ContractOverview,
  accountContractsFetchStatus: GlobalConstants.fetchStatus.idle,
  accountContracts: {} as AccountContractResponse,
  customerAccountContractsFetchStatus:
    {} as CustomerAccountContractsFetchStatus,
  customerAccountContracts: {} as CustomerAccountContracts,
  cloudContractsFetchStatus: GlobalConstants.fetchStatus.idle,
  cloudContracts: [],
  contractSLAsFetchStatus: GlobalConstants.fetchStatus.idle,
  contractSLAs: [],
  flexibleServiceAgreementsFetchStatus: GlobalConstants.fetchStatus.idle,
  flexibleServiceAgreements: {} as FSACacheResponse,
};

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    receivingContract(state) {
      state.contractFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedContract(state, action: PayloadAction<ContractDTO>) {
      state.contract = action.payload;
      state.contractFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveContractError(state) {
      state.contractFetchStatus = GlobalConstants.fetchStatus.error;
    },
    receivingContractOverview(state) {
      state.contractOverviewFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedContractOverview(state, action: PayloadAction<ContractOverview>) {
      state.contractOverview = action.payload;
      state.contractOverviewFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveContractOverviewError(state) {
      state.contractOverviewFetchStatus = GlobalConstants.fetchStatus.error;
    },
    requestAccountContracts(state) {
      state.accountContractsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveAccountContracts(
      state,
      action: PayloadAction<AccountContractResponse>
    ) {
      state.accountContracts = action.payload;
      state.accountContractsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveAccountContractsError(state) {
      state.accountContractsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    requestCustomerAccountContracts(state, action: PayloadAction<number>) {
      state.customerAccountContractsFetchStatus = {
        ...state.customerAccountContractsFetchStatus,
        [action.payload]: GlobalConstants.fetchStatus.loading,
      };
    },
    receiveCustomerAccountContracts(
      state,
      action: PayloadAction<{
        accountId: number;
        contracts: AccountContractResponse;
      }>
    ) {
      const { accountId, contracts } = action.payload;

      state.customerAccountContracts = {
        ...state.customerAccountContracts,
        [accountId]: contracts,
      };
      state.customerAccountContractsFetchStatus = {
        ...state.customerAccountContractsFetchStatus,
        [accountId]: GlobalConstants.fetchStatus.complete,
      };
    },
    receiveCustomerAccountContractsError(state, action: PayloadAction<number>) {
      state.customerAccountContractsFetchStatus = {
        ...state.customerAccountContractsFetchStatus,
        [action.payload]: GlobalConstants.fetchStatus.error,
      };
    },
    requestCloudContracts(state) {
      state.cloudContractsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveCloudContracts(state, action: PayloadAction<CloudAccount[]>) {
      state.cloudContracts = action.payload;
      state.cloudContractsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveCloudContractsError(state) {
      state.cloudContractsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    requestContractSLAs(state) {
      state.contractSLAsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveContractSLAs(state, action: PayloadAction<ContractSLA[]>) {
      state.contractSLAs = action.payload;
      state.contractSLAsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveContractSLAsError(state) {
      state.contractSLAsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    requestFSAs(state) {
      state.flexibleServiceAgreementsFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receiveFSAs(state, action: PayloadAction<FSACacheResponse>) {
      state.flexibleServiceAgreements = action.payload;
      state.flexibleServiceAgreementsFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveFSAsError(state) {
      state.flexibleServiceAgreementsFetchStatus =
        GlobalConstants.fetchStatus.error;
    },
    resetContract(state) {
      state.contract = {} as ContractDTO;
      state.contractFetchStatus = GlobalConstants.fetchStatus.idle;
      state.contractOverview = {} as ContractOverview;
      state.contractOverviewFetchStatus = GlobalConstants.fetchStatus.idle;
    },
  },
});

export const getContractById =
  (contractId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(contractSlice.actions.receivingContract());
    try {
      const contractById = await getRestContractById(contractId);
      dispatch(contractSlice.actions.receivedContract(contractById));
    } catch (err) {
      dispatch(contractSlice.actions.receiveContractError());
    }
  };

export const getContractOverview =
  (contractId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(contractSlice.actions.receivingContractOverview());
    try {
      const contractOverview = await getRestContractOverview(contractId);
      dispatch(
        contractSlice.actions.receivedContractOverview(contractOverview)
      );
    } catch (err) {
      dispatch(contractSlice.actions.receiveContractOverviewError());
    }
  };

export const getAccountContracts =
  (request: AccountContractRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (request.accountId) {
      dispatch(
        contractSlice.actions.requestCustomerAccountContracts(request.accountId)
      );
      try {
        const contracts = await getRestAccountContracts(request);
        dispatch(
          contractSlice.actions.receiveCustomerAccountContracts({
            accountId: request.accountId,
            contracts: contracts,
          })
        );
      } catch {
        dispatch(
          contractSlice.actions.receiveCustomerAccountContractsError(
            request.accountId
          )
        );
      }
    } else {
      dispatch(contractSlice.actions.requestAccountContracts());
      try {
        const contracts = await getRestAccountContracts(request);
        dispatch(contractSlice.actions.receiveAccountContracts(contracts));
      } catch {
        dispatch(contractSlice.actions.receiveAccountContractsError());
      }
    }
  };

export const getMyContracts = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(contractSlice.actions.requestCloudContracts());
  try {
    const contracts = await getRestCloudContracts();
    dispatch(contractSlice.actions.receiveCloudContracts(contracts));
  } catch (err) {
    dispatch(contractSlice.actions.receiveCloudContractsError());
  }
};

export const getSLAs = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(contractSlice.actions.requestContractSLAs());
  try {
    const slas = await getRestSLAs();
    dispatch(contractSlice.actions.receiveContractSLAs(slas));
  } catch (err) {
    dispatch(contractSlice.actions.receiveContractSLAsError());
  }
};

export const getFSAs = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(contractSlice.actions.requestFSAs());
  try {
    const fsas = await getRestFSAs();
    dispatch(contractSlice.actions.receiveFSAs(fsas));
  } catch (err) {
    dispatch(contractSlice.actions.receiveFSAsError());
  }
};

export const resetContractState = () => (dispatch: AppDispatch) => {
  dispatch(contractSlice.actions.resetContract());
};

export default contractSlice.reducer;
