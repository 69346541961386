import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const hideDetailsTelemetryName = "";

type Props = {
  dialogTitle: string;
  openDialog: boolean;
  closeDialog: () => void;
  children: React.ReactNode;
};

export const ReportDialog = ({
  dialogTitle,
  openDialog,
  closeDialog,
  children,
}: Props) => {
  const handleClose = () => {
    closeDialog();
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose} maxWidth={"sm"}>
        <DialogTitle>
          <Box display="flex" alignItems="center" margin={-1}>
            <Box flexGrow={1}>{dialogTitle}</Box>
            <Box>
              <IconButton
                onClick={handleClose}
                data-telemetry-name={hideDetailsTelemetryName}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent style={{ padding: 0 }} dividers>
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};
