import { create } from "zustand";
import { Report, Page } from "powerbi-client";
import { FeatureDefinitions } from "../../../../src/utils/FeatureDefinitions";

import { EmbeddedPowerBIParams } from "../entities";
import {
  AdvancedFilter,
  IReportEmbedConfiguration,
  RelativeDateFilter,
} from "powerbi-models";

type ActiveReportFilterDate = AdvancedFilter | RelativeDateFilter;

interface IActiveReportStore {
  activeReportParams?: EmbeddedPowerBIParams;
  activeEmbedConfig?: IReportEmbedConfiguration;
  activeEmbeddedReport?: Report;
  activeReportId: string;
  activeReportRelatedFeature?: typeof FeatureDefinitions[keyof typeof FeatureDefinitions];
  activeReportIsLoading: boolean;
  activePagesAreLoading: boolean;
  currentActivePage?: Page;
  activeReportFilterDate?: ActiveReportFilterDate;
  moveReportDown: boolean;
  setActiveReportRelatedFeature: (relatedFeature: typeof FeatureDefinitions[keyof typeof FeatureDefinitions]) => void;
  setActiveReportParams: (embeddedPowerBIParams: EmbeddedPowerBIParams) => void;
  setActiveEmbedConfig: (
    embedConfig: IReportEmbedConfiguration | undefined
  ) => void;
  setActiveEmbeddedReport: (embeddedReport: Report | undefined) => void;
  setCurrentActivePage: (page: Page) => void;
  setActiveReportId: (reportId: string | undefined) => void;
  setActiveReportIsLoading: (isLoading: boolean) => void;
  setActivePagesAreLoading: (isLoading: boolean) => void;
  setActiveReportFilterDate: (
    filterDate: AdvancedFilter | RelativeDateFilter | undefined
  ) => void;
  setMoveReportDown: (moveReportDown: boolean) => void;
}

export const ActiveReportStore = create<IActiveReportStore>((set) => ({
  activeReportParams: undefined,
  activeReportId: "",
  activeReportIsLoading: false,
  activePagesAreLoading: false,
  moveReportDown: false,
  activeReportRelatedFeature: undefined,
  setActiveReportRelatedFeature: (relatedFeature: typeof FeatureDefinitions[keyof typeof FeatureDefinitions]) => {
    set(() => ({
      activeReportRelatedFeature: relatedFeature,
    }))
  },
  setActiveReportParams: (embeddedPowerBIParams: EmbeddedPowerBIParams) => {
    set(() => ({
      activeReportParams: embeddedPowerBIParams,
    }));
  },
  setActiveEmbedConfig: (
    embedConfig: IReportEmbedConfiguration | undefined
  ) => {
    set(() => ({
      activeEmbedConfig: embedConfig,
    }));
  },
  setActiveEmbeddedReport: (embeddedReport: Report | undefined) => {
    set(() => ({
      activeEmbeddedReport: embeddedReport,
    }));
  },

  setCurrentActivePage: (page: Page) => {
    set(() => ({
      currentActivePage: page,
    }));
  },

  setActiveReportId: (reportId: string | undefined) => {
    set(() => ({
      activeReportId: reportId,
    }));
  },
  setActiveReportIsLoading(isLoading: boolean) {
    set(() => ({
      activeReportIsLoading: isLoading,
    }));
  },
  setActivePagesAreLoading(isLoading: boolean) {
    set(() => ({
      activePagesAreLoading: isLoading,
    }));
  },

  setActiveReportFilterDate: (
    filterDate: AdvancedFilter | RelativeDateFilter | undefined
  ) => {
    set(() => ({
      activeReportFilterDate: filterDate,
    }));
  },
  setMoveReportDown: (moveReportDown) =>
    set({ moveReportDown: moveReportDown }),
}));
