import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import { DeviceState } from "./deviceTypes";
import { getRestMyDevices } from "api/deviceStore";
import GlobalConstants from "@constants";
import { ConfigurationItemDTO } from "lib/ShiOneClient";

const initialState: DeviceState = {
  myDevicesFetchStatus: GlobalConstants.fetchStatus.idle,
  myDevices: [],
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    requestMyDevices(state) {
      state.myDevicesFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveMyDevices(state, action: PayloadAction<ConfigurationItemDTO[]>) {
      state.myDevices = action.payload;
      state.myDevicesFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveMyDevicesError(state) {
      state.myDevicesFetchStatus = GlobalConstants.fetchStatus.error;
    },
  },
});

export const getMyDevices = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(deviceSlice.actions.requestMyDevices());
  try {
    const devices = await getRestMyDevices();
    dispatch(deviceSlice.actions.receiveMyDevices(devices));
  } catch {
    dispatch(deviceSlice.actions.receiveMyDevicesError());
  }
};

export default deviceSlice.reducer;
