import {
  CurrentMonthCloudConsumptionWidgetClient,
  PageLayoutWidgetDto,
  WidgetContentResponseDto_1OfGetCurrentMonthCloudConsumptionResponse,
} from "@lib/ShiOneClient";
import {
  ItemBoxConfiguration,
  KeyInformationLabel,
  KeyInformationWidget,
  useKeyInformationData,
} from "shared-ui";
import { Cloud } from "@mui/icons-material";
import { useApi } from "../../utils";
import { getCurrentDateInformation } from "../../../../functions/getCurrentDateInformation";
import { NoContent } from "../../dashboards/framework";
import { formatCurrency } from "../../utils/formatters/formatCurrency";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    currentCost: 12345.65,
    averageDailyCost: 1234.43,
    projectedCost: 1234.32,
  },
};

function CurrentCostLabel() {
  const { days, currentMonth } = getCurrentDateInformation();

  return (
    <>
      <KeyInformationLabel label={"Current Cost"} />
      <KeyInformationLabel label={`As of ${currentMonth} ${days}`} />
    </>
  );
}

function transformFunction(
  rawData:
    | WidgetContentResponseDto_1OfGetCurrentMonthCloudConsumptionResponse
    | undefined
): ItemBoxConfiguration[] {
  // if we have an empty dataset, return an empty array, so we display the no content element
  if (
    rawData &&
    !rawData?.data?.currentCost &&
    !rawData?.data?.averageDailyCost &&
    !rawData?.data?.projectedCost
  ) {
    return [];
  }

  return [
    {
      label: <CurrentCostLabel />,
      amount: formatCurrency(rawData?.data?.currentCost),
      icon: <Cloud />,
    },
    {
      label: "Average Daily Cost",
      amount: formatCurrency(rawData?.data?.averageDailyCost),
      icon: <Cloud />,
    },
    {
      label: "Projected Cost",
      amount: formatCurrency(rawData?.data?.projectedCost),
      icon: <Cloud />,
    },
  ];
}

function useGetCurrentMonthCloudConsumption() {
  const api = useApi(CurrentMonthCloudConsumptionWidgetClient);

  return useKeyInformationData({
    queryKey: ["current-month-cloud-consumption-widget"],
    apiFunction: () => {
      return api.getCurrentMonthCloudConsumption();
    },
    transformFunction: transformFunction,
    placeholderData:
      placeholderData as WidgetContentResponseDto_1OfGetCurrentMonthCloudConsumptionResponse,
  });
}

function NoCloudConsumptionContent() {
  return (
    <NoContent
      containerHeight={200}
      icon={<Cloud />}
      header={"No Cloud Consumption Yet For This Month"}
      body={
        "Wait for a full day of the month to be completed or report an incident if this remains empty"
      }
    />
  );
}

export function CurrentMonthCloudConsumptionWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetCurrentMonthCloudConsumption}
      noContentElement={<NoCloudConsumptionContent />}
    />
  );
}
