import React from "react";
import {
  ContentUnitDto,
  KnowledgebaseClient,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import {
  Box,
  Icon,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../utils";
import ms from "ms";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import {
  SkeletonWrapper,
  WidgetWrapper,
} from "shared-ui";

const css = (theme: any) => ({
  avatar: {
    maxHeight: "1rem",
    maxWidth: theme.spacing(3),
  },
});

const placeholderData: ContentUnitDto[] = [
  {
    identifier: "office-365",
    title: "Office 365",
    url: "https://docs.one.shi.com/office365-knowledge-base",
    isPublic: true,
    icon: "https://shionestorage.blob.core.windows.net/shione/Microsoft_logo.jpg",
    contractCategoryIds: [11, 19, 25, 39],
  } as ContentUnitDto,
  {
    identifier: "office-365-admin",
    title: "Office 365 Admin",
    url: "https://docs.one.shi.com/office365-admin-knowledge-base",
    isPublic: true,
    icon: "https://shionestorage.blob.core.windows.net/shione/Microsoft_logo.jpg",
    contractCategoryIds: [11, 19, 25],
  } as ContentUnitDto,
  {
    identifier: "azure",
    title: "Azure",
    url: "https://docs.one.shi.com/azure-knowledge-base",
    isPublic: true,
    icon: "https://shionestorage.blob.core.windows.net/shione/Azure.png",
    contractCategoryIds: [15, 20, 25, 38],
  } as ContentUnitDto,
  {
    identifier: "aws",
    title: "Amazon Web Services",
    url: "https://docs.one.shi.com/amazon-web-services-knowledge-base",
    isPublic: true,
    icon: "https://shionestorage.blob.core.windows.net/shione/Amazon_Web_Services_Logo.svg.png",
    contractCategoryIds: [23, 24, 25, 26, 29],
  } as ContentUnitDto,
];

function useGetKnowledgeBaseItems() {
  const api = useApi(KnowledgebaseClient);
  return useQuery<ContentUnitDto[], Error>(
    [`knowledgebase-widget-data`],
    () => api.getAllKb().then((r) => r),
    {
      staleTime: ms("10m"),
      placeholderData: placeholderData,
    }
  );
}

function shouldDisplayContentUnit(kbi: ContentUnitDto) {
  return kbi && kbi.title;
}

function getImageSrcForContentItem(kbi: ContentUnitDto) {
  let imgSrc = null;

  const title = kbi?.title?.toLowerCase();

  if (!title) {
    return null;
  }

  if (title.includes("office 365")) {
    imgSrc =
      "https://shionestorage.blob.core.windows.net/shione/Microsoft_logo.jpg";
  } else if (title.includes("amazon web")) {
    imgSrc =
      "https://shionestorage.blob.core.windows.net/shione/Amazon_Web_Services_Logo.svg.png";
  } else if (title.includes("azure")) {
    imgSrc = "https://shionestorage.blob.core.windows.net/shione/Azure.png";
  }

  return imgSrc;
}

function generateAriaLabelForContentItem(kbi: ContentUnitDto) {
  return `View knowledge base articles for ${kbi.title}`;
}

function KnowledgeBaseItem({
  kbi,
  loading,
}: {
  kbi: ContentUnitDto;
  loading: boolean;
}) {
  const theme = useTheme();
  const styles = css(theme);
  const imgSrc = getImageSrcForContentItem(kbi);

  return (
    <ListItemButton
      aria-label={generateAriaLabelForContentItem(kbi)}
      href={AppendIfPrefixExists(kbi.url)}
      sx={{ borderTop: "solid 1px " + theme.palette.border }}
    >
      <ListItemAvatar>
        <SkeletonWrapper loading={loading}>
          {imgSrc ? (
            <img alt={kbi.title} src={imgSrc} style={styles.avatar} />
          ) : (
            <Icon
              className={"fas fa-graduation-cap"}
              style={{ fontSize: "1em", overflow: "visible" }}
            />
          )}
        </SkeletonWrapper>
      </ListItemAvatar>
      <SkeletonWrapper loading={loading}>
        <ListItemText primary={kbi.title} aria-hidden={true} />
      </SkeletonWrapper>
    </ListItemButton>
  );
}

export default function KnowledgeBaseWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const {
    data: response,
    isError,
    isPlaceholderData,
  } = useGetKnowledgeBaseItems();
  const contentUnitResults: ContentUnitDto[] = response ?? [];

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      noPadding={true}
    >
      <Box alignContent="stretch" sx={{ padding: 0 }}>
        <List sx={{ padding: 0 }}>
          {contentUnitResults &&
            contentUnitResults
              .filter((kbi) => {
                return shouldDisplayContentUnit(kbi);
              })
              .sort((a, b) => {
                return a?.title && b?.title
                  ? a?.title.localeCompare(b?.title)
                  : 0;
              })
              .map((kbi) => (
                <KnowledgeBaseItem
                  key={kbi.identifier}
                  kbi={kbi}
                  loading={isPlaceholderData}
                />
              ))}
        </List>
      </Box>
    </WidgetWrapper>
  );
}
