export const projectColors = {
  contractDialog: {
    header: "#000000DE",
    subheader: "#00000099",
    closeIcon: "#757575",
    dateRangeIcon: "#0000008A",
    recordDetails: {
      title: "#00000099",
      recordCount: "#253746",
      disabledLink: "#00000042",
    },
  },
};
