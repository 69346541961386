import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  useTheme,
} from "@mui/material";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    width: "100% !important",
    display: "block",
  },
});

export default function LimitedTextAreaField(props) {
  const theme = useTheme();
  const classes = css(theme);

  const { id, required, disabled, formData, onChange } = props;
  const {
    maxLength,
    outOfCharDesc,
    title,
    placeholder,
    color = "red",
  } = props.schema;

  const [currentCharCount, setCurrentCharCount] = useState(0);
  const [currentNote, setCurrentNote] = useState("");

  const defaultMaxChar = 500;

  useEffect(() => {
    if (formData) {
      handleInputChange(formData);
    }
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxCharsAllowed = maxLength != null ? maxLength : defaultMaxChar;
  const charsLeft = maxCharsAllowed - currentCharCount;

  const inputP = { maxLength: maxCharsAllowed };

  const handleInputChange = (value) => {
    setCurrentCharCount(value.length);
    setCurrentNote(value);
  };

  return (
    <FormControl style={{ display: "flex" }}>
      <TextField
        style={{ display: "flex" }}
        disabled={disabled}
        value={currentNote}
        inputProps={inputP}
        id={id}
        label={title}
        placeholder={placeholder}
        sx={classes.root}
        required={required}
        minRows={4}
        multiline={true}
        onChange={(event) => {
          const value = event.target.value;
          handleInputChange(value);
          onChange(value);
        }}
        helperText={`Characters left: ${charsLeft}`}
      />

      {charsLeft <= 0 && (
        <FormHelperText style={{ color: color }}>
          {outOfCharDesc}
        </FormHelperText>
      )}
    </FormControl>
  );
}
