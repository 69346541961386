const base = () => {
  return null;
};

let getAccessTokenSilently = base;

export const security = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func) => (getAccessTokenSilently = func),
};
