import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import {
  AceInvoiceRequest,
  EmailInvoicesRequest,
  InvoiceState,
} from "./invoiceTypes";
import {
  Invoice,
  Invoice_Response as InvoiceResponse,
  InvoiceEmail,
  InvoiceEmail_Response as InvoiceEmailResponse,
} from "lib/ShiOneClient";
import {
  getRestACEInvoices,
  getRestAwsInvoices,
  getRestAzureInvoices,
  getRestAllProjectInvoices,
  getRestGCPInvoices,
  getRestO365Invoices,
  postSendInvoicesToEmail,
} from "../../../api/invoiceStore";
import {
  CloudType,
  getDemoInvoiceData,
  isDemoAccount,
} from "../../../functions/demoData";

function filterInvoices(
  invoices: Invoice[] | undefined,
  aceInvoiceReq: AceInvoiceRequest
) {
  const filteredInvoices = invoices?.filter((invoice) => {
    return (
      (aceInvoiceReq.contractId &&
        invoice.serviceProjectName
          ?.toLowerCase()
          .includes(`c${aceInvoiceReq.contractId}`)) ||
      (aceInvoiceReq.projectId &&
        invoice.serviceProjectName
          ?.toLowerCase()
          .includes(`p${aceInvoiceReq.projectId}`))
    );
  });
  if (filteredInvoices !== null) {
    return filteredInvoices;
  }
  return [];
}

const initialState: InvoiceState = {
  projectInvoiceFetchStatus: "idle",
  projectInvoiceList: {} as InvoiceResponse,
  aceInvoiceFetchStatus: "idle",
  aceInvoiceList: {} as InvoiceResponse,
  awsInvoiceFetchStatus: "idle",
  awsInvoiceList: {} as InvoiceResponse,
  azureInvoiceFetchStatus: "idle",
  azureInvoiceList: {} as InvoiceResponse,
  gcpInvoiceFetchStatus: "idle",
  gcpInvoiceList: {} as InvoiceResponse,
  o365InvoiceFetchStatus: "idle",
  o365InvoiceList: {} as InvoiceResponse,
  sendInvoiceEmailStatus: "idle",
  sendInvoiceEmailResponse: {} as InvoiceEmailResponse,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    receivingProjectInvoices(state) {
      state.projectInvoiceFetchStatus = "loading";
    },
    receiveProjectInvoices(state, action: PayloadAction<InvoiceResponse>) {
      state.projectInvoiceList = action.payload;
      state.projectInvoiceFetchStatus = "complete";
    },
    receiveProjectInvoicesError(state) {
      state.projectInvoiceFetchStatus = "error";
    },
    receivingACEInvoices(state) {
      state.aceInvoiceFetchStatus = "loading";
    },
    receiveACEInvoices(state, action: PayloadAction<InvoiceResponse>) {
      state.aceInvoiceList = action.payload;
      state.aceInvoiceFetchStatus = "complete";
    },
    receiveACEInvoicesError(state) {
      state.aceInvoiceFetchStatus = "error";
    },
    receivingAwsInvoices(state) {
      state.awsInvoiceFetchStatus = "loading";
    },
    receiveAwsInvoices(state, action: PayloadAction<InvoiceResponse>) {
      state.awsInvoiceList = action.payload;
      state.awsInvoiceFetchStatus = "complete";
    },
    receiveAwsInvoicesError(state) {
      state.awsInvoiceFetchStatus = "error";
    },
    receivingAzureInvoices(state) {
      state.azureInvoiceFetchStatus = "loading";
    },
    receiveAzureInvoices(state, action: PayloadAction<InvoiceResponse>) {
      state.azureInvoiceList = action.payload;
      state.azureInvoiceFetchStatus = "complete";
    },
    receiveAzureInvoicesError(state) {
      state.azureInvoiceFetchStatus = "error";
    },
    receivingGCPInvoices(state) {
      state.gcpInvoiceFetchStatus = "loading";
    },
    receiveGCPInvoices(state, action: PayloadAction<InvoiceResponse>) {
      state.gcpInvoiceList = action.payload;
      state.gcpInvoiceFetchStatus = "complete";
    },
    receiveGCPInvoicesError(state) {
      state.gcpInvoiceFetchStatus = "error";
    },
    receivingO365Invoices(state) {
      state.o365InvoiceFetchStatus = "loading";
    },
    receiveO365Invoices(state, action: PayloadAction<InvoiceResponse>) {
      state.o365InvoiceList = action.payload;
      state.o365InvoiceFetchStatus = "complete";
    },
    receiveO365InvoicesError(state) {
      state.o365InvoiceFetchStatus = "error";
    },
    sendingInvoiceEmail(state) {
      state.sendInvoiceEmailStatus = "loading";
    },
    sentInvoiceEmail(state, action: PayloadAction<InvoiceEmailResponse>) {
      state.sendInvoiceEmailResponse = action.payload;
      state.sendInvoiceEmailStatus = "complete";
    },
    sendInvoiceEmailError(state) {
      state.sendInvoiceEmailStatus = "error";
    },
  },
});

export const getProjectInvoices =
  (axNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.receivingProjectInvoices());
    try {
      const projectInvoices = await getRestAllProjectInvoices(axNumber);
      dispatch(invoiceSlice.actions.receiveProjectInvoices(projectInvoices));
    } catch (err) {
      dispatch(invoiceSlice.actions.receiveProjectInvoicesError());
    }
  };

export const getACEInvoices =
  (aceInvoiceReq: AceInvoiceRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.receivingACEInvoices());
    try {
      const projectInvoices = await getRestACEInvoices(aceInvoiceReq);
      projectInvoices.invoices = filterInvoices(
        projectInvoices.invoices,
        aceInvoiceReq
      );
      dispatch(invoiceSlice.actions.receiveACEInvoices(projectInvoices));
    } catch (err) {
      dispatch(invoiceSlice.actions.receiveACEInvoicesError());
    }
  };

export const getAwsInvoices =
  (axNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (isDemoAccount(axNumber)) {
      const list = getDemoInvoiceData(axNumber, CloudType.AWS, 25000, 50000);
      const demoResponse = new InvoiceResponse({
        hasError: false,
        message: "Success",
        invoices: list,
      });
      dispatch(invoiceSlice.actions.receiveAwsInvoices(demoResponse));
    } else {
      dispatch(invoiceSlice.actions.receivingAwsInvoices());
      try {
        const awsInvoices = await getRestAwsInvoices();
        dispatch(invoiceSlice.actions.receiveAwsInvoices(awsInvoices));
      } catch (err) {
        dispatch(invoiceSlice.actions.receiveAwsInvoicesError());
      }
    }
  };

export const getAzureInvoices =
  (axNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (isDemoAccount(axNumber)) {
      const list = getDemoInvoiceData(axNumber, CloudType.Azure, 25000, 50000);
      const demoResponse = new InvoiceResponse({
        hasError: false,
        message: "Success",
        invoices: list,
      });
      dispatch(invoiceSlice.actions.receiveAzureInvoices(demoResponse));
    } else {
      dispatch(invoiceSlice.actions.receivingAzureInvoices());
      try {
        const azureInvoices = await getRestAzureInvoices();
        dispatch(invoiceSlice.actions.receiveAzureInvoices(azureInvoices));
      } catch (err) {
        dispatch(invoiceSlice.actions.receiveAzureInvoicesError());
      }
    }
  };

export const getGCPInvoices =
  (axNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (isDemoAccount(axNumber)) {
      const list = getDemoInvoiceData(axNumber, CloudType.GCP, 25000, 50000);
      const demoResponse = new InvoiceResponse({
        hasError: false,
        message: "Success",
        invoices: list,
      });
      dispatch(invoiceSlice.actions.receiveGCPInvoices(demoResponse));
    } else {
      dispatch(invoiceSlice.actions.receivingGCPInvoices());
      try {
        const gcpInvoices = await getRestGCPInvoices();
        dispatch(invoiceSlice.actions.receiveGCPInvoices(gcpInvoices));
      } catch (err) {
        dispatch(invoiceSlice.actions.receiveGCPInvoicesError());
      }
    }
  };

export const getO365Invoices =
  (axNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (isDemoAccount(axNumber)) {
      const list = getDemoInvoiceData(
        axNumber,
        CloudType.Office365,
        25000,
        50000
      );
      const demoResponse = new InvoiceResponse({
        hasError: false,
        message: "Success",
        invoices: list,
      });
      dispatch(invoiceSlice.actions.receiveO365Invoices(demoResponse));
    } else {
      dispatch(invoiceSlice.actions.receivingO365Invoices());
      try {
        const o365Invoices = await getRestO365Invoices();
        dispatch(invoiceSlice.actions.receiveO365Invoices(o365Invoices));
      } catch (err) {
        dispatch(invoiceSlice.actions.receiveO365InvoicesError());
      }
    }
  };

export const sendInvoicesEmail =
  (emailInvoicesRequest: EmailInvoicesRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.sendingInvoiceEmail());
    if (isDemoAccount(emailInvoicesRequest.axNumber)) {
      const demoResponse = new InvoiceEmailResponse({
        allSuccessful: true,
        message: "success",
        hasError: false,
        responseStatus: 1,
        invoiceResponses: [
          new InvoiceEmail({
            statusCode: 200,
            invoiceId: "12345",
            message: "Email successfully sent",
          }),
          new InvoiceEmail({
            statusCode: 400,
            invoiceId: "12346",
            message: "No email address found for invoice id: 12346",
          }),
        ],
      });
      dispatch(invoiceSlice.actions.sentInvoiceEmail(demoResponse));
    } else {
      try {
        const sendInvoiceEmailResponse = await postSendInvoicesToEmail(
          emailInvoicesRequest
        );
        dispatch(
          invoiceSlice.actions.sentInvoiceEmail(sendInvoiceEmailResponse)
        );
      } catch (err) {
        dispatch(invoiceSlice.actions.sendInvoiceEmailError());
      }
    }
  };

export default invoiceSlice.reducer;
