import { PhasesWithTasks } from "@lib/ShiOneClient";

const capitalize = function (s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getAENameFromEmail = (email: string) => {
  if (!email) return null;
  let emailToLower = email.toLowerCase();
  emailToLower = emailToLower.split("@")[0];

  const emailSplit = emailToLower.split("_");

  const firstName = capitalize(emailSplit[0].replace(/\d/g, ""));
  const lastName =
    emailSplit.length > 1 ? capitalize(emailSplit[1].replace(/\d/g, "")) : "";

  return {
    firstName,
    lastName,
  };
};

export const getActiveStep = (
  phases: PhasesWithTasks[],
  activePhaseId: number
) => {
  const activeStep = phases.findIndex((p) => {
    return p.phase?.id === activePhaseId;
  });
  if (activeStep === -1) return 999;

  return activeStep;
};
