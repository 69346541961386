import React from "react";
import PropTypes from "prop-types";
import { standardGraphColors, statusColors } from "../siteColors";
import { Box, useTheme } from "@mui/material";

const css = (theme) => ({
  root: {
    display: "inline-block",
    borderRadius: "50%",
    flexGrow: 0,
    flexShrink: 0,
  },
  sm: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  md: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  lg: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  neutral: {
    backgroundColor: theme.palette.text.hint,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  primaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  primaryDark: {
    backgroundColor: theme.palette.primary.dark,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  successLight: {
    backgroundColor: theme.palette.success.light,
  },
  successDark: {
    backgroundColor: theme.palette.success.dark,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  secondaryLight: {
    backgroundColor: theme.palette.secondary.light,
  },
  secondaryDark: {
    backgroundColor: theme.palette.secondary.dark,
  },
  discovered: {
    backgroundColor: standardGraphColors[3],
  },
  notDiscovered: {
    backgroundColor: standardGraphColors[4],
  },
  note: {
    backgroundColor: statusColors[0],
  },
});

const Status = (props) => {
  const { classes, className, size, color, style, ...rest } = props;
  const theme = useTheme();
  const styles = css(theme);
  return (
    <Box
      component={"span"}
      {...rest}
      sx={[styles[color], styles[size], styles.root, style]}
    />
  );
};

Status.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.oneOf([
    "neutral",
    "primary",
    "primaryLight",
    "primaryDark",
    "info",
    "success",
    "successLight",
    "successDark",
    "warning",
    "danger",
    "secondary",
    "secondaryLight",
    "secondaryDark",
    "discovered",
    "notDiscovered",
    "note",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  style: PropTypes.object,
};

Status.defaultProps = {
  size: "md",
  color: "neutral",
};

export default Status;
