export const ADD_TICKET_NOTE_TEMP = "ADD_TICKET_NOTE_TEMP";
export const GET_ACCOUNT_DEVICES = "GET_ACCOUNT_DEVICES";
export const GET_ACCOUNT_DEVICES_SUCCESS = "GET_ACCOUNT_DEVICES_SUCCESS";
export const GET_ACCOUNT_DEVICES_ERROR = "GET_ACCOUNT_DEVICES_ERROR";
export const GET_ACCOUNT_USERS = "GET_ACCOUNT_USERS";
export const GET_ACCOUNT_USERS_SUCCESS = "GET_ACCOUNT_USERS_SUCCESS";
export const GET_ACCOUNT_USERS_ERROR = "GET_ACCOUNT_USERS_ERROR";
export const ACTIVATE_ACCOUNT_USERS = "ACTIVATE_ACCOUNT_USERS";
export const ACTIVATE_ACCOUNT_USERS_SUCCESS = "ACTIVATE_ACCOUNT_USERS_SUCCESS";
export const ACTIVATE_ACCOUNT_USERS_ERROR = "ACTIVATE_ACCOUNT_USERS_ERROR";
export const ADMIN_UPDATE_USER = "ADMIN_UPDATE_USER";
export const ADMIN_UPDATE_USER_SUCCESS = "ADMIN_UPDATE_USER_SUCCESS";
export const ADMIN_UPDATE_USER_ERROR = "ADMIN_UPDATE_USER_ERROR";

export const ADMIN_CREATE_USER = "ADMIN_CREATE_USER";
export const ADMIN_CREATE_USER_SUCCESS = "ADMIN_CREATE_USER_SUCCESS";
export const ADMIN_CREATE_USER_ERROR = "ADMIN_CREATE_USER_ERROR";

export const ADMIN_RESET_USER_STATUS = "ADMIN_RESET_USER_STATUS";

export const ADMIN_MANUAL_EMAIL_APPROVAL = "ADMIN_UPDATE_MANUAL_EMAIL_APPROVAL";
export const ADMIN_MANUAL_EMAIL_APPROVAL_SUCCESS =
  "ADMIN_MANUAL_EMAIL_APPROVAL_SUCCESS";
export const ADMIN_MANUAL_EMAIL_APPROVAL_ERROR =
  "ADMIN_MANUAL_EMAIL_APPROVAL_ERROR";

export const GET_OTHER_ACCOUNTS = "GET_OTHER_ACCOUNTS";
export const GET_OTHER_ACCOUNTS_SUCCESS = "GET_OTHER_ACCOUNTS_SUCCESS";
export const GET_OTHER_ACCOUNTS_ERROR = "GET_OTHER_ACCOUNTS_ERROR";

export const GET_APPREG_STATUS = "GET_APPREG_STATUS";
export const GET_APPREG_STATUS_SUCCESS = "GET_APPREG_STATUS_SUCCESS";
export const GET_APPREG_STATUS_ERROR = "GET_APPREG_STATUS_ERROR";

export function resetCreateUserStatus() {
  return (dispatch) => {
    dispatch({
      type: ADMIN_RESET_USER_STATUS,
    });
  };
}

export function adminCreateUser(contact) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_CREATE_USER,
      payload: {
        data: contact,
      },
    });
  };
}

export function updateManualEmailApproval(manualEmailApproval) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_MANUAL_EMAIL_APPROVAL,
      payload: {
        data: {
          manualEmailApproval,
        },
      },
    });
  };
}

export function getAccountUsers() {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_USERS,
    });
  };
}

export function getOtherAccounts(id) {
  return (dispatch) => {
    dispatch({
      type: GET_OTHER_ACCOUNTS,
      payload: {
        accountId: id,
      },
    });
  };
}

export function getAccountDevices(accountList) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_DEVICES,
      payload: {
        data: accountList,
      },
    });
  };
}

export function ticketNoteStoreTemp(ticketNote) {
  return (dispatch) => {
    dispatch({
      type: ADD_TICKET_NOTE_TEMP,
      payload: {
        data: ticketNote,
      },
    });
  };
}
