import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import { BookmarkState } from "./bookmarkTypes";
import { AccountBookmark, Bookmark, ContactBookmark } from "lib/ShiOneClient";
import {
  deleteRestDeleteBookmark,
  getRestBookmarks,
  postRestAddBookmark,
  putRestUpdateBookmark,
} from "../../../api/bookmarkStore";
import _ from "lodash";
import GlobalConstants from "@constants";

const mapByBookmarkType = (bookmarkObject: any, type: string) => {
  if (type === GlobalConstants.bookmarkTypes.contact) {
    return Object.assign(new ContactBookmark(), bookmarkObject);
  }

  if (type === GlobalConstants.bookmarkTypes.account) {
    return Object.assign(new AccountBookmark(), bookmarkObject);
  }
};

const initialState: BookmarkState = {
  fetchBookmarksStatus: "idle",
  bookmarks: [],
  addBookmarkStatus: "idle",
  updateBookmarkStatus: "idle",
  deleteBookmarkStatus: "idle",
};

const bookmarkSlice = createSlice({
  name: "bookmark",
  initialState,
  reducers: {
    receivingBookmarks(state) {
      state.fetchBookmarksStatus = "loading";
    },
    receivedBookmarks(state, action: PayloadAction<Bookmark[]>) {
      state.bookmarks = action.payload;
      state.fetchBookmarksStatus = "complete";
    },
    receiveBookmarksError(state) {
      state.fetchBookmarksStatus = "error";
    },
    addingBookmark(state) {
      state.addBookmarkStatus = "loading";
    },
    addBookmarkError(state) {
      state.addBookmarkStatus = "error";
    },
    addedBookmark(state, action: PayloadAction<Bookmark>) {
      state.bookmarks = [...state.bookmarks, action.payload];
      state.addBookmarkStatus = "complete";
    },
    updatingBookmark(state) {
      state.updateBookmarkStatus = "loading";
    },
    updatedBookmark(state, action: PayloadAction<Bookmark>) {
      const index = _.findIndex(
        state.bookmarks,
        (bm) => bm.id === action.payload.id
      );

      if (index !== -1) {
        state.bookmarks.splice(index, 1, action.payload);
      }

      state.updateBookmarkStatus = "complete";
    },
    updateBookmarkError(state) {
      state.addBookmarkStatus = "error";
    },
    deletingBookmark(state) {
      state.deleteBookmarkStatus = "loading";
    },
    deletedBookmark(state, action: PayloadAction<number>) {
      state.bookmarks = _.filter(
        state.bookmarks,
        (bm) => bm.id !== action.payload
      );
      state.deleteBookmarkStatus = "complete";
    },
    deleteBookmarkError(state) {
      state.deleteBookmarkStatus = "error";
    },
  },
});

export const getBookmarks =
  (reportId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(bookmarkSlice.actions.receivingBookmarks());
    try {
      const bookmarks = await getRestBookmarks(reportId);
      dispatch(bookmarkSlice.actions.receivedBookmarks(bookmarks));
    } catch {
      dispatch(bookmarkSlice.actions.receiveBookmarksError());
    }
  };

export const addBookmark =
  (bookmark: any, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(bookmarkSlice.actions.addingBookmark());
    const newBookmark = mapByBookmarkType(bookmark, type);

    if (newBookmark) {
      try {
        const addedBookmark = await postRestAddBookmark(newBookmark);
        dispatch(bookmarkSlice.actions.addedBookmark(addedBookmark));
      } catch {
        dispatch(bookmarkSlice.actions.addBookmarkError());
      }
    } else {
      dispatch(bookmarkSlice.actions.addBookmarkError());
    }
  };

export const updateBookmark =
  (bookmark: any, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(bookmarkSlice.actions.updatingBookmark());
    const updatedBookmark = mapByBookmarkType(bookmark, type);

    if (updatedBookmark) {
      try {
        await putRestUpdateBookmark(updatedBookmark);
        dispatch(bookmarkSlice.actions.updatedBookmark(updatedBookmark));
      } catch {
        dispatch(bookmarkSlice.actions.updateBookmarkError());
      }
    } else {
      dispatch(bookmarkSlice.actions.updateBookmarkError());
    }
  };

export const deleteBookmark =
  (id: number, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(bookmarkSlice.actions.deletingBookmark());
    try {
      await deleteRestDeleteBookmark(id, type);
      dispatch(bookmarkSlice.actions.deletedBookmark(id));
    } catch {
      dispatch(bookmarkSlice.actions.deleteBookmarkError());
    }
  };

export default bookmarkSlice.reducer;
