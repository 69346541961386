import React from "react";
import { getPicklistValue } from "../../../../../functions/picklistValue";
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Tooltip,
} from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import { StyledTaskIcon } from "@features/projects-feature/utils/components/StyledProjectDetailsTasksComponents";

const commonStyles = () => ({
  radioIcon: {
    marginRight: "5px",
    width: "24px",
    height: "24px",
    color: "#757575",
  },
});

const ProjectTaskGridItem: React.FC<{
  status: number;
  title: string;
  checked: boolean;
  picklist: any;
}> = ({ status, title, checked, picklist }) => {
  const styles = commonStyles();

  const taskStatusValue = getPicklistValue(
    picklist,
    "project",
    "status",
    status
  );
  const getTaskStatusLabel = (taskStatusLabel: string) => {
    const taskIsNotComplete = taskStatusLabel.toLowerCase() !== "complete";
    const taskIsNotNew = taskStatusLabel.toLowerCase() !== "new";
    return taskIsNotComplete && taskIsNotNew ? "In Progress" : taskStatusLabel;
  };

  return (
    <ListItem>
      <Tooltip
        title={getTaskStatusLabel(taskStatusValue.label)}
        placement="left"
        arrow
      >
        <ListItemIcon>
          <Checkbox
            checked={checked}
            tabIndex={-1}
            disableRipple
            disabled
            icon={
              Number(taskStatusValue.value) !== 1 ? (
                <RadioButtonChecked sx={styles.radioIcon} />
              ) : (
                <Radio sx={styles.radioIcon} />
              )
            }
            checkedIcon={<StyledTaskIcon />}
            sx={{
              "&:Mui-disabled": {
                color: "#757575",
              },
            }}
          />
        </ListItemIcon>
      </Tooltip>

      <ListItemText primary={title} />
    </ListItem>
  );
};

export default ProjectTaskGridItem;
