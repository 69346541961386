import { styled } from "@mui/material/styles";
import { Avatar, Button, Grid } from "@mui/material";

export const StyledMetadataContainer = styled(Grid)({
  padding: "8px 24px",
  borderRadius: "4px",
});

export const StyledContractButton = styled(Button)({
  textTransform: "none",
  padding: 0,
  "&:hover": {
    background: "transparent",
  },
});

export const StyledAvatar = styled(Avatar)({
  backgroundColor: "default",
  width: "40px",
  height: "40px",
});
