import shiColors from "shared-ui/src/theme/shiColors";

export const standardGraphColors = [
  shiColors.green.dark,
  shiColors.blue.light,
  shiColors.purple.light,
  shiColors.turquoise.dark,
  shiColors.orange.DEFAULT,
  shiColors.yellow.dark,
  shiColors.blue.DEFAULT,
  shiColors.red.DEFAULT,
  shiColors.pink.medium,
  shiColors.purple.light,
  shiColors.blue.dark,
  shiColors.red.dark,
  shiColors.pink.DEFAULT,
  shiColors.purple.dark,
  shiColors.pink.dark,
];

export const otherColor = standardGraphColors[7];

export const inactiveColor = shiColors.gray.light;

export const severityGraphColors = {
  low: shiColors.green.DEFAULT,
  medium: shiColors.yellow.DEFAULT,
  high: shiColors.orange.DEFAULT,
  critical: shiColors.red.DEFAULT,
};

export const monitoringStatusColors = {
  ok: shiColors.green.DEFAULT,
  low: shiColors.yellow.DEFAULT,
  medium: shiColors.orange.light,
  high: shiColors.orange.DEFAULT,
  critical: shiColors.red.DEFAULT,
};

export const statusColors = [
  shiColors.green.DEFAULT,
  shiColors.yellow.DEFAULT,
  shiColors.red.DEFAULT,
  shiColors.gray.light,
];

export const bluePieChartColor = shiColors.blue.DEFAULT;

export const greenPieChartColor = shiColors.green.dark;

export const barChartColors = {
  created: shiColors.pink.DEFAULT,
  complete: shiColors.blue.DEFAULT,
};
