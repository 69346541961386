import { useQuery } from "@tanstack/react-query";
import { CACHE_KEY_WAREHOUSE_STOCK } from "@features/assets-feature/constants";
import ms from "ms";
import { getWarehouseStock } from "@features/assets-feature/stores/warehouseStore";
import { GetWarehouseStockResponse } from "@lib/ShiOneClient";
import { AssetsUseLocationState } from "../../../../interfaces/AssetInterfaces";

export const useGetWarehouseStock = (
  assetsUseLocationState: AssetsUseLocationState
) => {
  const fetchWarehouseStock = async () => {
    if (assetsUseLocationState?.state?.product)
      return new GetWarehouseStockResponse();
    return await getWarehouseStock();
  };
  return useQuery<GetWarehouseStockResponse, Error>({
    queryKey: CACHE_KEY_WAREHOUSE_STOCK,
    queryFn: fetchWarehouseStock,
    staleTime: ms("1m"),
  });
};
