import { AzureComplianceClient, Subscription } from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new AzureComplianceClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestAzureComplianceData(
  subscriptionId: string
): Promise<Subscription> {
  return api.getAzureComplianceBySubscriptionData(subscriptionId);
}
