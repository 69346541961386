import { NavItemType } from "../../NavigationTypes";
import { cloudAwsItemsToShowType } from "../../NavItemsToShowTypes";

export function CloudAwsMenuV2(
  cloudAwsItemsToShow: cloudAwsItemsToShowType
): NavItemType {
  return {
    id: "cloudAws",
    type: "Group",
    displayName: "AWS",
    visible: Object.entries(cloudAwsItemsToShow).some(([_k, v]) => v),
    children: [
      {
        id: "cloudAwsAccounts",
        type: "Link",
        displayName: "Accounts",
        path: "/cloud/aws/accounts",
        visible: cloudAwsItemsToShow.showAccounts,
      },
      {
        id: "cloudAwsReports",
        type: "Link",
        displayName: "Consumption",
        path: "/cloud/aws/reports",
        visible: cloudAwsItemsToShow.showConsumption,
      },
      {
        id: "cloudAwsBlueprints",
        type: "Link",
        displayName: "Blueprints",
        path: "/cloud/aws/blueprints",
        visible: cloudAwsItemsToShow.showBlueprints,
      },
      {
        id: "cloudAwsCostAllocationTags",
        type: "Link",
        displayName: "Cost Allocation Tags",
        path: "/cloud/aws/cat",
        visible: cloudAwsItemsToShow.showCostAllocationTags,
      },
      {
        id: "cloudAwsMonitoring",
        type: "Link",
        displayName: "Monitoring",
        path: "/cloud/aws/monitoring",
        visible: cloudAwsItemsToShow.showMonitoring,
      },
      {
        id: "cloudAwsRecommendations",
        type: "Link",
        displayName: "Recommendations",
        path: "/cloud/aws/recommendations",
        visible: cloudAwsItemsToShow.showRecommendations,
      },
      {
        id: "cloudAwsGovernanceReports",
        type: "Link",
        displayName: "Governance",
        path: "/cloud/aws/governance",
        visible: cloudAwsItemsToShow.showGovernance,
      },
    ],
  };
}
