import { Theme } from "@mui/material";

export const CustomToolbarStyles = (theme: Theme) => ({
  selectText: {
    paddingLeft: theme.spacing(1),
    fontSize: "15px",
    fontWeight: 600,
  },
  search: {
    marginLeft: theme.spacing(0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  searchIcon: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    height: "100%",
    pointerEvents: "none",
  },
  searchSwitch: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
  searchInput: {
    width: "100%",
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      width: "100%",
    },
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  selectedActionsContainer: {
    display: "flex",
    marginLeft: theme.spacing(1),
    alignItems: "center",
    gap: theme.spacing(1),
  },
  filterChipsBar: {
    position: "absolute",
    width: "100%",
    borderTop: "1px solid" + theme.palette.table.border,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
});
