import {
  AllServiceRequestsResponse,
  GetUniqueFilterValuesRequestBase,
  ServiceRequestClient,
  ServiceRequestDTO,
  ServiceRequestBaseElasticQueryRequest,
  ServiceRequestListDocumentDto,
  ServiceRequestResponse,
  ServiceRequestsRequest,
  ServiceRequestsResponseDTO,
  ServiceRequestSummaryDTO,
  UniqueFilterValuesDto,
  ServiceRequestDetailsDto,
  ContactDto,
  ServiceRequestMessageDto,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new ServiceRequestClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestAllServiceRequests(
  request: AllServiceRequestsResponse
): Promise<AllServiceRequestsResponse> {
  return api.getAllAccountServiceRequests(request);
}

export async function getRestMyServiceRequests(): Promise<ServiceRequestDTO[]> {
  return api.getMyServiceRequests();
}

export async function getRestMyAdditionalContactRequests(): Promise<
  ServiceRequestDTO[]
> {
  return api.getMyAdditionalContactServiceRequests();
}

export async function getRestServiceRequestById(
  id: string
): Promise<ServiceRequestDTO> {
  return api.getServiceRequestByServiceRequestId(id);
}

export async function postRestNewServiceRequest(
  request: ServiceRequestDTO
): Promise<ServiceRequestResponse> {
  return api.createNewServiceRequest(request);
}

export async function putRestUpdateServiceRequestById(
  request: ServiceRequestDTO
): Promise<ServiceRequestResponse> {
  return api.updateServiceRequest(request.id as number, request);
}

export async function getRequestsSummary(): Promise<ServiceRequestSummaryDTO> {
  return api.summary();
}

export async function getAllRequests(
  request: ServiceRequestsRequest
): Promise<ServiceRequestsResponseDTO> {
  return api.allRequests(request);
}

export async function getMyRequests(
  request: ServiceRequestsRequest
): Promise<ServiceRequestsResponseDTO> {
  return api.myRequests(request);
}

export async function getSecondaryRequests(
  request: ServiceRequestsRequest
): Promise<ServiceRequestsResponseDTO> {
  return api.secondaryContact(request);
}

export async function getElasticServiceRequests(
  queryRequest: ServiceRequestBaseElasticQueryRequest
): Promise<ServiceRequestListDocumentDto> {
  if (queryRequest.query?.pageSize === 0) {
    // @ts-ignore
    return { totalCount: 0, serviceRequestDtos: [] };
  }

  return api.searchServiceRequests(queryRequest);
}

export async function getElasticServiceRequestsUniqueFilterValues(
  queryRequest: GetUniqueFilterValuesRequestBase
): Promise<UniqueFilterValuesDto> {
  if (queryRequest.query?.pageSize === 0) {
    // @ts-ignore
    return { uniqueAssignedToValues: [], uniqueCreatedByValues: [] };
  }

  return api.uniqueFilterValues(queryRequest);
}

export async function getServiceRequestDetails(
  serviceRequestId: string
): Promise<ServiceRequestDetailsDto> {
  return api.serviceRequestDetails(serviceRequestId);
}

export async function postServiceRequestAdditionalContacts(
  serviceRequestId: string,
  contacts: ContactDto[]
): Promise<ContactDto[]> {
  return api.updateAdditionalContacts(serviceRequestId, contacts);
}

export async function postServiceRequestMessage(
  message: ServiceRequestMessageDto
) {
  return api.addServiceRequestMessage(message);
}

export async function postServiceRequestPreferredContactMethod(
  serviceRequestId: number,
  preferredContactMethod: string
) {
  return api.updatePreferredContactMethod(
    serviceRequestId,
    preferredContactMethod
  );
}
