import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function ReactRouterClassWrapper({ children }) {
  const routerProps = {
    navigate: useNavigate(),
    location: useLocation(),
    params: useParams(),
  };

  return children(routerProps);
}
