import React, { useEffect, useState } from "react";
import { telemetryBeginRoute } from "../functions/telemetry";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../containers/Dashboard";
import PropTypes from "prop-types";
import { FeatureDefinitions } from "shared-ui/src/utils/FeatureDefinitions";

// Documentation for Feature Components exists here:
// https://dev.azure.com/shi-dev/Product%20Development/_wiki/wikis/Product%20Development/2722/Feature-Tracking-Metrics#

export default function FeatureComponent({
  showDashboard,
  authRequired,
  children,
  ...props
}) {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authRequired && !isAuthenticated) {
      navigate("/login", {
        state: {
          from: location.pathname,
        },
      });
    }

    setAuthenticated(true);
  }, []);

  telemetryBeginRoute(props);
  if (authenticated && showDashboard) {
    return <Dashboard>{children}</Dashboard>;
  }

  if (authenticated && !showDashboard) {
    return children;
  }

  return null;
}

FeatureComponent.propTypes = {
  showDashboard: PropTypes.bool,
  authRequired: PropTypes.bool,
  path: PropTypes.string.isRequired,
  feature: PropTypes.oneOf(Object.values(FeatureDefinitions)).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
