import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import { FilePondErrorDescription, FilePondFile } from "filepond";

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileMetadata,
);
const getImpersonateHeaders = () => {
  let impersonate = localStorage.getItem("impersonateUser") ?? null;
  let impersonateAccount = localStorage.getItem("impersonateAccount") ?? null;
  let demoData = localStorage.getItem("demoData") ?? null;
  let header = {};
  if (impersonate !== null) {
    header = { ...header, impersonateContact: impersonate };
  }
  if (impersonateAccount !== null) {
    header = { ...header, impersonateAccount: impersonateAccount };
  }
  if (demoData !== null) {
    header = { ...header, demoData: demoData };
  }
  return header;
};

interface Process {
  method: "GET" | "POST" | "DELETE" | "PATCH";
  withCredentials: boolean;
  timeout: number;
  onload: Function;
  onerror: Function;
  ondata: Function;
  headers: unknown;
  revert?: {
    url: string;
    headers: unknown;
  };
}

export interface FilePondServer {
  url: string;
  timeout: number;
  process: Process;
}

export interface SHIFilePondProps {
  maxFileSize?: string;
  server: FilePondServer;
  fileRequired?: boolean;
  allowMultiple?: boolean;
  maxFiles?: number;
  showPreviewImage?: boolean;
  imagePreviewMaxHeight?: number;
  imagePreviewMinHeight?: number;
  files?: unknown[];
  setFiles?: Function;
  allowFileMetadata?: boolean;
  fileMetadataObject?: unknown;
  setFilesStillProcessing?: Function;
  acceptedFileTypes?: unknown[];
  expectedTypesMap?: object;
  onError?: (
    error: FilePondErrorDescription,
    file?: FilePondFile,
    status?: any,
  ) => void;
}

function SHIFilePond(props: SHIFilePondProps) {
  const {
    maxFileSize = "100MB",
    // @ts-ignore
    server,
    fileRequired = false,
    allowMultiple = false,
    maxFiles = 1,
    showPreviewImage = false,
    imagePreviewMaxHeight = 256,
    imagePreviewMinHeight = 44,
    files = [],
    setFiles = () => {},
    allowFileMetadata = true,
    fileMetadataObject = {},
    setFilesStillProcessing = (processing: boolean) => {},
    acceptedFileTypes = [],
    expectedTypesMap = {},
    onError,
  } = props;
  const allowFileTypeValidation =
    acceptedFileTypes && acceptedFileTypes.length > 0;

  const impersonateHeaders = getImpersonateHeaders();
  if (server?.process?.headers) {
    server.process.headers = {
      ...server.process.headers,
      ...impersonateHeaders,
    };
  }
  return (
    // @ts-ignore
    <FilePond
      style={{ minHeight: "2rem" }}
      files={files}
      // @ts-ignore
      onupdatefiles={(updatedFile) => setFiles(updatedFile)}
      allowMultiple={allowMultiple}
      maxFiles={maxFiles}
      allowFileSizeValidation
      server={server}
      maxFileSize={maxFileSize}
      labelMaxFileSizeExceeded={`File must be under ${maxFileSize}`}
      required={fileRequired}
      allowImagePreview={showPreviewImage}
      imagePreviewMaxHeight={imagePreviewMaxHeight}
      imagePreviewMinHeight={imagePreviewMinHeight}
      allowFileMetadata={allowFileMetadata}
      fileMetadataObject={fileMetadataObject}
      onprocessfiles={() => setFilesStillProcessing(false)}
      onprocessfilestart={() => setFilesStillProcessing(true)}
      onaddfilestart={() => setFilesStillProcessing(true)}
      allowFileTypeValidation={allowFileTypeValidation}
      fileValidateTypeLabelExpectedTypesMap={expectedTypesMap}
      acceptedFileTypes={acceptedFileTypes}
      onerror={onError}
    />
  );
}

export default SHIFilePond;
