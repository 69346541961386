import { styled } from "@mui/material/styles";
import { CardContent, List, ListSubheader } from "@mui/material";
import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";

export const StyledTasksCardContent = styled(CardContent)({
  padding: 0,
  "&:last-child": { paddingBottom: 0 },
});

export const StyledTasksList = styled(List)({ width: "100%", padding: 0 });

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  background: theme.palette.background.dark,
}));

export const StyledTaskIcon = styled(TaskAltSharpIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: "24px",
  height: "24px",
}));
