import React from "react";
import { useApi } from "../../utils";
import {
  DateFilterOption,
  ServiceRequestVisualsTypes,
  SupportRequestWidgetsClient,
} from "@lib/ShiOneClient";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { isLoading } from "@widgets/utils/widgetFunctions";
import { SupportRequestsWidgetHeaderAction } from "@widgets/utils/support/SupportRequestsWidgetHeaderAction";
import { Box, Button, Container } from "@mui/material";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { transformPieChartDtoWithLabelLink } from "shared-ui/src/utils/transformPieChartDto";
import { asDto, BaseWidgetProps, useSummaryData } from "shared-ui";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";
import { DateFilterOptionHeaderAction } from "@widgets/utils/support/DateFilterOptionHeaderAction";

const filterKey = "all-support-requests-widget-filter";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    totalLabel: "Total Requests",
    totalCount: 0,
    slices: [],
  },
};

function useGetAllSupportRequestsData(
  visualKey: ServiceRequestVisualsTypes,
  dateFilterOption: DateFilterOption
) {
  const api = useApi(SupportRequestWidgetsClient);

  return useSummaryData({
    queryKey: [`all-support-requests-widget-${visualKey}-${dateFilterOption}`],
    apiFunction: () =>
      api.getSupportRequestsData(visualKey, dateFilterOption, false),
    transformFunction: (rawData) =>
      transformPieChartDtoWithLabelLink(
        rawData?.data,
        "All Requests",
        prebuiltViewNames.allRequests,
        visualKey,
        dateFilterOption,
        ServiceRequestsPageTabs.allRequests
      ),
    placeholderData: asDto(placeholderData),
  });
}

function CardActions() {
  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          href={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.allRequests}#${ServiceRequestsPageTabs.allRequests}`
          )}
        >
          View All Requests
        </Button>
      </Box>
    </Container>
  );
}

const getFilterSpecificId = (id: string, filter: string) => {
  return `${id}-${filter}`;
};

const useAllRequestsWidgetFilterUserConfigs = (
  pageLayoutWidgetId: string,
  initialVisualKeyFilter: ServiceRequestVisualsTypes,
  initialDateFilterOption: DateFilterOption
) => {
  const visualKeyConfig = useWidgetFilterUserConfig(
    getFilterSpecificId(pageLayoutWidgetId, "visualKey"),
    filterKey,
    initialVisualKeyFilter
  );

  const dateFilterOptionConfig = useWidgetFilterUserConfig(
    getFilterSpecificId(pageLayoutWidgetId, "dateFilterOption"),
    filterKey,
    initialDateFilterOption
  );

  return { dateFilterOptionConfig, visualKeyConfig };
};
export default function AllRequestsWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const initialVisualKeyFilter = ServiceRequestVisualsTypes.ServiceRequestType;
  const initialDateFilter = DateFilterOption.CurrentMonth;

  const { dateFilterOptionConfig, visualKeyConfig } =
    useAllRequestsWidgetFilterUserConfigs(
      pageLayoutWidget.widgetId!,
      initialVisualKeyFilter,
      initialDateFilter
    );

  const responseFunc = useGetAllSupportRequestsData(
    visualKeyConfig.currentFilterValue,
    dateFilterOptionConfig.currentFilterValue
  );

  const isLoadingData = isLoading([
    visualKeyConfig.isLoadingFilterUserConfig,
    dateFilterOptionConfig.isLoadingFilterUserConfig,
    responseFunc.isPlaceholderData,
  ]);

  return (
    <SummaryWidget
      headerAction={
        <Box display={"flex"} gap={0.5}>
          <SupportRequestsWidgetHeaderAction
            filterBy={visualKeyConfig.currentFilterValue}
            setFilterBy={visualKeyConfig.setFilter}
            loading={isLoadingData}
            filterKey={filterKey}
          />
          <DateFilterOptionHeaderAction
            filterBy={dateFilterOptionConfig.currentFilterValue}
            setFilterBy={dateFilterOptionConfig.setFilter}
            loading={isLoadingData}
            filterKey={filterKey}
          />
        </Box>
      }
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => responseFunc}
      leftLabel={"All Requests"}
      cardActions={<CardActions />}
    />
  );
}
