import { PageLayoutDto, UserConfigDto } from "@lib/ShiOneClient";
import {
  useGetUserConfig,
  UserConfigResponse,
  useUpsertUserConfig,
} from "../../../../modules/hooks/useUserConfig";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { PageLayoutWidgetFilter } from "shared-ui/src/dashboards/models/PageLayoutWidgetFilter";

function getUserConfigWidgetFilterValueIfExists(
  userConfig: UserConfigDto | undefined,
  isLoading: boolean,
  initialValue: any
): any {
  if (userConfig?.settingsValue && !isLoading) {
    const widgetFilter = JSON.parse(userConfig.settingsValue);
    return widgetFilter.filterValue ?? initialValue;
  }
  return initialValue;
}

export function getColumnWidgetsFromPage(
  page: PageLayoutDto,
  position: number
) {
  if (page.columns && page.columns.length > position) {
    return page.columns[position];
  }
  return undefined;
}

export function useWidgetFilterUserConfig(
  widgetId: string,
  filterKey: string,
  initialValue: any
) {
  const filterUserConfig = useGetWidgetFilterUserConfig(widgetId);

  return {
    ...useSetUserColumnWidgetFilterConfig(
      widgetId,
      filterKey,
      filterUserConfig,
      initialValue
    ),
    isLoadingFilterUserConfig: filterUserConfig.isLoading,
  };
}

export function useGetWidgetFilterUserConfig(
  widgetId: string | undefined
): UserConfigResponse {
  let location = useLocation();
  const settingsKey = `${location.pathname}-WidgetId:${widgetId}`;
  const userConfig = useGetUserConfig(settingsKey);
  return userConfig;
}

export function useSetUserColumnWidgetFilterConfig(
  widgetId: string,
  filterKey: string,
  filterUserConfig: UserConfigResponse,
  initialFilterValue?: any
) {
  const defaultFilterValue = getUserConfigWidgetFilterValueIfExists(
    filterUserConfig.userConfig,
    filterUserConfig.isLoading,
    initialFilterValue
  );

  const [currentFilterValue, setCurrentFilterValue] =
    useState<any>(defaultFilterValue);

  let location = useLocation();
  const upsertUserConfigs = useUpsertUserConfig();

  useEffect(() => {
    if (defaultFilterValue !== undefined && !filterUserConfig.isLoading) {
      setCurrentFilterValue(defaultFilterValue);
    }
  }, [defaultFilterValue, filterUserConfig.isLoading]);

  const setFilter = useCallback(
    (value: any) => {
      setCurrentFilterValue(value);

      const widgetFilter = new PageLayoutWidgetFilter();
      widgetFilter.widgetId = widgetId;
      widgetFilter.filterKey = filterKey;
      widgetFilter.filterValue = value;

      const settings = new UserConfigDto();
      settings.settingsKey = `${location.pathname}-WidgetId:${widgetId}`;
      settings.settingsValue = JSON.stringify(widgetFilter);

      upsertUserConfigs.mutate(settings);
    },
    [filterKey, location.pathname, upsertUserConfigs, widgetId]
  );

  return { currentFilterValue, setFilter };
}
