import _ from "lodash";

export const getManagedSecurityGroups = (myContactId, teamsList) => {
  let managed = [];
  _.forEach(teamsList, (team) => {
    _.forEach(team.contactTeamRole, (ctr) => {
      if (ctr.contactId === myContactId && ctr.roleId === 1) {
        managed.push(team);
      }
    });
  });
  return managed;
};

export const getSecurityGroups = (myContactId, teamsList) => {
  let securityGroups = [];
  _.forEach(teamsList, (team) => {
    _.forEach(team.contactTeamRole, (ctr) => {
      if (ctr.contactId === myContactId) {
        securityGroups.push(team);
      }
    });
  });
  return securityGroups;
};

export const changeSecurityGroupPermissions = (
  currentPermissions,
  resource,
  action,
  allow
) => {
  if (allow) {
    return addPermission(currentPermissions, resource, action);
  } else {
    return removePermission(currentPermissions, resource, action);
  }
};

const addPermission = (currentPermissions, resource, action) => {
  const newStatements = _.cloneDeep(currentPermissions.currentStatements);

  const matchingIndex = _.findIndex(newStatements, (cs) => {
    return cs.action[0] === action && cs.effect === "Allow";
  });

  if (matchingIndex > -1) {
    newStatements[matchingIndex].resources.push(resource);
  } else {
    newStatements.push({
      action: [action],
      effect: "Allow",
      resources: [resource],
    });
  }

  return {
    ...currentPermissions,
    currentStatements: newStatements,
  };
};

const removePermission = (currentPermissions, resource, action) => {
  const newStatements = _.cloneDeep(currentPermissions.currentStatements);

  const matchingIndex = _.findIndex(newStatements, (cs) => {
    return cs.action[0] === action && cs.effect === "Allow";
  });

  if (matchingIndex > -1) {
    // check if it exists
    const resourceIndex = _.findIndex(
      newStatements[matchingIndex].resources,
      (r) => {
        return r === resource;
      }
    );
    if (resourceIndex > -1) {
      newStatements[matchingIndex].resources.splice(resourceIndex, 1);
    }
    if (newStatements[matchingIndex].resources.length === 0) {
      newStatements.splice(matchingIndex, 1);
    }
  }

  // console.log('currentPermissions', currentPermissions.currentStatements)
  // console.log('newPermissions', newStatements)

  return {
    ...currentPermissions,
    currentStatements: newStatements,
  };
};

export const getAcronym = (name) => {
  if (name && name.length > 0 && /\S/.test(name)) {
    const acronym = name.match(/\b(\w)/g);
    return acronym.length > 2 ? acronym.slice(0, 2) : acronym;
  }
  return "NA";
};

export const checkPermissions = (myTeams, resourceValue, action) => {
  return _.some(myTeams, (t) => {
    if (t.permissionStatements) {
      const permissions = JSON.parse(t.permissionStatements);
      return _.some(permissions, (p) => {
        if (p.Effect === "Allow" && p.Action && p.Action.length > 0) {
          const hasAction =
            action &&
            _.some(p.Action, (a) => {
              return a === action;
            });
          if (action && !hasAction) return false;
          // console.log('individual permission', p)
          return _.some(p.Resources, (r) => {
            const split = r.split(":");
            if (split.length === 4) {
              const splitPermissionNamespace = split[2].split("/");
              const resourceValueNamespace = resourceValue.split("/");
              // console.log(splitPermissionNamespace, resourceValueNamespace)
              let match = false;
              for (let i = 0; i < resourceValueNamespace.length; i++) {
                if (resourceValueNamespace[i]) {
                  if (
                    resourceValueNamespace[i] === splitPermissionNamespace[i]
                  ) {
                    match = true;
                  } else {
                    match = false;
                    i = resourceValueNamespace.length + 1;
                  }
                }
              }
              return match;
            }
          });
        }
      });
    }
  });
};

export const checkSecurityGroupContracts = (myTeams, contractId) => {
  return _.some(myTeams, (team) => {
    return _.some(
      team.teamContracts,
      (contract) => contract.contractId === contractId
    );
  });
};
