import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import theme from "./index";
import darkTheme from "./darkTheme";

const ThemeModeProviderContext = createContext({
  toggleThemeMode: () => {},
  mode: "light",
  currentTheme: {},
});

export const ThemeModeProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [mode, setMode] = useState<string>("light");
  const providerValues = useMemo(() => {
    const toggleThemeMode = () => {
      setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    };
    const currentTheme = mode === "light" ? theme : darkTheme;

    return {
      currentTheme,
      mode,
      toggleThemeMode,
    };
  }, [mode]);
  return (
    <ThemeModeProviderContext.Provider value={providerValues}>
      {children}
    </ThemeModeProviderContext.Provider>
  );
};

export function useThemeMode() {
  return useContext(ThemeModeProviderContext);
}
