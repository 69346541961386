import { ServiceRequestTemplateState } from "./serviceRequestTemplateTypes";
import GlobalConstants from "@constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AXAccountBillingUsersDTO,
  AXAccountDetailsDTO,
  AXBillingAddressDTO,
  ServiceRequestAccountLocationDTO,
  ServiceRequestContactDTO,
} from "lib/ShiOneClient";
import { AppDispatch, AppThunk } from "../../../store";
import {
  getRestAxAccountBillingAddresses,
  getRestAxAccountDetails,
  getRestAllAxBillingContacts,
  getRestContactsWidgetData,
  getRestLocationsWidgetData,
} from "../../../api/serviceRequestTemplateStore";

const initialState: ServiceRequestTemplateState = {
  accountContactsFetchStatus: GlobalConstants.fetchStatus.idle,
  accountContacts: [],
  accountLocationsFetchStatus: GlobalConstants.fetchStatus.idle,
  accountLocations: [],
  accountDetailsFetchStatus: GlobalConstants.fetchStatus.idle,
  accountDetails: {} as AXAccountDetailsDTO,
  accountBillingAddressFetchStatus: GlobalConstants.fetchStatus.idle,
  accountBillingAddress: {} as AXBillingAddressDTO,
  billingContactsFetchStatus: GlobalConstants.fetchStatus.idle,
  billingContacts: [],
};

const serviceRequestTemplateSlice = createSlice({
  name: "serviceRequestTemplate",
  initialState,
  reducers: {
    receivingAccountContacts(state) {
      state.accountContactsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedAccountContacts(
      state,
      action: PayloadAction<ServiceRequestContactDTO[]>
    ) {
      state.accountContacts = action.payload;
      state.accountContactsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveAccountContactsError(state) {
      state.accountContactsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    receivingAccountLocations(state) {
      state.accountLocationsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedAccountLocations(
      state,
      action: PayloadAction<ServiceRequestAccountLocationDTO[]>
    ) {
      state.accountLocations = action.payload;
      state.accountLocationsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveAccountLocationsError(state) {
      state.accountLocationsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    receivingAccountDetails(state) {
      state.accountDetailsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedAccountDetails(state, action: PayloadAction<AXAccountDetailsDTO>) {
      state.accountDetails = action.payload;
      state.accountDetailsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveAccountDetailsError(state) {
      state.accountDetailsFetchStatus = GlobalConstants.fetchStatus.error;
    },

    receivingBillingContacts(state) {
      state.billingContactsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedBillingContacts(
      state,
      action: PayloadAction<AXAccountBillingUsersDTO[]>
    ) {
      state.billingContacts = action.payload;
      state.billingContactsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveBillingContactsError(state) {
      state.billingContactsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    receivingAccountBillingAddress(state) {
      state.accountBillingAddressFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receivedAccountBillingAddress(
      state,
      action: PayloadAction<AXBillingAddressDTO>
    ) {
      state.accountBillingAddress = action.payload;
      state.accountBillingAddressFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveAccountBillingAddressError(state) {
      state.accountBillingAddressFetchStatus =
        GlobalConstants.fetchStatus.error;
    },
  },
});

export const getContactsWidgetData =
  (accountId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestTemplateSlice.actions.receivingAccountContacts());
    try {
      const contacts = await getRestContactsWidgetData(accountId);
      dispatch(
        serviceRequestTemplateSlice.actions.receivedAccountContacts(contacts)
      );
    } catch {
      dispatch(
        serviceRequestTemplateSlice.actions.receiveAccountContactsError()
      );
    }
  };

export const getLocationsWidgetData =
  (accountId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestTemplateSlice.actions.receivingAccountLocations());
    try {
      const contacts = await getRestLocationsWidgetData(accountId);
      dispatch(
        serviceRequestTemplateSlice.actions.receivedAccountLocations(contacts)
      );
    } catch {
      dispatch(
        serviceRequestTemplateSlice.actions.receiveAccountLocationsError()
      );
    }
  };

export const getAccountDetailsData =
  (axAccountNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestTemplateSlice.actions.receivingAccountDetails());
    try {
      const accountDetails = await getRestAxAccountDetails(axAccountNumber);
      dispatch(
        serviceRequestTemplateSlice.actions.receivedAccountDetails(
          accountDetails
        )
      );
    } catch {
      dispatch(
        serviceRequestTemplateSlice.actions.receiveAccountDetailsError()
      );
    }
  };

export const getAxBillingContacts =
  (axAccountNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestTemplateSlice.actions.receivingBillingContacts());
    try {
      const billingUsers = await getRestAllAxBillingContacts(axAccountNumber);
      dispatch(
        serviceRequestTemplateSlice.actions.receivedBillingContacts(
          billingUsers
        )
      );
    } catch {
      dispatch(
        serviceRequestTemplateSlice.actions.receiveBillingContactsError()
      );
    }
  };

export const getAccountBillingAddressData =
  (axAccountNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      serviceRequestTemplateSlice.actions.receivingAccountBillingAddress()
    );
    try {
      const accountBillingAddresses = await getRestAxAccountBillingAddresses(
        axAccountNumber
      );
      dispatch(
        serviceRequestTemplateSlice.actions.receivedAccountBillingAddress(
          accountBillingAddresses
        )
      );
    } catch {
      dispatch(
        serviceRequestTemplateSlice.actions.receiveAccountBillingAddressError()
      );
    }
  };

export default serviceRequestTemplateSlice.reducer;
