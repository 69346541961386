import { useDispatch, useSelector } from "react-redux";
import { setOpenSidebar } from "../modules/site/actions/siteActions";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

export function useNavHamburger() {
  const theme = useTheme();
  const deskTopBreakpoint = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [isDesktop, setIsDesktop] = useState(deskTopBreakpoint);
  useEffect(() => {
    if (isDesktop !== deskTopBreakpoint) {
      setIsDesktop(deskTopBreakpoint);
    }
  }, [deskTopBreakpoint]);

  const dispatch = useDispatch();
  const toggleSidebar = (status: boolean) => {
    // @ts-ignore
    dispatch(setOpenSidebar(status));
  };
  const handleHamburgerClick = () => {
    if (openSidebar === undefined && isDesktop) {
      toggleSidebar(false);
    } else {
      toggleSidebar(!openSidebar);
    }
  };

  // @ts-ignore
  const openSidebar = useSelector((state) => state.site.openSidebar);

  const isOpenSideBar =
    openSidebar === undefined && isDesktop ? true : openSidebar;

  return {
    isDesktop,
    isOpenSideBar,
    handleHamburgerClick,
  };
}
