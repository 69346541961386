import {
  ServiceContractsWidgetClient,
  WidgetContentResponseDto_1OfServicesExpiringContractsWidgetDto,
} from "@lib/ShiOneClient";
import { Divider, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import DevicesIcon from "@mui/icons-material/Devices";
import { NoContent } from "../../dashboards/framework";
import ServicesExpiringContractItem from "@widgets/services/ServicesExpiringContractItem";
import { RankingWidgetContainerHeight } from "@widgets/standard/RankingWidget";
import { RequestConsultationCardActions } from "@widgets/framework/RequestConsultationCardActions";
import {
  BaseWidgetProps,
  SkeletonWrapper,
  WidgetWrapper,
} from "shared-ui";

function addMonths(date: Date, months: number): Date {
  date.setMonth(date.getMonth() + months);
  return date;
}

function useGetExpiringContracts(termMonths: number) {
  const api = useApi(ServiceContractsWidgetClient);
  const endDate = addMonths(new Date(), termMonths);

  return useQuery<WidgetContentResponseDto_1OfServicesExpiringContractsWidgetDto>(
    {
      queryKey: ["services-expiring-contracts-widget", termMonths],
      queryFn: () => api.getServicesExpiringContractsWidgetData(endDate),
    }
  );
}

export default function ServicesExpiringContractsWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const [termMonths, setTermMonths] = useState(3);
  const { data, isLoading } = useGetExpiringContracts(termMonths);
  const containerStyle = { padding: "16px" };

  const containerSizeInPixels = 370; // approximately 5 rows...

  const termMonthsOptions = [
    { label: "Next 3 Months", value: 3 },
    { label: "Next 6 Months", value: 6 },
    { label: "Next 12 Months", value: 12 },
  ];

  const headerAction = () => {
    return (
      <Select
        sx={{ height: "40px" }}
        labelId="TermSelector"
        id="TermSelector"
        value={termMonths}
        onChange={(event) => {
          setTermMonths(
            Number(event.target.value) ? Number(event.target.value) : 0
          );
        }}
        autoWidth
        displayEmpty
      >
        {termMonthsOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      isEmpty={!isLoading && !data?.data?.expiringContracts?.length}
      containerSizeInPixels={containerSizeInPixels}
      noPadding={true}
      headerAction={headerAction()}
      cardActions={
        <RequestConsultationCardActions
          applicationContext={"Expiring Contracts"}
        />
      }
      cardHeaderDivider
    >
      {isLoading || (data?.data?.expiringContracts?.length ?? 0) > 0 ? (
        <SkeletonWrapper loading={isLoading}>
          {data?.data?.expiringContracts?.map((contract) => {
            return (
              <>
                <ServicesExpiringContractItem
                  key={`${contract.company}${contract.name}${contract.daysUntilExpiration}`}
                  contract={contract}
                  containerStyle={containerStyle}
                />
                <Divider
                  key={`Divider${contract.company}${contract.name}${contract.daysUntilExpiration}`}
                />
              </>
            );
          })}
        </SkeletonWrapper>
      ) : (
        <NoContent
          containerHeight={RankingWidgetContainerHeight}
          header={"No Expiring Contracts"}
          body={"You have no expiring contracts to view at the moment"}
          actionText={""}
          icon={<DevicesIcon />}
        />
      )}
    </WidgetWrapper>
  );
}
