import { RecordsDetailContainerProps } from "@features/projects-feature/projectTypes";
import { RecordsDetailItem } from "@features/projects-feature/components/RecordsDetailItem";
import React from "react";
import { KeyInformationWidgetContainer } from "shared-ui";

export const RecordsDetailContainer = ({
  recordsDetail,
  loading,
}: RecordsDetailContainerProps) => {
  return (
    <KeyInformationWidgetContainer>
      {recordsDetail.map((detail, index) => (
        <RecordsDetailItem
          key={detail.title || index}
          recordDetails={detail}
          loading={loading}
        />
      ))}
    </KeyInformationWidgetContainer>
  );
};
