export const FETCH_CONTRACTSLAS = "FETCH_CONTRACTSLAS";
export const FETCH_CONTRACTSLAS_SUCCESS = "FETCH_CONTRACTSLAS_SUCCESS";
export const FETCH_CONTRACTSLAS_ERROR = "FETCH_CONTRACTSLAS_ERROR";
export const CREATE_CONTRACTSLAS = "CREATE_CONTRACTSLAS";
export const CREATE_CONTRACTSLAS_SUCCESS = "CREATE_CONTRACTSLAS_SUCCESS";
export const CREATE_CONTRACTSLAS_ERROR = "CREATE_CONTRACTSLAS_ERROR";
export const UPDATE_CONTRACTSLAS = "UPDATE_CONTRACTSLAS";
export const UPDATE_CONTRACTSLAS_SUCCESS = "UPDATE_CONTRACTSLAS_SUCCESS";
export const UPDATE_CONTRACTSLAS_ERROR = "UPDATE_CONTRACTSLAS_ERROR";

export function fetchContractSlas() {
  return (dispatch) => {
    dispatch({
      type: FETCH_CONTRACTSLAS,
    });
  };
}
export function createContractSlas(request) {
  // console.log('trying to create contract sla', request)
  return (dispatch) => {
    // console.log('dispatching...')
    dispatch({
      type: CREATE_CONTRACTSLAS,
      payload: {
        data: {
          request,
        },
      },
    });
  };
}

export function updateContractSlas(request) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CONTRACTSLAS,
      payload: {
        data: {
          request,
        },
      },
    });
  };
}
