import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingOrError from "./LoadingOrError";

export default function SuccessRequestCallback() {
  const [proceed, setProceed] = useState<boolean>(false);
  const location = useLocation();
  const csParam = new URLSearchParams(location.search).get("route");

  useEffect(() => {
    localStorage.removeItem("impersonateUser");
    localStorage.removeItem("impersonateAccount");
    localStorage.removeItem("demoData");

    setProceed(true);
  }, []);

  if (proceed) {
    switch (csParam) {
      case "new-request-incident":
        return <Navigate to={"/support-center/new-request/incident"} />;
      case "new-request-service-request":
        return <Navigate to={"/support-center/new-request/service-request"} />;
      case "new-request-service-request-standard":
        return (
          <Navigate
            to={"/support-center/new-request/change-request/standard"}
          />
        );
      case "new-request":
        return <Navigate to={"/support-center/new-request"} />;
      case "requests":
        return <Navigate to={"/support-center/requests"} />;
      case "contracts":
        return <Navigate to={"/services/contracts"} />;
      case "projects":
        return <Navigate to={"/services/projects"} />;
      case "contractmapping":
        return <Navigate to={"/shi-internal/contractmapping"} />;
      case "marketplaceAccounts":
        return <Navigate to={"/shi-internal/marketplaceAccounts"} />;
      case "service-request-mapping":
        return <Navigate to={"/shi-internal/service-request-mapping"} />;
      case "myaccounts":
        return <Navigate to={"/shi-internal/myaccounts"} />;
    }
  }

  return <LoadingOrError loading />;
}
