export const GET_PICKLIST = "GET_PICKLIST";
export const GET_PICKLIST_SUCCESS = "GET_PICKLIST_SUCCESS";
export const GET_PICKLIST_ERROR = "GET_PICKLIST_ERROR";

export function getPicklist() {
  return (dispatch) => {
    dispatch({
      type: GET_PICKLIST,
    });
  };
}
