import {
  AzureBackupsClient,
  SimpleProtectedItemResource,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new AzureBackupsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestAzureBackups(): Promise<
  SimpleProtectedItemResource[]
> {
  return api.getAzureBackups();
}
