import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import { costAnalyticsState } from "./costAnalyticTypes";
import { searchAnalyticsByProvider } from "../../../api/costAnalyticsStore";
import { CostAnalyticProviders, CostAnalyticsResponse } from "lib/ShiOneClient";

const initialState: costAnalyticsState = {
  costAnalyticsFetchStatus: "idle",
  response: {} as { [key: string]: CostAnalyticsResponse },
};

const costAnalyticsSlice = createSlice({
  name: "costAnalytics",
  initialState,
  reducers: {
    receivingcostAnalytics(state) {
      state.costAnalyticsFetchStatus = "loading";
    },
    receivedcostAnalytics(
      state,
      action: PayloadAction<{ [key: string]: CostAnalyticsResponse }>
    ) {
      state.response = action.payload;
      state.costAnalyticsFetchStatus = "complete";
    },
    receivecostAnalyticsError(state) {
      state.costAnalyticsFetchStatus = "error";
    },
  },
});

export const getcostAnalytics = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(costAnalyticsSlice.actions.receivingcostAnalytics());
    try {
      const responseDictionary: { [key: string]: CostAnalyticsResponse } = {};
      const awsCostAnalyticsResponse = await searchAnalyticsByProvider(
        CostAnalyticProviders.Aws
      );
      const azureCostAnalyticsResponse = await searchAnalyticsByProvider(
        CostAnalyticProviders.Azure
      );
      const gcpCostAnalyticsResponse = await searchAnalyticsByProvider(
        CostAnalyticProviders.Gcp
      );
      const azureDirectCostAnalyticsResponse = await searchAnalyticsByProvider(
        CostAnalyticProviders.AzureDirect
      );

      responseDictionary.AWS = awsCostAnalyticsResponse;
      responseDictionary.Azure = azureCostAnalyticsResponse;
      responseDictionary.GCP = gcpCostAnalyticsResponse;
      responseDictionary.AzureDirect = azureDirectCostAnalyticsResponse;

      dispatch(
        costAnalyticsSlice.actions.receivedcostAnalytics(responseDictionary)
      );
    } catch {
      dispatch(costAnalyticsSlice.actions.receivecostAnalyticsError());
    }
  };
};

export default costAnalyticsSlice.reducer;
