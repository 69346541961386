import { ContractClient, ContractDTO } from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../utils";

const useGetContractById = (id: number) => {
  const isValidContractId = !isNaN(id);
  const api = useApi(ContractClient);

  const getContractById = async () => {
    return await api.getContract(id);
  };

  return useQuery<ContractDTO, Error>({
    queryKey: [`contract-detail-${id}`],
    queryFn: getContractById,
    enabled: isValidContractId,
  });
};

export default useGetContractById;
