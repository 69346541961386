export const PowerBiDateRangeStyles = (theme) => ({
  toggleContainer: {
    background: "white",
    border: "solid 1px #DFE3E8",
    boxShadow: "none",
    flex: "0 0 auto",
    [theme.breakpoints.down(940)]: {
      display: "none",
    },
  },
  dateSelector: {
    [theme.breakpoints.up(940)]: {
      display: "none",
    },
    display: "inline-flex",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    padding: theme.spacing(2),
  },
  noPadding: {
    padding: 0,
  },
  datePicker: {
    background: theme.palette.background.paper,
    width: "100%",
  },
  menuIcon: {
    fill: theme.palette.text.hint + " !important",
    marginRight: theme.spacing(0.5),
  },
});
