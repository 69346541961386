import React, { Fragment } from "react";
import { TextField, useTheme } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { getTimeZones } from "@vvo/tzdb";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
});

export default function TimezoneWidget({ id, label, onChange, required }) {
  const theme = useTheme();
  const classes = css(theme);

  const timezones = getTimeZones().map((zone) => zone.currentTimeFormat);

  return (
    <Autocomplete
      sx={classes.root}
      id={id}
      name={label}
      onChange={(event, value) => onChange(value)}
      options={timezones}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search timezones"
          InputLabelProps={{ shrink: true }}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
          }}
        />
      )}
    />
  );
}
