import { LabsClient } from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";

const api = new LabsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export interface ImageResponse {
  image: string;
  mimeType: string | undefined;
}

export class LabsLogoService {
  cache: Map<string, ImageResponse | undefined>;
  constructor() {
    this.cache = new Map();
  }
  mappedName(name: string): string {
    if (name.toLowerCase().startsWith("cisco")) return "Cisco";
    if (name.toLowerCase().startsWith("shi")) return "SHI";
    if (name.toLowerCase().startsWith("hp")) return "HPE";
    return name;
  }

  async getLogoByCompanyName(name: string): Promise<any> {
    const mappedName = this.mappedName(name);
    const logoId = `sites/asg-services/Logos/${mappedName}_Logo.png`;
    return this.getLogoById(logoId);
  }

  async getLogoById(logoId: string): Promise<ImageResponse | undefined> {
    if (this.cache.has(logoId)) {
      return this.cache.get(logoId) as ImageResponse;
    }
    try {
      const response = await api.getLabLogoBytes(logoId);
      const image = {
        image: `data:${response.mimeType};base64,${response.image}`,
        mimeType: response.mimeType,
      };
      this.cache.set(logoId, image);

      return image;
    } catch (error) {
      this.cache.set(logoId, undefined);
    }
  }
}
