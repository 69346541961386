import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";

// To add more see https://learn.microsoft.com/en-us/dotnet/standard/base-types/custom-date-and-time-format-strings
const dateFormats = [
  "MM/dd/yyyy",
  "dd/MM/yyyy",
  "yyyy-MM-dd",
  "MMM dd, yyyy",
  "MMMM dd, yyyy",
  "dd MMMM yyyy",
  "yyyy/MM/dd",
  "dddd, MMMM dd, yyyy",
  "M/d/yy",
  "MM/dd/yy",
  "dd-MMM-yy",
  "d-MMM-YY",
  "M/d/yyyy",
  "dd-MMM-yyyy",
];

// @ts-ignore
const DateFormatAutocomplete = ({ value, setValue }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      className={"grow"}
      freeSolo
      fullWidth
      options={dateFormats}
      value={value}
      onChange={setValue}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select or Create Date Format"
          placeholder="Type or select a date format in C# format"
        />
      )}
    />
  );
};

export default DateFormatAutocomplete;
