import {
  PageLayoutWidgetDto,
  ProjectClient,
  ProjectStatus,
  ProjectWidgetItemDto,
} from "@lib/ShiOneClient";
import { useApi } from "../../utils";
import { Box, Button, Container } from "@mui/material";
import React from "react";
import { prebuiltViewNames } from "@features/projects-feature/prebuiltViews";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";
import { graphColors, NoContent, Summary, useSummaryData } from "shared-ui";
import shiColors from "shared-ui/src/theme/shiColors";

const projectStatusColorPriorities = {
  Inactive: shiColors.gray.DEFAULT,
  New: shiColors.blue.DEFAULT,
  InProgress: shiColors.pink.DEFAULT,
  ChangeOrder: shiColors.green.DEFAULT,
  OnHold: shiColors.orange.DEFAULT,
  WaitingParts: shiColors.purple.dark,
  AtRisk: shiColors.red.DEFAULT,
  WaitingCustomer: shiColors.blue.dark,
  Complete: shiColors.purple.DEFAULT,
  Backlog: shiColors.yellow.DEFAULT,
};

const placeholderData = [
  {
    status: ProjectStatus.New,
    count: 5,
  },
  {
    status: ProjectStatus.InProgress,
    count: 2,
  },
  {
    status: ProjectStatus.AtRisk,
    count: 10,
  },
];

export default function ProjectsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const response = useGetProjectsWidgetsData();

  return (
    <SummaryWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => response}
      leftLabel={"Active Projects"}
      cardActions={
        response.data && response.data.length > 0 ? <CardActions /> : <></>
      }
      noDataElement={
        <NoContent
          header="No Active Projects"
          body="Browse our catalog of professional services to start a project"
          actionText={""}
        />
      }
    />
  );
}

function useGetProjectsWidgetsData() {
  const api = useApi(ProjectClient);

  return useSummaryData<ProjectWidgetItemDto[]>({
    queryKey: [`active-projects`],
    apiFunction: () => api.getActiveProjectsByAccount().then((r) => r || []),
    transformFunction: (data) => transformDataIntoSummaryWidgetData(data),
    placeholderData: placeholderData as ProjectWidgetItemDto[],
  });
}

function transformDataIntoSummaryWidgetData(
  projectGroup: ProjectWidgetItemDto[] | undefined
) {
  if (!projectGroup) {
    return [] as Summary[];
  }

  return projectGroup.map((group, index) => {
    const status = projectGroup[index]?.status;
    return {
      name: "",
      label: group.status?.replace(/([A-Z])/g, " $1") as string,
      data: group.count as number,
      color:
        status && projectStatusColorPriorities.hasOwnProperty(status)
          ? projectStatusColorPriorities[
              status as keyof typeof projectStatusColorPriorities
            ]
          : graphColors[index],
    };
  });
}

function CardActions() {
  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          href={AppendIfPrefixExists(
            `/services/projects?defaultView=${prebuiltViewNames.activeProjects}`
          )}
        >
          View Projects
        </Button>
      </Box>
    </Container>
  );
}
