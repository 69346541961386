import { Theme } from "@mui/material";

const MuiLink = (theme: Theme, rootElement: any) => ({
  defaultProps: {
    color: "primary",
    container: rootElement,
    underline: "none",
  },
  styleOverrides: {
    root: {
      fontWeight: "bold",
    },
  },
});
export default MuiLink;
