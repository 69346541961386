import React, { Fragment } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsWidgetData } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import GlobalConstants from "@constants";

const getDisplayNameFromLocation = (location) => {
  if (location) {
    const detailsArray = [
      location.city,
      location.state,
      location.postalCode,
    ].filter((x) => x);
    if (detailsArray.length === 0) {
      return location.name;
    }
    return `${location.name}: ${detailsArray.join(", ")}`;
  } else {
    return null;
  }
};

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
});

export default function LocationsWidget({ id, label, onChange, required }) {
  const theme = useTheme();
  const classes = css(theme);
  const dispatch = useDispatch();

  const accountId = useSelector(
    (state) => state.profile.userProfileResponse.userProfile.contact.accountID
  );
  const { accountLocations, accountLocationsFetchStatus } = useSelector(
    (state) => state.serviceRequestTemplate
  );

  const isLoading =
    accountLocationsFetchStatus === GlobalConstants.fetchStatus.loading;

  return (
    <Autocomplete
      sx={classes.root}
      onOpen={() => dispatch(getLocationsWidgetData(accountId))}
      id={id}
      name={label}
      options={accountLocations}
      getOptionLabel={(option) => getDisplayNameFromLocation(option)}
      onChange={(event, option) => onChange(getDisplayNameFromLocation(option))}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search for locations"
          InputLabelProps={{ shrink: true }}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
