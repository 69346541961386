import { security } from "./security";

export const GetBearerToken = async () => {
  try {
    return await security.getAccessTokenSilently()({});
  } catch (e) {
    console.log(e);
    if (typeof window !== "undefined") {
      (window as any).location.href = "/logout";
    }
  }
};
