import {
  PageLayoutWidgetDto,
  ServiceContractsWidgetClient,
  ServiceContractsWidgetDto,
  WidgetContentResponseDto_1OfServiceContractsWidgetDto,
} from "@lib/ShiOneClient";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { getContractTypeNameColor } from "@features/services-feature/contracts/functions";
import { asDto, Summary, useSummaryData } from "shared-ui";
import { useApi } from "../../utils";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

const placeholderData = [
  {
    label: "Service",
    count: 1,
  },
  {
    label: "SaaS",
    count: 1,
  },
];

function transformServiceContractsWidgetData(
  serviceContractsWidgetDto: ServiceContractsWidgetDto | undefined
): Summary[] {
  if (!serviceContractsWidgetDto || !serviceContractsWidgetDto.contractTypes) {
    return [];
  }

  return serviceContractsWidgetDto.contractTypes.map((item, _index) => ({
    name: "",
    label: item.contractType as string,
    data: item.count ?? 0,
    color: getContractTypeNameColor(item.contractType?.toLowerCase()),
  }));
}

function useGetServiceContractsWidgetData() {
  const api = useApi(ServiceContractsWidgetClient);

  return useSummaryData<WidgetContentResponseDto_1OfServiceContractsWidgetDto>({
    queryKey: ["service-contracts-widget"],
    apiFunction: () => api.getServiceContractsWidgetData(),
    transformFunction: (data) =>
      transformServiceContractsWidgetData(data?.data),
    placeholderData: asDto(placeholderData),
  });
}

function CardActions() {
  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button href={AppendIfPrefixExists("/services/contracts")}>
          View Service Contracts
        </Button>
      </Box>
    </Container>
  );
}

function NoDataElement() {
  const theme = useTheme();
  const greyColor = theme.palette.grey["600"];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      height={"100%"}
    >
      <Typography color={greyColor} variant={"h3"}>
        {"No Active Contracts"}
      </Typography>
    </Stack>
  );
}

export default function ServiceContractsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const response = useGetServiceContractsWidgetData();

  return (
    <SummaryWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => response}
      leftLabel={"Active Service Contracts"}
      cardActions={
        response.data && response.data.length > 0 ? <CardActions /> : <></>
      }
      noDataElement={<NoDataElement />}
    />
  );
}
