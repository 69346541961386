const updateUser = (userProfileResponse) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    CustomerName:
      userProfileResponse &&
      userProfileResponse.userProfile &&
      userProfileResponse.userProfile.accountName
        ? userProfileResponse.userProfile.accountName
        : "None",
    SHIDivision:
      userProfileResponse && userProfileResponse.accountDivision
        ? userProfileResponse.accountDivision
        : "None",
    SHIDistrict:
      userProfileResponse && userProfileResponse.accountDistrict
        ? userProfileResponse.accountDistrict
        : "None",
  });
};

class GoogleTagManagerTelemetry {
  constructor() {
    this.isInit = false;
  }

  init = () => {
    if (this.isInit) return;

    updateUser(null);

    const googleScript = document.createElement("script");
    googleScript.innerHTML = `
                (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm' });
                    var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                  })(window, document, 'script', 'dataLayer', 'GTM-KQJXHJL');`;

    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-KQJXHJL";
    iframe.style.height = "0";
    iframe.style.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);

    document.head.insertBefore(googleScript, document.head.children[0]);
    document.body.insertBefore(noscript, document.body.children[0]);

    this.isInit = true;
  };

  trackException = (
    uuid,
    errorMessage,
    browserType,
    userProfileResponse,
    router
  ) => {};

  beginUser = (userProfileResponse) => {
    updateUser(userProfileResponse);
  };

  endUser = () => {
    updateUser(null);
  };

  beginRoute = (location, featureRoute) => {};
  trackEvent = (name, context) => {};
}

export const googleTagManagerTelemetry = import.meta.env
  .GOOGLE_TAG_MANAGER_TELEMETRY_ENABLED
  ? new GoogleTagManagerTelemetry()
  : null;
