import {
  Autocomplete,
  lighten,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalConstants from "@constants";
import { useApi } from "../utils";
import {
  AccountSearchQuery,
  AccountSwitcherClient,
  AccountSwitcherResponse,
} from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { IRootState } from "../../../modules";
import { handleImpersonateAccount } from "functions/handleImpersonateAccount";
import { useDebouncedSearch } from "../utils/useDebouncedSearch";

const css = (theme: Theme) => ({
  shiAccountSelector: {
    backgroundColor: theme.palette.background.paper,
    "& .MuiOutlinedInput-root": {
      border: "1px solid",
      borderColor: lighten(theme.palette.common.black, 0.73),
      borderRadius: "4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

export default function AccountImpersonation() {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const classes = css(theme);

  const { responseFunc, setSearchTerm } = useGetActiveAccounts();

  const debouncedValue = useDebouncedSearch(searchText, 1000);

  useEffect(() => {
    if (debouncedValue.length >= 3 || debouncedValue.length === 0) {
      setSearchTerm(debouncedValue);
    }
  }, [debouncedValue, setSearchTerm]);

  const { userProfileResponse } = useSelector(
    (state: IRootState) => state.profile
  );

  const allAccounts = responseFunc.data?.accounts;

  return (
    <Autocomplete
      id="shi-account-select"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={allAccounts || []}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.accountName + " - " + option.axNumber}
      isOptionEqualToValue={(option, value) =>
        option.accountName === value.accountName
      }
      closeText="Return to SHI"
      fullWidth={true}
      onChange={handleImpersonateAccount}
      onInputChange={(_e, value) => setSearchText(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          value={searchText}
          placeholder={userProfileResponse.userProfile?.accountName}
          sx={classes.shiAccountSelector}
          data-telemetry-name="Switch Accounts"
          InputLabelProps={{ shrink: true }}
          label={""}
          variant="outlined"
          size={"small"}
        />
      )}
    />
  );
}

function useGetActiveAccounts() {
  const [searchTerm, setSearchTerm] = useState("");
  const api = useApi(AccountSwitcherClient);

  const query = {
    searchString: searchTerm,
    pageNumber: 0,
    pageSize: GlobalConstants.pageSize.thirty,
    impersonateOnly: true,
  } as AccountSearchQuery;

  return {
    responseFunc: useQuery<AccountSwitcherResponse>(
      ["accountImpersonation", searchTerm],
      () => api.getAllActiveAccounts(query)
    ),
    searchTerm,
    setSearchTerm,
  };
}
