import { Box, Grid, InputBase, Link, Tooltip, Typography } from "@mui/material";
import TableFilters from "../../../theme/extensions/TableFilters";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { CustomToolbarStyles } from "./styles/CustomToolbarStyles";
import ActionsDropdownMenuButton from "./custom-toolbar-components/ActionsDropdownMenuButton";
import SearchIcon from "@mui/icons-material/Search";
import SwitchWithCustomIcon from "../theme/overrides/SwitchWithCustomIcon";
import UserViews, { View } from "./custom-toolbar-components/UserViews";
import ToolbarFilterChips from "./custom-toolbar-components/ToolbarFilterChips";

interface CustomToolbarProps {
  selectedItems?: [] | Map<any, any>;
  allCheckboxSelected?: boolean;
  setAllCheckboxSelected?: Dispatch<SetStateAction<boolean>>;
  rowsCount?: number;
  filters?: any[];
  searchValue?: string;
  handleFilters?: (value: any) => void;
  handleUserViewFilters?: (value: any) => void;
  handleSearch?: (event: any) => void;
  actionsMenuListComponent?: () => React.ReactElement;
  handleIncludeSwitch?: (event: any) => void;
  isInclude?: boolean;
  otherComponents?: any;
  showUserViews?: boolean;
  prebuiltViews?: View[];
  defaultPrebuiltView?: View;
  defaultViewTitle?: string;
  disableCustomViews?: boolean;
}

export default function CustomToolbar({
  selectedItems,
  allCheckboxSelected,
  setAllCheckboxSelected,
  rowsCount = 0,
  filters,
  handleFilters,
  handleUserViewFilters,
  searchValue,
  handleSearch,
  actionsMenuListComponent,
  otherComponents,
  handleIncludeSwitch,
  isInclude,
  showUserViews = true,
  prebuiltViews,
  defaultPrebuiltView,
  disableCustomViews,
}: CustomToolbarProps) {
  const theme = useTheme();
  const styles = CustomToolbarStyles(theme);

  const [currentFilters, setCurrentFilters] = useState<any[]>([]);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (filters) {
      filters?.forEach((filter) => {
        if (filter.datePicker && (filter.startDate || filter.endDate)) {
          count++;
        }
        if (filter.datePicker && filter.datePickerGroupName && filter.value) {
          count++;
        }
        if (!filter.datePicker && filter.value?.length) {
          count++;
        }
        setActiveFiltersCount(count);
      });
    }
  }, [filters]);
  const selectedItemsSize = () => {
    if (selectedItems instanceof Map) {
      return selectedItems.size;
    } else if (selectedItems instanceof Array) {
      return selectedItems.length;
    } else return 0;
  };

  const handleCheckboxClick = () => {
    setAllCheckboxSelected?.(!allCheckboxSelected);
  };

  const selectedActions = () => {
    return (
      <div style={styles.selectedActionsContainer}>
        {actionsMenuListComponent && (
          <ActionsDropdownMenuButton>
            {actionsMenuListComponent()}
          </ActionsDropdownMenuButton>
        )}
        <Typography sx={styles.selectText} color="inherit">
          {allCheckboxSelected ? rowsCount : selectedItemsSize()} item(s)
          selected
        </Typography>
        <Link onClick={handleCheckboxClick}>
          {allCheckboxSelected ? "Unselect" : "Select"} all {rowsCount}
        </Link>
      </div>
    );
  };

  const searchBar = () => {
    if (handleSearch) {
      return (
        <Box sx={styles.search}>
          <Box
            sx={handleIncludeSwitch ? styles.searchSwitch : styles.searchIcon}
          >
            {handleIncludeSwitch ? (
              <Tooltip followCursor title={isInclude ? "Include" : "Exclude"}>
                <SwitchWithCustomIcon
                  checked={isInclude}
                  onChange={handleIncludeSwitch}
                />
              </Tooltip>
            ) : (
              <SearchIcon />
            )}
          </Box>
          <InputBase
            placeholder="Search items…"
            value={searchValue}
            onChange={handleSearch}
            sx={styles.searchInput}
            inputProps={{ "aria-label": "search" }}
          />
        </Box>
      );
    }
  };

  const handleFiltersInterceptor = (newArr: any[]) => {
    setCurrentFilters(newArr);
    if (handleFilters) {
      handleFilters(newArr);
    }
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Grid
        container
        sx={{ width: "100%", height: "36px" }}
        flexWrap={"nowrap"}
        alignItems={"center"}
      >
        {handleFilters && showUserViews && selectedItemsSize() === 0 && (
          <UserViews
            gridId={"toolbar"}
            defaultView={
              defaultPrebuiltView || { name: "All", filters: filters }
            }
            setFilterHandler={handleUserViewFilters || handleFilters}
            currentFilters={currentFilters}
            activeFiltersCount={activeFiltersCount}
            prebuiltViews={prebuiltViews}
            disableCustomViews={disableCustomViews}
          />
        )}
        <Grid item container flexGrow={1} alignItems={"center"}>
          {selectedItemsSize() > 0 ? selectedActions() : searchBar()}
        </Grid>
        <Grid item justifyContent={"flex-end"} alignItems={"center"}>
          {handleFilters && (
            <TableFilters
              filters={filters ?? []}
              changeFilters={handleFiltersInterceptor}
              activeFiltersCount={activeFiltersCount}
            />
          )}
        </Grid>
        <div style={styles.marginLeft}>{otherComponents}</div>
      </Grid>
      {handleFilters && filters && activeFiltersCount > 0 && (
        <>
          <div style={{ height: "39px" }}></div>
          <div
            id="filter-chips-bar"
            style={styles.filterChipsBar as React.CSSProperties}
          >
            <span style={{ color: "grey", paddingLeft: 16 }}>Filters: </span>
            <div
              style={{
                display: "flex",
                gap: 6,
                flexDirection: "row-reverse",
                paddingTop: 8,
                paddingBottom: 8,
                overflowY: "auto",
              }}
            >
              <ToolbarFilterChips
                handleFilters={handleFiltersInterceptor}
                filters={filters}
                setActiveFiltersCount={setActiveFiltersCount}
                activeFiltersCount={activeFiltersCount}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
