import React from "react";
import _ from "lodash";
import ShiOneLogo from "../../../shared-ui/src/components/ShiOneLogo";
import { Box, Link, Typography } from "@mui/material";
import AuthPageContainer from "./AuthPageContainer";
import { useLocation } from "react-router-dom";
import LogoutButton from "./Authorization/LogoutButton";

const auth0Error = "access_denied";

export default function AuthError() {
  let { search } = useLocation();
  const params = _.chain(search)
    .replace("?", "")
    .split("&")
    .map(_.partial(_.split, _, "=", 2))
    .fromPairs()
    .value();

  return (
    <AuthPageContainer>
      <ShiOneLogo height={"32px"} />
      {!params.error_description && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3>Login Error</h3>
          <Typography width={"80%"}>
            Sorry about that! Please <LogoutButton /> and log back in. We&#39;ve
            logged this error. If you see this message again, please contact us
            at{" "}
            <Link href="mailto:SupportServices@shi.com">
              SupportServices@shi.com
            </Link>
            .
          </Typography>
        </Box>
      )}
      {params.error_description && params.error_description.includes("IDP") && (
        <div>
          <h3>{decodeURI(params.error_description.replace("(IDP)", ""))}</h3>
          {params["error"] === auth0Error && (
            <p>
              Your email with SHI.com must be verified before you can access SHI
              One.
            </p>
          )}
          <p>
            Please <LogoutButton text={"Click here"} /> in order to begin the
            email verification process.
          </p>
        </div>
      )}
      {params.error_description &&
        !params.error_description.includes("IDP") &&
        params.error_description.includes("AADSTS65004") && (
          <div>
            <h3>AADSTS65004</h3>
            <p>
              You have declined to consent to this application. If that was a
              mistake, you can <LogoutButton /> and log back in to retry
              consent.
            </p>
            <p>
              If you attempted to consent but were denied access, please contact
              an Office 365 or Azure Active Directory Administrator within your
              company.
            </p>
            <p>
              If you think you may have logged in with the wrong account, you
              can <LogoutButton /> and log back in with another account.
            </p>
          </div>
        )}
      {params.error_description &&
        !params.error_description.includes("force-password-reset") &&
        !params.error_description.includes("IDP") &&
        !params.error_description.includes("AADSTS50105") &&
        !params.error_description.includes("AADSTS65004") && (
          <div>
            <h3>{decodeURIComponent(params.error_description)}</h3>
            {params["error"] === auth0Error && (
              <>
                <Typography>
                  An email is being sent to your email address from
                  no-reply@one.shi.com. Please check your junk and spam folders
                  if you don&#39;t see the email.
                </Typography>
                <Typography m={2}>
                  Still don't see your email? Contact us at{" "}
                  <Link href="mailto:SupportServices@shi.com">
                    SupportServices@shi.com
                  </Link>
                </Typography>
              </>
            )}

            <Typography variant={"body2"}>
              If you think you may have logged in with the wrong account, you
              can <LogoutButton /> and log back in with another account.
            </Typography>
          </div>
        )}
      {params.error_description &&
        params.error_description.includes("force-password-reset") && (
          <div>
            <h3>Password Reset Required</h3>
            <p>
              Please return to the login page and initiate a password reset. You
              may do this by clicking the <b>SHI ONE</b> login section, typing
              your email address into the login form, then clicking{" "}
              <b>Forgot password?</b> below the Log In button. Please see the
              image below for details.
            </p>
            <p>
              <LogoutButton />
            </p>
            <p>
              <img
                style={{ width: "100%" }}
                src={
                  "https://shionestorage.blob.core.windows.net/shione/reset-pass.png"
                }
                alt={"Reset Instructions"}
              />
            </p>
          </div>
        )}
      {params.error_description &&
        !params.error_description.includes("IDP") &&
        params.error_description.includes("AADSTS50105") && (
          <div>
            <h3>Error Code AADSTS50105</h3>
            <p>
              This error means an Azure AD/Office 365 administrator in your
              organization has likely changed some settings that has resulted in
              a loss of access to this application. You can ask an Azure
              AD/Office 365 administrator about this, and if this was in error
              you can copy and paste this link to them to click and allow users
              to regain access to this site:
            </p>
            <p>
              <Link
                href="https://login.microsoftonline.com/common/adminconsent?client_id=612f0fa7-b4f7-44b9-8684-5e0c36200771"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://login.microsoftonline.com/common/adminconsent?client_id=612f0fa7-b4f7-44b9-8684-5e0c36200771
              </Link>
            </p>
            <p>
              Alternatively, you can <LogoutButton /> and create a SHI One
              Account on the login page instead of using your Microsoft account.
              You may have to register (using your current email address) by
              clicking {'"'}Don't have a login? Register an account{'"'} first
              and following the instructions.
            </p>
            <p>
              If you think you may have logged in with the wrong account, you
              can <LogoutButton /> and log back in with another account.
            </p>
          </div>
        )}
    </AuthPageContainer>
  );
}
