import { View } from "../../components/custom-toolbar-components/UserViews";

export const prebuiltViewNames = {
  allProjects: "All Projects",
  activeProjects: "Active Projects",
  completedProjects: "Completed Projects",
};

export const prebuiltViews: View[] = [
  {
    name: prebuiltViewNames.allProjects,
    filters: [
      {
        label: "Contract Name",
        accessor: "contractName",
        labelWidth: 101,
        value: "",
        options: [],
      },
    ],
  },
  {
    name: prebuiltViewNames.activeProjects,
    filters: [
      {
        label: "Active",
        accessor: "active",
        labelWidth: 98,
        options: [true, false],
        value: true,
      },
    ],
  },
  {
    name: prebuiltViewNames.completedProjects,
    filters: [
      {
        label: "Complete",
        accessor: "complete",
        labelWidth: 98,
        options: [true, false],
        value: true,
      },
    ],
  },
];
