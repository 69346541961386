import {
  AccountContractRequest,
  AccountContractResponse,
  CloudAccount,
  ContractClient,
  ContractDTO,
  ContractOverview,
  ContractSLA,
  FSACacheResponse,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new ContractClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestContractById(
  contractId: number
): Promise<ContractDTO> {
  return api.getContract(contractId);
}

export async function getRestContractOverview(
  contractId: number
): Promise<ContractOverview> {
  return api.getContractOverview(contractId, false);
}

export async function getRestAccountContracts(
  request: AccountContractRequest
): Promise<AccountContractResponse> {
  return api.getAccountContracts(false, request);
}

export async function getRestCloudContracts(): Promise<CloudAccount[]> {
  return api.getCloudAccounts();
}

export async function getRestSLAs(): Promise<ContractSLA[]> {
  return api.getSLAs();
}

export async function getRestFSAs(): Promise<FSACacheResponse> {
  return api.getFSAs();
}
