import {
  Button,
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import {
  SampleWidgetAClient,
  WidgetContentResponseDto_1OfString,
} from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { useApi } from "../../utils";
import {
  useGetWidgetFilterUserConfig,
  useSetUserColumnWidgetFilterConfig,
} from "../../dashboards/framework";

import {
  BaseWidgetProps,
  loading,
  WidgetWrapper,
  asDto,
} from "shared-ui";

const filterKey = "widget-filters";

function HeaderAction({
  age,
  setAge,
}: {
  age: number;
  setAge: (age: number) => void;
}) {
  return (
    <FormControl sx={{ m: 0.5, minWidth: 200 }}>
      <Select
        sx={{ height: "40px" }}
        labelId={filterKey}
        id={filterKey}
        value={age}
        onChange={(e) => {
          setAge(e.target.value as number);
        }}
        autoWidth
        displayEmpty
      >
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
    </FormControl>
  );
}

function CardActions() {
  return (
    <>
      <Button size="small">Action</Button>
    </>
  );
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: "10/22/2023 12:23:29PM",
};

function useGetSampleWidgetEData() {
  const api = useApi(SampleWidgetAClient);

  return useQuery<WidgetContentResponseDto_1OfString, Error>({
    queryKey: [`sample-widget-e`],
    queryFn: () => api.getSampleWidgetA().then((r) => r),
    staleTime: ms("10m"),
    placeholderData: asDto(placeholderData),
  });
}

export default function SampleWidgetE({ pageLayoutWidget }: BaseWidgetProps) {
  const initialValue = 20;

  const filterUserConfig = useGetWidgetFilterUserConfig(
    pageLayoutWidget.widgetId
  );

  const { currentFilterValue: age, setFilter: setAge } =
    useSetUserColumnWidgetFilterConfig(
      pageLayoutWidget.widgetId!,
      filterKey,
      filterUserConfig,
      initialValue
    );

  const response = useGetSampleWidgetEData();

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      cardActions={<CardActions />}
      headerAction={<HeaderAction age={age} setAge={setAge} />}
      error={response.isError}
    >
      <Box>
        {loading(
          <Typography variant={"h1"}>{response?.data?.data}</Typography>,
          response.isPlaceholderData || filterUserConfig.isLoading,
          response.isError
        )}

        {loading(
          <Typography variant={"h2"}>{`Age is ${age}`}</Typography>,
          response.isPlaceholderData || filterUserConfig.isLoading,
          response.isError
        )}
      </Box>
    </WidgetWrapper>
  );
}
