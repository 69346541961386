import { IConfig } from "lib/ShiOneClient";
import { GetBearerToken } from "./BearerToken";

const getImpersonateHeaders = () => {
  return {
    impersonate: localStorage.getItem("impersonateUser"),
    impersonateAccount: localStorage.getItem("impersonateAccount"),
    demoData: localStorage.getItem("demoData"),
  };
};

const getAdditionalHeaders = () => {
  const { impersonate, impersonateAccount, demoData } = getImpersonateHeaders();
  const additionalHeaders: HeadersInit = new Headers();

  if (impersonate) {
    additionalHeaders.set("ImpersonateContact", impersonate);
  }

  if (impersonateAccount) {
    additionalHeaders.set("ImpersonateAccount", impersonateAccount);
  }

  if (demoData) {
    additionalHeaders.set("DemoData", demoData);
  }

  return additionalHeaders;
};

const getBearerToken = async () => {
  const token = await GetBearerToken();
  return `Bearer ${token}`;
};

export const ApiClientConfig: IConfig = {
  getAuthorization: getBearerToken,
  getAdditionalHeaders: getAdditionalHeaders,
};
