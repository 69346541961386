import React from "react";
import { getInitials } from "../../../../../functions/udfHelper";
import { Box, IconButton, Typography } from "@mui/material";
import { StyledAvatar } from "@features/projects-feature/utils/components/StyledProjectDetailsMetadataComponents";
import MailIcon from "@mui/icons-material/Mail";

const AvatarContact: React.FC<{ fullName: string; mail: string }> = ({
  fullName,
  mail,
}) => {
  const initials = getInitials(fullName);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1.2 }}>
      <StyledAvatar>{initials}</StyledAvatar>

      <Typography variant="body2">{fullName}</Typography>

      <IconButton aria-label="Mail" href={`mailto:${mail}`}>
        <MailIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default AvatarContact;
