const MuiListItemText = (rootElement) => ({
  defaultProps: {
    container: rootElement,
  },
  root: {
    "& span": {
      display: "inline",
    },
  },
});

export default MuiListItemText;
