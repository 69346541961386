import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import { awsSimpleRecommendationsState } from "./awsSimpleRecommendationsTypes";
import {
  getRestReservationPurchaseRecommendations,
  getRestRightsizingRecommendations,
  getRestSavingsPlanRecommendations,
  getRestTrustedAdvisor,
} from "../../../api/awsSimpleRecommendationsStore";
import {
  ReservationPurchaseResult,
  RightSizingResult,
  SavingsPlansPurchaseResult,
  TrustedAdvisorResult,
} from "lib/ShiOneClient";
import _ from "lodash";

const initialState: awsSimpleRecommendationsState = {
  fetchSimpleRecommendationsStatus: "idle",
  trustedAdvisor: [],
  savingsPlanRecommendations: [],
  rightSizingRecommendations: [],
  reservationPurchaseRecommendations: [],
};

const receivedEmptyModels = (response: any[]): boolean => {
  return _.every(response, (rec) => _.every(rec, (r) => !r || _.isEmpty(r)));
};

const awsSimpleRecommendationsSlice = createSlice({
  name: "awsSimpleRecommendations",
  initialState,
  reducers: {
    receivingSimpleRecommendations(state) {
      state.fetchSimpleRecommendationsStatus = "loading";
    },
    receiveTrustedAdvisor(
      state,
      action: PayloadAction<TrustedAdvisorResult[]>
    ) {
      if (!receivedEmptyModels(action.payload)) {
        state.trustedAdvisor = action.payload;
      }
    },
    receiveSavingsPlanRecommendations(
      state,
      action: PayloadAction<SavingsPlansPurchaseResult[]>
    ) {
      if (!receivedEmptyModels(action.payload)) {
        state.savingsPlanRecommendations = action.payload;
      }
    },
    receiveRightSizingRecommendations(
      state,
      action: PayloadAction<RightSizingResult[]>
    ) {
      if (!receivedEmptyModels(action.payload)) {
        state.rightSizingRecommendations = action.payload;
      }
    },
    receiveReservationPurchaseRecommendations(
      state,
      action: PayloadAction<ReservationPurchaseResult[]>
    ) {
      if (!receivedEmptyModels(action.payload)) {
        state.reservationPurchaseRecommendations = action.payload;
      }
    },
    receiveSimpleRecommendations(state) {
      state.fetchSimpleRecommendationsStatus = "complete";
    },
    receiveSimpleRecommendationsError(state) {
      state.fetchSimpleRecommendationsStatus = "error";
    },
  },
});

export const getSimpleRecommendations =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(
      awsSimpleRecommendationsSlice.actions.receivingSimpleRecommendations()
    );
    try {
      const trustedAdvisor = await getRestTrustedAdvisor();
      dispatch(
        awsSimpleRecommendationsSlice.actions.receiveTrustedAdvisor(
          trustedAdvisor
        )
      );

      const rightsizingRecommendations =
        await getRestRightsizingRecommendations();
      dispatch(
        awsSimpleRecommendationsSlice.actions.receiveRightSizingRecommendations(
          rightsizingRecommendations
        )
      );

      const savingsPlanRecommendations =
        await getRestSavingsPlanRecommendations();
      dispatch(
        awsSimpleRecommendationsSlice.actions.receiveSavingsPlanRecommendations(
          savingsPlanRecommendations
        )
      );

      const reservationPurchaseRecommendations =
        await getRestReservationPurchaseRecommendations();
      dispatch(
        awsSimpleRecommendationsSlice.actions.receiveReservationPurchaseRecommendations(
          reservationPurchaseRecommendations
        )
      );

      dispatch(
        awsSimpleRecommendationsSlice.actions.receiveSimpleRecommendations()
      );
    } catch {
      dispatch(
        awsSimpleRecommendationsSlice.actions.receiveSimpleRecommendationsError()
      );
    }
  };

export default awsSimpleRecommendationsSlice.reducer;
