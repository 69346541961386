import RankingWidget, {
  RankingWidgetContainerHeight,
} from "@widgets/standard/RankingWidget";
import { PageLayoutWidgetProps } from "@widgets/utils/widgetTypes";
import React from "react";
import { DateFilterOptionHeaderAction } from "@widgets/utils/support/DateFilterOptionHeaderAction";
import { DateFilterOption } from "@lib/ShiOneClient";
import {
  NoContent,
  useWidgetFilterUserConfig,
} from "../../dashboards/framework";
import {
  ContractRequestRankingsFunctionKey,
  ContractRequestRankingsWidgetGridDefinitions,
  processContractRequestRankingsGridData,
} from "@widgets/utils/services/ContractRequestRankingsWidgetFunctions";
import useGetContractRequestRankings from "@widgets/services/hooks/useGetContractRequestRankings";
import { isLoading } from "@widgets/utils/widgetFunctions";

const ContractRequestRankingsWidget: React.FC<PageLayoutWidgetProps> = ({
  pageLayoutWidget,
}) => {
  const initialDateFilter = DateFilterOption.CurrentMonth;

  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      pageLayoutWidget.widgetId!,
      ContractRequestRankingsFunctionKey,
      initialDateFilter
    );

  const {
    data: widgetContentResponse,
    isError: responseHasError,
    isPlaceholderData: responseIsPlaceholderData,
  } = useGetContractRequestRankings(currentFilterValue);

  const isLoadingData = isLoading([
    isLoadingFilterUserConfig,
    responseIsPlaceholderData,
  ]);

  let processedData: any[] = [];
  if (!isLoadingData && widgetContentResponse?.data) {
    processedData = processContractRequestRankingsGridData(
      widgetContentResponse.data
    );
  }

  const gridDefinitions = ContractRequestRankingsWidgetGridDefinitions(
    isLoadingData,
    responseHasError,
    processedData.length
  );

  return (
    <RankingWidget
      headerAction={
        <DateFilterOptionHeaderAction
          filterBy={currentFilterValue}
          setFilterBy={setFilter}
          loading={isLoadingData}
          filterKey={ContractRequestRankingsFunctionKey}
        />
      }
      pageLayoutWidget={pageLayoutWidget}
      data={processedData}
      gridDefinitions={gridDefinitions}
      getRowId={(row) => row.contractId}
      rowExpandLookup={undefined}
      noDataElement={
        <NoContent
          header={"No Requests"}
          containerHeight={RankingWidgetContainerHeight}
        />
      }
      loading={isLoadingData}
    />
  );
};

export default ContractRequestRankingsWidget;
