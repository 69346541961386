import "./wdyr";

import React from "react";
import store from "./store";
import "sanitize.css";
import "./css/app.css";
import BrowserDetection from "react-browser-detection";

import { trackException } from "functions/telemetry";
import { v1 as uuidv1 } from "uuid";
import ms from "ms";
import AuthPageContainer from "./containers/AuthPageContainer";
import { Link, Typography } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      countdown: 3,
      browserType: props.browserType,

      uuid: uuidv1(),
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error };
  }

  componentDidCatch(error, errorInfo) {
    if (this.isDynamicLoadError()) {
      setInterval(() => {
        if (this.state.countdown === 1) {
          localStorage.setItem("autoRestartFromIndex", Date.now());
          window.location.reload();
        } else {
          this.setState({
            countdown: this.state.countdown - 1,
          });
        }
      }, 1000);
    }
    this.setState({
      HasError: true,
    });
  }

  isDynamicLoadError = () => {
    const storedTimestamp = localStorage.getItem("autoRestartFromIndex");
    const errorMessage = this.state.errorMessage.toString().toLowerCase();

    if (storedTimestamp) {
      const currentTimestamp = Date.now();

      if (currentTimestamp - storedTimestamp < ms("5m")) return false;
    }

    const importModuleErrors = [
      "failed to fetch dynamically imported module",
      "failed to asynchronously load component",
      "failed to load module script",
      "error loading dynamically imported module",
    ];
    return importModuleErrors.some((err) => errorMessage.includes(err));
  };

  render() {
    if (this.state.hasError) {
      if (this.props.submitted) {
        const currentState = store.getState();
        trackException(
          this.state.uuid,
          this.state.errorMessage,
          this.props.browserType,
          currentState?.profile?.userProfileResponse,
          currentState?.router
        );
        this.props.onSubmit();
      }

      return (
        <AuthPageContainer>
          {this.isDynamicLoadError() ? (
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <h2>A new version is available</h2>
              <p>
                <hr />
                The application will reload to get the recent changes. Restart
                in: {this.state.countdown}
              </p>
            </div>
          ) : (
            <>
              <BrowserDetection>{this.props.browserHandler}</BrowserDetection>
              <Typography style={{ marginTop: "10px", textAlign: "center" }}>
                This error has been logged and reported with an ID of{" "}
                {this.state.uuid}.
                <p>
                  <hr />
                  You can also try to{" "}
                  <Link underline={"none"} href="/logout">
                    log out
                  </Link>{" "}
                  and log back in.
                </p>
              </Typography>
            </>
          )}
        </AuthPageContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
