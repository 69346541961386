import { FormControl, MenuItem, Select } from "@mui/material";
import { useApi } from "../../utils";
import {
  LastMonthsCostLineItem,
  LastMonthsCostsWidgetClient,
  LastMonthsCostType,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";

import DevicesIcon from "@mui/icons-material/Devices";
import React from "react";
import { formatCurrency } from "../../utils/formatters/formatCurrency";
import {
  graphColors,
  NoContent,
  SkeletonWrapper,
  Summary,
  useSummaryData,
} from "shared-ui";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    lastMonthsCostType: "ByServiceType",
    totalCost: 86142.32,
    lastMonthsCostLineItems: [
      {
        name: "Subscription",
        cost: 86142.32,
        percentage: 100.0,
      },
    ],
  },
};

function transformDataIntoSummaryWidgetData(
  lineItems: LastMonthsCostLineItem[] | undefined
) {
  if (!lineItems || !lineItems.length) {
    return [] as Summary[];
  }

  return lineItems?.map((lineItem, index) => ({
    name: "",
    label: lineItem.name || "Unknown",
    data: lineItem.cost || 0,
    color: graphColors[index],
  }));
}

function HeaderAction({
  filter,
  setFilter,
  loading,
}: {
  filter: string;
  setFilter: (string: string) => void;
  loading: boolean;
}) {
  return (
    <SkeletonWrapper loading={loading}>
      <FormControl sx={{ m: 0.5, minWidth: 200 }}>
        <Select
          sx={{ height: "40px", width: 200 }}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value={LastMonthsCostType.ByContract}>By Contract</MenuItem>
          <MenuItem value={LastMonthsCostType.ByContractType}>
            By Contract Type
          </MenuItem>
          <MenuItem value={LastMonthsCostType.ByVendor}>By Vendor</MenuItem>
          <MenuItem value={LastMonthsCostType.ByServiceType}>
            By Service Type
          </MenuItem>
        </Select>
      </FormControl>
    </SkeletonWrapper>
  );
}

function useGetWidgetData(filter: LastMonthsCostType) {
  const api = useApi(LastMonthsCostsWidgetClient);

  return useSummaryData<LastMonthsCostLineItem[]>({
    queryKey: [`last-months-cost-widget-data`, filter],
    apiFunction: () =>
      api.costs(filter).then((r) => r.data?.lastMonthsCostLineItems || []),
    transformFunction: (data) => transformDataIntoSummaryWidgetData(data) ?? [],
    placeholderData: placeholderData.data
      .lastMonthsCostLineItems as LastMonthsCostLineItem[],
  });
}

export default function LastMonthsCostsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    "last-months-cost-widget-filter",
    LastMonthsCostType.ByServiceType
  );

  const response = useGetWidgetData(currentFilterValue);

  return (
    <SummaryWidget
      formatter={formatCurrency}
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => response}
      leftLabel={"Total Cost"}
      headerAction={
        <HeaderAction
          filter={currentFilterValue}
          setFilter={setFilter}
          loading={response.isPlaceholderData}
        />
      }
      noDataElement={
        <NoContent
          containerHeight={350}
          header={"No Costs"}
          body={"You have no costs to view at the moment"}
          actionText={""}
          icon={<DevicesIcon />}
        />
      }
    />
  );
}
