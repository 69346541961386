import { QueryKey } from "@tanstack/react-query";
import { TabType } from "@features/assets-feature/types/TabsType";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import assetsAccessor from "@features/assets-feature/utils/assetsAccessor";

export const CACHE_KEY_ASSET_INVENTORY: QueryKey = ["asset_inventory"];

export const CACHE_KEY_ASSET_LOCATIONS: QueryKey = ["asset_locations"];
export const CACHE_KEY_PRODUCT_CATALOG: QueryKey = ["product_catalog"];
export const CACHE_KEY_END_USER_DEVICES: QueryKey = ["end_user_devices"];
export const CACHE_KEY_ASSET_LIFECYCLE: QueryKey = ["asset_lifecycle"];
export const CACHE_KEY_ASSET_DETAIL: QueryKey = ["asset_detail"];
export const CACHE_KEY_ASSET_CONTRACTS: QueryKey = ["asset_contracts"];
export const CACHE_KEY_ASSET_PICKLIST: QueryKey = ["asset_picklist"];
export const CACHE_KEY_ASSET_TAGS: QueryKey = ["asset_tags"];
export const CACHE_KEY_WAREHOUSE_STOCK: QueryKey = ["warehouse_stock"];
export const CACHE_KEY_WAREHOUSE_ORDERS: QueryKey = ["warehouse_orders"];

export const InventoryTabIds = {
  all: "all",
  hardwareConsumables: "hardware-consumables",
  softwareSubscription: "software-subscription",
  dataDigital: "data-digital",
};
export const AssetInventoryTabs: TabType[] = [
  { tabId: InventoryTabIds.all, tabName: "All" },
  {
    tabId: InventoryTabIds.hardwareConsumables,
    tabName: "Hardware & Consumables",
  },
  {
    tabId: InventoryTabIds.softwareSubscription,
    tabName: "Software & Subscriptions",
  },
  {
    tabId: InventoryTabIds.dataDigital,
    tabName: "Data & Digital",
  },
];

export const InventoryInitialDataGrid = {
  initialPageSize: 25,
  initialSortField: assetsAccessor.displayName,
  initialSortDirection: "desc" as GridSortDirection,
};
export const featureFlags = {
  assetV2: "assets.v2-UI",
};

export const AssetDialogTabs = {
  details: "details",
  contracts: "contracts",
  children: "children",
  tagsAndNotes: "tagsAndNotes",
  activity: "activity",
  support: "support",
  locations: "locations",
};

export const ManagedBy = {
  shi: "Managed by SHI",
  other: "Managed by Other Partner",
};

export const KnoxWareHouse = {
  name: "Knox",
  displayName: "SHI - Knox Warehouse",
};
export const RidgeWareHouse = {
  name: "Ridge",
  displayName: "SHI - Ridge Warehouse",
};

export const WarehouseOrderType = {
  transfer: "Transfer",
  integration: "Integration",
  rackNStack: "Rack & Stack",
  rma: "RMA",
};

export const GridDefinitionConstants = {
  shi: "shi",
  shiComplete: "SHI Complete",
};

export const untaggedTag = "Untagged";

export const assetDatasource = {
  CRR: "Cisco Ready Report",
  CC: "Contract Coverage",
  SNTC: "SmartNet Total Care",
  ciscoSubscriptionManagement: "Subscription Management API",
  ciscoEA: "Enterprise Agreement API",
  manual: "Manual Upload",
};
export const datasourceWithSupport = new Set([
  assetDatasource.CRR,
  assetDatasource.CC,
  assetDatasource.SNTC,
  assetDatasource.ciscoEA,
  assetDatasource.ciscoSubscriptionManagement,
]);
