/* eslint-disable no-loop-func */
import {
  GET_MY_TICKETS_INITIAL,
  GET_MY_TICKETS_INITIAL_SUCCESS,
  GET_MY_TICKETS_INITIAL_ERROR,
  CREATE_BACKUP_TICKET_COMPLETE,
  CREATE_BACKUP_TICKET_ERROR,
  CREATE_BACKUP_TICKET,
  CREATE_TICKET_NOTE,
  CREATE_TICKET_NOTE_COMPLETE,
  CREATE_TICKET_NOTE_ERROR,
  DOWNLOAD_TICKET_ATTACHMENT,
  DOWNLOAD_TICKET_ATTACHMENT_COMPLETE,
  GET_TICKET_ATTACHMENT_INFO,
  GET_TICKET_ATTACHMENT_INFO_COMPLETE,
  GET_TICKET_ATTACHMENT_INFO_ERROR,
} from "modules/ticket/actions/ticketActions";
import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${import.meta.env.API_ENDPOINT}/api/Ticket`;
const downloadEndpoint = `${import.meta.env.API_ENDPOINT}/api/attachments`;

const ticketApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_TICKET_ATTACHMENT_INFO: {
      AuthFetch(fetch, `${downloadEndpoint}/ticket/${action.payload.data}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data, data.status)
          if (data.status && data.status !== 200) {
            return next({
              type: GET_TICKET_ATTACHMENT_INFO_ERROR,
              payload: {
                data: action.payload.data,
              },
            });
          }
          return next({
            type: GET_TICKET_ATTACHMENT_INFO_COMPLETE,
            payload: {
              data: data,
            },
          });
        })
        .catch(() => {
          return next({
            type: GET_TICKET_ATTACHMENT_INFO_ERROR,
            payload: {
              data: action.payload.data,
            },
          });
        });
      break;
    }
    case DOWNLOAD_TICKET_ATTACHMENT: {
      let fileName = "";
      AuthFetch(fetch, `${downloadEndpoint}/download/${action.payload.data}`, {
        method: "GET",
      })
        .then(function (resp) {
          for (const entry of resp.headers.entries()) {
            if (entry[0] === "content-disposition") {
              const splitAttributes = entry[1].split(";");
              splitAttributes.forEach((sa) => {
                let ssa = sa.split("=");
                if (ssa[0].trim() === "filename") {
                  fileName = ssa[1].replace(/^"(.*)"$/, "$1");
                }
              });
            }
          }
          // console.log(resp.headers)
          return resp.blob();
        })
        .then(function (blob) {
          const link = window.document.createElement("a");

          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          link.remove();
        })
        .then(() => {
          return next({
            type: DOWNLOAD_TICKET_ATTACHMENT_COMPLETE,
            payload: {
              data: action.payload.data,
            },
          });
        });
      break;
    }
    case CREATE_BACKUP_TICKET: {
      AuthFetch(fetch, `${apiEndpoint}/createBackupTicket`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: CREATE_BACKUP_TICKET_COMPLETE,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: CREATE_BACKUP_TICKET_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case CREATE_TICKET_NOTE: {
      AuthFetch(fetch, `${apiEndpoint}/ticketnote`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: CREATE_TICKET_NOTE_COMPLETE,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: CREATE_TICKET_NOTE_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_MY_TICKETS_INITIAL: {
      AuthFetch(fetch, `${apiEndpoint}/checkdeleted`)
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: GET_MY_TICKETS_INITIAL_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_MY_TICKETS_INITIAL_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default ticketApi;
