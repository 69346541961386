import { UserProfileCacheResponse } from "@lib/ShiOneClient";
import { getRestMyUserProfile } from "@api/profileStore";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";

export function useGetUserProfile() {
  const { isAuthenticated } = useAuth0();

  return useQuery<UserProfileCacheResponse | null, Error>({
    queryKey: ["userProfile"],
    queryFn: () => getRestMyUserProfile(),
    enabled: isAuthenticated,
  });
}
