const MuiPaper = (theme, rootElement) => ({
  styleOverrides: {
    root: {
      boxShadow: "0px 2px 1.5px 0px rgba(0,0,0,0.08)",
      border: "solid 1px " + theme.palette.border,
    },
  },
  defaultProps: {
    container: rootElement,
  },
});

export default MuiPaper;
