import {
  DateFilterOption,
  PageLayoutWidgetDto,
  RequesterRankingDto,
  RequesterRankingsVisualsDto,
  RequesterRankingsWidgetClient,
  WidgetContentResponseDto_1OfRequesterRankingsVisualsDto,
} from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../utils";
import RankingWidget, {
  RankingWidgetContainerHeight,
} from "@widgets/standard/RankingWidget";
import {
  NoContent,
  useWidgetFilterUserConfig,
} from "../../dashboards/framework";
import { DateFilterOptionHeaderAction } from "@widgets/utils/support/DateFilterOptionHeaderAction";
import React from "react";
import { isLoading } from "@widgets/utils/widgetFunctions";
import { SortingOrder } from "../../../../components/mui-data-grid/constants";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { RequestsSearchParamAccessors } from "@features/service-requests-feature/utils/constants";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import LabelLink from "shared-ui/src/components/LabelLink";
import {
  ShadedCellContent,
  SkeletonWrapper,
} from "shared-ui";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    requesterRankings: [
      {
        requester: "one",
        requestCount: 41,
        requestPercentage: 25.153374233128833,
      },
      {
        requester: "two",
        requestCount: 35,
        requestPercentage: 21.472392638036812,
      },
      {
        requester: "three",
        requestCount: 32,
        requestPercentage: 19.631901840490798,
      },
      {
        requester: "four",
        requestCount: 17,
        requestPercentage: 10.429447852760736,
      },
      {
        requester: "five",
        requestCount: 8,
        requestPercentage: 4.9079754601226995,
      },
    ],
  },
};

function getGridDefinitions(
  loading: boolean,
  error: boolean,
  totalRecords: number,
  dateFilterOption: DateFilterOption
) {
  return {
    sortedColumns: [{ field: "requestCount", sort: SortingOrder.Descending }],
    columnDefinitions: [
      {
        id: "requester",
        headerName: "Name",
        sortable: true,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <LabelLink
                label={params.value}
                baseUrl={"/support-center/requests"}
                queryParams={{
                  [RequestsSearchParamAccessors.view]:
                    prebuiltViewNames.allRequests,
                  [RequestsSearchParamAccessors.createdBy]: params.value,
                  [RequestsSearchParamAccessors.createdDate]: dateFilterOption,
                }}
                hashes={[ServiceRequestsPageTabs.allRequests]}
              />
            </SkeletonWrapper>
          );
        },
      },
      {
        id: "requestCount",
        headerName: "Requests",
        sortable: true,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <ShadedCellContent
                index={params.row.itemIndex}
                value={`${
                  params.row.rawValue?.requestCount
                } (${getRequestPercentage(
                  params.row.rawValue?.requestPercentage
                )}%)`}
                numberOfRows={totalRecords}
                rgbNumber={"29, 221, 141"}
              />
            </SkeletonWrapper>
          );
        },
      },
    ],
  };
}

function useGetRequesterRankingsWidgetsData(
  dateFilterOption: DateFilterOption
) {
  const client = useApi(RequesterRankingsWidgetClient);

  return useQuery({
    queryKey: ["requester-rankings-support-widget", dateFilterOption],
    queryFn: () => client.getRequesterRankings(dateFilterOption),
    placeholderData:
      placeholderData as WidgetContentResponseDto_1OfRequesterRankingsVisualsDto,
  });
}

function getRequestPercentage(percent: number | undefined): string {
  if (percent === undefined) {
    return "--";
  }

  return percent.toFixed(2);
}
function processGridData(data: RequesterRankingsVisualsDto) {
  if (!data?.requesterRankings) {
    return [];
  }

  return data?.requesterRankings?.map((item: RequesterRankingDto, index) => {
    return {
      id: item?.requester,
      requester: item?.requester,
      requestCount: item?.requestCount,
      rawValue: item,
      itemIndex: index,
    };
  });
}
const filterKey = "requester-rankings-support-widget-filter";

export function RequesterRankingsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const initialDateFilter = DateFilterOption.CurrentMonth;

  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      pageLayoutWidget.widgetId!,
      filterKey,
      initialDateFilter
    );

  const responseFunc = useGetRequesterRankingsWidgetsData(currentFilterValue);

  const isLoadingData = isLoading([
    isLoadingFilterUserConfig,
    responseFunc.isPlaceholderData,
  ]);

  let data: any[] = [];
  if (!isLoadingData && responseFunc.data?.data) {
    data = processGridData(responseFunc.data?.data);
  }

  return (
    <RankingWidget
      headerAction={
        <DateFilterOptionHeaderAction
          filterBy={currentFilterValue}
          setFilterBy={setFilter}
          loading={isLoadingData}
          filterKey={filterKey}
        />
      }
      pageLayoutWidget={pageLayoutWidget}
      data={data}
      gridDefinitions={getGridDefinitions(
        isLoadingData,
        responseFunc.isError,
        data.length,
        currentFilterValue
      )}
      rowExpandLookup={undefined}
      noDataElement={
        <NoContent containerHeight={RankingWidgetContainerHeight} />
      }
      loading={isLoadingData}
    />
  );
}
