import { Theme } from "@mui/material";

export const PowerBiReportStyles = (theme: Theme) => ({
  root: {
    height: "100%",
  },
  notConfigured: {
    padding: theme.spacing(3),
  },
  navContainer: {
    borderRight: "solid 1px" + theme.palette.border,
    height: "100%",
  },
  subHeaderNav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: ".5px solid" + theme.palette.border,
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
    border: "solid 1px" + theme.palette.border,
    borderRadius: "5px",
  },
  toolbar: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-72px 0 0 -72px",
  },
  embedWrapper: {
    position: "relative",
    height: "100%",
    "& .reportStyle": {
      height: "100%",
      clear: "both",
      "& iframe": {
        border: "none !important",
      },
    },
    "& .reportStyleMobile": {
      height: "100%",
      clear: "both",
    },
  },
  activePageList: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1,
    backgroundColor: "white",
    ":hover": { backgroundColor: "white" },
  },
  fullScreenButton: {
    position: "absolute",
    bottom: 5,
    right: 5,
    zIndex: 2,
    minWidth: theme.spacing(6.4),
    paddingLeft: 0,
    paddingRight: 0,
    background: "rgba(0,0,0,0.1)",
    "&:hover": {
      background: "rgba(0,0,0,0.3)",
    },
  },
  flex: {
    display: "flex",
  },
  actions: {
    flex: "0 0 auto",
    paddingRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  inputLabel: {},
  filterFormControl: {
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  back: {
    "&:hover": {
      color: theme.palette.secondary.main + " !important",
    },
  },
  grow: {
    flexGrow: 1,
  },
  pageTitle: {
    display: "flex",
    flex: "1 1",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(2),
    },
    "& > *:first-child": {
      marginLeft: 0,
    },
    "& > *:nth-child(2)": {
      marginLeft: 0,
    },
  },
  mainTitle: {
    textTransform: "uppercase",
  },
  fifty: {
    width: "50%",
  },
  fade: {
    opacity: 0.5,
    width: "50%",
    display: "flex",
    minHeight: theme.spacing(40),
  },
  spacer: {
    flex: "1 1 100%",
  },
  formControl: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(6),
    "& .MuiFormControl-root": {
      marginBottom: theme.spacing(3),
    },
  },
  subheader: {
    borderBottom: "solid 1px " + theme.palette.border,
  },
  subHeaderText: {
    marginLeft: theme.spacing(1),
    minWidth: "100px",
  },
  centerItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: theme.spacing(3),
  },
});
