export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";
export const GET_ALL_DOCUMENTS_SUCCESS = "GET_ALL_DOCUMENTS_SUCCESS";
export const GET_ALL_DOCUMENTS_ERROR = "GET_ALL_DOCUMENTS_ERROR";

export function getKnowledgebase() {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_DOCUMENTS,
    });
  };
}