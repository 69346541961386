import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "../../utils";
import {
  AssetContract,
  AssetContractsResponse,
  AssetExpiringContractQuery,
  ExpiringContractWidgetClient,
  ShiOneAssetType,
} from "@lib/ShiOneClient";
import {
  BaseWidgetProps,
  NoContent,
  WidgetWrapper,
} from "shared-ui";
import { useQuery } from "@tanstack/react-query";
import { Divider, MenuItem, Select } from "@mui/material";
import AssetExpiringContractItem from "./asset_components/AssetExpiringContractItem";
import DevicesIcon from "@mui/icons-material/Devices";
import { RequestConsultationCardActions } from "@widgets/framework/RequestConsultationCardActions";

const placeholderData: AssetContractsResponse = new AssetContractsResponse({
  contracts: [
    new AssetContract({
      shiOneAssetType: ShiOneAssetType.Cisco,
      contractId: "placeholder1",
      vendor: "cisco",
      shiContractType: ["placeholder1"],
      earliestCoveredEndDate: "2024-02-28",
    }),
    new AssetContract({
      shiOneAssetType: ShiOneAssetType.Cisco,
      contractId: "placeholder2",
      vendor: "cisco",
      shiContractType: ["placeholder2"],
      earliestCoveredEndDate: "2024-02-28",
    }),
  ],
  totalCount: 10,
});

function addMonths(date: Date, months: number): Date {
  date.setMonth(date.getMonth() + months);
  return date;
}

const useGetExpiringContracts = (termMonths: number) => {
  const api = useApi(ExpiringContractWidgetClient);
  const query = new AssetExpiringContractQuery();
  if (!termMonths) termMonths = 3;
  query.contractEndDate = addMonths(new Date(), termMonths);
  return useQuery<AssetContractsResponse>({
    queryKey: ["assets-expiring-contracts-widget", termMonths],
    queryFn: () => api.getExpiringContracts(query),
    placeholderData: placeholderData,
  });
};
const ContainerSizeInPixels = 350; // approximately 5 rows...

export default function AssetsExpiringContractsWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const [termMonths, setTermMonths] = useState(3);
  const { data, isPlaceholderData } = useGetExpiringContracts(termMonths);
  const containerStyle = { padding: "16px" };

  const sortedContracts: AssetContract[] | undefined = useMemo(() => {
    if (!data?.contracts) return undefined;
    return [...data.contracts].sort((a, b) => {
      const dateA = new Date(a.earliestCoveredEndDate || 0);
      const dateB = new Date(b.earliestCoveredEndDate || 0);
      return dateA.getTime() - dateB.getTime();
    });
  }, [data?.contracts]);

  const [displayedContainerHeight, setDisplayedContainerHeight] = useState<
    number | string
  >(ContainerSizeInPixels);
  useEffect(() => {
    if (!data?.contracts) {
      return;
    }
    switch (data?.contracts.length) {
      case 1:
      case 2:
      case 3:
        setDisplayedContainerHeight(210);
        break;
      case 4:
      case 5:
        setDisplayedContainerHeight("100%");
        break;
      default:
        setDisplayedContainerHeight(ContainerSizeInPixels);
    }
  }, [data]);

  const termMonthsOptions = [
    { label: "Next 3 Months", value: 3 },
    { label: "Next 6 Months", value: 6 },
    { label: "Next 12 Months", value: 12 },
  ];

  const headerAction = () => {
    return (
      <Select
        sx={{ height: "40px" }}
        labelId="TermSelector"
        id="TermSelector"
        value={termMonths}
        onChange={(event) => {
          setTermMonths(
            Number(event.target.value) ? Number(event.target.value) : 0
          );
        }}
        autoWidth
        displayEmpty
      >
        {termMonthsOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };
  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      isEmpty={!data?.totalCount && !isPlaceholderData}
      cardHeaderDivider
      cardActions={
        <RequestConsultationCardActions
          applicationContext={"Assets Expiring"}
        />
      }
      noPadding={true}
      headerAction={headerAction()}
    >
      {sortedContracts?.length || isPlaceholderData ? (
        <div style={{ height: displayedContainerHeight, overflow: "auto" }}>
          {sortedContracts?.map((item: AssetContract) => {
            return (
              <div key={"container-" + item.contractId}>
                <AssetExpiringContractItem
                  key={"contractItem-" + item.contractId}
                  contract={item}
                  containerStyle={containerStyle}
                  loading={isPlaceholderData}
                />
                <Divider key={"divider-" + item.contractId} />
              </div>
            );
          })}
        </div>
      ) : (
        <NoContent
          containerHeight={150}
          header={"No Expiring Contracts"}
          body={"You have no expiring contracts to view at the moment"}
          actionText={""}
          icon={<DevicesIcon />}
        />
      )}
    </WidgetWrapper>
  );
}
