import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import components from "./overrides";
import darkPalette from "./darkPalette";

const paletteTheme = createTheme({
  palette: darkPalette,
});

const darkTheme = createTheme(paletteTheme, {
  typography,
  components: components(paletteTheme),
  zIndex: {
    appBar: 1100,
    drawer: 1200,
  },
});

export default darkTheme;
