export const GET_CLIENT_ACCOUNTS = "GET_CLIENT_ACCOUNTS";
export const GET_CLIENT_ACCOUNTS_SUCCESS = "GET_CLIENT_ACCOUNTS_SUCCESS";
export const GET_CLIENT_ACCOUNTS_ERROR = "GET_CLIENT_ACCOUNTS_ERROR";
export const GET_CLIENT_USERS = "GET_CLIENT_USERS";
export const GET_CLIENT_USERS_SUCCESS = "GET_CLIENT_USERS_SUCCESS";
export const GET_CLIENT_USERS_ERROR = "GET_CLIENT_USERS_ERROR";
export const GET_CLIENT_AX_NUMBERS = "GET_CLIENT_AX_NUMBERS";
export const GET_CLIENT_AX_NUMBERS_SUCCESS = "GET_CLIENT_AX_NUMBERS_SUCCESS";
export const GET_CLIENT_AX_NUMBERS_ERROR = "GET_CLIENT_AX_NUMBERS_ERROR";
export const GET_CLIENT_ACCOUNT_TEAM = "GET_CLIENT_ACCOUNT_TEAM";
export const GET_CLIENT_ACCOUNT_TEAM_SUCCESS =
  "GET_CLIENT_ACCOUNT_TEAM_SUCCESS";
export const GET_CLIENT_ACCOUNT_TEAM_ERROR = "GET_CLIENT_ACCOUNT_TEAM_ERROR";
export const ADD_CLIENT_ACCOUNT_TEAM_MEMBER = "ADD_CLIENT_ACCOUNT_TEAM_MEMBER";
export const ADD_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS =
  "ADD_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS";
export const ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR =
  "ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR";
export const REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER =
  "REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER";
export const REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS =
  "REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS";
export const REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR =
  "REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR";
export const UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER =
  "UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER";
export const UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS =
  "UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS";
export const UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR =
  "UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR";
export const UPDATE_CLIENT_AX_NUMBERS = "UPDATE_CLIENT_AX_NUMBERS";
export const UPDATE_CLIENT_AX_NUMBERS_SUCCESS =
  "UPDATE_CLIENT_AX_NUMBERS_SUCCESS";
export const UPDATE_CLIENT_AX_NUMBERS_ERROR = "UPDATE_CLIENT_AX_NUMBERS_ERROR";
export const RESET_CLIENT_ACCOUNT_STORE = "RESET_CLIENT_ACCOUNT_STORE";

export function getClientAxNumbers(accountId) {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_AX_NUMBERS,
      payload: {
        data: accountId,
      },
    });
  };
}

export function updateClientAxNumbers(account) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CLIENT_AX_NUMBERS,
      payload: {
        data: account,
      },
    });
  };
}

export function getClientAccountUsers(id) {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_USERS,
      payload: {
        data: id,
      },
    });
  };
}

export function getClientAccounts() {
  // console.log('Dispatching getAccounts')
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_ACCOUNTS,
    });
  };
}

export function getClientAccountTeam(id) {
  // console.log('Dispatching getAccounts')
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_ACCOUNT_TEAM,
      payload: {
        data: id,
      },
    });
  };
}

export function addClientAccountTeamMember(teamMember) {
  return (dispatch) => {
    dispatch({
      type: ADD_CLIENT_ACCOUNT_TEAM_MEMBER,
      payload: {
        data: teamMember,
      },
    });
  };
}

export function removeClientAccountTeamMember(teamMember) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER,
      payload: {
        data: teamMember,
      },
    });
  };
}

export function updateClientAccountTeamMember(teamMember) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER,
      payload: {
        data: teamMember,
      },
    });
  };
}

export function resetClientManagementStore() {
  return (dispatch) => {
    dispatch({
      type: RESET_CLIENT_ACCOUNT_STORE,
    });
  };
}
