import { Step, StepLabel, Tooltip } from "@mui/material";
import { getActiveStep } from "../../../../../functions/projectHelper";
import React from "react";
import { ProjectDetailsPhaseStepperProps } from "@features/projects-feature/projectTypes";
import ProgressStepper from "../../../../../components/ProgressStepper";

const ProjectDetailsPhaseStepper: React.FC<ProjectDetailsPhaseStepperProps> = ({
  phasesWithTasks,
  activePhase,
}) => {
  const filterOutEmptyPhases = phasesWithTasks.filter(
    (phase) => phase.tasks?.length !== 0
  );
  if (filterOutEmptyPhases.length !== 0)
    return (
      <ProgressStepper
        activeStep={getActiveStep(filterOutEmptyPhases, activePhase)}
      >
        {filterOutEmptyPhases?.map((phase) => {
          return (
            <Step key={phase.phase?.id}>
              <Tooltip title={phase.phase?.title}>
                <StepLabel>{phase.phase?.title}</StepLabel>
              </Tooltip>
            </Step>
          );
        })}
      </ProgressStepper>
    );
  else return null;
};

export default ProjectDetailsPhaseStepper;
