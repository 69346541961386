import React from "react";

import { ProjectDetailChildPhaseProps } from "@features/projects-feature/projectTypes";
import GlobalConstants from "@constants";
import ProjectTaskGridItem from "@features/projects-feature/components/ProjectTaskGridItem";

const ProjectDetailsChildPhaseTasks: React.FC<ProjectDetailChildPhaseProps> = ({
  childPhases,
  displayTasks,
  picklist,
  style,
}) => {
  const { phase, tasks } = childPhases;
  const phasedComplete = tasks?.filter(
    (task: any) => task.completedByResourceID === 0
  );
  const phaseStatus = tasks?.filter(
    (task: any) => task.status !== GlobalConstants.projectStatus.complete
  );
  if (tasks?.length !== 0 && phase) {
    return (
      <>
        <ProjectTaskGridItem
          title={phase.title ?? ""}
          checked={phasedComplete?.length === 0}
          status={phaseStatus?.[0]?.status ?? GlobalConstants.projectStatus.new}
          picklist={picklist}
        />
        {displayTasks(tasks ?? [], style)}
      </>
    );
  }
  return null;
};

export default ProjectDetailsChildPhaseTasks;
