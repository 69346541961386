import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, AppDispatch } from "store";
import {
  AlertState,
  AlertTicket,
  MonitoringAlert,
} from "modules/rtk/alert/alertTypes";

import {
  getRestAlertTicket,
  getRestMonitoringAlerts,
} from "../../../api/alertStore";

const initialState: AlertState = {
  alertFetchStatus: "idle",
  alertTicketFetchStatus: "idle",
  alerts: [],
  alertTickets: [],
};

const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    receivingAlerts(state) {
      state.alertFetchStatus = "loading";
    },
    receiveAlerts(state, action: PayloadAction<MonitoringAlert[]>) {
      state.alerts = action.payload;
      state.alertFetchStatus = "complete";
    },
    receiveTicket(state, action: PayloadAction<number>) {
      const matchingTicketIndex = state.alertTickets.findIndex(
        (t) => t.id === action.payload
      );
      if (matchingTicketIndex > -1) {
        state.alertTickets[matchingTicketIndex].fetchStatus = "loading";
      } else {
        const incomingTicket = {
          id: action.payload,
          fetchStatus: "loading",
        } as AlertTicket;
        state.alertTickets.push(incomingTicket);
      }
      state.alertTicketFetchStatus = "loading";
    },
    receivingTicket(state, action: PayloadAction<AlertTicket>) {
      const matchingTicketIndex = state.alertTickets.findIndex(
        (t) => t.id === action.payload.id
      );
      if (matchingTicketIndex > -1) {
        state.alertTickets[matchingTicketIndex] = action.payload;
        state.alertTickets[matchingTicketIndex].fetchStatus = "complete";
      } else {
        action.payload.fetchStatus = "complete";
        state.alertTickets.push(action.payload);
      }
      state.alertTicketFetchStatus = "complete";
    },
  },
});

export const getMonitoringAlerts =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(alertSlice.actions.receivingAlerts());
    const monitoringAlerts = await getRestMonitoringAlerts();
    dispatch(alertSlice.actions.receiveAlerts(monitoringAlerts));
  };

export const getAlertTicket =
  (ticketId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(alertSlice.actions.receiveTicket(ticketId));
    const ticketResult = await getRestAlertTicket(ticketId);
    dispatch(alertSlice.actions.receivingTicket(ticketResult));
  };

export default alertSlice.reducer;
