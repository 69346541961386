import shiColors from "shared-ui/src/theme/shiColors";

export const servicesColors: { [key: string]: string } = {
  microsoft: shiColors.blue.DEFAULT,
  exchange: shiColors.pink.DEFAULT,
  teams: shiColors.green.DEFAULT,
  sharepoint: shiColors.orange.light,
  dynamics365: shiColors.pink.dark,
  vivaSuite: shiColors.red.DEFAULT,
  unknown: shiColors.gray.lighter,
};
