import shiColors from "shared-ui/src/theme/shiColors";

export const chartColors: { [key: string]: string } = {
  consumption: shiColors.blue.DEFAULT,
  tooltipBackground: "rgba(97, 97, 97, 0.9)",
};

export const categoryColors: { [key: string]: string } = {
  security: shiColors.pink.dark,
  services: shiColors.blue.DEFAULT,
  licensing: shiColors.green.DEFAULT,
  adoption: shiColors.pink.DEFAULT,
  unknown: shiColors.gray.lighter,
};

export const severityLevelColors: { [key: string]: string } = {
  low: shiColors.green.DEFAULT,
  medium: shiColors.orange.lighter,
  high: shiColors.orange.DEFAULT,
  unknown: shiColors.gray.lighter,
};
