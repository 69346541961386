import { IConfig } from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../auth/ApiClientConfig";

// Helper to create an instance of the API you need
export function useApi<ClientType>(
  type: new (config: IConfig, endpoint: string) => ClientType
) {
  return new type(
    ApiClientConfig,
    import.meta.env.API_ENDPOINT
  );
}
