export const twoLineHeightCell = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  "@supports (-webkit-line-clamp: 2)": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },
};

export const threeLineHeightCell = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  "@supports (-webkit-line-clamp: 3)": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  },
};
