import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { NavLink } from "react-router-dom";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { Chip, Tooltip } from "@mui/material";
import { graphColors } from "shared-ui";

export const getInitialsFromName = (name: string) => {
  return name?.split(" ").reduce((v, c) => v + c.charAt(0), "");
};

export const standardizeDateFormat = (
  params: GridRenderCellParams,
  asUtcTime?: boolean
) => {
  const date = new Date(params.value);
  return date.toLocaleDateString("es-pa", asUtcTime ? { timeZone: "UTC" } : {});
};

export const linkToServiceRequest = (
  params: GridRenderCellParams,
  returnPath?: string
) => {
  let navLinkPath = `/support-center/requests/${params.id}`;

  if (returnPath) {
    navLinkPath = `${navLinkPath}?returnPath=${returnPath}`;
  }

  return (
    <Tooltip title={params.value} followCursor>
      <NavLink to={AppendIfPrefixExists(navLinkPath)}>{params.value}</NavLink>
    </Tooltip>
  );
};

export const chipIt = (params: GridRenderCellParams, style?: any) => {
  return (
    <Chip label={params.value} size="small" variant="outlined" sx={style} />
  );
};

const knownStatuses = [
  "New",
  "In Progress",
  "Customer Note Added",
  "Waiting Customer",
  "Waiting Account Team",
  "Complete",
];

export function getRequestStatusColor(status: string) {
  const statusIndex = knownStatuses.indexOf(status);
  if (statusIndex > -1) {
    return graphColors[statusIndex];
  }

  return graphColors[9];
}

const knownTypes = [
  "Alert",
  "Change Request",
  "Incident",
  "Problem",
  "Service Request",
].sort((a, b) => a.localeCompare(b));

export function getServiceRequestTypeColor(value: string) {
  const typeIndex = knownTypes.indexOf(value);
  if (typeIndex > -1) {
    return graphColors[typeIndex];
  }

  return graphColors[9];
}

const knownPriorities = ["Critical", "High", "Medium", "Low"].sort((a, b) =>
  a.localeCompare(b)
);

export function getServiceRequestPriorityColor(value: string) {
  const priorityIndex = knownPriorities.indexOf(value);
  if (priorityIndex > -1) {
    return graphColors[priorityIndex];
  }

  return graphColors[9];
}
