import { NavItemType } from "../../NavigationTypes";
import GradingIcon from "@mui/icons-material/Grading";
import React from "react";

export function AssessmentsMenu(): NavItemType {
  return {
    id: "assessments",
    type: "Link",
    displayName: "Assessments",
    path: "/assessments",
    icon: <GradingIcon />,
    visible: true,
  };
}
