import {
  GET_MY_TICKETS_INITIAL,
  GET_MY_TICKETS_INITIAL_SUCCESS,
  GET_MY_TICKETS_INITIAL_ERROR,
  SCHEDULE_TICKET_POLL,
  RESET_CREATE_TICKET,
  CREATE_BACKUP_TICKET,
  CREATE_BACKUP_TICKET_COMPLETE,
  CREATE_BACKUP_TICKET_ERROR,
  CREATE_TICKET_NOTE,
  CREATE_TICKET_NOTE_COMPLETE,
  CREATE_TICKET_NOTE_ERROR,
  DOWNLOAD_TICKET_ATTACHMENT,
  DOWNLOAD_TICKET_ATTACHMENT_COMPLETE,
  GET_TICKET_ATTACHMENT_INFO,
  GET_TICKET_ATTACHMENT_INFO_COMPLETE,
  GET_TICKET_ATTACHMENT_INFO_ERROR,
  RESET_TICKET_NOTE_SUBMITTED,
} from "../actions/ticketActions";

const updateTicketList = (existingTickets, newPayload) => {
  return {
    ...newPayload,
    lastTicketId:
      newPayload.lastTicketId !== undefined && newPayload.lastTicketId !== 0
        ? newPayload.lastTicketId
        : existingTickets.lastTicketId,
    lastTicketNumber:
      newPayload.lastTicketNumber !== undefined
        ? newPayload.lastTicketNumber
        : existingTickets.lastTicketNumber,
  };
};

export function ticketReducer(
  state = {
    ticket: undefined,
    tickets: {
      tickets: [],
    },
    fetchingTicket: false,
    fetchTicketComplete: false,
    fetchTicketError: undefined,
    fetchingTickets: false,
    fetchTicketsComplete: false,
    fetchTicketsError: undefined,
    submittingTicket: false,
    ticketSubmitted: false,
    ticketSubmissionError: undefined,
    submittingBackupTicket: false,
    backupTicketSubmitted: false,
    backupTicketSubmissionError: undefined,
    submittingTicketNote: false,
    ticketNoteSubmitted: false,
    ticketNoteSubmissionError: undefined,
    ticketPollScheduled: false,
    checkingForDeletedTickets: false,
    checkingForDeletedTicketsComplete: false,
    checkingForDeletedTicketsError: undefined,
    fetchingFile: {},
    fetchFileComplete: {},
    fetchFileError: {},
    ticketAttachmentInfoContent: {},
    fetchingTicketAttachmentInfo: {},
    fetchTicketAttachmentInfoComplete: {},
    fetchTicketAttachmentInfoError: {},
  },
  action
) {
  // console.log(action.type)
  switch (action.type) {
    case RESET_TICKET_NOTE_SUBMITTED: {
      return Object.assign({}, state, {
        ticketNoteSubmitted: false,
      });
    }
    case GET_TICKET_ATTACHMENT_INFO: {
      return Object.assign({}, state, {
        fetchingTicketAttachmentInfo: {
          ...state.fetchingTicketAttachmentInfo,
          [action.payload.data]: true,
        },
        fetchTicketAttachmentInfoComplete: {
          ...state.fetchTicketAttachmentInfoComplete,
          [action.payload.data]: false,
        },
        fetchTicketAttachmentInfoError: {
          ...state.fetchTicketAttachmentInfoError,
          [action.payload.data]: undefined,
        },
      });
    }
    case GET_TICKET_ATTACHMENT_INFO_COMPLETE: {
      return Object.assign({}, state, {
        ticketAttachmentInfoContent: {
          ...state.ticketAttachmentInfoContent,
          [action.payload.data.Id]: action.payload.data.Content,
        },
        fetchingTicketAttachmentInfo: {
          ...state.fetchingTicketAttachmentInfo,
          [action.payload.data.Id]: false,
        },
        fetchTicketAttachmentInfoComplete: {
          ...state.fetchTicketAttachmentInfoComplete,
          [action.payload.data.Id]: true,
        },
        fetchTicketAttachmentInfoError: {
          ...state.fetchTicketAttachmentInfoError,
          [action.payload.data]: undefined,
        },
      });
    }
    case GET_TICKET_ATTACHMENT_INFO_ERROR: {
      return Object.assign({}, state, {
        fetchingTicketAttachmentInfo: {
          ...state.fetchingTicketAttachmentInfo,
          [action.payload.data]: false,
        },
        fetchTicketAttachmentInfoComplete: {
          ...state.fetchTicketAttachmentInfoComplete,
          [action.payload.data]: true,
        },
        fetchTicketAttachmentInfoError: {
          ...state.fetchTicketAttachmentInfoError,
          [action.payload.data]: undefined,
        },
      });
    }
    case DOWNLOAD_TICKET_ATTACHMENT: {
      return Object.assign({}, state, {
        fetchingFile: {
          ...state.fetchingFile,
          [action.payload.data]: true,
        },
        fetchFileComplete: {
          ...state.fetchFileComplete,
          [action.payload.data]: false,
        },
        fetchFileError: {
          ...state.fetchFileError,
          [action.payload.data]: undefined,
        },
      });
    }
    case DOWNLOAD_TICKET_ATTACHMENT_COMPLETE: {
      return Object.assign({}, state, {
        fetchingFile: {
          ...state.fetchingFile,
          [action.payload.data]: false,
        },
        fetchFileComplete: {
          ...state.fetchFileComplete,
          [action.payload.data]: true,
        },
        fetchFileError: {
          ...state.fetchFileError,
          [action.payload.data]: undefined,
        },
      });
    }
    case RESET_CREATE_TICKET: {
      return Object.assign({}, state, {
        ticketSubmitted: false,
        submittingTicket: false,
        ticketSubmissionError: undefined,
      });
    }
    case CREATE_BACKUP_TICKET: {
      return Object.assign({}, state, {
        submittingBackupTicket: true,
        backupTicketSubmitted: false,
      });
    }
    case CREATE_BACKUP_TICKET_COMPLETE: {
      return Object.assign({}, state, {
        submittingBackupTicket: false,
        backupTicketSubmitted: true,
      });
    }
    case CREATE_BACKUP_TICKET_ERROR: {
      return Object.assign({}, state, {
        submittingBackupTicket: false,
        backupTicketSubmitted: false,
        backupTicketSubmissionError: action.payload.data,
      });
    }
    case CREATE_TICKET_NOTE: {
      return Object.assign({}, state, {
        ticketNoteSubmitted: false,
        submittingTicketNote: true,
      });
    }
    case CREATE_TICKET_NOTE_COMPLETE: {
      return Object.assign({}, state, {
        ticketNoteSubmitted: true,
        submittingTicketNote: false,
        tickets: updateTicketList(state.tickets, action.payload.data),
      });
    }
    case CREATE_TICKET_NOTE_ERROR: {
      return Object.assign({}, state, {
        ticketNoteSubmitted: false,
        submittingTicketNote: false,
        ticketNoteSubmissionError: action.payload.data,
      });
    }
    case GET_MY_TICKETS_INITIAL:
      return Object.assign({}, state, {
        ticketPollScheduled: false,
        checkingForDeletedTickets: true,
        checkingForDeletedTicketsComplete: false,
      });
    case GET_MY_TICKETS_INITIAL_SUCCESS:
      return Object.assign({}, state, {
        checkingForDeletedTickets: false,
        checkingForDeletedTicketsComplete: true,
        tickets: updateTicketList(state.tickets, action.payload.data),
      });
    case GET_MY_TICKETS_INITIAL_ERROR:
      return Object.assign({}, state, {
        checkingForDeletedTickets: false,
        checkingForDeletedTicketsComplete: true,
        fetchTicketsError: action.payload.data,
      });
    case SCHEDULE_TICKET_POLL:
      return Object.assign({}, state, {
        ticketPollScheduled: true,
      });
    default:
      return state;
  }
}
