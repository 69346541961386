export function parseNewURLWithSitePrefix(url: URL, sitePrefix: string) {
  let baseDomain = window.location.protocol + "//" + url.hostname;
  if (window.location.port) baseDomain += ":" + window.location.port;
  const pathName = url.pathname;
  return new URL(`${sitePrefix}${pathName}`, baseDomain);
}

export function parseInvalidNewURLWithSitePrefix(url: URL, sitePrefix: string) {
  let baseDomain = window.location.protocol + "//" + url.hostname;
  if (window.location.port) baseDomain += ":" + window.location.port;
  return new URL(`${sitePrefix}/invalidurl`, baseDomain);
}
