import React from "react";
import { NavLink } from "react-router-dom";

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} ref={ref} />
));

ForwardNavLink.displayName = "NavLink";

export default ForwardNavLink;
