import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import { clearImpersonate } from "shared-ui/src/utils/clientmanagement";
import GlobalConstants from "@constants";
import { getMyUserProfile } from "../modules/rtk/profile/profileSlice";
import LoadingOrError from "./LoadingOrError";
import Loading from "../components/Loading";
import ShiOneLogo from "../../../shared-ui/src/components/ShiOneLogo";
import { Link, Typography } from "@mui/material";
import AuthPageContainer from "./AuthPageContainer";

export default function ErrorPage() {
  const { userProfileResponse, profileFetchStatus } = useSelector(
    (state) => state.profile
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (profileFetchStatus === GlobalConstants.fetchStatus.idle) {
      setLoading(true);
      dispatch(getMyUserProfile());
    }

    if (profileFetchStatus === GlobalConstants.fetchStatus.complete) {
      setLoading(false);
      setError(false);
    }
    if (profileFetchStatus === GlobalConstants.fetchStatus.error) {
      setLoading(false);
      setError(true);
    }
  }, [userProfileResponse, profileFetchStatus, dispatch]);

  if (
    profileFetchStatus === GlobalConstants.fetchStatus.complete &&
    !userProfileResponse.responseStatus == 1
  ) {
    navigate("/");
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <LoadingOrError error message={"Please log out and try again"} />;
  }

  const isImpersonating = localStorage.getItem("impersonateUser");
  const waitingApprovalWithAdmins =
    userProfileResponse.responseStatus === 6 &&
    userProfileResponse.myAdmins.length > 0;

  return (
    <AuthPageContainer>
      <ShiOneLogo />
      <div>
        <h4>{userProfileResponse.errorMessage}</h4>

        {isImpersonating && (
          <div style={{ marginBottom: "25px" }}>
            <a
              onClick={() => clearImpersonate()}
              href="#0"
              className="landing-btn"
            >
              Return to your SHI Account
            </a>
          </div>
        )}

        {userProfileResponse.responseStatus === 9 && (
          <div>
            <p>
              If you don&#39;t hear from our Support Services within one
              business day, please contact us at{" "}
              <Link href="mailto:SupportServices@shi.com">
                SupportServices@shi.com
              </Link>
              .
            </p>
          </div>
        )}

        {waitingApprovalWithAdmins && (
          <div>
            <ul
              style={{
                paddingLeft: 0,
                listStyle: "none",
                textAlign: "center",
              }}
            >
              {userProfileResponse.myAdmins.map((a, k) => {
                return <li key={k}>{a}</li>;
              })}
            </ul>
            <p>
              If none of these users are available please contact{" "}
              <Link href="mailto:SupportServices@shi.com">
                SupportServices@shi.com
              </Link>{" "}
              for support.
            </p>
          </div>
        )}

        {userProfileResponse.responseStatus === 6 &&
          !waitingApprovalWithAdmins && (
            <div>
              <p>
                Please contact{" "}
                <Link href="mailto:SupportServices@shi.com">
                  SupportServices@shi.com
                </Link>{" "}
                to set up your account.
              </p>
            </div>
          )}

        {!isImpersonating && userProfileResponse.responseStatus === 5 && (
          <div style={{ marginBottom: "25px" }}>
            <NavLink
              to={AppendIfPrefixExists("/register")}
              className="landing-btn"
            >
              Update Profile
            </NavLink>
          </div>
        )}

        {!isImpersonating && userProfileResponse.responseStatus !== 5 && (
          <>
            <hr />
            <Typography>
              If you think you may have logged in with the wrong account, you
              can <Link href="/logout">log out</Link> and log back in with
              another account.
            </Typography>
          </>
        )}
        <div>
          <span style={{ fontStyle: "italic" }}>
            Message Code {userProfileResponse.responseStatus}
          </span>
        </div>
      </div>
    </AuthPageContainer>
  );
}
