import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GlobalConstants from "@constants";
import Grid from "@mui/material/Grid";
import { getAccountDetailsData } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import Divider from "@mui/material/Divider";
import { debounce } from "lodash";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  formControl: {
    display: "block",
    variant: "outlined",
  },
  grow: {
    flexGrow: 1,
  },
  componentHeader: {
    marginBottom: theme.spacing(2),
  },
});

const ErrorMessage = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <FormHelperText style={{ color: "red" }}>
      No account information for the provided AX Account
    </FormHelperText>
  );
};

export default function AccountDetailsField(props) {
  const { id, label, onChange, required, formData } = props;
  const theme = useTheme();
  const classes = css(theme);
  const dispatch = useDispatch();

  const { accountDetails, accountDetailsFetchStatus } = useSelector(
    (state) => state.serviceRequestTemplate
  );

  const {
    clientAxNumbers,
    fetchingClientAxNumbers,
    fetchClientAxNumbersComplete,
  } = useSelector((state) => state.clientManagement);

  const defaultAccountDetailsState = {
    axAccountNumber: "",
    companyName: "",
    paymentTerms: "",
  };

  const [currentAccountDetails, setCurrentAccountDetails] = useState(
    defaultAccountDetailsState
  );
  const [axAccountNumbers, setAxAccountNumbers] = useState([]);
  const [hasSingleAccount, setHasSingleAccount] = useState(false);

  const isLoading =
    accountDetailsFetchStatus === GlobalConstants.fetchStatus.loading;
  const isComplete =
    accountDetailsFetchStatus === GlobalConstants.fetchStatus.complete;
  const hasError =
    accountDetailsFetchStatus === GlobalConstants.fetchStatus.error;

  useEffect(() => {
    if (isComplete) {
      setCurrentAccountDetails(accountDetails);
    }

    if (hasError) {
      setCurrentAccountDetails(defaultAccountDetailsState);
    }
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetailsFetchStatus]);

  useEffect(() => {
    const newData = {
      ...formData.shiAccountDetails,
      axAccountNumber: currentAccountDetails.axAccountNumber,
      companyName: currentAccountDetails.companyName,
      paymentTerms: currentAccountDetails.paymentTerms,
    };

    onChange(newData);
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountDetails]);

  useEffect(() => {
    if (axAccountNumbers.length === 1) {
      dispatch(getAccountDetailsData(axAccountNumbers[0]));
      setHasSingleAccount(true);
    }
  }, [axAccountNumbers, dispatch]);

  useEffect(() => {
    if (fetchClientAxNumbersComplete && !fetchingClientAxNumbers) {
      const { clientAccounts } = clientAxNumbers;
      clientAccounts.forEach((ca) => {
        if (ca.primaryAXAccount) {
          if (axAccountNumbers.indexOf(ca.primaryAXAccount) === -1) {
            setAxAccountNumbers((state) => [...state, ca.primaryAXAccount]);
          }
        }
        if (ca.axReferences && ca.axReferences.length >= 1) {
          ca.axReferences.forEach((axRef) => {
            if (axAccountNumbers.indexOf(axRef.axNumber) === -1) {
              setAxAccountNumbers((state) => [...state, axRef.axNumber]);
            }
          });
        }
      });
    }
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingClientAxNumbers, fetchClientAxNumbersComplete, clientAxNumbers]);

  const debouncedAccountDetailsSearch = debounce((value) => {
    dispatch(getAccountDetailsData(value));
  }, 1000);

  const handleOnChange = (value) => {
    if (value) {
      debouncedAccountDetailsSearch(value);
    }

    if (!value) {
      setCurrentAccountDetails(defaultAccountDetailsState);
    }
  };

  return (
    <Box sx={classes.root}>
      <Typography variant="h5">SHI Account Details</Typography>
      <Divider sx={classes.componentHeader} />
      {hasSingleAccount ? (
        <Grid container sx={classes.grow} spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="filled">
              <InputLabel shrink htmlFor="axAccountNumbers">
                AX Account
              </InputLabel>
              <FilledInput
                readOnly={true}
                disableUnderline
                id="axAccountNumbers"
                name="axAccountNumbers"
                label="AX Accounts"
                value={axAccountNumbers[0]}
                endAdornment={
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={classes.grow} spacing={3}>
          <Grid item xs={12}>
            <FormControl sx={classes.formControl}>
              <Autocomplete
                sx={classes.root}
                id={id}
                name={label}
                onChange={(e, value) => handleOnChange(value)}
                options={
                  fetchClientAxNumbersComplete
                    ? [
                        ...new Set(
                          axAccountNumbers.map(
                            (axAccountNumber) => `${axAccountNumber}`
                          )
                        ),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="axAccountNumbers"
                    name="axAccountNumbers"
                    variant="outlined"
                    placeholder="Search AX accounts"
                    label="AX Accounts"
                    aria-label="AX Accounts"
                    required={required}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {hasError && <ErrorMessage />}
      <Grid container sx={classes.grow} spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }} variant="filled">
            <InputLabel shrink htmlFor="companyName">
              Company Name
            </InputLabel>
            <FilledInput
              readOnly={true}
              disableUnderline
              id="companyName"
              name="companyName"
              value={currentAccountDetails.companyName}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={classes.grow} spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="filled">
            <InputLabel shrink htmlFor="paymentTerms">
              Payment Terms
            </InputLabel>
            <FilledInput
              readOnly={true}
              disableUnderline
              name="paymentTerms"
              id="paymentTerms"
              value={currentAccountDetails.paymentTerms}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
