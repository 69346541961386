import { useThemeMode } from "../theme/ThemeModeProviderContext";
import { IconButton, useTheme } from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import React from "react";

const SwitchThemeButton = () => {
  const { toggleThemeMode } = useThemeMode();
  const theme = useTheme();
  return (
    <IconButton sx={{ ml: 1 }} onClick={toggleThemeMode} color="inherit">
      {theme.palette.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};
export default SwitchThemeButton;
