import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FieldProps } from "@rjsf/utils";
import countries from "./ComponentData/AllCountryCodes.json";

// Actually came from Material UI demo page
// Country codes come from here: https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
export function CountryChooser(props: FieldProps) {
  return (
    <Autocomplete
      id={props.id}
      sx={{ width: 300 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.Country}
      value={countries.find((c) => c.Country === props.value)}
      onChange={(e, value) => props.onChange(value?.Country)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.ISO.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.ISO.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.Country}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          required={props.required}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
