import { Button, useTheme } from "@mui/material";
import { SortingOrder } from "../../../../components/mui-data-grid/constants";
import MuiDataGrid from "../../../../components/mui-data-grid/MuiDataGrid";
import { UseQueryResult } from "@tanstack/react-query";
import {
  PageLayoutWidgetDto,
  ServiceRequestDto,
  ServiceRequestListDocumentDto,
} from "@lib/ShiOneClient";
import { Add } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import {
  NoContent,
  widgetStyles,
  WidgetStylesType,
  WidgetWrapper,
} from "shared-ui";
import { widgetTableTheme } from "shared-ui/src/widgets/framework/widgetTableTheme";

const ContainerSizeInPixels = 350; // approximately 5 rows...

function Content({
  loading,
  data,
  columnDefinitions,
  containerHeight = ContainerSizeInPixels,
}: {
  loading: boolean;
  data: ServiceRequestDto[];
  columnDefinitions: any;
  containerHeight?: number;
}) {
  const [displayedContainerHeight, setDisplayedContainerHeight] = useState<
    number | string
  >(containerHeight);
  useEffect(() => {
    if (!data) {
      return;
    }
    switch (data.length) {
      case 1:
      case 2:
      case 3:
        setDisplayedContainerHeight(210);
        break;
      case 4:
      case 5:
        setDisplayedContainerHeight("100%");
        break;
      default:
        setDisplayedContainerHeight(ContainerSizeInPixels);
    }
  }, [data]);

  if (data.length === 0) {
    return <NoContent containerHeight={210} />;
  }

  return (
    <MuiDataGrid
      loading={loading}
      gridDefinitions={columnDefinitions}
      gridData={data}
      getRowId={(row: { [x: string]: any }) => row["id"]}
      sortingOrder={[SortingOrder.Descending, SortingOrder.Ascending]}
      sortingMode={"client"}
      pagination={false}
      containerStyle={{ height: displayedContainerHeight }}
      hideFooter
      autoHeight={false}
      customTheme={widgetTableTheme()}
    />
  );
}

function CardActions() {
  return (
    <Button href={AppendIfPrefixExists("/support-center/new-request")}>
      <Add sx={{ fontSize: "16px", marginRight: "8px" }} />
      New Request
    </Button>
  );
}

export default function RequestsWidget({
  pageLayoutWidget,
  useQueryFunction,
  columnDefinitionsFunction,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
  useQueryFunction: () => UseQueryResult<ServiceRequestListDocumentDto>;
  columnDefinitionsFunction: (
    loading: boolean,
    error: boolean,
    styles: WidgetStylesType
  ) => any;
}) {
  const theme = useTheme();
  const styles = widgetStyles(theme);

  // @ts-ignore
  const response = useQueryFunction();

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={response.isError}
      cardActions={
        response.data?.serviceRequestDtos?.length ? <CardActions /> : undefined
      }
      noPadding={true}
      isEmpty={response.data?.serviceRequestDtos?.length === 0}
    >
      <Content
        data={response.data?.serviceRequestDtos ?? []}
        loading={response.isPlaceholderData || response.isError}
        columnDefinitions={columnDefinitionsFunction(
          response.isPlaceholderData,
          response.isError,
          styles
        )}
      />
    </WidgetWrapper>
  );
}
