import React from "react";

export function isLoading(loadingIndicators: boolean[]) {
  return loadingIndicators.some((loadingIndicator) => loadingIndicator);
}

export function getRequestPercentage(percent: number | undefined): string {
  if (percent === undefined) {
    return "--";
  }

  return percent.toFixed(2);
}
