import React from "react";
import { Grid } from "@mui/material";
import { ContractDetailTextField } from "@features/projects-feature/components/ContractDetailTextField";
import { ContractDetailFields } from "@features/projects-feature/utils/fields/ContractDetailsFields";
import { ServiceContractDetailsContainerProps } from "@features/projects-feature/projectTypes";
import {
  StyledServiceContractDetailsContainer,
  StyledServiceContractDetailsSubheader,
} from "@features/projects-feature/utils/components/StyledServiceContractDetailsComponents";

const getSortedColumnFields = (columnKey: number) => {
  return [...ContractDetailFields]
    .filter((field) => field.columnKey === columnKey)
    .sort((a, b) => a.sortPriority - b.sortPriority);
};
export const ServiceContractDetailsContainer = ({
  contract,
  picklist,
}: ServiceContractDetailsContainerProps) => {
  const contractDetailsColumnOne = getSortedColumnFields(1);
  const contractDetailsColumnTwo = getSortedColumnFields(2);

  return (
    <StyledServiceContractDetailsContainer container spacing={2}>
      <Grid item xs={12}>
        <StyledServiceContractDetailsSubheader>
          Service Contract
        </StyledServiceContractDetailsSubheader>
      </Grid>
      {contractDetailsColumnOne.length > 0 && (
        <Grid item xs={6}>
          {contractDetailsColumnOne.map((field) => {
            return (
              <ContractDetailTextField
                key={field.fieldKey}
                field={field}
                contract={contract}
                picklist={picklist}
              />
            );
          })}
        </Grid>
      )}
      {contractDetailsColumnTwo.length > 0 && (
        <Grid item xs={6}>
          {contractDetailsColumnTwo.map((field) => {
            return (
              <ContractDetailTextField
                key={field.fieldKey}
                field={field}
                contract={contract}
                picklist={picklist}
              />
            );
          })}
        </Grid>
      )}
    </StyledServiceContractDetailsContainer>
  );
};
