import React, { Ref } from "react";
import { Alert, AlertColor, AlertProps, SnackbarContent } from "@mui/material";

interface SnackbarMessageProps
  extends Omit<AlertProps, "severity" | "variant"> {
  message?: string;
  variant?: AlertColor | "neutral";
}

const SnackbarMessage = React.forwardRef(
  (props: SnackbarMessageProps, ref: Ref<HTMLDivElement>) => {
    const { message, action, variant = "neutral", ...other } = props;

    if (variant !== "neutral")
      return (
        <Alert
          ref={ref}
          severity={variant}
          variant="standard"
          action={action}
          sx={{ mb: 1 }}
          {...other}
        >
          {message ?? ""}
        </Alert>
      );
    else
      return (
        <SnackbarContent
          ref={ref}
          aria-describedby="neutral-snackbar"
          message={message ?? ""}
          action={action}
          {...other}
        />
      );
  }
);

export default SnackbarMessage;
