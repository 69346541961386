import { UserProfile } from "lib/ShiOneClient";
import { shiUserEmailDomains } from "../constants";

export function isShiUserEmail(emailAddress: string): boolean {
  return shiUserEmailDomains.some((emailDomain) =>
    emailAddress.toLowerCase().includes(emailDomain.toLowerCase())
  );
}

export function isValidShiProfile(
  shiProfile: UserProfile | undefined | null
): boolean {
  if (
    shiProfile == null ||
    shiProfile.contact == null ||
    !shiProfile.contact.eMailAddress
  ) {
    return false;
  }

  return isShiUserEmail(shiProfile.contact.eMailAddress);
}
