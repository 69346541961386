import React, { useState } from "react";
import { EmbeddedPowerBIParams } from "../entities";
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { usePowerBiReportMenu } from "../hooks";
import { PowerBiReportStyles } from "../styles";
import AssessmentIcon from "@mui/icons-material/Assessment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListSubheader from "@mui/material/ListSubheader";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import PowerBiReportPagesMenu from "./PowerBiReportPagesMenu";

type Props = {
  reports?: EmbeddedPowerBIParams[];
};

const PowerBiReportMenu = ({ reports = [] }: Props) => {
  const theme = useTheme();
  const styles = PowerBiReportStyles(theme);
  const {
    ActiveReportId,
    reportPages,
    isLoading,
    showPages,
    handleReportClick,
  } = usePowerBiReportMenu();

  const [collapsed, setCollapsed] = useState(false);
  const hasOneReport = reports?.length === 1 && reportPages.length <= 1;

  return (
    <Grid
      item
      container
      flexDirection="column"
      flexWrap={"nowrap"}
      xs={"auto"}
      sx={[styles.navContainer, hasOneReport && { display: "none" }]}
    >
      <Grid item>
        <ListSubheader
          sx={[
            styles.subHeaderNav,
            collapsed
              ? { justifyContent: "center", padding: theme.spacing(2, 0) }
              : { padding: theme.spacing(2) },
          ]}
        >
          {collapsed ? (
            <KeyboardDoubleArrowRightIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <>
              <Typography variant="subtitle1">Reports</Typography>
              <KeyboardDoubleArrowLeftIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={() => setCollapsed(!collapsed)}
              />
            </>
          )}
        </ListSubheader>
      </Grid>
      <Grid
        item
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
      >
        <List>
          {reports.map((report) => {
            const reportTitle = report.reportName
              ? report.reportName
              : "Reports";
            const isActive = report.reportId === ActiveReportId;
            return (
              <Box key={report.reportId}>
                <ListItemButton
                  onClick={() => handleReportClick(report.reportId)}
                  sx={[
                    { justifyContent: "center" },
                    isActive && styles.activePageList,
                  ]}
                >
                  <Tooltip
                    title={collapsed ? reportTitle : ""}
                    placement="right-start"
                  >
                    {isLoading && isActive ? (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress color="inherit" size="1.2rem" />
                      </Box>
                    ) : (
                      <AssessmentIcon
                        sx={{
                          color: isActive
                            ? theme.palette.secondary.main
                            : theme.palette.text.primary,
                        }}
                      />
                    )}
                  </Tooltip>
                  {!collapsed && (
                    <div style={styles.centerItems}>
                      <ListItemText
                        sx={styles.subHeaderText}
                        primary={reportTitle}
                      />
                      {isActive && showPages ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </div>
                  )}
                </ListItemButton>
                <Collapse
                  in={isActive && showPages && !isLoading}
                  timeout={{ enter: 300, exit: 200 }}
                  unmountOnExit
                >
                  <PowerBiReportPagesMenu
                    reportPages={reportPages}
                    collapsed={collapsed}
                  />
                </Collapse>
              </Box>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
export default PowerBiReportMenu;
