import { CircularProgress } from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import React from "react";
import { NavItemType } from "../../NavigationTypes";
import CloudConsumptionMenu from "../customers/CloudConsumptionMenu";
import CloudMonitoringMenu from "../customers/CloudMonitoringMenu";
import { CloudAwsMenuV2 } from "./CloudAwsMenu";
import { CloudAzureMenuV2 } from "./CloudAzureMenu";
import { CloudGcpMenuV2 } from "./CloudGcpMenu";
import { CloudOffice365MenuV2 } from "./CloudOffice365Menu";
import { CloudDatadogMenu } from "./CloudDatadogMenu";
import { navItemsToShowType } from "../../NavItemsToShowTypes";
import { ShiOnePermission } from "../../../../../../auth/authorizationTypes";
import { useClaims } from "../../../../../../auth/ClaimsProvider";

type CloudMenuParameters = {
  loadingBackupStats: boolean;
  showCloud: boolean;
  navItemsToShow: navItemsToShowType;
};
export function CloudMenu({
  loadingBackupStats,
  showCloud,
  navItemsToShow,
}: CloudMenuParameters): NavItemType {
  const { hasPermission } = useClaims();

  return {
    id: "cloud",
    type: "Group",
    displayName: "Cloud",
    icon: loadingBackupStats ? <CircularProgress size={18} /> : <CloudIcon />,
    visible: showCloud,
    children: [
      {
        id: "cloudAccounts",
        type: "Link",
        displayName: "Accounts",
        path: "/cloud/accounts",
        visible:
          hasPermission(ShiOnePermission.awsAccountRead) ||
          hasPermission(ShiOnePermission.azureAccountRead) ||
          hasPermission(ShiOnePermission.o365AccountRead) ||
          hasPermission(ShiOnePermission.gcpAccountRead),
      },
      CloudConsumptionMenu(navItemsToShow.homeItemsToShow),
      CloudMonitoringMenu(navItemsToShow.homeItemsToShow),
      {
        id: "tagGroups",
        type: "Link",
        displayName: "Tag Groups",
        path: "/cloud/tag-groups",
        visible: navItemsToShow.showTagGroups,
      },
      CloudAwsMenuV2(navItemsToShow.cloudAwsItemsToShow),
      CloudAzureMenuV2(navItemsToShow.cloudAzureItemsToShow),
      CloudGcpMenuV2(navItemsToShow.cloudGcpItemsToShow),
      CloudOffice365MenuV2(navItemsToShow.cloudOffice365ItemsToShow),
      CloudDatadogMenu(navItemsToShow.cloudDatadogItemsToShow),
    ],
  };
}
