export const GET_BACKUP_STATS = "GET_BACKUP_STATS";
export const GET_BACKUP_STATS_SUCCESS = "GET_BACKUP_STATS_SUCCESS";
export const GET_BACKUP_STATS_ERROR = "GET_BACKUP_STATS_ERROR";

export function getBackupStats(dateFrom, dateTo) {
  return (dispatch) => {
    dispatch({
      type: GET_BACKUP_STATS,
      payload: {
        data: {
          startDate: dateFrom,
          endDate: dateTo,
        },
      },
    });
  };
}
