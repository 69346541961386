import { create } from "zustand";
import { LabsLogoService } from "./LabsLogoService";

interface ILogoStore {
  logoService: LabsLogoService;
}

export const useLabsLogoStore = create<ILogoStore>(() => ({
  logoService: new LabsLogoService(),
}));
