import axios from "axios";
import {
  AccountConfiguredStatus,
  AccountExecutiveCustomerContractInformation,
  AccountOverview,
  AccountTypeUpdate,
  AccountWelcomeLink,
  ClientManagementClient,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "auth/ApiClientConfig";
import { GetAxiosHeaders } from "../auth/GetAxiosHeaders";

const baseUrl = `${
  import.meta.env.API_ENDPOINT
}/api/clientmanagement`;

const api = new ClientManagementClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getClientById(id: number): Promise<any> {
  return api.getClientAccount(id);
}

export async function getRestAccountsOverview(): Promise<AccountOverview[]> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<AccountOverview[]>(
    baseUrl + "/accountTeam/accountsOverview",
    { headers: axiosHeaders }
  );

  return response.data;
}

export async function getRestAccountsOnboardingStatuses(
  accountIds: number[]
): Promise<AccountConfiguredStatus[]> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios
    .post<AccountConfiguredStatus[]>(
      baseUrl + "/checkOnboardingStatus",
      accountIds,
      { headers: axiosHeaders }
    )
    .catch((err) => err);

  return response.data;
}

export async function getRestCloudAccountsOverview(): Promise<AccountExecutiveCustomerContractInformation> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<AccountExecutiveCustomerContractInformation>(
    baseUrl + "/contract-information",
    { headers: axiosHeaders }
  );

  return response.data;
}

export async function getRestAccountWelcomeLink(
  linkRequest: AccountWelcomeLink
): Promise<AccountWelcomeLink> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<AccountWelcomeLink>(
    baseUrl + "/createWelcomeLink",
    linkRequest,
    { headers: axiosHeaders }
  );

  return response.data;
}

export async function updateAccountType(
  request: AccountTypeUpdate
): Promise<AccountTypeUpdate> {
  return api.updateAccountType(request);
}
