import React, { createContext, useCallback, useState } from "react";
import { Button, Popover } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ActionsDropdownContext = createContext({
  handleClose: () => {},
});

export const useActionsDropdownContext = () => {
  const context = React.useContext(ActionsDropdownContext);
  if (!context) {
    throw new Error(
      "ActionsDropdownContext must be used within a ActionsDropdownProvider"
    );
  }
  return context;
};

export function ActionsDropdownProvider({
  handleClose,
  children,
}: {
  handleClose: () => void;
  children: React.ReactNode;
}) {
  return (
    <ActionsDropdownContext.Provider value={{ handleClose }}>
      {children}
    </ActionsDropdownContext.Provider>
  );
}

const buttonPopoverWidth = { width: "112px" };
export default function ActionsDropdownMenuButton({
  children,
}: {
  children: React.ReactElement;
}) {
  const [actionAnchorEl, setActionAnchorEl] = useState<any>(null);
  const handleActionsClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setActionAnchorEl(null);
  }, [setActionAnchorEl]);

  return (
    <>
      <Button
        aria-label="selected-actions"
        aria-controls="actions-menu"
        aria-haspopup="true"
        aria-expanded={"true"}
        sx={buttonPopoverWidth}
        onClick={(event) => {
          handleActionsClick(event);
        }}
        color="secondary"
        variant="outlined"
      >
        Actions <ArrowDropDownIcon sx={{ marginLeft: 1 }} />
      </Button>
      <Popover
        PaperProps={{
          style: buttonPopoverWidth,
        }}
        anchorEl={actionAnchorEl}
        open={Boolean(actionAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ActionsDropdownProvider handleClose={handleClose}>
          {children}
        </ActionsDropdownProvider>
      </Popover>
    </>
  );
}
