import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import React from "react";
import { ShiIcon } from "@features/assets-feature/utils/assetInventoryFunctions";
import {
  assetTypesColors,
  categoryColors,
  contractStatusColors,
  lifecycleStateColors,
  useStateColors,
} from "@features/assets-feature/utils/assetsColors";
import shiColors from "shared-ui/src/theme/shiColors";
import { Asset } from "@lib/ShiOneClient";
import {
  AssetDialogTabs,
  GridDefinitionConstants,
  ManagedBy,
} from "@features/assets-feature/constants";
import GlobalConstants from "@constants";
import { parseDateToDate } from "@features/assets-feature/helpers/DateHelpers";
import assetsAccessor from "@features/assets-feature/utils/assetsAccessor";
import { AssetWithChildrenCount } from "../../../interfaces/AssetInterfaces";

const gridCss = () => ({
  overFlowEllipsis: {
    fontSize: "12px",
    width: 260,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  } as React.CSSProperties,
  assetClick: {
    textAlign: "left",
    cursor: "pointer",
    fontWeight: 600,
    userSelect: "unset",
    maxHeight: "40px",
    overflowY: "hidden",
  } as React.CSSProperties,
});
export const getAssetsGridMap = (
  handleAssetClick?: (value: Asset, tabValue?: string) => void
) => {
  const style = gridCss();
  return new Map<string, GridColDef>([
    [
      assetsAccessor.displayName,
      {
        field: assetsAccessor.displayName,
        headerName: "Asset",
        minWidth: 180,
        renderCell: (param: GridRenderCellParams) => {
          const { value, row } = param;
          if (handleAssetClick) {
            return (
              <Link
                sx={style.assetClick}
                component="button"
                underline="none"
                onClick={() => handleAssetClick(row)}
              >
                {value || row.assetId}
              </Link>
            );
          }
        },
      },
    ],
    [
      assetsAccessor.product,
      {
        field: assetsAccessor.product,
        headerName: "Product",
        minWidth: 300,
        renderCell: (param: GridRenderCellParams) => {
          if (param?.value)
            return (
              <div>
                <div>{param.value}</div>
                {param.value !== param.row.description && (
                  <div style={style.overFlowEllipsis}>
                    {param.row.productDescription}
                  </div>
                )}
              </div>
            );
        },
      },
    ],
    [
      assetsAccessor.productDescription,
      {
        field: assetsAccessor.productDescription,
        minWidth: 320,
        headerName: "Product Description",
      },
    ],
    [
      assetsAccessor.manufacturer,
      {
        field: assetsAccessor.manufacturer,
        headerName: "Manufacturer",
        minWidth: 150,
      },
    ],
    [
      assetsAccessor.itemQuantity,
      {
        field: assetsAccessor.itemQuantity,
        headerName: "Quantity",
        type: "number",
      },
    ],
    [
      assetsAccessor.serialNumber,
      {
        field: assetsAccessor.serialNumber,
        headerName: "Serial Number",
        minWidth: 200,
      },
    ],
    [
      assetsAccessor.assetType,
      {
        field: assetsAccessor.assetType,
        headerName: "Type",
        minWidth: 140,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <ColoredChips
              key={param.value}
              bgColor={
                assetTypesColors[param.value?.toLowerCase() || "unknown"]
              }
              label={param.value ?? "unknown"}
            />
          );
        },
      },
    ],
    [
      assetsAccessor.shiCategory,
      {
        field: assetsAccessor.shiCategory,
        headerName: "Category",
        minWidth: 140,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <>
              {param.value && (
                <ColoredChips
                  bgColor={categoryColors[param.value.toLowerCase()]}
                  label={
                    param.value?.charAt(0)?.toUpperCase() + param.value.slice(1)
                  }
                  textColor={
                    categoryColors[param.value.toLowerCase()] === undefined
                      ? shiColors.black
                      : undefined
                  }
                />
              )}
            </>
          );
        },
      },
    ],
    [
      assetsAccessor.subCategory,
      {
        field: assetsAccessor.subCategory,
        headerName: "Sub Category",
        minWidth: 200,
      },
    ],
    [
      assetsAccessor.microCategory,
      {
        field: assetsAccessor.microCategory,
        headerName: "Micro Category",
        minWidth: 200,
      },
    ],
    [
      assetsAccessor.shiUseState,
      {
        field: assetsAccessor.shiUseState,
        headerName: "Use State",
        minWidth: 140,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <>
              {param.value && (
                <ColoredChips
                  bgColor={useStateColors[`${param.value?.toLowerCase()}`]}
                  label={
                    param.value.charAt(0).toUpperCase() + param.value.slice(1)
                  }
                />
              )}
            </>
          );
        },
      },
    ],
    [
      assetsAccessor.discoveredOnNet,
      {
        field: assetsAccessor.discoveredOnNet,
        headerName: "On Network",
        minWidth: 150,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <>
              {param.value?.toLowerCase() === "yes" ? (
                <ColoredChips
                  bgColor={shiColors.green.DEFAULT}
                  label="Discovered"
                />
              ) : (
                <ColoredChips
                  bgColor={shiColors.red.DEFAULT}
                  label="Not Discovered"
                />
              )}
            </>
          );
        },
      },
    ],
    [
      assetsAccessor.shiLifecycleState,
      {
        field: assetsAccessor.shiLifecycleState,
        headerName: "Lifecycle State",
        minWidth: 220,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <>
              {param.value && (
                <ColoredChips
                  bgColor={
                    lifecycleStateColors[`${param.value?.toLowerCase()}`]
                  }
                  label={param.value}
                />
              )}
            </>
          );
        },
      },
    ],
    [
      assetsAccessor.contract,
      {
        field: assetsAccessor.contractId,
        headerName: " Contract",
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const { value, row } = params;
          return (
            <>
              {row.contractVendor?.toLowerCase() ===
              GridDefinitionConstants.shi ? (
                <Stack>
                  <div>
                    <Tooltip title={GridDefinitionConstants.shi}>
                      <ShiIcon inheritViewBox fontSize={"large"} />
                    </Tooltip>
                  </div>
                  <Link
                    component="button"
                    onClick={() =>
                      handleAssetClick &&
                      handleAssetClick(row, AssetDialogTabs.contracts)
                    }
                    sx={style.assetClick}
                  >
                    {value}
                  </Link>
                </Stack>
              ) : (
                <Stack>
                  {row.contractVendor}
                  <Link
                    sx={style.assetClick}
                    component="button"
                    onClick={() =>
                      handleAssetClick &&
                      handleAssetClick(row, AssetDialogTabs.contracts)
                    }
                  >
                    {value}
                  </Link>
                </Stack>
              )}
            </>
          );
        },
      },
    ],
    [
      assetsAccessor.serviceLevelAgreement,
      {
        field: assetsAccessor.serviceLevelAgreement,
        headerName: "Service Level Agreement",
        minWidth: 200,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <div>
              <div>{param.value}</div>
              {param.row.serviceLevelDescription && (
                <div style={style.overFlowEllipsis}>
                  {param.row.serviceLevelDescription}
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [
      assetsAccessor.coveredStatus,
      {
        field: assetsAccessor.coveredStatus,
        headerName: " Covered Status",
        minWidth: 150,
        renderCell: (param: GridRenderCellParams) => {
          const lowerCaseValue = param.value?.toLowerCase();

          return (
            <>
              {param.value && (
                <ColoredChips
                  bgColor={
                    contractStatusColors[`${lowerCaseValue}`] ?? shiColors.white
                  }
                  textColor={
                    lowerCaseValue === "overdue"
                      ? shiColors.gray.DEFAULT
                      : undefined
                  }
                  label={
                    lowerCaseValue.charAt(0).toUpperCase() +
                    lowerCaseValue.slice(1)
                  }
                />
              )}
            </>
          );
        },
      },
    ],
    [
      assetsAccessor.coveredStartDate,
      {
        field: assetsAccessor.coveredStartDate,
        headerName: "Covered Start Date",
        minWidth: 150,
        valueGetter: (value: any) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.coveredEndDate,
      {
        field: assetsAccessor.coveredEndDate,
        headerName: "Covered End Date",
        minWidth: 180,
        valueGetter: (value: any) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.renewalDate,
      {
        field: assetsAccessor.renewalDate,
        headerName: "Renewal",
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const isTrueForward =
            params.row.subCategory === "Enterprise Agreement";
          const renewalDate = params.value
            ? parseDateToDate(params.value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;

          return isTrueForward ? (
            <Stack>
              <ColoredChips
                bgColor={shiColors.pink.DEFAULT}
                label={"True Forward"}
              />
              <Box sx={{ textAlign: "center" }}>{renewalDate}</Box>
            </Stack>
          ) : (
            renewalDate
          );
        },
      },
    ],
    [
      assetsAccessor.endOfLife,
      {
        field: assetsAccessor.endOfLife,
        headerName: "End Of Life",
        minWidth: 130,
        valueGetter: (value: any) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.endOfSaleDate,
      {
        field: assetsAccessor.endOfSaleDate,
        headerName: "End Of Sale",
        minWidth: 130,
        valueGetter: (value) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.endOfSoftwareMaintenanceDate,
      {
        field: assetsAccessor.endOfSoftwareMaintenanceDate,
        headerName: "End Of Software Maintenance",
        minWidth: 220,
        valueGetter: (value) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.lastDateOfSupport,
      {
        field: assetsAccessor.lastDateOfSupport,
        headerName: "End Of Support",
        minWidth: 150,
        valueGetter: (value) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.shipDate,
      {
        field: assetsAccessor.shipDate,
        headerName: "Ship Date",
        minWidth: 130,
        valueGetter: (value) => {
          return value
            ? parseDateToDate(value)?.toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
        },
      },
    ],
    [
      assetsAccessor.managingPartner,
      {
        field: assetsAccessor.managingPartner,
        headerName: "Managing Partner",
        minWidth: 150,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <div
              style={{ display: "flex", alignItems: "center", height: "42px" }}
            >
              {param.value === ManagedBy.shi ? (
                <Tooltip title={ManagedBy.shi}>
                  <>
                    <ShiIcon inheritViewBox fontSize={"large"} />
                  </>
                </Tooltip>
              ) : (
                <div>Other</div>
              )}
            </div>
          );
        },
      },
    ],
    [
      "locationName",
      {
        field: "locationName",
        headerName: "Location",
        minWidth: 230,
      },
    ],
    [
      assetsAccessor.displayLocationName,
      {
        field: assetsAccessor.displayLocationName,
        headerName: "Location",
        minWidth: 230,
      },
    ],
    [
      assetsAccessor.children,
      {
        field: assetsAccessor.children,
        headerName: "Child Assets",
        minWidth: 120,
        sortable: false,
      },
    ],
    [
      assetsAccessor.tags,
      {
        field: assetsAccessor.tags,
        headerName: "Tags",
        renderHeader: (param: GridColumnHeaderParams) => {
          return (
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                gap: 0.5,
              }}
            >
              <LabelIcon sx={{ paddingRight: "3px" }} fontSize={"small"} />
              {param.colDef.headerName}
            </Typography>
          );
        },
        maxWidth: 200,
        sortable: false,
        renderCell: (param: GridRenderCellParams) => {
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 3,
              }}
            >
              {param.value?.sort().map((tag: string, tagIndex: number) => {
                const tagKey = tag + tagIndex;
                return (
                  <Tooltip title={tag} key={tagKey} followCursor>
                    <div
                      style={{
                        maxWidth: 90,
                      }}
                    >
                      <ColoredChips
                        bgColor={shiColors.gray.lighter}
                        textColor={shiColors.black}
                        label={tag}
                      />
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          );
        },
      },
    ],
  ]);
};

export const assetsGridDefinitions = (
  columnsArray: (string | GridColDef)[],
  handleAssetClick?: (value: AssetWithChildrenCount, tabValue?: string) => void
) => {
  const assetsGridMap = getAssetsGridMap(handleAssetClick);

  return columnsArray.map((col) => {
    if (col instanceof Object) {
      return col;
    } else {
      return assetsGridMap.get(col) ?? ({} as GridColDef);
    }
  });
};
