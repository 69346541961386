import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store";
import { MicrosoftSSOResult, OnboardingState } from "./onboardingTypes";
import {
  getRestOnboardingStatus,
  getRestWelcomeLinkStatus,
  postRestSendVerificationCodeEmail,
  postRestSubmitOnboardingAdmin,
  postRestUpdateWelcomeLink,
  postRestVerifyEmailCode,
} from "../../../api/onboardingStore";
import {
  getWelcomeProgressLocalStorage,
  setWelcomeProgressLocalStorage,
} from "../../../auth/WelcomeHelpers";
import {
  AccountWelcomeLink,
  EmailVerificationRequest,
  OnboardingStatus,
  ValidateEmailStatus,
} from "../../../lib/ShiOneClient";

const initialState: OnboardingState = {
  onboardingRequestStatus: "idle",
  onboardingRequestError: "",
  onboardingStatus: {} as OnboardingStatus,
  welcomeLinkRequestStatus: "idle",
  welcomeLinkRequestError: "",
  welcomeLinkStatus: {} as AccountWelcomeLink,
  createEmailVerificationStatus: "idle",
  createEmailVerificationError: "",
  emailVerificationStatus: {} as ValidateEmailStatus,
  validateEmailVerificationStatus: "idle",
  validateEmailVerificationError: "",
  validateEmailVerificationResult: {} as ValidateEmailStatus,
  microsoftSSOResultStatus: "idle",
  microsoftSSOResultError: "",
  microsoftSSOResult: {} as MicrosoftSSOResult,
  submitOnboardingAdminStatus: "idle",
  submitOnboardingAdminError: "",
  submitOnboardingAdminResult: {} as OnboardingStatus,
  welcomeLinkUpdateStatus: "idle",
  welcomeLinkUpdateError: "",
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    receivingWelcomeLinkStatus(state) {
      state.welcomeLinkRequestStatus = "loading";
    },
    receiveWelcomeLinkStatus(state, action: PayloadAction<AccountWelcomeLink>) {
      state.welcomeLinkStatus = action.payload;
      state.welcomeLinkRequestStatus = "complete";
    },
    receiveWelcomeLinkError(state, action: PayloadAction<string>) {
      state.welcomeLinkRequestError = action.payload;
      state.welcomeLinkRequestStatus = "error";
    },
    receivingOnboardingStatus(state) {
      state.onboardingRequestStatus = "loading";
    },
    receiveOnboardingStatus(state, action: PayloadAction<OnboardingStatus>) {
      state.onboardingStatus = action.payload;
      state.onboardingRequestStatus = "complete";
    },
    receiveOnboardingError(state, action: PayloadAction<string>) {
      state.onboardingRequestError = action.payload;
      state.onboardingRequestStatus = "error";
    },
    receivingCreateEmailVerificationCode(state) {
      state.createEmailVerificationStatus = "loading";
    },
    receiveCreateEmailVerificationCode(
      state,
      action: PayloadAction<ValidateEmailStatus>
    ) {
      state.emailVerificationStatus = action.payload;
      state.createEmailVerificationStatus = "complete";
    },
    receiveCreateEmailCodeError(state, action: PayloadAction<string>) {
      state.createEmailVerificationStatus = "error";
      state.createEmailVerificationError = action.payload;
    },
    receivingValidateEmailVerification(state) {
      state.validateEmailVerificationStatus = "loading";
    },
    receiveValidateEmailVerification(
      state,
      action: PayloadAction<ValidateEmailStatus>
    ) {
      state.validateEmailVerificationResult = action.payload;
      state.validateEmailVerificationStatus = "complete";
    },
    receiveValidateEmailError(state, action: PayloadAction<string>) {
      state.validateEmailVerificationStatus = "error";
      state.validateEmailVerificationError = action.payload;
    },
    receiveSSOStatus(state, action: PayloadAction<MicrosoftSSOResult>) {
      state.microsoftSSOResult = action.payload;
      state.microsoftSSOResultStatus = "complete";
    },
    submittingOnboardingAdmin(state) {
      state.submitOnboardingAdminStatus = "loading";
    },
    submitOnboardingAdmin(state, action: PayloadAction<OnboardingStatus>) {
      state.submitOnboardingAdminStatus = "complete";
      state.submitOnboardingAdminResult = action.payload;
    },
    submitOnboardingAdminError(state, action: PayloadAction<string>) {
      state.submitOnboardingAdminStatus = "error";
      state.submitOnboardingAdminError = action.payload;
    },
    receivingWelcomeLinkUpdate(state) {
      state.welcomeLinkUpdateStatus = "loading";
    },
    receiveWelcomeLinkUpdate(state, action: PayloadAction<AccountWelcomeLink>) {
      state.welcomeLinkUpdateStatus = "complete";
      state.welcomeLinkStatus = action.payload;
    },
    receiveWelcomeLinkUpdateError(state, action: PayloadAction<string>) {
      state.welcomeLinkUpdateStatus = "error";
      state.welcomeLinkUpdateError = action.payload;
    },
  },
});

export const getWelcomeLinkStatus =
  (guid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receivingWelcomeLinkStatus());
    try {
      const welcomeLinkStatus = await getRestWelcomeLinkStatus(guid);
      dispatch(
        onboardingSlice.actions.receiveWelcomeLinkStatus(welcomeLinkStatus)
      );
    } catch (error: any) {
      dispatch(
        onboardingSlice.actions.receiveWelcomeLinkError(error.response.data)
      );
    }
  };

export const getOnboardingStatus =
  (guid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receivingOnboardingStatus());
    try {
      const onboardingStatus = await getRestOnboardingStatus(guid);
      dispatch(
        onboardingSlice.actions.receiveOnboardingStatus(onboardingStatus)
      );
    } catch (error: any) {
      dispatch(
        onboardingSlice.actions.receiveOnboardingError(error.response.data)
      );
    }
  };

export const postCreateEmailVerificationCode =
  (emailVerificationRequest: EmailVerificationRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receivingCreateEmailVerificationCode());
    try {
      const emailVerificationResult = await postRestSendVerificationCodeEmail(
        emailVerificationRequest
      );
      dispatch(
        onboardingSlice.actions.receiveCreateEmailVerificationCode(
          emailVerificationResult
        )
      );
    } catch (error: any) {
      dispatch(
        onboardingSlice.actions.receiveCreateEmailCodeError(error.response.data)
      );
    }
  };

export const postValidateCode =
  (emailVerificationRequest: EmailVerificationRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receivingValidateEmailVerification());
    try {
      const emailVerificationResult = await postRestVerifyEmailCode(
        emailVerificationRequest
      );
      dispatch(
        onboardingSlice.actions.receiveValidateEmailVerification(
          emailVerificationResult
        )
      );
    } catch (error: any) {
      dispatch(
        onboardingSlice.actions.receiveValidateEmailError(error.response.data)
      );
    }
  };

export const setOnboardingStatusFromLocalStorage =
  (ssoResult: MicrosoftSSOResult): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receivingOnboardingStatus());
    const onboardingState = getWelcomeProgressLocalStorage();
    dispatch(onboardingSlice.actions.receiveOnboardingStatus(onboardingState));
    dispatch(onboardingSlice.actions.receiveSSOStatus(ssoResult));
  };

export const updateOnboardingStatus =
  (onboardingStatus: OnboardingStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receiveOnboardingStatus(onboardingStatus));
    setWelcomeProgressLocalStorage(onboardingStatus);
  };

export const postFinalOnboardingStatus =
  (onboardingStatus: OnboardingStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.submittingOnboardingAdmin());
    try {
      const postResult = await postRestSubmitOnboardingAdmin(onboardingStatus);
      dispatch(onboardingSlice.actions.submitOnboardingAdmin(postResult));
    } catch (error: any) {
      dispatch(
        onboardingSlice.actions.submitOnboardingAdminError(error.response.data)
      );
    }
  };

export const updateWelcomeLinkOptions =
  (welcomeLink: AccountWelcomeLink): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(onboardingSlice.actions.receivingWelcomeLinkUpdate());
    try {
      const updateResult = await postRestUpdateWelcomeLink(welcomeLink);
      dispatch(onboardingSlice.actions.receiveWelcomeLinkUpdate(updateResult));
    } catch (error: any) {
      dispatch(
        onboardingSlice.actions.receiveWelcomeLinkUpdateError(
          error.response.data
        )
      );
    }
  };

export default onboardingSlice.reducer;
