import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { mapSubmissionTypeToText } from "../../../../functions/serviceRequestFormHelper";
import GlobalConstants from "@constants";

const ServiceRequestSubmissionType =
  GlobalConstants.ServiceRequestSubmissionType;

const css = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
});

export default function RequestActions(props) {
  const theme = useTheme();
  const classes = css(theme);
  const params = useParams();

  const {
    request,
    submittingRequest,
    shouldDisplayForm,
    cancelButton,
    submitFormButtonRef,
  } = props;

  const [serviceRequestSubmissionType, setServiceRequestSubmissionType] =
    useState(ServiceRequestSubmissionType.Submit);

  const { ticketSubmitted } = useSelector((state) => state.ticket);
  const { serviceRequestMappings } = useSelector(
    (state) => state.serviceRequestMapping
  );

  useEffect(() => {
    if (
      serviceRequestMappings.length > 0 &&
      request.issueType !== -1 &&
      request.subIssueType !== -1
    ) {
      const matchingMapping = serviceRequestMappings.find((mapping) => {
        return (
          mapping.issue === Number(request.issueType) &&
          JSON.parse(mapping.enabledSubIssues).includes(
            Number(request.subIssueType)
          ) &&
          mapping.approved
        );
      });
      if (matchingMapping) {
        setServiceRequestSubmissionType(
          matchingMapping.serviceRequestSubmissionType
        );
      } else {
        setServiceRequestSubmissionType(ServiceRequestSubmissionType.Submit);
      }
    }
  }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

  if (shouldDisplayForm || params.formName === "create-user") {
    return (
      <>
        <Divider sx={classes.divider} />
        <Grid container justifyContent="flex-end" sx={classes.root}>
          {cancelButton && (
            <Grid item>
              <Button
                onClick={() => cancelButton(ticketSubmitted)}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              disabled={submittingRequest}
              onClick={() => submitFormButtonRef.current.click()}
              variant="contained"
              color="primary"
            >
              {mapSubmissionTypeToText(serviceRequestSubmissionType)}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
