import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import { getAxBillingContacts } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalConstants from "@constants";

export default function PrimaryContactField(props: FieldProps) {
  const dispatch = useDispatch();

  const [contactName, setContactName] = useState<string | null>(null);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { billingContacts, billingContactsFetchStatus } = useSelector(
    (state) => state.serviceRequestTemplate
  );

  useEffect(() => {
    if (props.formData.primaryContactName) {
      setContactName(props.formData.primaryContactName);
    }
  }, [props.formData, billingContacts]);

  useEffect(() => {
    handleContactUpdate();
    setContactName(null);
  }, [props.formContext.shiAccountDetails?.axAccountNumber]);

  useEffect(() => {
    setIsLoading(
      billingContactsFetchStatus === GlobalConstants.fetchStatus.loading
    );
    if (billingContactsFetchStatus === GlobalConstants.fetchStatus.complete) {
      let newContacts = billingContacts.map((contact) => {
        return contact.name;
      });
      if (props.formData.primaryContactName) {
        setContactName(props.formData.primaryContactName);
      }
      setContacts(newContacts);
    }
  }, [billingContactsFetchStatus]);

  const handleContactUpdate = () => {
    dispatch(
      getAxBillingContacts(props.formContext.shiAccountDetails?.axAccountNumber)
    );
  };

  const handleNameChange = (name: string | null) => {
    setContactName(name);
    let email = billingContacts.filter((contact) => {
      return contact.name === name;
    })[0].emails[0];
    props.onChange({
      ...props.formData,
      primaryContactName: name,
      primaryContactEmail: email,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Autocomplete
          disabled={props.disabled}
          onChange={(event, value) => {
            handleNameChange(value);
          }}
          value={contactName}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Primary Contact"
              InputLabelProps={{ shrink: true }}
              label={"Primary Contact"}
              required={props.required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          options={contacts}
        />
      </Grid>
    </Grid>
  );
}
