export enum PbiEvents {
  loaded = "loaded",
  rendered = "rendered",
  dataSelected = "dataSelected",
  buttonClicked = "buttonClicked",
  filtersApplied = "filtersApplied",
  pagedChanged = "pageChanged",
  commandTriggered = "commandTriggered",
  bookmarkApplied = "bookmarkApplied",
  dataHyperLinkClicked = "dataHyperLinkClicked",
  visualRendered = "visualRendered",
  visualClicked = "visualClicked",
  selectionChanged = "selectionChanged",
  renderingStarted = "renderingStarted",
  error = "error",
}
