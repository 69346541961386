import {
  GET_PICKLIST,
  GET_PICKLIST_SUCCESS,
  GET_PICKLIST_ERROR,
} from "../actions/picklistActions";

export function picklistReducer(
  state = {
    picklist: undefined,
    fetchingPicklist: false,
    fetchPicklistComplete: false,
    fetchPicklistError: undefined,
  },
  action
) {
  switch (action.type) {
    case GET_PICKLIST:
      return Object.assign({}, state, {
        fetchingPicklist: true,
        fetchPicklistComplete: false,
        picklist: undefined,
      });
    case GET_PICKLIST_SUCCESS:
      return Object.assign({}, state, {
        fetchingPicklist: false,
        fetchPicklistComplete: true,
        picklist: action.payload.data,
      });
    case GET_PICKLIST_ERROR:
      return Object.assign({}, state, {
        fetchingPicklist: false,
        fetchPicklistComplete: true,
        picklist: undefined,
        fetchPicklistError: action.payload.data,
      });
    default:
      return state;
  }
}
