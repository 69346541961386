export const GET_SITE = "GET_SITE";
export const GET_SITE_SUCCESS = "GET_SITE_SUCCESS";
export const GET_SITE_ERROR = "GET_SITE_ERROR";
export const SET_SUBDOMAIN = "SET_SUBDOMAIN";
export const SET_SUBDOMAIN_SUCCESS = "SET_SUBDOMAIN_SUCCESS";
export const SET_SUBDOMAIN_ERROR = "SET_SUBDOMAIN_ERROR";
export const SET_WHITELABEL = "SET_WHITELABEL";
export const SET_WHITELABEL_SUCCESS = "SET_WHITELABEL_SUCCESS";
export const SET_WHITELABEL_ERROR = "SET_WHITELABEL_ERROR";
export const SET_OPEN_SIDEBAR = "SET_OPEN_SIDEBAR";

export function getSite(data) {
  return (dispatch) => {
    dispatch({
      type: GET_SITE,
      payload: {
        data,
      },
    });
  };
}

export function setSubdomain(prefix) {
  return (dispatch) => {
    dispatch({
      type: SET_SUBDOMAIN,
      payload: {
        data: prefix,
      },
    });
  };
}

export function setWhitelabel(whitelabel) {
  return (dispatch) => {
    dispatch({
      type: SET_WHITELABEL,
      payload: {
        data: whitelabel,
      },
    });
  };
}

export function setOpenSidebar(status) {
  return (dispatch) => {
    dispatch({
      type: SET_OPEN_SIDEBAR,
      payload: {
        data: status,
      },
    });
  };
}
