import React from "react";
import { Box } from "@mui/material";

function MainContainer({
  children,
  isDrawerOpen,
}: {
  children: React.ReactNode;
  isDrawerOpen: boolean;
}) {
  return (
    <Box
      id="main-container" //The id value is important for MuiDataGrid's sticky header
      marginLeft={isDrawerOpen ? 31.25 : 0}
      padding={(theme) => theme.spacing(3, 3, 4)}
      overflow="auto"
      position="relative"
      top={"50px"}
      height={"calc(100vh - 50px)"}
    >
      {children}
    </Box>
  );
}

export default MainContainer;
