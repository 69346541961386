import React, { forwardRef, useContext } from "react";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { PowerBIEmbed } from "powerbi-client-react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import classNames from "classnames";
import { useEmbeddedPowerBiLayout, useEmbedPowerBi } from "../hooks";
import { getPowerBiEventHandlers } from "../helpers";
import { PowerBiReportStyles } from "../styles";
import { PbiContext } from "./PbiContext";

const EmbeddedPowerBi = forwardRef((_: unknown, ref: any) => {
  const theme = useTheme();
  const styles = PowerBiReportStyles(theme);

  const {
    ActiveEmbedConfig,
    onLoaded,
    onPageChange,
    setFullScreenMode,
    fullScreenMode,
    isLoading,
    isSuccess,
    setActiveEmbeddedReport,
  } = useEmbedPowerBi();

  const { pbiTelemetryProps } = useContext(PbiContext);

  const { isReallyMobile } = useEmbeddedPowerBiLayout();

  if (!ActiveEmbedConfig || isLoading || !isSuccess) return <></>;

  return (
    <Box sx={styles.embedWrapper} ref={ref}>
      <Tooltip arrow title="Full Screen Mode">
        <Button
          color="inherit"
          sx={styles.fullScreenButton}
          onClick={() => setFullScreenMode(!fullScreenMode)}
        >
          <FullscreenIcon sx={{ fontSize: "1.5vw" }} />
        </Button>
      </Tooltip>
      <PowerBIEmbed
        cssClassName={classNames(
          {
            reportStyleMobile: isReallyMobile,
          },
          {
            reportStyle: !isReallyMobile,
          }
        )}
        embedConfig={ActiveEmbedConfig}
        eventHandlers={getPowerBiEventHandlers(
          onLoaded,
          onPageChange,
          pbiTelemetryProps
        )}
        getEmbeddedComponent={(embeddedReport) => {
          // @ts-ignore
          setActiveEmbeddedReport(embeddedReport);
        }}
      />
    </Box>
  );
});
export default EmbeddedPowerBi;
