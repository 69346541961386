import jsonTheme from "../../../theme/PowerBI-ColorTheme.json";
import { PbiEvents, TelemetryFunc } from "../entities";
import { PbiTelemetryProps } from "../components/PowerBiReportCenter";
import { BackgroundType, DisplayOption, LayoutType, TokenType, VisualContainerDisplayMode } from "powerbi-models";

export const getLayoutConfigSettings = (
  pageName: string,
  displayOption: DisplayOption,
  visuals = {}
) => {
  return {
    layoutType: LayoutType.Custom,
    customLayout: {
      displayOption: displayOption,
      pagesLayout: {
        [pageName]: {
          defaultLayout: {
            displayState: {
              mode: VisualContainerDisplayMode.Visible,
            },
          },
          visualsLayout: visuals,
        },
      },
    },
  };
};

export const getDefaultEmbedConfig = (
  currentReport: any | undefined,
  expandFilterPane = false
) => {
  if (!currentReport) return undefined;

  const settings = getEmbedConfigSettings(expandFilterPane);

  return {
    type: "report",
    id: currentReport?.embedReport?.reportId,
    embedUrl: currentReport?.embedReport?.embedUrl,
    accessToken: currentReport?.embedToken?.token,
    tokenType: TokenType.Embed,
    settings: settings,
    theme: { themeJson: jsonTheme[0] },
    // @ts-ignore
    filters: currentReport?.defaultModelFilters,
  };
};

export const getEmbedConfigSettings = (
  expandFilterPane = false,
  onFullScreen = false
) => {
  return {
    panes: {
      pageNavigation: {
        visible: onFullScreen,
      },
      filters: {
        expanded: expandFilterPane,
        visible: true,
      },
    },
    background: onFullScreen
      ? BackgroundType.Default
      : BackgroundType.Transparent,
  };
};

export const getPowerBiEventHandlers = (
  onLoaded: (e: any, entity: any) => void,
  onPageChange: (e: any, entity: any) => void,
  telemetryProps: PbiTelemetryProps
) => {
  const map = new Map<string, TelemetryFunc>();
  map.set(PbiEvents.loaded, onLoaded);
  map.set(PbiEvents.pagedChanged, onPageChange);

  if (telemetryProps?.trackRendered) {
    map.set(PbiEvents.rendered, telemetryProps?.trackRendered);
  }

  if (telemetryProps?.trackDataSelected) {
    map.set(PbiEvents.dataSelected, telemetryProps?.trackDataSelected);
  }

  if (telemetryProps?.trackButtonClicked) {
    map.set(PbiEvents.buttonClicked, telemetryProps?.trackButtonClicked);
  }

  if (telemetryProps?.trackFiltersApplied) {
    map.set(PbiEvents.filtersApplied, telemetryProps?.trackFiltersApplied);
  }

  if (telemetryProps?.trackCommandTriggered) {
    map.set(PbiEvents.commandTriggered, telemetryProps?.trackCommandTriggered);
  }

  if (telemetryProps?.trackBookmarkApplied) {
    map.set(PbiEvents.bookmarkApplied, telemetryProps?.trackBookmarkApplied);
  }

  if (telemetryProps?.trackDataHyperlinkClicked) {
    map.set(
      PbiEvents.dataHyperLinkClicked,
      telemetryProps?.trackDataHyperlinkClicked
    );
  }

  if (telemetryProps?.trackVisualRendered) {
    map.set(PbiEvents.visualRendered, telemetryProps?.trackVisualRendered);
  }

  if (telemetryProps?.trackVisualClicked) {
    map.set(PbiEvents.visualClicked, telemetryProps?.trackVisualClicked);
  }

  if (telemetryProps?.trackSelectionChanged) {
    map.set(PbiEvents.selectionChanged, telemetryProps?.trackSelectionChanged);
  }

  if (telemetryProps?.trackRenderingStarted) {
    map.set(PbiEvents.renderingStarted, telemetryProps?.trackRenderingStarted);
  }

  if (telemetryProps?.trackError) {
    map.set(PbiEvents.error, telemetryProps?.trackError);
  }
  return map;
};
