import {
  CloudAssetsWidgetClient,
  CloudAssetsWidgetDto,
  CloudAssetWidgetRequestType,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import {
  graphColors,
  SkeletonWrapper,
  Summary,
  useSummaryData,
} from "shared-ui";
import { useApi } from "../../utils";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: [
    { name: "Test1", count: 4 },
    { name: "Test2", count: 4 },
    { name: "Test3", count: 4 },
  ],
};

function compareByName(a: CloudAssetsWidgetDto, b: CloudAssetsWidgetDto) {
  if (!a.name || !b.name) {
    return 0;
  }

  return a.name.localeCompare(b.name);
}
function transformDataIntoSummaryWidgetData(
  cloudAssets: CloudAssetsWidgetDto[] | undefined
) {
  if (!cloudAssets) {
    return [] as Summary[];
  }

  const data = cloudAssets
    .sort((a, b) => compareByName(a, b))
    .map((cloudAsset, index) => ({
      name: "",
      label: cloudAsset.name || "",
      data: cloudAsset.count || 0,
      color: graphColors[index],
    }));
  return data;
}

const ByVendor = "ByVendor";
const ByCostCenter = "ByCostCenter";

function HeaderAction({
  filter,
  setFilter,
  loading,
}: {
  filter: string;
  setFilter: (string: string) => void;
  loading: boolean;
}) {
  return (
    <SkeletonWrapper loading={loading}>
      <FormControl sx={{ m: 0.5, minWidth: 200 }}>
        <Select
          sx={{ height: "40px" }}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          autoWidth
          displayEmpty
        >
          <MenuItem value={ByVendor}>By Vendor</MenuItem>
          <MenuItem value={ByCostCenter}>By Cost Center</MenuItem>
        </Select>
      </FormControl>
    </SkeletonWrapper>
  );
}

function useGetCloudWidgetsData(filter: string) {
  const api = useApi(CloudAssetsWidgetClient);
  const requestType =
    filter === ByVendor
      ? CloudAssetWidgetRequestType.Cloud
      : CloudAssetWidgetRequestType.CostCenter;

  return useSummaryData<CloudAssetsWidgetDto[]>({
    queryKey: [`cloud-accounts-widget-data`, filter],
    apiFunction: () =>
      api.getCloudAssets(requestType).then((r) => r.data || []),
    transformFunction: (data) => transformDataIntoSummaryWidgetData(data),
    placeholderData: placeholderData.data as CloudAssetsWidgetDto[],
  });
}

export default function CloudAccountsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    "cloud-accounts-widget-filter",
    ByVendor
  );

  const response = useGetCloudWidgetsData(currentFilterValue);

  return (
    <SummaryWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => response}
      leftLabel={"Active Cloud Accounts"}
      headerAction={
        <HeaderAction
          filter={currentFilterValue}
          setFilter={setFilter}
          loading={response.isPlaceholderData}
        />
      }
    />
  );
}
