import React from "react";

export function ShadedCellContent({
  value,
  index,
  numberOfRows,
  reverse = false,
  rgbNumber,
}: {
  value: string;
  index: number;
  numberOfRows: number;
  reverse?: boolean;
  rgbNumber: string;
}) {
  let ratio;
  if (reverse) {
    ratio = Number(index / numberOfRows) + 0.1;
  } else {
    ratio = 1 - Number(index / numberOfRows);
  }
  const generatedColor = `rgba(${rgbNumber}, ${(ratio * 0.2).toFixed(3)})`;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        backgroundColor: `${generatedColor}`,
        width: "100%",
        height: "100%",
      }}
    >
      {value}
    </div>
  );
}
