import React from "react";
import AuthPageContainer from "../AuthPageContainer";
import ShiOneLogo from "shared-ui/src/components/ShiOneLogo";

export default function SamlLogoutComponent() {
  return (
    <AuthPageContainer>
      <div>
        <ShiOneLogo />
        <h3>You have been signed out.</h3>
        Please return to your company's application portal to sign back in to
        SHI One.
      </div>
    </AuthPageContainer>
  );
}
