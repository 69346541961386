import React, { useEffect, useState } from "react";
import { getAENameFromEmail } from "../../../../../functions/projectHelper";
import { Grid, Typography } from "@mui/material";
import {
  StyledContractButton,
  StyledMetadataContainer,
} from "@features/projects-feature/utils/components/StyledProjectDetailsMetadataComponents";
import { ProjectDetailsMetadataProps } from "@features/projects-feature/projectTypes";
import AvatarContact from "@features/projects-feature/components/AvatarContact";

const ProjectDetailsMetadata: React.FC<ProjectDetailsMetadataProps> = ({
  selectedProject,
  openContractDetailDialog,
  contract,
}) => {
  const [projectLeadFullName, setProjectLeadFullName] = useState<
    string | undefined
  >(undefined);
  const [accountExecutiveFullName, setAccountExecutiveFullName] = useState<
    string | undefined
  >(undefined);

  const accountExecutive = getAENameFromEmail(
    selectedProject.project?.accountExecutive ?? ""
  );

  useEffect(() => {
    if (accountExecutive) {
      const fullName =
        accountExecutive?.firstName + " " + accountExecutive?.lastName;
      setAccountExecutiveFullName(fullName);
    }
  }, [accountExecutive]);

  useEffect(() => {
    if (selectedProject.project) {
      const fullName =
        selectedProject.project?.projectLeadFirstName +
        " " +
        selectedProject.project?.projectLeadLastName;

      setProjectLeadFullName(fullName);
    }
  }, [selectedProject]);

  return (
    <StyledMetadataContainer container gap={3}>
      {contract && (
        <Grid container item spacing={1} direction="column">
          <Grid item>
            <Typography variant="body1">Associated Contract</Typography>
          </Grid>
          <Grid item>
            <StyledContractButton onClick={openContractDetailDialog}>
              {contract.contractName}
            </StyledContractButton>
          </Grid>
        </Grid>
      )}
      {projectLeadFullName && (
        <Grid container item spacing={1} direction={"column"}>
          <Grid item>
            <Typography variant="body1">Project Lead</Typography>
          </Grid>
          <Grid item>
            <AvatarContact
              fullName={projectLeadFullName}
              mail={selectedProject.project?.projectLeadEmail ?? ""}
            />
          </Grid>
        </Grid>
      )}
      {accountExecutiveFullName && (
        <Grid container item spacing={1} direction={"column"}>
          <Grid item>
            <Typography variant="body1">Account Executive</Typography>
          </Grid>
          <Grid item>
            <AvatarContact
              fullName={accountExecutiveFullName}
              mail={selectedProject.project?.accountExecutive ?? ""}
            />
          </Grid>
        </Grid>
      )}
    </StyledMetadataContainer>
  );
};

export default ProjectDetailsMetadata;
