import { StyledDialogContent } from "@features/projects-feature/utils/components/StyledContractDetailsDialogComponents";
import { RecordsDetailContainer } from "@features/projects-feature/components/RecordsDetailContainer";
import { ServiceContractDetailsContainer } from "@features/projects-feature/components/ServiceContractDetailsContainer";
import React from "react";
import { ServiceContractDetailsContainerProps } from "@features/projects-feature/projectTypes";
import { useContractRecordsDetailFields } from "@features/projects-feature/utils/fields/ContractDetailsFields";

export const ContractDetailDialogContent = ({
  contract,
  picklist,
}: ServiceContractDetailsContainerProps) => {
  const { data: recordsDetail, loading } =
    useContractRecordsDetailFields(contract);

  return (
    <StyledDialogContent dividers>
      <RecordsDetailContainer recordsDetail={recordsDetail} loading={loading} />
      <ServiceContractDetailsContainer
        contract={contract}
        picklist={picklist}
      />
    </StyledDialogContent>
  );
};
