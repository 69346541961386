import {DateTime} from "luxon";

export const getDiffDays = (firstDate, secondDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(
    Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
  );
};

export const getDuration = (startDate, endDate) => {
  const startTime = new Date(startDate);
  const endTime = new Date(endDate);
  const currentDate = new Date();

  const duration = getDiffDays(startTime, endTime) + 1;
  const currentProgress = getDiffDays(startTime, currentDate) - 1;
  return {
    duration,
    currentProgress,
  };
};

export const isDate = (str) => {
  return Date.parse(str) && DateTime.fromJSDate(str).isValid;
};

export const dateDisplayOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const localDateTimeValidator =
  /^[01]?\d\/[0123]?\d\/(\d{2})?\d{2},? [01]?\d:[012345]\d(:[012345]\d)? ?[AaPp][Mm]$/;
