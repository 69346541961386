import { UserConfigDto } from "@lib/ShiOneClient";
import React, { useCallback, useEffect } from "react";
import { GridApi } from "@mui/x-data-grid-pro";
import { useLocation } from "react-router-dom";
import { useUpsertUserConfig } from "../../modules/hooks/useUserConfig";

export function useRegisterDataGridSaveEvents(
  apiRef: React.MutableRefObject<GridApi>,
  gridId: string | undefined,
  saveUserConfig: boolean
) {
  let location = useLocation();
  const upsertUserConfig = useUpsertUserConfig();

  const SaveGridState = useCallback(() => {
    if (!apiRef?.current || !saveUserConfig) return;
    let state = apiRef.current.exportState();
    const settings = new UserConfigDto();
    settings.settingsKey =
      location.pathname + "_MuiGridSettings" + (gridId ? ":" + gridId : "");

    const gridSettings: any = {
      gridState: state,
    };

    settings.settingsValue = JSON.stringify(gridSettings);
    upsertUserConfig.mutate(settings);
  }, [apiRef, gridId, location.pathname, saveUserConfig, upsertUserConfig]);

  // Need individual useEffects because subscribeEvent must return its cleanup function
  // to avoid multiple event subscriptions.

  useEffect(() => {
    return apiRef?.current?.subscribeEvent(
      "columnVisibilityModelChange",
      SaveGridState
    );
  }, [SaveGridState, apiRef]);
  useEffect(() => {
    return apiRef?.current?.subscribeEvent("columnOrderChange", SaveGridState);
  }, [SaveGridState, apiRef]);
  useEffect(() => {
    return apiRef?.current?.subscribeEvent("sortModelChange", SaveGridState);
  }, [SaveGridState, apiRef]);
  useEffect(() => {
    return apiRef?.current?.subscribeEvent("columnResizeStop", SaveGridState);
  }, [SaveGridState, apiRef]);
  useEffect(() => {
    return apiRef?.current?.subscribeEvent(
      "pinnedColumnsChange",
      SaveGridState
    );
  }, [SaveGridState, apiRef]);
}
