import {
  SampleWidgetBClient,
  WidgetContentResponseDto_1OfSampleWidgetBResponseDto,
} from "@lib/ShiOneClient";
import { Button } from "@mui/material";
import {
  AddCircleOutlineOutlined,
  CheckCircleOutlineOutlined,
  ErrorOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";

import { useApi } from "../../utils";
import React from "react";
import {
  BaseWidgetProps,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
  asDto,
} from "shared-ui";

function CardActions() {
  return <Button href={"/"}>View Warehouse Stock</Button>;
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    incomingAssets: 11,
    transferringAssets: 2,
    assetsAvailableToTransfer: 1,
    productsAtLevelRisk: 0,
  },
};

function transformFunction(
  rawData: WidgetContentResponseDto_1OfSampleWidgetBResponseDto | undefined
): ItemBoxConfiguration[] {
  return [
    {
      amount: rawData?.data?.incomingAssets,
      label: "Incoming Assets",
      icon: <AddCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.transferringAssets,
      label: "Transferring Assets",
      icon: <RemoveCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.assetsAvailableToTransfer,
      label: "Assets Available to Transfer",
      icon: <CheckCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.productsAtLevelRisk,
      label: "Products at Level Risk",
      icon: <ErrorOutlined fontSize={"large"} color={"warning"} />,
    },
  ];
}

function useGetSampleWidgetBData() {
  const api = useApi(SampleWidgetBClient);

  return useKeyInformationData({
    queryKey: ["sample-widget-b"],
    apiFunction: () => api.getSampleWidgetB(),
    transformFunction: transformFunction,
    placeholderData: asDto(placeholderData),
  });
}

export default function SampleWidgetB({ pageLayoutWidget }: BaseWidgetProps) {
  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetSampleWidgetBData}
      cardActions={<CardActions />}
    />
  );
}
