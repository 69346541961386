import React from "react";
import { NavItemType } from "../../NavigationTypes";
import { settingsItemsToShowType } from "../../NavItemsToShowTypes";
import SettingsIcon from "@mui/icons-material/Settings";

export function SettingsMenu(
  settingsItemsToShow: settingsItemsToShowType,
  shouldDisplayAdmin: boolean,
): NavItemType {
  return {
    id: "settings",
    type: "Group",
    displayName: "Settings",
    icon: <SettingsIcon />,
    visible:
      Object.entries(settingsItemsToShow).some(([_k, v]) => v) &&
      shouldDisplayAdmin,
    children: [
      {
        id: "settingsGeneral",
        type: "Link",
        displayName: "General",
        path: "/settings/general",
        visible: settingsItemsToShow.showGeneral,
      },
      {
        id: "settingsIntegrations",
        type: "Link",
        displayName: "Integrations",
        path: "/settings/integrations",
        visible: settingsItemsToShow.showIntegrations,
      },
      {
        id: "settingsUserManagement",
        type: "Link",
        displayName: "User Management",
        path: "/settings/user-management",
        visible: settingsItemsToShow.showUserManagement,
      },
      {
        id: "settingsSecurityGroupsESG",
        type: "Link",
        displayName: "Security Groups",
        path: "/settings/esg",
        visible: settingsItemsToShow.showSecurityGroups,
      },
      {
        id: "costCenters",
        type: "Link",
        displayName: "Cost Centers",
        path: "/settings/cost-centers",
        visible: settingsItemsToShow.showCostCenters,
      },
    ],
  };
}
