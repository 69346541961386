import {
  ServiceRequestMapping,
  ServiceRequestMappingClient,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new ServiceRequestMappingClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestServiceRequestMappings(): Promise<
  ServiceRequestMapping[]
> {
  return api.getServiceRequestMappings();
}

export async function postRestServiceRequestMappings(
  request: ServiceRequestMapping
): Promise<ServiceRequestMapping[]> {
  return api.createServiceRequestMapping(request);
}

export async function putRestServiceRequestMappings(
  request: ServiceRequestMapping
): Promise<ServiceRequestMapping[]> {
  return api.updateServiceRequestMapping(request);
}

export async function deleteRestServiceRequestMapping(
  request: ServiceRequestMapping
): Promise<void> {
  return api.deleteServiceRequestMapping(request);
}
