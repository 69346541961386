import {
  Button,
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import {
  SampleWidgetAClient,
  WidgetContentResponseDto_1OfString,
} from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { useApi } from "../../utils";
import {
  useGetWidgetFilterUserConfig,
  useSetUserColumnWidgetFilterConfig,
} from "../../dashboards/framework";
import {
  BaseWidgetProps,
  loading,
  SkeletonWrapper,
  WidgetWrapper,
} from "shared-ui";

const filterKey = "widget-filters";

function HeaderAction({
  age,
  setAge,
  loading,
}: {
  age: number;
  setAge: (age: number) => void;
  loading: boolean;
}) {
  return (
    <SkeletonWrapper loading={loading}>
      <FormControl sx={{ m: 0.5, minWidth: 200 }}>
        <Select
          sx={{ height: "40px" }}
          labelId={filterKey}
          id={filterKey}
          value={age}
          onChange={(e) => {
            setAge(e.target.value as number);
          }}
          autoWidth
          displayEmpty
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </SkeletonWrapper>
  );
}

function CardActions() {
  return (
    <>
      <Button size="small">Action</Button>
    </>
  );
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: "10/20/2023 12:23:29PM",
};

function useGetSampleWidgetAData() {
  const api = useApi(SampleWidgetAClient);

  return useQuery<WidgetContentResponseDto_1OfString, Error>(
    [`sample-widget-a`],
    () => api.getSampleWidgetA().then((r) => r),
    {
      staleTime: ms("10m"),
      placeholderData: placeholderData as WidgetContentResponseDto_1OfString,
    }
  );
}

function isLoading(loadingIndicators: boolean[]) {
  return loadingIndicators.some((loadingIndicator) => loadingIndicator);
}

export default function SampleWidgetA({ pageLayoutWidget }: BaseWidgetProps) {
  const initialValue = 10;

  const filterUserConfig = useGetWidgetFilterUserConfig(
    pageLayoutWidget.widgetId
  );

  const { currentFilterValue: age, setFilter: setAge } =
    useSetUserColumnWidgetFilterConfig(
      pageLayoutWidget.widgetId!,
      filterKey,
      filterUserConfig,
      initialValue
    );

  const response = useGetSampleWidgetAData();

  const isLoadingData = isLoading([
    filterUserConfig.isLoading,
    response.isPlaceholderData,
  ]);

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      cardActions={<CardActions />}
      headerAction={
        <HeaderAction age={age} setAge={setAge} loading={isLoadingData} />
      }
      error={response.isError}
    >
      <Box>
        {loading(
          <Typography variant={"h1"}>{response?.data?.data}</Typography>,
          isLoadingData,
          response.isError
        )}

        {loading(
          <Typography variant={"h2"}>{`Age is ${age}`}</Typography>,
          isLoadingData,
          response.isError
        )}
      </Box>
    </WidgetWrapper>
  );
}
