// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { MappingRow } from "./AddMapping";
import { MappingCol } from "./useMappingEngineSchema";

export enum mappingActions {
  add_mapping = "add_mapping",
  update_mapping = "update_mapping",
  delete_mapping = "delete_mapping",
  na_override = "na_override",
}

const validateObject = (object: MappingRow) => {
  if (
    (object.naOverride && object.mappedCol) ||
    (object.excelSheet.locationKey.length > 0 &&
      object.column.length > 0 &&
      object.mappedCol)
  ) {
    return {
      ...object,
      valid: true,
    };
  }
  return {
    ...object,
    valid: false,
  };
};

export function mappingReducer(state: any, action: any) {
  switch (action.type) {
    case "add_mapping": {
      const newMappingCol: MappingCol = {
        name: "",
        type: "string",
        required: false,
      };

      const newMapping: MappingRow = {
        excelSheet: {
          name: "",
          sourceSheetName: "",
          locationPattern: "",
          locationKey: "",
          id: "",
          valid: false,
        },
        aliasName: "",
        editMode: false,
        column: "",
        mappedCol: newMappingCol,
        mappedColDateFormat: "",
        naOverride: false,
        id: uuidv4(),
        valid: false,
      };
      return [...state, newMapping];
    }
    case "update_mapping": {
      return state.map((t: MappingRow) => {
        if (t.id === action.id) {
          const updatedObject = {
            ...t,
            [action.name]: action.value,
          };
          return validateObject(updatedObject);
        } else {
          return t;
        }
      });
    }
    case "delete_mapping": {
      return state.filter((item: { id: string }) => item.id !== action.id);
    }
    case "na_override": {
      return state.map((t: MappingRow) => {
        if (t.id === action.id) {
          if (!t.naOverride) {
            const updatedObject = {
              ...t,
              naOverride: true,
              aliasName: "N/A",
            };
            return validateObject(updatedObject);
          } else {
            const updatedObject = {
              ...t,
              naOverride: false,
              aliasName: "",
            };
            return validateObject(updatedObject);
          }
        } else {
          return t;
        }
      });
    }
    default: {
      return state;
    }
  }
}
