import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { UserConfigDto } from "lib/ShiOneClient";
import {
  deleteUserConfig,
  getUserConfigs,
  postUserConfig,
} from "@api/userSettingsStore";
import ms from "ms";

export const cacheKey = "UserConfigs";

export interface UserConfigResponse {
  userConfig: UserConfigDto | undefined;
  isLoading: boolean;
  error: Error | null;
}

export const useGetUserConfig = (settingsKey: string): UserConfigResponse => {
  const {
    data: userConfigs,
    error,
    isLoading,
  } = useGetUserConfigs(settingsKey);
  const userConfig = userConfigs?.find(
    (config) => config.settingsKey === settingsKey
  );
  return { userConfig, isLoading: isLoading && !!settingsKey, error };
};

const useGetUserConfigs = (settingKeys: string) => {
  return useQuery<UserConfigDto[], Error>({
    queryKey: [cacheKey],
    queryFn: () => getUserConfigs(),
    staleTime: ms("10m"),
    enabled: !!settingKeys,
  });
};

export const useUpsertUserConfig = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UserConfigDto>(
    async (userConfigDto) => {
      await postUserConfig(userConfigDto);
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.setQueryData(
          [cacheKey],
          (oldData: UserConfigDto[] | undefined) => {
            if (!oldData) return [];
            const index = oldData.findIndex(
              (item) => item.settingsKey === variables.settingsKey
            );
            if (index !== -1) {
              const updatedData = [...oldData];
              updatedData[index] = variables;
              return updatedData;
            } else {
              return [...oldData, variables];
            }
          }
        );
      },
    }
  );
};

export const useDeleteUserConfig = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UserConfigDto>(
    async (userConfigDto) => {
      await deleteUserConfig(userConfigDto);
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.setQueryData(
          [cacheKey],
          (oldData: UserConfigDto[] | undefined) => {
            if (!oldData) return [];
            return oldData.filter(
              (item) => item.settingsKey !== variables.settingsKey
            );
          }
        );
      },
    }
  );
};
