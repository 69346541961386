import { useThemeMode } from "shared-ui/src/theme/ThemeModeProviderContext";
import { Grid, Typography, TypographyProps } from "@mui/material";

export default function Logo({
  product,
  shape,
  size,
  tag,
  tagProps,
}: {
  product: string;
  shape: "square";
  size: "small" | "medium" | "large";
  tag?: string;
  tagProps?: TypographyProps;
}) {
  let typedProduct: keyof typeof LOGO_CONSTANTS;
  if (!(product in LOGO_CONSTANTS)) {
    typedProduct = "Unknown";
  } else {
    typedProduct = product as keyof typeof LOGO_CONSTANTS;
  }

  const { mode } = useThemeMode();
  const logosBaseUrl = import.meta.env.LOGOS;
  let logoUrl =
    logosBaseUrl +
    LOGO_CONSTANTS[typedProduct] +
    "-" +
    shape +
    "-" +
    mode +
    ".png";

  let width;
  switch (size) {
    case "small":
      width = 25;
      break;
    case "medium":
      width = 50;
      break;
    case "large":
      width = 100;
      break;
  }

  return (
    <Grid container gap={2} direction="row">
      <img
        src={logoUrl}
        alt={product + "-" + shape + "-" + size + "-" + mode}
        width={width}
      />
      {tag && (
        <Typography {...tagProps} alignSelf={"center"}>
          {tag}
        </Typography>
      )}
    </Grid>
  );
}

export const LOGO_CONSTANTS = {
  "Active Directory": "entra",
  AWS: "aws",
  Azure: "azure",
  Copilot: "copilot",
  DataDog: "datadog",
  Dynamics365: "dynamics365",
  Entra: "entra",
  Exchange: "exchange",
  GCP: "gcp",
  Microsoft365: "microsoft365",
  Microsoft: "microsoft",
  OneDrive: "onedrive",
  SharePoint: "sharepoint",
  SHI: "shi",
  "SHI One": "shi",
  Teams: "teams",
  Viva: "viva",
  Unknown: "unknown",
};
