import { useQuery } from "@tanstack/react-query";
import {
  ServiceRequestBaseElasticQueryRequest,
  ServiceRequestListDocumentDto,
} from "@lib/ShiOneClient";
import { CACHE_KEY_SERVICE_REQUESTS } from "../constants";
import { getElasticServiceRequests } from "@api/serviceRequestStore";
import ms from "ms";

export function useGetElasticServiceRequests(
  queryRequest: ServiceRequestBaseElasticQueryRequest
) {
  const fetchServiceRequests = async () => {
    return await getElasticServiceRequests(queryRequest);
  };

  return useQuery<ServiceRequestListDocumentDto, Error>({
    queryKey: [
      `${CACHE_KEY_SERVICE_REQUESTS}/${queryRequest?.serviceRequestQueryType}/${queryRequest?.query?.resultsFrom}`,
      queryRequest,
    ],
    queryFn: fetchServiceRequests,
    staleTime: ms("5m"),
    refetchOnWindowFocus: true,
  });
}
