export const GET_ACCOUNT_TEAMS = "GET_ACCOUNT_TEAMS";
export const GET_ACCOUNT_TEAMS_SUCCESS = "GET_ACCOUNT_TEAMS_SUCCESS";
export const GET_ACCOUNT_TEAMS_ERROR = "GET_ACCOUNT_TEAMS_ERROR";

export const CREATE_TEAM = "CREATE_TEAM";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";

export const ADD_CONTRACTS_TO_TEAM = "ADD_CONTRACTS_TO_TEAM";
export const ADD_CONTRACTS_TO_TEAM_SUCCESS = "ADD_CONTRACTS_TO_TEAM_SUCCESS";
export const ADD_CONTRACTS_TO_TEAM_ERROR = "ADD_CONTRACTS_TO_TEAM_ERROR";

export const REMOVE_CONTRACTS_FROM_TEAM = "REMOVE_CONTRACTS_FROM_TEAM";
export const REMOVE_CONTRACTS_FROM_TEAM_SUCCESS =
  "REMOVE_CONTRACTS_FROM_TEAM_SUCCESS";
export const REMOVE_CONTRACTS_FROM_TEAM_ERROR =
  "REMOVE_CONTRACTS_FROM_TEAM_ERROR";

export const ADD_USERS_TO_TEAM = "ADD_USERS_TO_TEAM";
export const ADD_USERS_TO_TEAM_SUCCESS = "ADD_USERS_TO_TEAM_SUCCESS";
export const ADD_USERS_TO_TEAM_ERROR = "ADD_USERS_TO_TEAM_ERROR";

export const REMOVE_USERS_FROM_TEAM = "REMOVE_USERS_FROM_TEAM";
export const REMOVE_USERS_FROM_TEAM_SUCCESS = "REMOVE_USERS_FROM_TEAM_SUCCESS";
export const REMOVE_USERS_FROM_TEAM_ERROR = "REMOVE_USERS_FROM_TEAM_ERROR";

export const MODIFY_CONTRACT_MAPPING = "MODIFY_CONTRACT_MAPPING";
export const MODIFY_CONTRACT_MAPPING_SUCCESS =
  "MODIFY_CONTRACT_MAPPING_SUCCESS";
export const MODIFY_CONTRACT_MAPPING_ERROR = "MODIFY_CONTRACT_MAPPING_ERROR";

export const GET_TEAM_CONTRACTS = "GET_TEAM_CONTRACTS";
export const GET_TEAM_CONTRACTS_ERROR = "GET_TEAM_CONTRACTS_ERROR";
export const GET_TEAM_CONTRACTS_SUCCESS = "GET_TEAM_CONTRACTS_SUCCESS";

export const ADD_TEAM_CONFIG_ITEMS = "ADD_TEAM_CONFIG_ITEMS";
export const ADD_TEAM_CONFIG_ITEMS_ERROR = "ADD_TEAM_CONFIG_ITEMS_ERROR";
export const ADD_TEAM_CONFIG_ITEMS_SUCCESS = "ADD_TEAM_CONFIG_ITEMS_SUCCESS";

export const REMOVE_TEAM_CONFIG_ITEMS = "REMOVE_TEAM_CONFIG_ITEMS";
export const REMOVE_TEAM_CONFIG_ITEMS_ERROR = "REMOVE_TEAM_CONFIG_ITEMS_ERROR";
export const REMOVE_TEAM_CONFIG_ITEMS_SUCCESS =
  "REMOVE_TEAM_CONFIG_ITEMS_SUCCESS";

export const REMOVE_TEAM = "REMOVE_TEAM";
export const REMOVE_TEAM_ERROR = "REMOVE_TEAM_ERROR";
export const REMOVE_TEAM_SUCCESS = "REMOVE_TEAM_SUCCESS";

export const RENAME_TEAM = "RENAME_TEAM";
export const RENAME_TEAM_ERROR = "RENAME_TEAM_ERROR";
export const RENAME_TEAM_SUCCESS = "RENAME_TEAM_SUCCESS";

export const SET_CONTACT_TEAM_ROLE = "SET_CONTACT_TEAM_ROLE";
export const SET_CONTACT_TEAM_ROLE_ERROR = "SET_CONTACT_TEAM_ROLE_ERROR";
export const SET_CONTACT_TEAM_ROLE_SUCCESS = "SET_CONTACT_TEAM_ROLE_SUCCESS";

export function renameTeam(id, name) {
  return (dispatch) => {
    dispatch({
      type: RENAME_TEAM,
      payload: {
        data: name,
        teamId: id,
      },
    });
  };
}

export function removeTeam(id) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_TEAM,
      payload: {
        data: id,
      },
    });
  };
}

export function addAndRemoveItemsToTeam(configItemsToAdd, configItemsToRemove) {
  // console.log(configItemsToAdd, configItemsToRemove)
  return (dispatch) => {
    if (configItemsToAdd.configItemIds.length > 0) {
      dispatch({
        type: ADD_TEAM_CONFIG_ITEMS,
        payload: {
          data: configItemsToAdd,
        },
      });
    }
    if (configItemsToRemove.configItemIds.length > 0) {
      dispatch({
        type: REMOVE_TEAM_CONFIG_ITEMS,
        payload: {
          data: configItemsToRemove,
        },
      });
    }
  };
}

export function addConfigItemsToTeam(configItems) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_CONFIG_ITEMS,
      payload: {
        data: configItems,
      },
    });
  };
}

export function removeConfigItemsFromTeam(configItems) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_TEAM_CONFIG_ITEMS,
      payload: {
        data: configItems,
      },
    });
  };
}

export function getTeamContracts() {
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_CONTRACTS,
    });
  };
}

export function getAccountTeams(id) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_TEAMS,
      payload: {
        data: id,
      },
    });
  };
}

export function createTeam(team) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM,
      payload: {
        data: team,
      },
    });
  };
}

export function addContractsToTeam(contracts) {
  return (dispatch) => {
    dispatch({
      type: ADD_CONTRACTS_TO_TEAM,
      payload: {
        data: contracts,
      },
    });
  };
}

export function addContactsToTeam(contacts) {
  return (dispatch) => {
    dispatch({
      type: ADD_USERS_TO_TEAM,
      payload: {
        data: contacts,
      },
    });
  };
}

export function removeContactsFromTeam(contacts) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USERS_FROM_TEAM,
      payload: {
        data: contacts,
      },
    });
  };
}

export function removeContractsFromTeam(contracts) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CONTRACTS_FROM_TEAM,
      payload: {
        data: contracts,
      },
    });
  };
}

export function modifyContractMapping(contractMapping) {
  // console.log('modifyContractMapping', contractMapping)
  return (dispatch) => {
    dispatch({
      type: MODIFY_CONTRACT_MAPPING,
      payload: {
        data: contractMapping,
      },
    });
  };
}

export function setContactTeamRole(contact) {
  return (dispatch) => {
    dispatch({
      type: SET_CONTACT_TEAM_ROLE,
      payload: {
        data: contact,
      },
    });
  };
}
