import { ActiveReportStore } from "../stores";
import { Page } from "powerbi-client";
import { useEffect, useState } from "react";

export const usePowerBiReportMenu = () => {
  const [
    ActiveEmbeddedReport,
    ActiveReportId,
    ActiveReportIsLoading,
    ActivePagesAreLoading,
  ] = ActiveReportStore((s) => [
    s.activeEmbeddedReport,
    s.activeReportId,
    s.activeReportIsLoading,
    s.activePagesAreLoading,
  ]);

  const [
    setActiveReportId,
    setCurrentActivePage,
    setActivePagesAreLoading,
    setActiveReportIsLoading,
  ] = ActiveReportStore((s) => [
    s.setActiveReportId,
    s.setCurrentActivePage,
    s.setActivePagesAreLoading,
    s.setActiveReportIsLoading,
  ]);

  const [showPages, setShowPages] = useState(true);
  const [reportPages, setReportPages] = useState<Page[]>([]);

  const isLoading =
    ActiveReportIsLoading || ActivePagesAreLoading || reportPages.length === 0;

  useEffect(() => {
    if (ActiveReportIsLoading && reportPages.length) {
      setReportPages([]);
    }
    if (
      ActiveEmbeddedReport &&
      ActiveEmbeddedReport.iframeLoaded &&
      ActivePagesAreLoading
    ) {
      ActiveEmbeddedReport?.getPages().then((pages) => {
        const validPages = pages.filter((page: Page) => !page.visibility);
        setReportPages(validPages);
      });
      ActiveEmbeddedReport?.getActivePage().then((page) =>
        setCurrentActivePage(page)
      );

      setActivePagesAreLoading(false);
    }
  }, [
    ActiveEmbeddedReport?.iframeLoaded,
    ActivePagesAreLoading,
    ActiveReportIsLoading,
  ]);

  const handleReportClick = async (value: string) => {
    if (ActiveReportId === value) {
      setShowPages(!showPages);
      return;
    }
    setShowPages(true);
    setActiveReportId(value);
    setActiveReportIsLoading(true);
  };

  return {
    ActiveReportId,
    reportPages,
    isLoading,
    showPages,
    handleReportClick,
  };
};
