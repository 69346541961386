import React from "react";
import NavLink from "./NavLink";
import NavGroup from "./NavGroup";
import NavFunction from "./NavFunction";
import { NavItemType } from "./NavigationTypes";
import NavDashboard from "./NavDashboard";

interface NavItemProps {
  navItem: NavItemType;
  tier: number;
  parentContext?: string;
}
export default function NavItem({
  navItem,
  tier,
  parentContext = "Main Menu",
}: NavItemProps) {
  if (navItem.type === "Group") {
    return (
      <NavGroup navItem={navItem} tier={tier} parentContext={parentContext} />
    );
  } else if (navItem.type === "Function") {
    return (
      <NavFunction
        navItem={navItem}
        tier={tier}
        parentContext={parentContext}
      />
    );
  } else if (navItem.type === "Link") {
    return (
      <NavLink navItem={navItem} tier={tier} parentContext={parentContext} />
    );
  } else if (navItem.type === "Dashboard") {
    return (
      <NavDashboard
        navItem={navItem}
        tier={tier}
        parentContext={parentContext}
      />
    );
  } else {
    return <></>;
  }
}
