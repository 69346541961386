import { useEffect } from "react";
import { ActiveReportStore, EmbeddedPowerBiStore } from "../stores";
import { ReportCenterParams } from "../entities";

export const usePowerBiReportCenter = (
  ReportCenterParams: ReportCenterParams
) => {
  const { MoveReportDown, setActiveReportId } = ActiveReportStore((s) => ({
    MoveReportDown: s.moveReportDown,
    setActiveReportId: s.setActiveReportId,
  }));

  const { setReportCenterParams } = EmbeddedPowerBiStore((s) => ({
    setReportCenterParams: s.setReportCenterParams,
  }));

  useEffect(() => {
    if (ReportCenterParams?.reports?.length) {
      setReportCenterParams(ReportCenterParams);
      setActiveReportId(ReportCenterParams?.reports?.[0]?.reportId ?? "");
    }
  }, [ReportCenterParams?.reports, setReportCenterParams, setActiveReportId]); // eslint-disable-line react-hooks/exhaustive-deps

  return { MoveReportDown };
};
