import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import React from "react";
import { NavItemType } from "../../NavigationTypes";

export function CustomerDashboardsMenuV2(): NavItemType {
  return {
    id: "home",
    type: "Dashboard",
    displayName: "Home",
    path: "/home",
    icon: <SpaceDashboardIcon className={"fas fa-th"} />,
    children: [],
    visible: true,
  };
}
