import { ActiveReportStore } from "../stores";
import { Page } from "powerbi-client";

export const usePowerBiReportPagesMenu = () => {
  const currentActivePage = ActiveReportStore((s) => s.currentActivePage);
  const setCurrentActivePage = ActiveReportStore((s) => s.setCurrentActivePage);

  const handlePageClick = async (page: Page) => {
    if (!page) return;
    await page.setActive();
    setCurrentActivePage(page);
  };

  return {
    currentActivePage,
    handlePageClick,
  };
};
