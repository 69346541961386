import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import { marketplaceMappingState } from "./marketplaceMappingTypes";
import { SubscriptionMapping, TenantSubscriptions } from "lib/ShiOneClient";
import {
  getRestCustomerTenantSubscriptions,
  postRestSubscriptionMapping,
} from "../../../api/marketplaceMappingStore";

const initialState: marketplaceMappingState = {
  unmappedTenantsFetchStatus: "idle",
  unmappedTenants: [],
  submitSubscriptionMappingStatus: "idle",
};

const marketplaceMappingSlice = createSlice({
  name: "marketplaceMapping",
  initialState,
  reducers: {
    receivingUnmappedTenants(state) {
      state.unmappedTenantsFetchStatus = "loading";
    },
    receivedUnmappedTenants(
      state,
      action: PayloadAction<TenantSubscriptions[]>
    ) {
      state.unmappedTenants = action.payload;
      state.unmappedTenantsFetchStatus = "complete";
    },
    receiveUnmappedTenantsError(state) {
      state.unmappedTenantsFetchStatus = "error";
    },
    submittingSubscriptionMapping(state) {
      state.submitSubscriptionMappingStatus = "loading";
    },
    submittedSubscriptionMapping(
      state,
      action: PayloadAction<string[] | undefined>
    ) {
      state.submitSubscriptionMappingStatus = "complete";
    },
    submitSubscriptionMappingError(state) {
      state.submitSubscriptionMappingStatus = "error";
    },
    resetSubmitStatus(state) {
      state.submitSubscriptionMappingStatus = "idle";
    },
  },
});

export const getUnmappedTenants =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(marketplaceMappingSlice.actions.receivingUnmappedTenants());
    try {
      const unmappedTenants = await getRestCustomerTenantSubscriptions(true);
      dispatch(
        marketplaceMappingSlice.actions.receivedUnmappedTenants(unmappedTenants)
      );
    } catch {
      dispatch(marketplaceMappingSlice.actions.receiveUnmappedTenantsError());
    }
  };

export const postSubscriptionMapping =
  (postRequest: SubscriptionMapping): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(marketplaceMappingSlice.actions.submittingSubscriptionMapping());
    try {
      await postRestSubscriptionMapping(postRequest);
      dispatch(
        marketplaceMappingSlice.actions.submittedSubscriptionMapping(
          postRequest.subscriptionIds
        )
      );

      const newUnmappedTenants = await getRestCustomerTenantSubscriptions(true);
      dispatch(
        marketplaceMappingSlice.actions.receivedUnmappedTenants(
          newUnmappedTenants
        )
      );
    } catch {
      dispatch(
        marketplaceMappingSlice.actions.submitSubscriptionMappingError()
      );
    }
  };

export const resetSubmitSubscriptionMappingStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(marketplaceMappingSlice.actions.resetSubmitStatus());
  };

export default marketplaceMappingSlice.reducer;
