import {
  ActiveUsersByServiceWidgetResponse,
  MicrosoftAdoptionWidgetsClient,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import { SkeletonWrapper, WidgetWrapper } from "shared-ui";
import { Box, Grid, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import LinearProgress from "@mui/material/LinearProgress";
import theme from "../../../../theme";
import ToggleButton from "@mui/material/ToggleButton";
import { timeOptionKeys, timeOptions } from "@widgets/utils/DateRanges";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";

const placeholderData = {
  services: [
    {
      service: "undefined",
      activeUsers: 100,
      totalUsers: 100,
    },
    {
      service: "undefined",
      activeUsers: 100,
      totalUsers: 100,
    },
    {
      service: "undefined",
      activeUsers: 100,
      totalUsers: 100,
    },
  ],
};

function useGetWidgetData(dateOption: string) {
  const api = useApi(MicrosoftAdoptionWidgetsClient);

  return useQuery<ActiveUsersByServiceWidgetResponse, Error>({
    queryKey: ["active-users-by-service-widget", dateOption],
    queryFn: () => api.getActiveUsersByServiceWidget(dateOption),
    placeholderData: placeholderData as ActiveUsersByServiceWidgetResponse,
    staleTime: ms("10m"),
    enabled: true,
  });
}

export default function ActiveUsersByServiceWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const filterKey = "active-users-by-service-widget";
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    filterKey,
    timeOptionKeys.thisMonth
  );

  const { data, isFetching, isError } = useGetWidgetData(currentFilterValue);

  let timeOptionsFiltered = Object.keys(timeOptions).filter((to) => {
    return to === timeOptionKeys.lastMonth || to === timeOptionKeys.thisMonth;
  });

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      noPadding={true}
      headerAction={
        <ToggleButtonGroup
          color="secondary"
          exclusive
          value={currentFilterValue}
          onChange={(e, value) => {
            if (value === null) return;
            setFilter(value);
          }}
        >
          {timeOptionsFiltered.map((to) => {
            return (
              <ToggleButton style={{ lineHeight: 1 }} key={to} value={to}>
                {to}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      }
    >
      <Grid container spacing={2} sx={{ padding: theme.spacing(2) }}>
        {data?.services?.map((item, i) => (
          <Grid key={i} item xs={4}>
            <SkeletonWrapper loading={isFetching} error={isError}>
              <Box
                sx={{
                  padding: theme.spacing(2),
                  flexDirection: "column",
                  background: theme.palette.background.default,
                }}
              >
                <Logo
                  product={item.service || "undefined"}
                  shape={"square"}
                  size={"medium"}
                />
                <Typography variant={"h6"}>
                  {item.activeUsers} / {item.totalUsers}
                </Typography>
                <Box sx={{ width: "100%", padding: `${theme.spacing(1)} 0` }}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      ((item.activeUsers || 0) / (item.totalUsers || 1)) * 100
                    }
                    sx={{ borderRadius: "5px" }}
                  />
                </Box>
                <Typography
                  variant={"body2"}
                  color={theme.palette.text.secondary}
                >
                  {item.service?.replace(/([A-Z])|(\d+)/g, " $&")} Active Users
                </Typography>
              </Box>
            </SkeletonWrapper>
          </Grid>
        ))}
      </Grid>
    </WidgetWrapper>
  );
}
