import {
  AzureCreateSubscriptionRequest,
  AzureSubscriptionClient,
  AzureSubscriptionResponse,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new AzureSubscriptionClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function putRestAzureSubscription(
  request: AzureCreateSubscriptionRequest
): Promise<AzureSubscriptionResponse> {
  return api.putSubscription(request);
}
