import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import { getRequestPercentage } from "@widgets/utils/widgetFunctions";
import {
  ContractRequestRankingDto,
  ContractRequestRankingsVisualsDto,
} from "@lib/ShiOneClient";
import { faker } from "@faker-js/faker";
import { SortingOrder } from "../../../../../components/mui-data-grid/constants";
import theme from "../../../../../theme";
import { AppendIfPrefixExists } from "../../../../../modules/sitePaths";
import { NavLink } from "react-router-dom";
import {
  ShadedCellContent,
  SkeletonWrapper,
} from "shared-ui";

export const ContractRequestRankingsFunctionKey =
  "contract-request-rankings-widget-filter";

export const ContractRequestRankingsWidgetGridDefinitions = (
  loading: boolean,
  error: boolean,
  totalRecords: number
) => {
  return {
    sortedColumns: [{ field: "requestsCount", sort: SortingOrder.Descending }],
    columnDefinitions: [
      {
        id: "contractName",
        headerName: "Contract",
        sortable: true,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          const { row, value } = params;
          return (
            <SkeletonWrapper loading={loading || error}>
              <NavLink
                to={AppendIfPrefixExists(`/services/contracts/`)}
                state={params.row}
              >
                {value}
              </NavLink>
            </SkeletonWrapper>
          );
        },
      },
      {
        id: "requestsCount",
        headerName: "Requests",
        sortable: true,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          let red = theme.palette.red;

          return (
            <SkeletonWrapper loading={loading || error}>
              <ShadedCellContent
                index={row.rankingIndex}
                value={`${row.requestsCount} (${getRequestPercentage(
                  row.requestsPercentage
                )}%)`}
                numberOfRows={totalRecords}
                rgbNumber={red}
              />
            </SkeletonWrapper>
          );
        },
      },
    ],
  };
};

export const processContractRequestRankingsGridData = (
  data: ContractRequestRankingsVisualsDto
) => {
  if (!data?.contractRequestRankings) {
    return [];
  }
  const sortedContractRequestRankings = [...data.contractRequestRankings].sort(
    (a, b) => b.requestsCount! - a.requestsCount!
  );

  return sortedContractRequestRankings.map(
    (ranking: ContractRequestRankingDto, index) => {
      return {
        contractId: ranking?.contractId,
        contractName: ranking?.contractName,
        requestsCount: ranking?.requestsCount,
        requestsPercentage: ranking?.requestsPercentage,
        rankingIndex: index,
      };
    }
  );
};

export const ContractRequestRankingsPlaceholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    contractRequestRankings: [
      {
        contractId: 1,
        contractName: faker.company.name(),
        requestsCount: faker.datatype.number(),
        requestsPercentage: faker.datatype.float(),
      },
      {
        contractId: 2,
        contractName: faker.company.name(),
        requestsCount: faker.datatype.number(),
        requestsPercentage: faker.datatype.float(),
      },
      {
        contractId: 3,
        contractName: faker.company.name(),
        requestsCount: faker.datatype.number(),
        requestsPercentage: faker.datatype.float(),
      },
      {
        contractId: 4,
        contractName: faker.company.name(),
        requestsCount: faker.datatype.number(),
        requestsPercentage: faker.datatype.float(),
      },
    ],
  },
};
