import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {
  StyledDialogCloseIcon,
  StyledDialogHeader,
  StyledDialogHeaderContainer,
  StyledDialogSubheader,
  StyledDialogTitle,
} from "@features/projects-feature/utils/components/StyledContractDetailsDialogComponents";
import { ContractDetailsDialogCloseOnlyProps } from "@features/projects-feature/projectTypes";

export const ContractDetailDialogTitle = ({
  onClose,
}: ContractDetailsDialogCloseOnlyProps) => {
  return (
    <StyledDialogTitle>
      <StyledDialogHeaderContainer>
        <StyledDialogHeader>View Contract</StyledDialogHeader>
        <StyledDialogSubheader variant="body2">Service</StyledDialogSubheader>
      </StyledDialogHeaderContainer>
      <StyledDialogCloseIcon aria-label="close" onClick={onClose} size="small">
        <CloseIcon />
      </StyledDialogCloseIcon>
    </StyledDialogTitle>
  );
};
