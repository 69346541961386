export function constructUrl(
  baseUrl: string,
  queryParams?: Record<string, string>,
  hashes?: string[]
) {
  let url = baseUrl;
  const queryString = new URLSearchParams(queryParams).toString();

  if (queryString) {
    url += `?${queryString}`;
  }

  if (hashes && hashes.length) {
    url += `#${hashes.join("&")}`;
  }

  return url;
}
