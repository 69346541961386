import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond/dist/filepond.min.css";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { ArrowRight } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import fetch from "isomorphic-fetch";

const FilepondSchemaUrl = import.meta.env.FILEPOND_SCHEMAS;

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

const filePondServer = {
  url: `${import.meta.env.API_ENDPOINT}/api/attachments`,
  timeout: 230000,
  process: {
    url: "/process",
    method: "POST",
    withCredentials: false,
    timeout: 230000,
    onload: (response) => {
      return response;
    },
    onerror: (response) => response.data,
    ondata: (formData) => {
      return formData;
    },
  },
  revert: null,
  restore: "/restore/",
  load: "/load/",
  fetch: "/fetch/",
};

export default function AttachmentsField(props) {
  const { name, onChange, schema } = props;

  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [images, setImages] = useState([]);
  const file = useRef(null);

  const [acceptedFileTypes, setAcceptedFileTypes] = useState([
    "image/*",
    "application/*",
    "message/rfc822",
  ]);
  const [expectedTypesMap, setExpectedTypesMap] = useState({
    "image/*": "Images",
    "application/*": "Documents",
    "message/rfc822": "Email Message",
  });

  const [fileTypeLimitationDescription, setFileTypeLimitationDescription] =
    useState("Images, Documents, Archives, and EML files are allowed");
  const [fileSizeValidation, setFileSizeValidation] = useState(true);
  const [maxFileSizeMB, setMaxFileSize] = useState("10MB");

  useEffect(() => {
    if (!schema) return;
    const { filepondSchema } = schema;
    if (!filepondSchema) return;
    fetch(FilepondSchemaUrl, { method: "get" })
      .then((resp) => resp.json())
      .then((data) => {
        const schema = data.filter((x) => x.SchemaName === filepondSchema);
        if (schema.length > 0) setSchema(schema[0]);
      })
      .catch(() => {
        return {};
      });
  }, [schema]);

  function setSchema(schema) {
    if (schema.acceptedFileTypes)
      setAcceptedFileTypes(schema.acceptedFileTypes);
    if (schema.expectedTypesMap) setExpectedTypesMap(schema.expectedTypesMap);
    if (schema.fileTypeLimitationDescription)
      setFileTypeLimitationDescription(schema.fileTypeLimitationDescription);
    if (schema.fileSizeValidation !== null)
      setFileSizeValidation(schema.fileSizeValidation);
    if (schema.maxFileSizeMB) setMaxFileSize(schema.maxFileSizeMB);
  }

  const onRemoveFile = (err, file) => {
    const fileId = file && file.id ? file.id : err.id;
    const filteredImages = _.filter(images, function (val) {
      return val.id !== fileId;
    });
    setImages(filteredImages);
    onChange(filteredImages.map((image) => image.serverId));
    setUploadingFiles(
      _.filter(uploadingFiles, function (val) {
        return !file || val !== fileId;
      })
    );
  };

  const onProcessFile = (error, file) => {
    if (!error) {
      const newImages = [...images, file];
      setImages(newImages);

      onChange(newImages.map((image) => image.serverId));
      setUploadingFiles(
        _.filter(uploadingFiles, function (val) {
          return val !== file.id;
        })
      );
    }
  };

  const onAddFile = (file) => {
    file.setMetadata("fieldLabel", name + ":" + file.id);
    setUploadingFiles([...uploadingFiles, file]);
  };

  const routerParams = useParams();
  const serviceRequestId = routerParams?.id;
  if (serviceRequestId) {
    return null;
  }
  return (
    <Grid item xs={12}>
      <InputLabel>Attachments</InputLabel>
      <FilePond
        allowMultiple
        ref={file}
        onaddfilestart={(file) => onAddFile(file)}
        onprocessfile={(error, file) => onProcessFile(error, file)}
        onremovefile={(err, file) => onRemoveFile(err, file)}
        onprocessfileabort={(err, file) => onRemoveFile(err, file)}
        server={filePondServer}
        acceptedFileTypes={acceptedFileTypes}
        fileValidateTypeLabelExpectedTypesMap={expectedTypesMap}
        allowFileSizeValidation={fileSizeValidation}
        maxFileSize={maxFileSizeMB}
        labelMaxFileSizeExceeded={`File must be under ${maxFileSizeMB}`}
      />
      <FormHelperText id="attachments" component="div">
        <Typography variant="caption" component="div">
          <Typography variant="body2" gutterBottom>
            If you need to attach any files, please attach them here.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Attachment limitations:
          </Typography>
          <List dense>
            {fileSizeValidation === true && (
              <ListItem>
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText primary={`${maxFileSizeMB} Maximum (per file)`} />
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText primary={fileTypeLimitationDescription} />
            </ListItem>
          </List>
        </Typography>
      </FormHelperText>
    </Grid>
  );
}
