import { TicketAdditionalContactDTO, TicketClient } from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
import { CancelToken } from "axios";
const api = new TicketClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);
export async function getRestSecondaryTicketContactsById(
  ticketNumber: number
): Promise<TicketAdditionalContactDTO[]> {
  return api.getTicketAdditionalContactsByTicket(ticketNumber);
}

export async function postRestSecondaryTicketsByContactIdAndTicketNumber(
  ticketNumber: number,
  contactIds: number[]
): Promise<TicketAdditionalContactDTO[]> {
  return api.postTicketAdditionalContacts(ticketNumber, contactIds);
}

export async function getTicketCategoryOptions(
  cancelToken?: CancelToken | undefined
) {
  return api.ticketCategoriesByAccountId(cancelToken);
}

export async function getTicketTypeOptions(
  cancelToken?: CancelToken | undefined
) {
  return api.ticketTypesByAccountId(cancelToken);
}

export async function getIssueTypeOptions(
  cancelToken?: CancelToken | undefined
) {
  return api.issueTypesByAccountId(cancelToken);
}
