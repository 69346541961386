import React from "react";
import {
  DataGridProProps,
  GridCallbackDetails,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { useCXUserPrefs } from "./useCXUserPrefs";
import { ShiGridColDefs } from "shared-ui/src/interfaces/ShiDataGridInterface";
import SharedMuiDataGrid from "shared-ui/src/components/mui-data-grid/MuiDataGrid";

//This component props is extending the DataGridProProps,
interface ShiDataGridProps extends Partial<DataGridProProps> {
  loading?: boolean;
  gridDefinitions: ShiGridColDefs;
  gridData: any[];
  totalRowCount?: number;
  hideSelectAllCheckBox?: boolean;
  onPageChangeHandler?: (e: { page: number; pageSize: number }) => void;
  onSortChangeHandler?: (sortModel: any) => void;
  getRowId?: (rowData: any) => string;
  adjustRowHeight?: boolean;
  handleRowSelection?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails<any>
  ) => void;
  rowExpandLookup?: any;
  apiRef?: any;
  saveUserConfig?: boolean;
  gridId?: string;
  customToolbar?: () => React.ReactElement;
  containerStyle?: any;
  customTheme?: any;
  rowDensityMenu?: boolean;
  exportMenu?: boolean;
  viewSelect?: () => React.ReactElement;
  hideNoTableDataSuggestions?: boolean;
  hideRowHoverBackground?: boolean;
  customNoDataMessage?: string;
}

const MuiDataGrid: React.FC<ShiDataGridProps> = (props) => {
  const { gridDefinitions, gridData, totalRowCount, ...otherProps } = props;

  return (
    <SharedMuiDataGrid
      gridColsDef={gridDefinitions}
      gridRows={gridData}
      userPrefHook={useCXUserPrefs}
      rowCount={totalRowCount}
      {...otherProps}
    />
  );
};

export default MuiDataGrid;
