import {
  addUserToTeam,
  getRestAccountTeams,
  getRestMyTeams,
  getRestTeamPermissions,
  postRestTeamPermissions,
  removeUserFromTeam,
} from "api/teamStore";
import { PermissionSet, TeamState } from "modules/rtk/team/teamTypes";
import { AccountTeamReponse, AddContacts, Team } from "lib/ShiOneClient";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store";
import GlobalConstants from "@constants";

import _ from "lodash";

const initialState: TeamState = {
  teams: [],
  teamsFetchStatus: GlobalConstants.fetchStatus.idle,
  addUserToTeamStatus: GlobalConstants.fetchStatus.idle,
  removeUserFromTeamStatus: GlobalConstants.fetchStatus.idle,
  permissionRequestStatus: [],
  permissionSets: [],
  updatingTeamStatus: "",
  myTeams: [],
  myTeamsFetchStatus: GlobalConstants.fetchStatus.idle,
  teamsEnabled: false,
  globalAdmin: false,
  ticketCategoryMappings: [],
  contractSLAs: [],
};

const teamSlice = createSlice({
  name: "teamRtk",
  initialState,
  reducers: {
    settingTeamPermissions(state, action: PayloadAction<string>) {
      state.updatingTeamStatus = action.payload;
    },
    receivingTeamPermissions(state, action: PayloadAction<number>) {
      const existingRequestIndex = state.permissionRequestStatus.findIndex(
        (e) => e.teamId === action.payload
      );
      if (existingRequestIndex > -1) {
        state.permissionRequestStatus[existingRequestIndex].status = "loading";
      } else {
        state.permissionRequestStatus.push({
          status: "loading",
          teamId: action.payload,
        });
      }
    },
    receiveTeamPermissions(state, action: PayloadAction<PermissionSet>) {
      const existingRequestIndex = state.permissionRequestStatus.findIndex(
        (e) => e.teamId === action.payload.teamId
      );
      state.permissionRequestStatus[existingRequestIndex].status = "complete";
      const existingSetIndex = state.permissionSets.findIndex(
        (e) => e.teamId === action.payload.teamId
      );
      if (existingSetIndex > -1) {
        state.permissionSets[existingSetIndex] = action.payload;
      } else {
        state.permissionSets.push(action.payload);
      }
    },
    receivingAccountTeams(state) {
      state.teamsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveAccountTeams(state, action: PayloadAction<Team[]>) {
      state.teamsFetchStatus = GlobalConstants.fetchStatus.complete;
      state.teams = action.payload;
    },
    receiveAccountTeamsError(state) {
      state.teamsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    addingUserToTeam(state) {
      state.addUserToTeamStatus = GlobalConstants.fetchStatus.loading;
    },
    addedUserToTeam(state, action: PayloadAction<Team>) {
      state.addUserToTeamStatus = GlobalConstants.fetchStatus.complete;
      const newTeamsContacts = _.map(state.teams, (team) => {
        if (team.id === action.payload.id) {
          return action.payload;
        }
        return team;
      });
      state.teams = newTeamsContacts;
    },
    addUserToTeamError(state) {
      state.addUserToTeamStatus = GlobalConstants.fetchStatus.error;
    },
    removingUserFromTeam(state) {
      state.removeUserFromTeamStatus = GlobalConstants.fetchStatus.loading;
    },
    removedUserFromTeam(state, action: PayloadAction<Team>) {
      state.removeUserFromTeamStatus = GlobalConstants.fetchStatus.complete;
      const newTeamsRemoveContacts = _.map(state.teams, (team) => {
        if (team.id === action.payload.id) {
          return action.payload;
        }
        return team;
      });
      state.teams = newTeamsRemoveContacts;
    },
    removeUserFromTeamError(state) {
      state.removeUserFromTeamStatus = GlobalConstants.fetchStatus.error;
    },
    requestMyTeams(state) {
      state.myTeamsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedMyTeams(state, action: PayloadAction<AccountTeamReponse>) {
      state.myTeams = action.payload.teams ?? [];
      state.ticketCategoryMappings =
        action.payload.ticketCategoryMappings ?? [];
      state.contractSLAs = action.payload.contractSLAs ?? [];
      state.teamsEnabled = action.payload.teamsEnabled ?? false;
      state.globalAdmin = action.payload.isGlobalAdmin ?? false;
      state.myTeamsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveMyTeamsError(state) {
      state.myTeamsFetchStatus = GlobalConstants.fetchStatus.error;
    },
  },
});

export const getTeamPermissions =
  (teamId: number, accountId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(teamSlice.actions.receivingTeamPermissions(teamId));
    const teamPermissions = await getRestTeamPermissions(teamId, accountId);
    dispatch(teamSlice.actions.receiveTeamPermissions(teamPermissions));
  };

export const setTeamPermissions =
  (accountId: number, permissionSet: PermissionSet): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(teamSlice.actions.settingTeamPermissions("saving"));
    const teamPermissions = await postRestTeamPermissions(
      accountId,
      permissionSet
    );
    dispatch(teamSlice.actions.receiveTeamPermissions(teamPermissions));
    dispatch(teamSlice.actions.settingTeamPermissions(""));
  };

export const getAccountTeams =
  (accountId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(teamSlice.actions.receivingAccountTeams());
    try {
      const accountTeams = await getRestAccountTeams(accountId);

      dispatch(teamSlice.actions.receiveAccountTeams(accountTeams));
    } catch {
      dispatch(teamSlice.actions.receiveAccountTeamsError());
    }
  };

export const addContactsToTeams =
  (request: AddContacts): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(teamSlice.actions.addingUserToTeam());
    try {
      const team = await addUserToTeam(request);

      dispatch(teamSlice.actions.addedUserToTeam(team));
    } catch {
      dispatch(teamSlice.actions.addUserToTeamError());
    }
  };

export const removeContactsFromTeams =
  (request: AddContacts): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(teamSlice.actions.removingUserFromTeam());
    try {
      const team = await removeUserFromTeam(request);

      dispatch(teamSlice.actions.removedUserFromTeam(team));
    } catch {
      dispatch(teamSlice.actions.removeUserFromTeamError());
    }
  };

export const getMyTeams = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(teamSlice.actions.requestMyTeams());
  try {
    const myTeams = await getRestMyTeams();

    dispatch(teamSlice.actions.receivedMyTeams(myTeams));
  } catch {
    dispatch(teamSlice.actions.receiveMyTeamsError());
  }
};

export default teamSlice.reducer;
