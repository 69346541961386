import { NavItemType } from "./NavigationTypes";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavItem from "./NavItem";
import React, { useEffect, useState } from "react";
import { NavGroupStyles } from "../styles/NavigationStyles";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { NavLink, useLocation } from "react-router-dom";

interface NavDashboardProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}
export default function NavDashboard({
  navItem,
  tier,
  parentContext,
}: NavDashboardProps) {
  const theme = useTheme();
  const styles = NavGroupStyles(theme);
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (navItem.path) {
      if (
        navItem.path.split("/")[1] === "assets-dashboard" &&
        handleCheckPathName("/assets")
      ) {
        setOpen(true);
      } else setOpen(handleCheckPathName(navItem.path));
    }
  }, [location.pathname, navItem.path]);

  const handleCheckPathName = (path: string) => {
    return (
      location.pathname.startsWith(path) ||
      location.pathname.startsWith(AppendIfPrefixExists(path))
    );
  };

  const handleDropDownChange = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setOpen(!open);
  };
  const hasVisibleChildren =
    navItem.children && navItem.children.some((child) => child?.visible);

  let telemetryContext = `${parentContext} > ${navItem.displayName}`;
  return (
    <>
      <ListItemButton
        component={NavLink}
        to={AppendIfPrefixExists(navItem.path)}
        data-telemetry-name={telemetryContext}
        onClick={() => setOpen(!open)}
        sx={
          !hasVisibleChildren
            ? {
                "&.active": {
                  bgcolor: "action.selected",
                },
              }
            : undefined
        }
      >
        {navItem.icon && (
          <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={navItem.displayName}
          sx={[
            tier === 1 ? styles.listItemText : {},
            tier === 2 ? styles.tier2 : {},
          ]}
          title={navItem.displayName}
        />
        {hasVisibleChildren ? (
          open ? (
            <ExpandLess
              color={"action"}
              onClick={(e) => handleDropDownChange(e)}
            />
          ) : (
            <ExpandMore
              color={"action"}
              onClick={(e) => handleDropDownChange(e)}
            />
          )
        ) : (
          <></>
        )}
      </ListItemButton>
      {navItem.children && navItem.children.length > 0 && (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {navItem.children.map((child, i) => {
                if (child) {
                  return (
                    <NavItem
                      key={i}
                      navItem={child}
                      tier={tier + 1}
                      parentContext={telemetryContext}
                    />
                  );
                }
              })}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
}
