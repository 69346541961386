import { homeItemsToShowType } from "../../NavItemsToShowTypes";

export default function CloudConsumptionMenu(itemsToShow: homeItemsToShowType) {
  return {
    id: "cloudConsumption",
    type: "Link",
    displayName: "Consumption",
    path: "/cloud/consumption",
    visible: itemsToShow.showCloudConsumption,
  };
}
