import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

export const useDebouncedSearch = (
  searchTerm: string,
  debounceTime: number = 1000
) => {
  const [debouncedValue, setDebouncedValue] = useState(searchTerm);

  const debouncedCallback = useMemo(() => {
    return debounce(async (searchTerm) => {
      setDebouncedValue(searchTerm);
    }, debounceTime);
  }, [setDebouncedValue]);

  useEffect(() => {
    debouncedCallback(searchTerm);
  }, [setDebouncedValue, searchTerm]);

  return debouncedValue;
};
