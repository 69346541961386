import { Chip } from "@mui/material";
import React from "react";
import { DateTime } from "luxon";
import GlobalConstants from "@constants";

export default function ToolbarFilterChips({
  handleFilters,
  filters,
  setActiveFiltersCount,
  activeFiltersCount,
}: {
  handleFilters: (value: any) => void;
  filters: any[];
  setActiveFiltersCount: (value: number) => void;
  activeFiltersCount: number;
}) {
  const handleDelete = (filter: any) => {
    // @ts-ignore
    const newArr = [...filters];
    const index = newArr.findIndex((n) => n.accessor === filter.accessor);

    newArr[index] = {
      ...newArr[index],
      ...(filter.datePicker &&
        !filter.datePickerGroupName && {
          endDate: null,
          startDate: null,
        }),
      ...(filter.datePickerGroupName && {
        value: null,
      }),
      ...(!filter.datePicker && {
        value: filter.multiselect ? [] : "",
      }),
    };
    handleFilters(newArr);
    setActiveFiltersCount(activeFiltersCount - 1);
  };

  const handleTimeFilterDisplay = (
    label: string,
    startDate: string | null,
    endDate: string | null
  ) => {
    if (startDate) {
      return `${label}: After ${startDate}`;
    } else return `${label}: Before ${endDate}`;
  };

  return (
    <>
      {filters?.map((filter, k) => {
        const key = filter.label + k;
        if (filter.datePicker && (filter.startDate || filter.endDate)) {
          const startDate = filter.startDate
            ? DateTime.fromJSDate(filter.startDate).toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
          const endDate = filter.endDate
            ? DateTime.fromJSDate(filter.endDate).toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
          const chipText =
            startDate && endDate
              ? `${filter.label}: ${startDate} - ${endDate}`
              : handleTimeFilterDisplay(filter.label, startDate, endDate);

          return (
            <Chip
              label={chipText}
              size="small"
              onDelete={() => handleDelete(filter)}
              key={key}
            />
          );
        }

        if (filter.datePicker && filter.datePickerGroupName && filter.value) {
          const jsDate = new Date(filter.value);
          const filterDate = jsDate
            ? DateTime.fromJSDate(jsDate).toFormat(
                GlobalConstants.MuiGridFormats.dateFormat
              )
            : null;
          const chipText = `${filter.label}:  ${
            filter.isStart ? " After" : "Before"
          }  ${filterDate} `;
          return (
            <Chip
              label={chipText}
              size="small"
              onDelete={() => handleDelete(filter)}
              key={key}
            />
          );
        }

        if (!filter.datePicker && filter.value?.length) {
          return (
            <Chip
              label={filter.label + ": " + filter.value}
              size="small"
              onDelete={() => handleDelete(filter)}
              key={key}
              data-telemetry-name={`remove filter ${filter.label}: ${filter.value}`}
            />
          );
        }
      })}
    </>
  );
}
