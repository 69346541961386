import {
  AccountAdminClient,
  AccountNotifications,
  AccountResponse,
  AdminDashboardReports,
  ApprovalResponse,
  BulkPermissionsUpdateDTO,
  Contact,
  ProfileApprovals,
  UpdateUserObject,
  UserProfile,
  UserProfileManager,
  UserProfilesRequest,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new AccountAdminClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestAccounts(): Promise<AccountResponse> {
  return api.getAccounts();
}

export async function getRestAccountNotifications(): Promise<AccountNotifications> {
  return api.getAccountNotifications();
}

export async function getRestAccountReports(): Promise<AdminDashboardReports> {
  return api.getAccountReports();
}

export async function postRestRejectUser(id: number): Promise<void> {
  return api.rejectUser(id);
}

export async function createUser(request: Contact): Promise<ApprovalResponse> {
  return api.createUser(request);
}

export async function updateUser(
  request: UpdateUserObject
): Promise<ApprovalResponse> {
  return api.updateUser(request);
}
export async function activateUser(
  request: ProfileApprovals
): Promise<ApprovalResponse> {
  return api.approveUsers(request);
}

export async function getMappedUserProfile(): Promise<UserProfile[]> {
  return api.getMappedUserProfiles();
}

export async function postRestPagedUserProfiles(
  request: UserProfilesRequest
): Promise<UserProfileManager> {
  return api.getPagedUserProfiles(request);
}

export async function postRestUpdateUsersPermissions(
  updatedPermissions: BulkPermissionsUpdateDTO
) {
  return api.updatePermissionsOfMultipleUsers(updatedPermissions);
}
