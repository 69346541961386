import axios from "axios";
import { AlertTicket, MonitoringAlert } from "../modules/rtk/alert/alertTypes";
import { GetAxiosHeaders } from "../auth/GetAxiosHeaders";

const baseUrl = `${import.meta.env.API_ENDPOINT}/api/alert`;

export async function getRestMonitoringAlerts(): Promise<MonitoringAlert[]> {
  const axiosHeaders = await GetAxiosHeaders();

  try {
    const response = await axios.get<MonitoringAlert[]>(baseUrl, {
      headers: axiosHeaders,
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getRestAlertTicket(
  ticketId: number
): Promise<AlertTicket> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<AlertTicket>(baseUrl + `/${ticketId}`, {
    headers: axiosHeaders,
  });

  return response.data;
}
