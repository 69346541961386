import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@mui/material";

const LogoutButton = ({ text = "log out" }: { text?: string }) => {
  const { logout } = useAuth0();

  return (
    <Link
      sx={{ verticalAlign: "baseline" }}
      component={"button"}
      onClick={() =>
        logout({
          logoutParams: { returnTo: window.config.REACT_APP_AUTH0_LOGOUT_URL },
        })
      }
    >
      {text}
    </Link>
  );
};

export default LogoutButton;
