import { useApi } from "../../utils";
import { RequestConsultationClient } from "@lib/ShiOneClient";
import React, { useState } from "react";
import SnackbarMessage from "shared-ui/src/theme/extensions/SnackbarMessage";
import { AlertColor, Box, Button, Container, Snackbar } from "@mui/material";
import { Add } from "@mui/icons-material";

export function RequestConsultationCardActions({
  applicationContext,
}: {
  applicationContext: string;
}) {
  const api = useApi(RequestConsultationClient);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState<
    AlertColor | undefined
  >(undefined);

  function requestConsultation() {
    api
      .createRequestConsultation(applicationContext)
      .then(() => {
        setSnackbarMessage("Consultation Requested");
        setSnackbarVariant("success");
        setShowSnackbar(true);
      })
      .catch(() => {
        setSnackbarMessage("Failed to request consultation");
        setSnackbarVariant("error");
        setShowSnackbar(true);
      });
  }

  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={() => requestConsultation()}>
          <Add sx={{ fontSize: "16px", marginRight: "8px" }} />
          Request Consultation
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <SnackbarMessage variant={snackbarVariant} message={snackbarMessage} />
      </Snackbar>
    </Container>
  );
}
