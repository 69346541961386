import { Button } from "@mui/material";
import React from "react";
import {
  asDto,
  BaseWidgetProps,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
} from "shared-ui";
import {
  AssetsWarehouseStockWidgetClient,
  WidgetContentResponseDto_1OfSampleWidgetBResponseDto,
} from "@lib/ShiOneClient";
import {
  AddCircleOutlineOutlined,
  CheckCircleOutlineOutlined,
  ErrorOutline,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { useApi } from "../../utils";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

function CardActions() {
  return (
    <Button href={AppendIfPrefixExists("/assets/warehouse/stock")}>
      View Warehouse Stock
    </Button>
  );
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    incomingAssets: 0,
    transferringAssets: 0,
    assetsAvailableToTransfer: 0,
    productsAtLevelRisk: 0,
  },
};

function transformFunction(
  rawData: WidgetContentResponseDto_1OfSampleWidgetBResponseDto | undefined
): ItemBoxConfiguration[] {
  return [
    {
      amount: rawData?.data?.incomingAssets,
      label: "Incoming Assets",
      icon: <AddCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.transferringAssets,
      label: "Outgoing Assets",
      icon: <RemoveCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.assetsAvailableToTransfer,
      label: "Assets Available to Transfer",
      icon: <CheckCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.productsAtLevelRisk,
      label: "Products at Inventory Level Risk",
      icon: <ErrorOutline fontSize={"large"} color={"warning"} />,
    },
  ];
}

const useGetAssetsWarehouseWidgetData = () => {
  const api = useApi(AssetsWarehouseStockWidgetClient);

  return useKeyInformationData({
    queryKey: ["assets-warehouse-stock-widget"],
    apiFunction: () => api.getAssetsWarehouseStockWidgetData(),
    transformFunction: transformFunction,
    placeholderData: asDto(placeholderData),
  });
};

const AssetsWarehouseStockWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetAssetsWarehouseWidgetData}
      cardActions={<CardActions />}
    />
  );
};

export default AssetsWarehouseStockWidget;
