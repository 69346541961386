import React from "react";
import { Chip, ChipProps, lighten, Tooltip, useTheme } from "@mui/material";
interface ColoredChipsProps extends ChipProps {
  bgColor?: string;
  textColor?: string;
  minWidth?: number;
  display?: string;
  height?: string;
  m?: number;
  tooltipText?: string;
}

const ColoredChips = (props: ColoredChipsProps) => {
  const {
    bgColor,
    textColor,
    size = "small",
    minWidth,
    display,
    height,
    m,
    tooltipText,
    ...defaultProps
  } = props;
  const theme = useTheme();
  const defaultChipBackground = lighten(theme.palette.action.disabled, 0.7);

  const handleChipTextColor = () => {
    if (textColor) return textColor;
    else if (bgColor && bgColor !== defaultChipBackground) return "white";
    else return theme.palette.text.primary;
  };
  const chipStyle = {
    color: handleChipTextColor(),
    "& .MuiChip-icon": {
      fontSize: "1rem",
      color: handleChipTextColor(),
    },
    background: bgColor ?? defaultChipBackground,
    minWidth: minWidth ?? null,
    display: display ?? null,
    height: height ?? null,
    m: m ?? null,
  };
  return (
    <Tooltip title={tooltipText ?? ""}>
      <Chip {...defaultProps} sx={chipStyle} size={size} />
    </Tooltip>
  );
};
export default ColoredChips;
