import { DateFilterOption } from "@lib/ShiOneClient";
import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { SkeletonWrapper } from "shared-ui";

const getDisplayTitleByDateFilterOptionKey = (
  key: DateFilterOption
): string => {
  switch (key) {
    case DateFilterOption.CurrentMonth:
      return "This Month";
    case DateFilterOption.LastMonth:
      return "Last Month";
    case DateFilterOption.CurrentQuarter:
      return "This Quarter";
    case DateFilterOption.LastQuarter:
      return "Last Quarter";
    case DateFilterOption.YearToDate:
      return "Year To Date";
    case DateFilterOption.LastYear:
      return "Last Year";
    default:
      return "";
  }
};

export const allDateFilterOptions = [
  DateFilterOption.CurrentMonth,
  DateFilterOption.LastMonth,
  DateFilterOption.CurrentQuarter,
  DateFilterOption.LastQuarter,
  DateFilterOption.YearToDate,
  DateFilterOption.LastYear,
];

export function DateFilterOptionHeaderAction({
  filterBy,
  setFilterBy,
  loading,
  filterKey,
  dateFilterOptions = allDateFilterOptions,
}: {
  filterBy: DateFilterOption;
  setFilterBy: (dateFilterOption: DateFilterOption) => void;
  loading: boolean;
  filterKey: string;
  dateFilterOptions?: DateFilterOption[];
}) {
  return (
    <SkeletonWrapper loading={loading}>
      <FormControl sx={{ m: 0.5, minWidth: 180 }}>
        <Select
          sx={{ height: "40px" }}
          labelId={filterKey}
          id={filterKey}
          value={filterBy}
          onChange={(e) => {
            setFilterBy(e.target.value as DateFilterOption);
          }}
          displayEmpty
        >
          {dateFilterOptions.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {getDisplayTitleByDateFilterOptionKey(value)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </SkeletonWrapper>
  );
}
