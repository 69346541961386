import { Theme } from "@mui/material";

const MuiInputBase = (theme: Theme, rootElement: HTMLElement) => ({
  defaultProps: {
    container: rootElement,
  },
  styleOverrides: {
    root: {
      "&:before": {
        borderBottomColor: theme.palette.text.primary,
      },
      "&&:after": {
        borderBottomColor: theme.palette.text.primary,
      },
    },
  },
});
export default MuiInputBase;
