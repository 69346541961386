import React from "react";
import Grid from "@mui/material/Grid";
import { FormControl, TextField, Theme, useTheme } from "@mui/material";
import { FieldProps } from "@rjsf/utils";
import { CountryChooser } from "../../components/CountryChooser";
import countries from "../../components/ComponentData/AllCountryCodes.json";

const css = (theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
});

function FieldWrapper({
  children,
  width = 12,
}: {
  children: React.ReactNode;
  width?: number;
}) {
  return (
    <Grid item xs={width}>
      <FormControl fullWidth sx={{ mt: 2, mb: 1 }} variant="filled">
        {children}
      </FormControl>
    </Grid>
  );
}

export const CityFieldName = "City";
export const StateFieldName = "Province or Territory or State";
export const PostalCodeFieldName = "Postal Code";
export const StreetAddressFieldName = "Street Address";
export const CountryFieldName = "Country";

export function AddressField(props: FieldProps) {
  const theme = useTheme();
  const classes = css(theme);
  let country:
    | {
        Note: string;
        Country: string;
        ISO: string;
        Format: string;
        Regex: string;
      }
    | null
    | undefined = null;

  // Get the postal code reg ex from the selected country
  if (props.formData?.Country) {
    country = countries.find(
      (country) => country.Country === props.formData?.Country
    );
  }

  const postalCodeRegEx = country?.Regex;

  function makeIdOrName(field: string) {
    return `${props.name}_${field.replace(/\s/g, "")}`;
  }

  function updateAddress(value: string, field: string) {
    props.onChange({
      ...props.formData,
      [field]: value,
    });
  }

  function makePostalCodePrompt() {
    if (country) {
      return `Please enter a postal code in this format ${country?.Format} (A=letter, N=number)`;
    } else {
      return "Please select a country first";
    }
  }

  function onInvalidPostalCode(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.setCustomValidity(makePostalCodePrompt());
  }

  function resetInvalidPostalCode(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.setCustomValidity("");
  }

  return (
    <Grid container spacing={3}>
      <FieldWrapper>
        <CountryChooser
          label={CountryFieldName}
          disabled={props.disabled}
          schema={props.schema}
          idSchema={props.idSchema}
          value={props.formData?.Country}
          name={makeIdOrName(CountryFieldName)}
          onChange={function (newFormData: any) {
            updateAddress(newFormData, CountryFieldName);
          }}
          onBlur={function (): void {}}
          onFocus={function (): void {}}
          readonly={false}
          required={props.required}
          registry={props.registry}
        />
      </FieldWrapper>
      <FieldWrapper>
        <TextField
          name={makeIdOrName(StreetAddressFieldName)}
          sx={classes.root}
          label="Address"
          type="string"
          disabled={props.disabled}
          required={props.required}
          value={props.formData?.[StreetAddressFieldName]}
          onChange={(e) =>
            updateAddress(e.target.value, StreetAddressFieldName)
          }
        />
      </FieldWrapper>
      <FieldWrapper>
        <TextField
          sx={classes.root}
          name={makeIdOrName(CityFieldName)}
          label="City"
          value={props.formData?.City}
          type="string"
          disabled={props.disabled}
          required={props.required}
          onChange={(e) => updateAddress(e.target.value, CityFieldName)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <TextField
          sx={classes.root}
          name={makeIdOrName(StateFieldName)}
          label="State, Province or Territory"
          type="string"
          disabled={props.disabled}
          required={props.required}
          value={props.formData?.[StateFieldName]}
          onChange={(e) => updateAddress(e.target.value, StateFieldName)}
        />
      </FieldWrapper>
      <FieldWrapper width={4}>
        <TextField
          sx={classes.root}
          name={makeIdOrName(PostalCodeFieldName)}
          value={props.formData?.[PostalCodeFieldName]}
          label="Zip or Postal Code"
          type="string"
          placeholder={"Enter a valid postal code"}
          inputProps={{ pattern: postalCodeRegEx }}
          disabled={props.disabled}
          required={props.required}
          onInvalid={onInvalidPostalCode}
          onInput={resetInvalidPostalCode}
          onChange={(e) => updateAddress(e.target.value, PostalCodeFieldName)}
        />
      </FieldWrapper>
    </Grid>
  );
}
