import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { FeatureFlagClient } from "../../../customer-experience/src/lib/ShiOneClient";
import { ApiClientConfig } from "../../../customer-experience/src/auth/ApiClientConfig";
import { CancelToken } from "axios";

type WindowWithConfig = typeof window & {
  config: {
    API_ENDPOINT: string;
  };
};

const api = new FeatureFlagClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestFeatureFlagAccess(
  featureFlag: string,
  cancelToken?: CancelToken | undefined
): Promise<boolean> {
  return api.validateFeatureIsEnabled(featureFlag, cancelToken);
}

async function isFeatureFlagEnabled(flagKey: string) {
  return await getRestFeatureFlagAccess(flagKey);
}

function useFeatureFlag(flagKey: string, defaultValue?: boolean) {
  if (!flagKey.toString().trim().length) {
    throw new Error("A feature flag key must be supplied.");
  }

  // We can't call the feature flag endpoint until we're authenticated, so disable the useQuery call until we are.
  const { isAuthenticated } = useAuth0();

  const result = useQuery<boolean, Error>(
    ["feature-flags", flagKey],
    () => getRestFeatureFlagAccess(flagKey).then((enabled: boolean) => enabled),
    {
      staleTime: 30 * 1000,
      placeholderData: defaultValue,
      enabled: isAuthenticated,
    } // 10 seconds
  );

  // The original version of this hook returned an enabled and loading value. Follow that same
  // pattern here so those using this hook don't have to change their code. But also return the
  // values expected from react-query if users want to use it that way.
  return {
    enabled: result.data,
    loading: result.isLoading,
    ...result,
  };
}

interface FeatureFlagProps {
  FeatureFlagKey: string;
}
function FeatureFlag(props: React.PropsWithChildren<FeatureFlagProps>) {
  const { enabled: featureFlag } = useFeatureFlag(props.FeatureFlagKey);
  return <>{featureFlag && props.children}</>;
}

export { isFeatureFlagEnabled, useFeatureFlag, FeatureFlag };
