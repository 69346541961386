import React, { useEffect, useState } from "react";
import CircleSharpIcon from "@mui/icons-material/CircleSharp";
import { Box, Grid, Skeleton, Tooltip, useTheme } from "@mui/material";
import { Summary } from "../interfaces/WidgetInterfaces";
import SingleDataWithLabels from "./SingleDataWithLabels";
import { SkeletonWrapper } from "shared-ui";

const PercentageLineStyles = (theme: any) => ({
  lineVisualFull: {
    display: "flex",
    height: "10px",
    margin: "15px 0",
  },
  lineVisualPart: {
    borderRadius: "3px",
    transition: "width 2s",
    marginRight: "2px",
  },
  lineLabels: {
    paddingTop: "10px",
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
});
const PercentageLine = ({
  visualParts,
  leftLabel,
  loading,
  additionalStyles,
  showLabels = true,
  showSummaryValue = true,
  customValue,
  containerStyle,
  formatter = new Intl.NumberFormat("en-US").format,
}: {
  visualParts: Summary[];
  leftLabel?: string;
  loading?: boolean;
  additionalStyles?: any;
  showLabels?: boolean;
  showSummaryValue?: boolean;
  customValue?: string;
  containerStyle?: any;
  formatter?: (value: number) => string;
}) => {
  const theme = useTheme();
  const styles = PercentageLineStyles(theme);
  const greyColor = theme.palette.grey["300"];

  const [data, setData] = useState<number[]>([]);
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return "0";
    })
  );

  const handlePercentage = (value: number, totalValue: number) => {
    return Math.round((value / totalValue) * 100);
  };

  useEffect(() => {
    setData(
      visualParts.map((item) => {
        return item.data as number;
      })
    );
  }, [visualParts]);

  const totalValue = data.reduce((a, b) => {
    return a + b;
  }, 0);

  useEffect(() => {
    let timerId: number;
    const animation = () => {
      setWidths(data.map((value) => `${handlePercentage(value, totalValue)}%`));
    };
    timerId = requestAnimationFrame(animation);
    return () => {
      cancelAnimationFrame(timerId);
    };
  }, [data, totalValue, setWidths]);

  const formattedNumber = (data: number | string) =>
    typeof data === "number" ? formatter(data) : data;

  const getCharts = (visualParts: Summary[]) => {
    if (loading) {
      return <Skeleton variant={"rounded"} width={"100%"} height={"10px"} />;
    } else if (totalValue === 0) {
      return (
        <Box
          sx={[
            {
              width: "100%",
              backgroundColor: greyColor,
            },
            styles.lineVisualPart,
          ]}
        />
      );
    }
    return visualParts.map((item, index) => {
      if (item.data !== 0) {
        const key = item.label + index;
        return (
          <Tooltip
            key={key}
            title={`${item.label}: ${formattedNumber(item.data)}`}
            followCursor
            placement="top"
            arrow
          >
            <Box
              key={item.label + item.data}
              sx={[
                {
                  width: widths[index] === "0%" ? "0.8%" : widths[index],
                  backgroundColor: item.color,
                },
                styles.lineVisualPart,
              ]}
            />
          </Tooltip>
        );
      }
    });
  };

  const getLineLabels = (visualParts: Summary[]) => {
    return visualParts.map((item, index) => {
      return (
        <Grid container sx={{ width: "100%" }} key={item.label + item.data}>
          <Grid item xs={7} sx={styles.lineLabels}>
            <SkeletonWrapper loading={loading}>
              <CircleSharpIcon sx={{ color: item.color, fontSize: "10px" }} />{" "}
              <span>{item.customLabel ?? item.label}</span>
            </SkeletonWrapper>
          </Grid>
          <Grid
            item
            container
            xs={5}
            justifyContent="flex-end"
            sx={styles.lineLabels}
          >
            <SkeletonWrapper loading={loading}>
              <span>
                {formattedNumber(item.data)}
                {` (${widths[index]})`}
              </span>
            </SkeletonWrapper>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <div style={containerStyle}>
      {totalValue === 0 && !loading ? (
        <>
          <SingleDataWithLabels
            label={leftLabel ?? visualParts[0].name}
            data={customValue ?? totalValue}
            loading={loading}
          />
          <div style={styles.lineVisualFull}>{getCharts(visualParts)}</div>
        </>
      ) : (
        <>
          <SingleDataWithLabels
            data={customValue ?? formatter(totalValue)}
            label={leftLabel ?? visualParts[0].name}
            loading={loading}
            showValue={showSummaryValue}
          />
          <div style={styles.lineVisualFull}>{getCharts(visualParts)}</div>
          <div
            style={{
              width: "100%",
              maxHeight: "170px",
              overflowY: "auto",
              ...additionalStyles,
            }}
          >
            {showLabels && totalValue ? getLineLabels(visualParts) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default PercentageLine;
