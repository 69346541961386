import {
  GET_ALL_DOCUMENTS,
  GET_ALL_DOCUMENTS_ERROR,
  GET_ALL_DOCUMENTS_SUCCESS,
} from "../actions/knowledgebaseActions";

export function knowledgebaseReducer(
    state = {
      contentUnits: [],
      fetchingKnowledgebase: false,
      fetchKnowledgebaseComplete: false,
      fetchKnowledgebaseError: undefined
    },
    action
) {
  switch (action.type) {
    case GET_ALL_DOCUMENTS:
      return Object.assign({}, state, {
        fetchingKnowledgebase: true,
        fetchKnowledgebaseComplete: false,
        fetchKnowledgebaseError: undefined,
      });
    case GET_ALL_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        fetchingKnowledgebase: false,
        fetchKnowledgebaseComplete: true,
        fetchKnowledgebaseError: undefined,
        contentUnits: action.payload.data,
        products: action.payload.products,
      });
    case GET_ALL_DOCUMENTS_ERROR:
      return Object.assign({}, state, {
        fetchingKnowledgebase: false,
        fetchKnowledgebaseComplete: true,
        fetchKnowledgebaseError: action.payload.data,
      });
    default:
      return state;
  }
}