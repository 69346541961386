export function getCurrentDateInformation() {
  const today = new Date();
  const days = today.getDate();
  const currentMonth = today.toLocaleString("en-GB", { month: "short" });
  const daysInMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  ).getDate();
  return { days, currentMonth, daysInMonth };
}
