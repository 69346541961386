import { Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const NoProjectTasksFound = () => {
  const theme = useTheme();

  return (
    <Typography
      component="div"
      sx={{
        padding: theme.spacing(4),
        opacity: 0.5,
        width: "100%",
        display: "flex",
        minHeight: theme.spacing(40),
      }}
    >
      <Box>
        <Typography component="div" variant="h4" gutterBottom>
          Sorry no tasks were found for this project
        </Typography>
        <Typography component="div" variant="caption">
          <Typography component="div" gutterBottom>
            Suggestions:
          </Typography>
          <Typography component="ul">
            <li>Wait 24 hours for project to update</li>
            <li>
              If the project continues to remain empty please contact your
              project lead
            </li>
          </Typography>
        </Typography>
      </Box>
    </Typography>
  );
};

export default NoProjectTasksFound;
