import { useApi } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import {
  ActiveUserTrendsWidgetResponse,
  MicrosoftAdoptionWidgetsClient,
} from "@lib/ShiOneClient";
import ms from "ms";

export function useActiveUserTrendsWidget(frequency: string) {
  const api = useApi(MicrosoftAdoptionWidgetsClient);

  return useQuery<ActiveUserTrendsWidgetResponse, Error>({
    queryKey: ["active-user-trends-widget"],
    queryFn: () => api.getActiveUserTrendsWidget(frequency),
    staleTime: ms("10m"),
  });
}
