import React from "react";
import {
  asDto,
  BaseWidgetProps,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
} from "shared-ui";
import { Button } from "@mui/material";
import { useApi } from "../../utils";
import {
  AssetsWarehouseOrdersWidgetClient,
  WidgetContentResponseDto_1OfGetWarehouseAggregatedOrdersResponse,
} from "@lib/ShiOneClient";
import { DeviceUnknown, Inventory2, Storage } from "@mui/icons-material";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

function CardActions() {
  return (
    <Button href={AppendIfPrefixExists("/assets/warehouse/orders")}>
      View Warehouse Orders
    </Button>
  );
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    openTransfersOrders: 0,
    openIntegrationOrders: 0,
    openRackAndStackOrders: 0,
    rmaRequests: 0,
  },
};
function transformFunction(
  rawData:
    | WidgetContentResponseDto_1OfGetWarehouseAggregatedOrdersResponse
    | undefined
): ItemBoxConfiguration[] {
  return [
    {
      amount: rawData?.data?.openTransfersOrders,
      label: "Open Transfer Orders",
      icon: <Inventory2 fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.openIntegrationOrders,
      label: "Open Integration Center Orders",
      icon: <Inventory2 fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.openRackAndStackOrders,
      label: "Open Rack & Stack Orders",
      icon: <Storage fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.rmaOrders,
      label: "RMA Requests",
      icon: <DeviceUnknown fontSize={"large"} />,
    },
  ];
}
const useGetWarehouseOrdersData = () => {
  const api = useApi(AssetsWarehouseOrdersWidgetClient);

  return useKeyInformationData({
    queryKey: ["assets-warehouse-orders-widget"],
    apiFunction: () => api.getAssetsWarehouseOrdersWidgetData(),
    transformFunction: transformFunction,
    placeholderData: asDto(placeholderData),
  });
};
const AssetsWarehouseOrdersWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetWarehouseOrdersData}
      cardActions={<CardActions />}
    />
  );
};
export default AssetsWarehouseOrdersWidget;
