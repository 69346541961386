const shiColors = {
  white: "#ffffff",
  black: "#000000",
  red: {
    DEFAULT: "#DF3141",
    medium: "#DE0007",
    dark: "#9B1F2A",
  },
  orange: {
    lighter: "#FFA726",
    light: "#E66C37",
    DEFAULT: "#ED6C02",
    dark: "#C65326",
  },
  blue: {
    lighter: "#A4C2DB",
    light: "#1976D2",
    DEFAULT: "#1C64A5",
    dark: "#2B3F50",
  },
  pink: {
    DEFAULT: "#F765A3",
    medium: "#CC3C97",
    dark: "#A80073",
  },
  purple: {
    light: "#9960AF",
    DEFAULT: "#A155B9",
    dark: "#5C2673",
  },
  green: {
    DEFAULT: "#1DDD8D",
    light: "#3bd63b",
    medium: "#54B949",
    dark: "#00b271",
  },
  turquoise: {
    DEFAULT: "#09B3B1",
    dark: "#038487",
  },
  gray: {
    lighter: "#ebebeb",
    light: "#949494",
    DEFAULT: "rgba(0, 0, 0, 0.87)",
  },
  yellow: {
    DEFAULT: "#FFD919",
    dark: "#787B13",
  },
};

export const goodToBadColors = {
  best: shiColors.green.dark,
  good: shiColors.green.DEFAULT,
  ok: shiColors.orange.lighter,
  warning: shiColors.orange.light,
  bad: shiColors.red.DEFAULT,
  expired: shiColors.pink.dark,
};

export const graphColors = [
  shiColors.blue.DEFAULT,
  shiColors.pink.DEFAULT,
  shiColors.green.DEFAULT,
  shiColors.orange.DEFAULT,
  shiColors.purple.dark,
  shiColors.red.DEFAULT,
  shiColors.blue.dark,
  shiColors.purple.DEFAULT,
  shiColors.yellow.DEFAULT,
  shiColors.turquoise.DEFAULT,
];

export default shiColors;
