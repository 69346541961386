import {
  GET_BACKUP_STATS,
  GET_BACKUP_STATS_ERROR,
  GET_BACKUP_STATS_SUCCESS,
} from "../actions/backupActions";

export function backupReducer(
  state = {
    backupStats: undefined,
    fetchingBackupStats: false,
    fetchBackupStatsComplete: false,
    backupStatsError: undefined,
  },
  action
) {
  switch (action.type) {
    case GET_BACKUP_STATS: {
      return Object.assign({}, state, {
        fetchBackupStatsComplete: false,
        backupStatsError: undefined,
        fetchingBackupStats: true,
      });
    }
    case GET_BACKUP_STATS_SUCCESS: {
      return Object.assign({}, state, {
        fetchBackupStatsComplete: true,
        fetchingBackupStats: false,
        backupStatsError: undefined,
        backupStats: action.payload.data,
      });
    }
    case GET_BACKUP_STATS_ERROR: {
      return Object.assign({}, state, {
        fetchBackupStatsComplete: false,
        fetchingBackupStats: false,
        backupStatsError: action.payload.data,
      });
    }
    default:
      return state;
  }
}
