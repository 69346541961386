import React, { ReactElement, ReactNode } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import ms from "ms";
import PercentageLine from "../../components/PercentageLine";
import {
  NoContent,
  WidgetWrapper,
  BaseWidgetProps,
  Summary,
} from "../framework";

const SummaryContainerNoDataMaxHeight = 250;

export function useSummaryData<ApiDataType>({
  queryKey,
  apiFunction,
  transformFunction,
  placeholderData,
}: {
  queryKey: string[];
  apiFunction: () => Promise<ApiDataType>;
  transformFunction: (rawData: ApiDataType | undefined) => Summary[];
  placeholderData: ApiDataType;
}): UseQueryResult<Summary[]> {
  return useQuery(
    queryKey,
    () => apiFunction().then((r) => transformFunction(r)),
    {
      staleTime: ms("10m"),
      placeholderData: transformFunction(placeholderData),
    }
  );
}

export type SummaryWidgetProps = BaseWidgetProps & {
  useQueryFunction: (params?: any) => UseQueryResult<Summary[]>;
  cardActions?: ReactNode;
  leftLabel?: string;
  headerAction?: ReactNode;
  noDataElement?: ReactElement;
  containerStyles?: React.CSSProperties;
  formatter?: (value: number) => string;
};

function SummaryWidgetContent({
  data,
  loading,
  leftLabel,
  noDataElement,
  containerStyles,
  formatter,
}: {
  data: Summary[];
  loading: boolean;
  leftLabel?: string;
  noDataElement?: ReactElement;
  containerStyles?: React.CSSProperties;
  formatter?: (value: number) => string;
}) {
  if (data.length === 0 && !loading && !noDataElement) {
    return <NoContent containerHeight={SummaryContainerNoDataMaxHeight} />;
  } else if (data.length === 0 && !loading && noDataElement) {
    return (
      <>
        <PercentageLine
          visualParts={data}
          loading={loading}
          leftLabel={leftLabel}
          additionalStyles={containerStyles}
          formatter={formatter}
        />
        {noDataElement}
      </>
    );
  }

  return (
    <PercentageLine
      visualParts={data}
      loading={loading}
      leftLabel={leftLabel}
      additionalStyles={containerStyles}
      formatter={formatter}
    />
  );
}

export default function SummaryWidget({
  pageLayoutWidget,
  useQueryFunction,
  leftLabel,
  headerAction,
  cardActions,
  noDataElement,
  containerStyles,
  formatter,
}: SummaryWidgetProps) {
  const response = useQueryFunction();
  const loading =
    response.isPlaceholderData || response.isLoading || response.isError;

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={response.isError}
      headerAction={headerAction}
      cardActions={cardActions}
      isEmpty={response.data?.length === 0 && !loading && !noDataElement}
    >
      <SummaryWidgetContent
        data={response.data ?? []}
        loading={loading}
        leftLabel={leftLabel}
        noDataElement={noDataElement}
        containerStyles={containerStyles}
        formatter={formatter}
      />
    </WidgetWrapper>
  );
}
