import {
  MicrosoftAdoptionWidgetsClient,
  PageLayoutWidgetDto,
  ProductivityWidgetResponse,
} from "@lib/ShiOneClient";
import { WidgetWrapper } from "shared-ui";
import React from "react";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { Box, Grid, ToggleButtonGroup, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import theme from "../../../../theme";
import Logo from "../../components/Logo";

const services = ["Collaboration", "Storage", "Communication", "AI"];
const key = "microsoft-productivity-widget";

const useGetWidgetData = (service: string) => {
  const api = useApi(MicrosoftAdoptionWidgetsClient);

  return useQuery<ProductivityWidgetResponse, Error>({
    queryKey: [key, service],
    queryFn: () => api.getProductivityWidget(service),
    staleTime: ms("10m"),
    enabled: true,
  });
};

export default function ProductivityWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    key,
    services[0]
  );

  const { data, isError } = useGetWidgetData(currentFilterValue);

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      noPadding={true}
      headerAction={
        <HeaderActions
          currentFilterValue={currentFilterValue}
          setFilter={setFilter}
        />
      }
    >
      <Grid
        container
        spacing={2}
        sx={{ padding: theme.spacing(2), justifyContent: "center" }}
      >
        {data?.services?.map((item, i) => (
          <Grid key={i} xs={4} item>
            <Box
              sx={{
                padding: theme.spacing(2),
                flexDirection: "column",
                background: theme.palette.background.default,
              }}
            >
              <Logo
                product={item.service || "undefined"}
                shape={"square"}
                size={"medium"}
              />
              <Typography variant={"h6"}>
                {item.amount} {item.unit}
              </Typography>
              <Typography
                variant={"body2"}
                color={theme.palette.text.secondary}
              >
                Used in {item.service}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </WidgetWrapper>
  );
}

function HeaderActions({
  currentFilterValue,
  setFilter,
}: {
  currentFilterValue: any;
  setFilter: any;
}) {
  return (
    <ToggleButtonGroup
      color="secondary"
      exclusive
      value={currentFilterValue}
      onChange={(e, value) => {
        if (value === null) return;
        setFilter(value);
      }}
    >
      {services.map((to) => {
        return (
          <ToggleButton style={{ lineHeight: 1 }} key={to} value={to}>
            {to}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
