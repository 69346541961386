import { telemetryTrackEvent } from "../../functions/telemetry";
import { ActiveReportStore } from "shared-ui/src/shared-features/report-center/stores";

const applyQuotes = (value) => {
  return value === value.toString() ? `'${value}'` : value.toString();
};

const valuesToString = (values) => {
  return values?.map(applyQuotes).join(", ") ?? "";
};

const targetToString = (target) => {
  if (!target) {
    return "";
  }

  const columnName =
    target.table && target.column ? `[${target.table}].[${target.column}]` : "";
  const result = target.aggregationFunction
    ? `${target.aggregationFunction}(${columnName})`
    : columnName;
  return result;
};

const dataPointToString = (dataPoint) => {
  return (
    dataPoint.identity?.map(dataPointIdentityToString)?.join(" and ") ?? ""
  );
};

const dataPointIdentityToString = (identity) => {
  if (identity.equals) {
    return `${targetToString(identity.target)} = ${applyQuotes(
      identity.equals
    )}`;
  }
  return "";
};

const filterConditionToString = (condition) => {
  return condition?.operator && condition?.value
    ? `${condition.operator}(${applyQuotes(condition.value)})`
    : "";
};

// From https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/control-report-filters#filter-type
const FilterType = {
  Advanced: 0,
  Basic: 1,
  Unknown: 2,
  IncludeExclude: 3,
  RelativeDate: 4,
  TopN: 5,
  Tuple: 6,
  RelativeTime: 7,
};

const relativeDateOperatorToString = (operator) => {
  switch (operator) {
    case 0:
      return "in the last";
    case 1:
      return "in this";
    case 2:
      return "in the next";
    default:
      return `RelativeDate_${operator}`;
  }
};

const timeUnitToString = (timeUnit) => {
  switch (timeUnit) {
    case 0:
      return "days";
    case 1:
      return "weeks";
    case 2:
      return "calendar weeks";
    case 3:
      return "months";
    case 4:
      return "calendar months";
    case 5:
      return "years";
    case 6:
      return "calendar years";
    case 7:
      return "minutes";
    case 8:
      return "hours";
    default:
      return `TimeUnit_${timeUnit}`;
  }
};

const dateTimeFilterToString = (filter, target) => {
  if (
    !filter ||
    !filter.operator ||
    !filter.timeUnitType ||
    !filter.timeUnitsCount
  ) {
    return "";
  }
  const operator = relativeDateOperatorToString(filter.operator);
  const timeUnit = timeUnitToString(filter.timeUnitType);
  const includeToday = filter.includeToday ? " including today" : "";

  return `${target} ${operator} ${filter.timeUnitsCount} ${timeUnit}${includeToday}`;
};

const filterToString = (filter) => {
  if (!filter) {
    return "";
  }

  const target = targetToString(filter.target);

  let expression = "[unknown expression]";
  switch (filter.filterType) {
    case FilterType.Advanced:
      let conditions = filter.conditions
        ?.map(filterConditionToString)
        .join(` ${filter.logicalOperator} `);
      expression = conditions ? `${target} ${conditions}` : "";
      break;
    case FilterType.Basic:
      expression = filter.values?.length
        ? `${target} ${filter.operator}(${valuesToString(filter.values)})`
        : "";
      break;
    case FilterType.Unknown:
      break;
    case FilterType.IncludeExclude:
      const inclusionOperator = filter.isExclude ? "Excludes" : "Includes";
      expression = filter.values?.length
        ? `${target} ${inclusionOperator}(${valuesToString(filter.values)})`
        : "";
      break;
    case FilterType.RelativeDate:
      expression = dateTimeFilterToString(filter, target);
      break;
    case FilterType.TopN:
      expression = `${filter.operator}(${
        filter.itemCount
      }) order by ${targetToString(filter.orderBy)}`;
      break;
    case FilterType.Tuple:
      expression = filter.values?.length
        ? `${target} ${filter.operator}(${valuesToString(filter.values)})`
        : "";
      break;
    case FilterType.RelativeTime:
      expression = dateTimeFilterToString(filter, target);
      break;
    default:
      break;
  }

  return expression;
};

const extractContextDetails = (e) => {
  let context = {};

  let store = ActiveReportStore.getState();

  if (!e) {
    return context;
  }

  if(store?.activeReportRelatedFeature){
    context.isMetricsTracking = true;
    context.relatedFeature = store.activeReportRelatedFeature;
  }

  if(store?.activeReportId) {
    context.activeShiReportId = store.activeReportId;
  }

  if(store?.currentActivePage?.displayName) {
    context.activeReportTab = store.currentActivePage.displayName;
  }

  if(store?.activeReportParams?.reportName) {
    context.activeReportName = store.activeReportParams.reportName;
  }

  if(store?.activeEmbedConfig?.id) {
    context.powerBiReportId = store.activeEmbedConfig.id;
  }

  if (e.detail?.bookmark) {
    context.bookmark = e.detail.bookmark;
  }

  if (e.detail?.command) {
    context.command = e.detail.command;
  }

  if (e.detail?.dataPoints?.length) {
    context.dataPoints = e.detail.dataPoints
      .map(dataPointToString)
      .filter((x) => x);
  }

  if (e.detail?.filters?.length) {
    context.filters = e.detail.filters.map(filterToString).filter((x) => x);
  }
  if (e.detail?.newPage) {
    context.newPage = {
      name: e.detail.newPage.name,
      displayName: e.detail.newPage.displayName,
    };
  }

  if (e.detail?.page) {
    context.page = e.detail?.page;
  }

  if (e.detail?.report) {
    context.report = e.detail.report;
  }

  if (e.detail?.title) {
    context.title = e.detail.title;
  }

  if (e.detail?.url) {
    context.title = e.detail.url;
  }
  if (e.detail?.visual) {
    context.visual = e.detail.visual;
  }

  if (e.timeStamp) {
    context.timestamp = e.timeStamp;
  }

  return context;
};

const trackPowerBIEvent = (name, e) => {
  try {
    const context = extractContextDetails(e);
    telemetryTrackEvent(name, context);
  } catch (e) {
    // console.error(e);
  }
};

export const trackDataSelected = (e, entity) => {
  trackPowerBIEvent("PowerBI data selected", e);
};

export const trackLoaded = (e, entity) => {
  trackPowerBIEvent("PowerBI loaded", e);
};

export const trackRenderingStarted = (e, entity) => {
  trackPowerBIEvent("PowerBI rendering started", e);
};

export const trackRendered = (e, entity) => {
  trackPowerBIEvent("PowerBI rendered", e);
};

export const trackError = (e, entity) => {
  trackPowerBIEvent("PowerBI error", e);
};

export const trackButtonClicked = (e, entity) => {
  trackPowerBIEvent("PowerBI button clicked", e);
};

export const trackFiltersApplied = (e, entity) => {
  trackPowerBIEvent("PowerBI filters applied", e);
};

export const trackPageChanged = (e, entity) => {
  trackPowerBIEvent("PowerBI page changed", e);
};

export const trackCommandTriggered = (e, entity) => {
  trackPowerBIEvent("PowerBI command triggered", e);
};

export const trackBookmarkApplied = (e, entity) => {
  trackPowerBIEvent("PowerBI bookmark applied", e);
};

export const trackDataHyperlinkClicked = (e, entity) => {
  trackPowerBIEvent("PowerBI data hyperlink clicked", e);
};

export const trackVisualRendered = (e, entity) => {
  trackPowerBIEvent("PowerBI visual rendered", e);
};

export const trackVisualClicked = (e, entity) => {
  trackPowerBIEvent("PowerBI visual clicked", e);
};

export const trackSelectionChanged = (e, entity) => {
  trackPowerBIEvent("PowerBI selection changed", e);
};
