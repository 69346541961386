import {
  Card,
  CardActions,
  CardHeader,
  Divider,
  useTheme,
} from "@mui/material";
import { WidgetContent } from "./WidgetContent";
import { widgetStyles } from "./widgetStyles";
import { PageLayoutWidgetDto } from "../../generated/models";

export function WidgetWrapper({
  pageLayoutWidget,
  headerAction,
  children,
  cardActions,
  error = undefined,
  cardHeaderDivider = false,
  cardActionsDivider = false,
  isEmpty,
  noPadding,
  containerSizeInPixels,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
  headerAction?: any;
  children?: any;
  cardActions?: any;
  error?: boolean;
  cardHeaderDivider?: boolean;
  cardActionsDivider?: boolean;
  isEmpty?: boolean;
  noPadding?: boolean;
  containerSizeInPixels?: number;
}) {
  const theme = useTheme();
  const styles = widgetStyles(theme);

  return (
    <Card sx={styles.card}>
      <CardHeader
        title={pageLayoutWidget.title || ""}
        subheader={pageLayoutWidget.widgetGroup}
        action={headerAction}
        sx={styles.cardHeader}
      />
      {cardHeaderDivider && <Divider />}
      <WidgetContent
        error={error}
        isEmpty={isEmpty}
        noPadding={noPadding}
        containerSizeInPixels={containerSizeInPixels}
      >
        {children}
      </WidgetContent>
      {cardActionsDivider && <Divider />}
      {cardActions && <CardActions>{cardActions}</CardActions>}
    </Card>
  );
}
