import {
  formatDetailValue,
  getContractDetailPicklistLabel,
} from "@features/projects-feature/projectHelpers";
import { ContractDto } from "@lib/ShiOneClient";
import { Box } from "@mui/material";
import React from "react";
import { ContractDetailTextFieldProps } from "@features/projects-feature/projectTypes";

export const ContractDetailTextField = ({
  field,
  contract,
  picklist,
}: ContractDetailTextFieldProps) => {
  return (
    <Box
      sx={{
        "& :hover": {
          borderLeft: (theme) => "2px solid" + theme.palette.secondary.dark,
        },
        "& div": {
          "& :hover": {
            borderLeft: "0px",
          },
        },
      }}
    >
      <Box pl={2} mb={2} borderLeft={"2px solid transparent"}>
        <div className={"text-[13px]"}>{field.label}</div>
        <div className={"max-h-10 overflow-auto text-[16px]"}>
          {field.isPicklist
            ? getContractDetailPicklistLabel(field, contract, picklist)
            : formatDetailValue(
                contract[field.fieldKey as keyof ContractDto],
                field.type,
              )}
        </div>
      </Box>
    </Box>
  );
};
