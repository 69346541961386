import {
  GET_ACCOUNT_DEVICES,
  GET_ACCOUNT_DEVICES_SUCCESS,
  GET_ACCOUNT_DEVICES_ERROR,
  GET_ACCOUNT_USERS,
  GET_ACCOUNT_USERS_SUCCESS,
  GET_ACCOUNT_USERS_ERROR,
  ACTIVATE_ACCOUNT_USERS,
  ACTIVATE_ACCOUNT_USERS_SUCCESS,
  ACTIVATE_ACCOUNT_USERS_ERROR,
  ADMIN_UPDATE_USER,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_ERROR,
  ADMIN_MANUAL_EMAIL_APPROVAL,
  ADMIN_MANUAL_EMAIL_APPROVAL_ERROR,
  ADMIN_MANUAL_EMAIL_APPROVAL_SUCCESS,
  GET_OTHER_ACCOUNTS,
  GET_OTHER_ACCOUNTS_ERROR,
  GET_OTHER_ACCOUNTS_SUCCESS,
  ADMIN_CREATE_USER,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_CREATE_USER_ERROR,
} from "../modules/accountAdmin/actions/accountAdminActions";
import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${import.meta.env.API_ENDPOINT}/api/AccountAdmin`;

const accountAdminApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case ADMIN_MANUAL_EMAIL_APPROVAL: {
      AuthFetch(fetch, `${apiEndpoint}/setManualEmailRequired`, {
        method: "POST",
        body: JSON.stringify(action.payload.data.manualEmailApproval),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: ADMIN_MANUAL_EMAIL_APPROVAL_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: ADMIN_MANUAL_EMAIL_APPROVAL_SUCCESS,
            payload: {
              data: data.accounts,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADMIN_UPDATE_USER_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ADMIN_CREATE_USER: {
      AuthFetch(fetch, `${apiEndpoint}/user`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: ADMIN_CREATE_USER_ERROR,
              payload: {
                data: data.message,
              },
            });
          }
          return next({
            type: ADMIN_CREATE_USER_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADMIN_CREATE_USER_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ADMIN_UPDATE_USER: {
      AuthFetch(fetch, `${apiEndpoint}/update`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: ADMIN_UPDATE_USER_ERROR,
              payload: {
                data: data.message,
              },
            });
          }
          return next({
            type: ADMIN_UPDATE_USER_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADMIN_UPDATE_USER_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ACTIVATE_ACCOUNT_USERS: {
      AuthFetch(fetch, `${apiEndpoint}/approve`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: ACTIVATE_ACCOUNT_USERS_ERROR,
              payload: {
                data: data.message,
              },
            });
          }
          return next({
            type: ACTIVATE_ACCOUNT_USERS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ACTIVATE_ACCOUNT_USERS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_ACCOUNT_USERS: {
      AuthFetch(fetch, `${apiEndpoint}/users`)
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: GET_ACCOUNT_USERS_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: GET_ACCOUNT_USERS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_ACCOUNT_USERS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_ACCOUNT_DEVICES: {
      AuthFetch(fetch, `${apiEndpoint}/devices`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.resultInfo) {
            return next({
              type: GET_ACCOUNT_DEVICES_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: GET_ACCOUNT_DEVICES_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_ACCOUNT_DEVICES_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_OTHER_ACCOUNTS: {
      AuthFetch(fetch, `${apiEndpoint}/accounts/${action.payload.accountId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: GET_OTHER_ACCOUNTS_ERROR,
              payload: {
                data: data.errorMessage,
                accountId: action.payload.accountId,
              },
            });
          }
          return next({
            type: GET_OTHER_ACCOUNTS_SUCCESS,
            payload: {
              data: data.accounts,
              accountId: action.payload.accountId,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_OTHER_ACCOUNTS_ERROR,
            payload: {
              data: err,
              accountId: action.payload.accountId,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default accountAdminApi;
