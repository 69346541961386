import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import GlobalConstants from "@constants";
import CostCenterStore from "../../../api/costCenterStore";
import { CostCenterMessage, CostCenterState } from "./costCentersTypes";
import {
  ApiException,
  CloudAccountDTO,
  CostCenterCloudAccountRequest,
  CostCenterDTO,
  ICostCenterCloudAccountRequest,
} from "lib/ShiOneClient";
import { remove, some } from "lodash";
// @ts-ignore
import { v1 as uuidv1 } from 'uuid';

const initialState: CostCenterState = {
  createCostCenterStatus: GlobalConstants.fetchStatus.idle,
  updateAllCostCentersStatus: GlobalConstants.fetchStatus.idle,
  deleteCostCenterStatus: GlobalConstants.fetchStatus.idle,
  fetchCostCenterStatus: GlobalConstants.fetchStatus.idle,
  fetchCloudAccountsStatus: GlobalConstants.fetchStatus.idle,
  addCloudAccountToCostCenterStatus: GlobalConstants.fetchStatus.idle,
  removeCloudAccountToCostCenterStatus: GlobalConstants.fetchStatus.idle,
  cloudAccounts: [],
  costCenters: [],
  costCenterMessages: [],
};

const costCentersSlice = createSlice({
  name: "costCenters",
  initialState,
  reducers: {
    receivingCostCenters(state) {
      state.fetchCostCenterStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveCostCenters(state, action: PayloadAction<CostCenterDTO[]>) {
      state.fetchCostCenterStatus = GlobalConstants.fetchStatus.complete;
      state.costCenters = action.payload;
    },
    receiveCostCentersError(state) {
      state.fetchCloudAccountsStatus = GlobalConstants.fetchStatus.error;
    },
    receivingCloudAccounts(state) {
      state.fetchCloudAccountsStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveCloudAccounts(state, action: PayloadAction<CloudAccountDTO[]>) {
      state.fetchCloudAccountsStatus = GlobalConstants.fetchStatus.complete;
      state.cloudAccounts = action.payload;
    },
    receiveCloudAccountsError(state) {
      state.fetchCloudAccountsStatus = GlobalConstants.fetchStatus.error;
    },
    creatingCostCenters(state) {
      state.createCostCenterStatus = GlobalConstants.fetchStatus.loading;
    },
    createCostCenters(state, action: PayloadAction<CostCenterDTO>) {
      state.createCostCenterStatus = GlobalConstants.fetchStatus.complete;
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.success,
        message: "Cost Center successfully created",
      });
      state.costCenters.push(action.payload);
    },
    createCostCentersError(state) {
      state.createCostCenterStatus = GlobalConstants.fetchStatus.error;
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.error,
        message: "Cost Center creation was unsuccessful. Cannot create multiple CostCenters with the same Display Name.",
      });
    },
    updatingCostCenters(state) {
      state.updateAllCostCentersStatus = GlobalConstants.fetchStatus.loading;
    },
    updateCostCenters(state, action: PayloadAction<CostCenterDTO>) {
      state.updateAllCostCentersStatus = GlobalConstants.fetchStatus.complete;
      const index = state.costCenters.findIndex(
        (cc) => cc.id === action.payload.id
      );

      if (!some(state.costCenters, action.payload)) {
        state.costCenterMessages.push({
          id: uuidv1(),
          variant: GlobalConstants.messages.severity.success,
          message: "Cost Center successfully updated",
        });
      }

      state.costCenters[index] = action.payload;
    },
    updateCostCentersError(state) {
      state.updateAllCostCentersStatus = GlobalConstants.fetchStatus.error;
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.error,
        message: "Cost Center update was unsuccessful. Cannot have multiple CostCenters with the same Display Name.",
      });
    },
    deletingCostCenters(state) {
      state.deleteCostCenterStatus = GlobalConstants.fetchStatus.loading;
    },
    deleteCostCenters(state, action: PayloadAction<CostCenterDTO>) {
      state.deleteCostCenterStatus = GlobalConstants.fetchStatus.complete;
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.success,
        message: "Cost Center successfully removed",
      });

      const defaultCostCenter = state.costCenters.find(
        cc => cc.isDefaultCostCenter
      );

      if (defaultCostCenter) {
        state.cloudAccounts.forEach(
          ca => {
            if (ca.costCenterId === action.payload.id) {
              ca.costCenterId = defaultCostCenter.id
            }
          }
        );
      }

      const index = state.costCenters.findIndex(
        (cc) => cc.id === action.payload.id
      );
      state.costCenters.splice(index, 1);
    },
    deleteCostCentersError(state) {
      state.deleteCostCenterStatus = GlobalConstants.fetchStatus.error;
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.error,
        message: "Cost Center deletion was unsuccessful",
      });
    },
    addingCloudAccountToCostCenter(state) {
      state.addCloudAccountToCostCenterStatus =
        GlobalConstants.fetchStatus.loading;
    },
    addCloudAccountToCostCenter(
      state,
      action: PayloadAction<CloudAccountDTO[]>
    ) {
      state.addCloudAccountToCostCenterStatus =
        GlobalConstants.fetchStatus.complete;
      state.cloudAccounts = action.payload;
    },
    addCloudAccountToCostCenterError(state, action: PayloadAction<string>) {
      state.addCloudAccountToCostCenterStatus =
        GlobalConstants.fetchStatus.error;

      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.error,
        message: "Unable to add Cloud Account: " + action.payload,
      });
    },
    removingCloudAccountFromCostCenter(state) {
      state.removeCloudAccountToCostCenterStatus =
        GlobalConstants.fetchStatus.loading;
    },
    removeCloudAccountFromCostCenter(
      state,
      action: PayloadAction<CloudAccountDTO[]>
    ) {
      state.removeCloudAccountToCostCenterStatus =
        GlobalConstants.fetchStatus.complete;
      state.cloudAccounts = action.payload;
    },
    removeCloudAccountFromCostCenterError(
      state,
      action: PayloadAction<string>
    ) {
      state.removeCloudAccountToCostCenterStatus =
        GlobalConstants.fetchStatus.error;
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.error,
        message: "Unable to remove Cloud Account: " + action.payload,
      });
    },
    removeCloudAccountMessage(state, action: PayloadAction<CostCenterMessage>) {
      remove(state.costCenterMessages, { id: action.payload.id });
    },
    addCloudAccountMessageSuccess(state, action: PayloadAction<number>) {
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.success,
        message:
          action.payload === 1
            ? "Cloud Account added"
            : action.payload.toString() + " Cloud Accounts added",
      });
    },
    removeCloudAccountMessageSuccess(state, action: PayloadAction<number>) {
      state.costCenterMessages.push({
        id: uuidv1(),
        variant: GlobalConstants.messages.severity.success,
        message:
          action.payload === 1
            ? "Cloud Account removed"
            : action.payload.toString() + " Cloud Accounts removed",
      });
    },
  },
});

export const getAccountCostCenters =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(costCentersSlice.actions.receivingCostCenters());
    try {
      const costCenters = await CostCenterStore.getCostCenterByAccount();

      dispatch(costCentersSlice.actions.receiveCostCenters(costCenters));
    } catch {
      dispatch(costCentersSlice.actions.receiveCostCentersError());
    }
  };

export const getAccountCloudAccounts =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(costCentersSlice.actions.receivingCloudAccounts());
    try {
      const cloudAccounts = await CostCenterStore.cloudAccountsByAccountId();

      dispatch(costCentersSlice.actions.receiveCloudAccounts(cloudAccounts));
    } catch {
      dispatch(costCentersSlice.actions.receiveCloudAccountsError());
    }
  };

export const createCostCenter =
  (request: CostCenterDTO, selectedResources: CloudAccountDTO[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(costCentersSlice.actions.creatingCostCenters());
    try {
      const newCostCenter = await CostCenterStore.createCostCenter(request);

      if (selectedResources.length > 0) {
        const costCenterCloudAccountInterface: ICostCenterCloudAccountRequest =
          {
            costCenterId: newCostCenter.id,
            configurationItemIds: selectedResources.map(
              (x) => x.configurationItemId ?? -1
            ),
          };

        const costCenterCloudAccountRequest =
          costCenterCloudAccountInterface as CostCenterCloudAccountRequest;

        try {
          const currentCloudAccounts =
            await CostCenterStore.addConfigurationItemToCostCenter(
              costCenterCloudAccountRequest
            );
          dispatch(
            costCentersSlice.actions.addCloudAccountToCostCenter(
              currentCloudAccounts
            )
          );
          dispatch(
            costCentersSlice.actions.addCloudAccountMessageSuccess(
              selectedResources.length
            )
          );
        } catch (error) {
          if (error instanceof ApiException) {
            dispatch(
              costCentersSlice.actions.addCloudAccountToCostCenterError(
                error.response
              )
            );
          }
        }
      }

      dispatch(costCentersSlice.actions.createCostCenters(newCostCenter));
    } catch {
      dispatch(costCentersSlice.actions.createCostCentersError());
    }
  };

export const updateAllCostCenters =
  (
    request: CostCenterDTO,
    selectedResources: CloudAccountDTO[],
    initialSelectResources: CloudAccountDTO[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(costCentersSlice.actions.updatingCostCenters());
    try {
      const resourcesToAdd = selectedResources.filter(
        (sr) => !initialSelectResources.includes(sr)
      );
      const resourcesToRemove = initialSelectResources.filter(
        (ir) => !selectedResources.includes(ir)
      );

      if (resourcesToAdd && resourcesToAdd.length > 0) {
        dispatch(costCentersSlice.actions.addingCloudAccountToCostCenter());

        const costCenterCloudAccountInterface: ICostCenterCloudAccountRequest =
          {
            costCenterId: request.id,
            configurationItemIds: resourcesToAdd.map(
              (x) => x.configurationItemId ?? -1
            ),
          };

        const costCenterCloudAccountRequest =
          costCenterCloudAccountInterface as CostCenterCloudAccountRequest;

        let currentCloudAccounts: CloudAccountDTO[] = [];

        try {
          currentCloudAccounts =
            await CostCenterStore.addConfigurationItemToCostCenter(
              costCenterCloudAccountRequest
            );

          dispatch(
            costCentersSlice.actions.addCloudAccountToCostCenter(
              currentCloudAccounts
            )
          );
          dispatch(
            costCentersSlice.actions.addCloudAccountMessageSuccess(
              resourcesToAdd.length
            )
          );
        } catch (error) {
          if (error instanceof ApiException) {
            dispatch(
              costCentersSlice.actions.addCloudAccountToCostCenterError(
                error.response
              )
            );
          }
        }
      }

      if (resourcesToRemove && resourcesToRemove.length > 0) {
        dispatch(costCentersSlice.actions.removingCloudAccountFromCostCenter());
        const costCenterCloudAccountInterface: ICostCenterCloudAccountRequest =
          {
            costCenterId: request.id,
            configurationItemIds: resourcesToRemove.map(
              (x) => x.configurationItemId ?? -1
            ),
          };

        const costCenterCloudAccountRequest =
          costCenterCloudAccountInterface as CostCenterCloudAccountRequest;
        try {
          const currentCloudAccounts =
            await CostCenterStore.removeConfigurationItemFromCostCenter(
              costCenterCloudAccountRequest
            );

          dispatch(
            costCentersSlice.actions.removeCloudAccountFromCostCenter(
              currentCloudAccounts
            )
          );
          dispatch(
            costCentersSlice.actions.removeCloudAccountMessageSuccess(
              resourcesToRemove.length
            )
          );
        } catch (error) {
          if (error instanceof ApiException) {
            dispatch(
              costCentersSlice.actions.removeCloudAccountFromCostCenterError(
                error.response
              )
            );
          }
        }
      }

      const updatedCostCenters = await CostCenterStore.updateCostCenter(
        request
      );

      dispatch(costCentersSlice.actions.updateCostCenters(updatedCostCenters));
    } catch {
      dispatch(costCentersSlice.actions.updateCostCentersError());
    }
  };

export const deleteCostCenter =
  (id: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(costCentersSlice.actions.deletingCostCenters());
    try {
      const currentCostCenters = await CostCenterStore.deleteCostCenter(id);

      dispatch(costCentersSlice.actions.deleteCostCenters(currentCostCenters));
    } catch {
      dispatch(costCentersSlice.actions.deleteCostCentersError());
    }
  };

export const acknowledgeCloudAccountError = (
  cloudAccountError: CostCenterMessage
) => costCentersSlice.actions.removeCloudAccountMessage(cloudAccountError);

export default costCentersSlice.reducer;
