import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import { azureComplianceState } from "modules/rtk/azureCompliance/azureComplianceTypes";
import { Subscription } from "lib/ShiOneClient";
import { getRestAzureComplianceData } from "api/azureComplianceStore";

const initialState: azureComplianceState = {
  azureComplianceFetchStatus: "idle",
  azureComplianceData: {} as Subscription,
};

const azureComplianceSlice = createSlice({
  name: "azureCompliance",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    receivingAzureComplianceData(state) {
      state.azureComplianceFetchStatus = "loading";
    },
    receiveAzureComplianceData(state, action: PayloadAction<Subscription>) {
      state.azureComplianceData = action.payload;
      state.azureComplianceFetchStatus = "complete";
    },
    azureComplianceAccountsError(state) {
      state.azureComplianceFetchStatus = "error";
    },
  },
});

export const getAzureComplianceData =
  (subscriptionId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(azureComplianceSlice.actions.receivingAzureComplianceData());

    try {
      const azureComplianceData = await getRestAzureComplianceData(
        subscriptionId
      );
      dispatch(
        azureComplianceSlice.actions.receiveAzureComplianceData(
          azureComplianceData
        )
      );
    } catch (err) {
      dispatch(azureComplianceSlice.actions.azureComplianceAccountsError());
    }
  };

export const resetAzureComplianceAccounts =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(azureComplianceSlice.actions.resetState());
  };

export default azureComplianceSlice.reducer;
