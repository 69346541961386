import { NavItemType } from "../../NavigationTypes";
import { cloudAzureItemsToShowType } from "../../NavItemsToShowTypes";

export function CloudAzureMenuV2(
  cloudAzureItemsToShow: cloudAzureItemsToShowType
): NavItemType {
  return {
    id: "cloudAzure",
    type: "Group",
    displayName: "Azure",
    visible: Object.entries(cloudAzureItemsToShow).some(([_k, v]) => v),
    children: [
      {
        id: "cloudAzureBackup",
        type: "Link",
        displayName: "Backup",
        path: "/cloud/azure/backup",
        visible: cloudAzureItemsToShow.showBackup,
      },
      {
        id: "cloudAzureBlueprints",
        type: "Link",
        displayName: "Blueprints",
        path: "/cloud/azure/blueprints",
        visible: cloudAzureItemsToShow.showBlueprints,
      },
      {
        id: "cloudAzureMonitoring",
        type: "Link",
        displayName: "Monitoring",
        path: "/cloud/azure/monitoring",
        visible: cloudAzureItemsToShow.showMonitoring,
      },
      {
        id: "cloudAzureRecommendations",
        type: "Link",
        displayName: "Recommendations",
        path: "/cloud/azure/recommendations",
        visible: cloudAzureItemsToShow.showRecommendations,
      },
      {
        id: "cloudAzureRecommendationsAllUpReport",
        type: "Link",
        displayName: "All-Up Recommendations",
        path: "/cloud/azure/reports/all-up/recommendations",
        visible: cloudAzureItemsToShow.showAllUpRecommendations,
      },
      {
        id: "cloudAzureReports",
        type: "Link",
        displayName: "Reports",
        path: "/cloud/azure/reports",
        visible: cloudAzureItemsToShow.showReports,
      },
      {
        id: "cloudAzureSecurityCompliance",
        type: "Link",
        displayName: "Security & Compliance",
        path: "/cloud/azure/securitycompliance",
        visible: cloudAzureItemsToShow.showSecurityAndCompliance,
      },
      {
        id: "cloudAzureGovernance",
        type: "Link",
        displayName: "Governance",
        path: "/cloud/azure/governance",
        visible: cloudAzureItemsToShow.showGovernance,
      },
      {
        id: "cloudAzureSubscriptions",
        type: "Link",
        displayName: "Subscriptions",
        path: "/cloud/azure/subscriptions",
        visible: cloudAzureItemsToShow.showSubscriptions,
      },
    ],
  };
}
