import { getLayoutConfigSettings } from "../helpers";
import { ActiveReportStore } from "../stores";
import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { PageSizeType, VisualContainerDisplayMode, DisplayOption, IPage } from "powerbi-models";

export const useEmbeddedPowerBiLayout = () => {
  const theme = useTheme();
  const margin = 30;
  const ActiveEmbeddedReport = ActiveReportStore((s) => s.activeEmbeddedReport);
  const currentActivePage = ActiveReportStore((s) => s.currentActivePage);

  const isReallyMobile = useMediaQuery(theme.breakpoints.down("lg"), {
    defaultMatches: true,
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const visualsLayout: {
    [key: string]: {
      x: number;
      y: number;
      width: number;
      height: number;
      displayState: any;
    };
  } = {};

  useEffect(() => {
    if (currentActivePage) {
      applyLayout(currentActivePage).then();
    }
  }, [currentActivePage]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyLayout = async (currentPage: IPage) => {
    const visuals = await currentPage?.getVisuals();
    if (currentPage.displayName === "Dashboard") {
      await embedDashboard(currentPage, visuals);
    } else {
      await embedReportLayout(currentPage);
    }
  };

  const embedDashboard = async (currentPage: IPage, visuals: any) => {
    if (visuals && ActiveEmbeddedReport && ActiveEmbeddedReport.element) {
      const columns = isDesktop ? 2 : 1;
      const pageWidth = ActiveEmbeddedReport.element.offsetWidth;
      let pageHeight = ActiveEmbeddedReport.element.offsetHeight;

      let x = margin;
      let y = margin;

      const visualsTotalWidth = pageWidth - margin * 3;

      const width = visualsTotalWidth / columns;

      const height = width * (9 / 16);

      const lines = Math.ceil(visuals.length / columns);

      pageHeight = Math.max(
        pageHeight,
        lines * height + (lines + 1) * margin + 20
      );

      for (const visual of visuals) {
        const { name } = visual;
        visualsLayout[name] = {
          x: x,
          y: y,
          width: width,
          height: height,
          displayState: {
            mode: VisualContainerDisplayMode.Visible,
          },
        };
        x += width + margin;
        if (x + width > pageWidth) {
          x = margin;
          y += height + margin;
        }
      }

      const settings = getLayoutConfigSettings(
        currentPage?.name ?? "",
        DisplayOption.FitToWidth,
        visualsLayout
      );

      await ActiveEmbeddedReport.updateSettings(settings);
      await currentPage?.resizePage(
        PageSizeType.Custom,
        pageWidth - 10,
        pageHeight - 20
      );
    }
  };

  const embedReportLayout = async (currentPage: IPage) => {
    if (ActiveEmbeddedReport && currentPage) {
      const defaultPageSize = currentPage.defaultSize;

      const isCustom =
        defaultPageSize &&
        defaultPageSize.width &&
        defaultPageSize.width >= 1280;
      const settings = getLayoutConfigSettings(
        "",
        DisplayOption.FitToPage
      );

      if (defaultPageSize && !isCustom) {
        await currentPage?.resizePage(PageSizeType.Custom, 1280, 720);
      }
      await ActiveEmbeddedReport.updateSettings(settings);
    }
  };
  return { isReallyMobile, embedDashboard };
};
