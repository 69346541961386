import { CircularProgress, Icon } from "@mui/material";
import React from "react";
import { NavItemType } from "../../NavigationTypes";

type KnowledgeBaseMenuParameters = {
  knowledgebase: any;
  v2UIEnabled?: boolean;
};

function getIcon(v2UIEnabled?: boolean, fetchingKnowledgebase?: boolean) {
  if (v2UIEnabled) {
    return undefined;
  }

  return fetchingKnowledgebase ? (
    <CircularProgress size={18} />
  ) : (
    <Icon className={"fas fa-graduation-cap"} />
  );
}

export function KnowledgeBaseMenu({
  knowledgebase,
  v2UIEnabled,
}: KnowledgeBaseMenuParameters): NavItemType {
  const { contentUnits, fetchingKnowledgebase } = knowledgebase;
  const kbUnits = ((contentUnits as []) ?? [])
    .sort((a: any, b: any) => a.title - b.title)
    .map((cu: any) => {
      return {
        id: "knowledgeBaseUnit-" + cu.identifier,
        type: "Link",
        displayName: cu.title,
        path: cu.url,
        target: "_blank",
        visible: true,
      };
    });
  return {
    id: "knowledgeBase",
    type: "Group",
    displayName: "Knowledge Base",
    icon: getIcon(v2UIEnabled, fetchingKnowledgebase),
    visible: fetchingKnowledgebase || kbUnits.length > 0,
    children: [...kbUnits],
  };
}
