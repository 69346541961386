import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { FilledInput, FormControl, InputLabel } from "@mui/material";

export default function PrefixedCompanyWidget({
  schema,
  formContext,
  onChange,
  disabled,
}) {
  const [payerName, setPayerName] = useState("");
  const [labelTitle, setLabelTitle] = useState("");

  useEffect(() => {
    const companyName = formContext.shiAccountDetails?.companyName;
    const prefix = schema?.prefix ? schema.prefix : "";

    if (companyName) {
      const payerName = `${prefix}${companyName}`;

      setPayerName(payerName);
      onChange(payerName);
    } else {
      setPayerName("");
      onChange("");
    }
    if (schema.title) {
      const labelTitle = schema.title;
      setLabelTitle(labelTitle);
    }
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.shiAccountDetails?.companyName]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="filled">
            <InputLabel shrink htmlFor="awsCustomerPayerName">
              {labelTitle}
            </InputLabel>
            <FilledInput
              disabled={disabled}
              readOnly={true}
              disableUnderline
              id="awsCustomerPayerName"
              name="awsCustomerPayerName"
              value={payerName}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
