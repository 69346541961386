import { styled } from "@mui/material/styles";

const StyledInitials = styled("h3", {
  shouldForwardProp: (prop) =>
    prop !== "iconPrimaryColor" && prop !== "iconSecondaryColor",
})<{ iconPrimaryColor?: string; iconSecondaryColor?: any }>(
  ({ iconPrimaryColor, iconSecondaryColor }) => ({
    margin: 0,
    fontSize: "1rem",
    display: "inline-block",
    fontFamily: "Tahoma",
    backgroundColor: iconPrimaryColor,
    backgroundImage: `linear-gradient(155deg, ${iconPrimaryColor} 60%, ${iconSecondaryColor})`,
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    backgroundClip: "text",
    color: "transparent",
    transform: "scale(1, 1.3)",
  })
);

export const CustomerExperienceIcon = () => {
  return (
    <StyledInitials iconPrimaryColor={"#1d64a6"} iconSecondaryColor={"#000000"}>
      CX
    </StyledInitials>
  );
};

export const CustomerSuccessIcon = () => {
  return (
    <StyledInitials iconPrimaryColor={"#fd2f8b"} iconSecondaryColor={"#f36630"}>
      CS
    </StyledInitials>
  );
};
