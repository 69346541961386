import axios from "axios";
import {
  AccountWelcomeLink,
  EmailVerificationRequest,
  OnboardingStatus,
} from "../lib/ShiOneClient";

const baseUrl = `${
  import.meta.env.API_ENDPOINT
}/api/onboarding`;

export async function getRestWelcomeLinkStatus(
  guid: string
): Promise<AccountWelcomeLink> {
  const response = await axios.get<AccountWelcomeLink>(
    baseUrl + `/welcome/${guid}`
  );

  return response.data;
}

export async function getRestOnboardingStatus(
  guid: string
): Promise<OnboardingStatus> {
  const response = await axios.get<OnboardingStatus>(
    baseUrl + `/status/${guid}`
  );

  return response.data;
}

export async function postRestSendVerificationCodeEmail(
  evr: EmailVerificationRequest
): Promise<void> {
  const response = await axios.post<EmailVerificationRequest>(
    baseUrl + "/generateCode",
    evr
  );

  return response.data;
}

export async function postRestVerifyEmailCode(
  evr: EmailVerificationRequest
): Promise<ValidateEmailStatus> {
  const response = await axios.post<ValidateEmailStatus>(
    baseUrl + "/verifyCode",
    evr
  );

  return response.data;
}

export async function postRestSubmitOnboardingAdmin(
  onboardingStatus: OnboardingStatus
): Promise<OnboardingStatus> {
  const response = await axios.post<OnboardingStatus>(
    baseUrl + "/submit",
    onboardingStatus
  );

  return response.data;
}

export async function postRestUpdateWelcomeLink(
  welcomeLink: AccountWelcomeLink
): Promise<AccountWelcomeLink> {
  const response = await axios.post<AccountWelcomeLink>(
    baseUrl + "/updateWelcomeOptions",
    welcomeLink
  );

  return response.data;
}
