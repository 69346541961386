const MuiAppBar = (theme, rootElement) => ({
  defaultProps: {
    container: rootElement,
  },
  colorDefault: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
});

export default MuiAppBar;
