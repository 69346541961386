import {
  asDto,
  BaseWidgetProps,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
  colorSeverities,
} from "shared-ui";
import React from "react";
import { useApi } from "../../utils";
import {
  CloudPotentialSavingsWidgetClient,
  SeverityColor,
  WidgetContentResponseDto_1OfCloudPotentialSavingsWidgetDto,
} from "@lib/ShiOneClient";
import { Box, Button, Container, Typography } from "@mui/material";
import { usdFormatter } from "../../../../functions/currency";
import { RequestConsultationCardActions } from "@widgets/framework/RequestConsultationCardActions";
import { useClaims } from "../../../../auth/ClaimsProvider";
import { ShiOnePermission } from "../../../../auth/authorizationTypes";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

function getAmountStyle(severity: SeverityColor | undefined): any {
  if (severity === undefined) {
    return {};
  }

  return { color: colorSeverities[`${severity}`] };
}

function getFormattedAmount(
  amount: number | undefined,
  severity: SeverityColor | undefined
) {
  return (
    <>
      <Typography sx={getAmountStyle(severity)}>
        {usdFormatter.format(amount ?? 0)}
      </Typography>
      <Typography variant={"caption"} sx={getAmountStyle(severity)}>
        per month
      </Typography>
    </>
  );
}

const PotentialSavingsPlaceholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    optimizationSavingsAmount: 0,
    rightsizingSavingsAmount: 0,
    withSavingsPlansAmount: 0,
    reservationsSavingsAmount: 0,
  },
};

function transformPotentialSavingsFunction(
  rawData:
    | WidgetContentResponseDto_1OfCloudPotentialSavingsWidgetDto
    | undefined
): ItemBoxConfiguration[] {
  const itemBoxConfigurations: ItemBoxConfiguration[] = [];

  if (
    rawData?.data?.optimizationSavingsAmount !== undefined &&
    rawData?.data?.optimizationSavingsAmount !== 0
  ) {
    itemBoxConfigurations.push({
      amount: getFormattedAmount(
        rawData?.data?.optimizationSavingsAmount,
        rawData?.data?.optimizationSavingsSeverityColor
      ),
      label: "Optimization Savings",
      icon: <></>,
    });
  }

  itemBoxConfigurations.push(
    {
      amount: getFormattedAmount(
        rawData?.data?.rightsizingSavingsAmount,
        rawData?.data?.rightsizingSavingsSeverityColor
      ),
      label: "Rightsizing Savings",
      icon: <></>,
    },
    {
      amount: getFormattedAmount(
        rawData?.data?.withSavingsPlansAmount,
        rawData?.data?.withSavingsPlansSeverityColor
      ),
      label: "With Savings Plans",
      icon: <></>,
    },
    {
      amount: getFormattedAmount(
        rawData?.data?.reservationsSavingsAmount,
        rawData?.data?.reservationsSavingsSeverityColor
      ),
      label: "Reservations Savings",
      icon: <></>,
    }
  );

  return itemBoxConfigurations;
}

const useGetCloudPotentialSavingsWidgetData = () => {
  const api = useApi(CloudPotentialSavingsWidgetClient);

  return useKeyInformationData({
    queryKey: ["cloud-potential-savings-widget"],
    apiFunction: () => api.getCloudPotentialSavingsWidgetData(),
    transformFunction: transformPotentialSavingsFunction,
    placeholderData: asDto(PotentialSavingsPlaceholderData),
  });
};

export const CloudPotentialSavingsWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetCloudPotentialSavingsWidgetData}
      cardActions={<CardActions />}
    />
  );
};

function CardActions() {
  const claims = useClaims();
  const hasAwsRecommendation = claims.hasPermission(
    ShiOnePermission.awsRecommendationRead
  );
  const hasAzureRecommendation = claims.hasPermission(
    ShiOnePermission.azureRecommendationRead
  );

  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {hasAwsRecommendation && !hasAzureRecommendation && (
            <Button href={AppendIfPrefixExists("/cloud/aws/recommendations")}>
              View Recommendations
            </Button>
          )}
          {!hasAwsRecommendation && hasAzureRecommendation && (
            <Button href={AppendIfPrefixExists("/cloud/azure/recommendations")}>
              View Recommendations
            </Button>
          )}
        </div>
        <div style={{ float: "right" }}>
          <RequestConsultationCardActions
            applicationContext={"Cloud Potential Savings"}
          />
        </div>
      </Box>
    </Container>
  );
}
