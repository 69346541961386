import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { projectColors } from "@features/projects-feature/utils/projectColors";

export const StyledServiceContractDetailsContainer = styled(Grid)({
  padding: "16px 0px 32px 0px",
})

export const StyledServiceContractDetailsSubheader = styled(Typography)({
  color: projectColors.contractDialog.subheader,
  fontFamily: "Roboto",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22.88px",
  letterSpacing: "0.17px",
})