import React from "react";
import { IconButton, Typography, useTheme, Theme, Grid } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import { NavLink, useLocation } from "react-router-dom";
import ChevronRight from "@mui/icons-material/ChevronRight";

const css = (theme: Theme) => ({
  headerChevron: {
    width: "18px",
    height: "18px",
  },
  headerTextContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  pageTitle: {
    minHeight: 6,
    color: "text.secondary",
  },
  grow: {
    flexGrow: 1,
  },
  subTitle: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
});

export default function PageHeader({
  header,
  subheader = "",
  icon,
  headerComponents,
  backButtonUrl,
  backButtonAction,
  backButtonAriaLabel,
}: {
  header: string | string[];
  subheader?: string;
  icon?: any;
  headerComponents?: any;
  backButtonUrl?: string;
  backButtonAction?: () => void;
  backButtonAriaLabel?: string;
}) {
  const theme = useTheme();
  const classes = css(theme);
  const { state: assetsUseLocationState } = useLocation();
  const handleHeader = (header: string | string[]) => {
    if (Array.isArray(header) && header.length > 0) {
      return header.map((element) => {
        if (header.indexOf(element) !== header.length - 1) {
          return [
            element,
            <ChevronRight key="arrow" sx={classes.headerChevron} />,
          ];
        } else return element;
      });
    }
    return header;
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        paddingBottom={3}
      >
        <Grid item xs={"auto"} container gap={1} sx={classes.pageTitle}>
          {backButtonUrl && (
            <IconButton
              color="inherit"
              component={NavLink}
              to={AppendIfPrefixExists(backButtonUrl)}
              aria-label={backButtonAriaLabel}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          {backButtonAction && (
            // @ts-ignore
            <IconButton
              color="inherit"
              onClick={() => backButtonAction()}
              state={assetsUseLocationState?.state}
              aria-label={backButtonAriaLabel}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <div style={classes.iconContainer}>
            {icon && <div>{icon}</div>}
            <div style={classes.grow}>
              <Typography component="div" variant="h6" noWrap>
                <div style={classes.headerTextContainer}>
                  {handleHeader(header)}
                </div>
              </Typography>
              {subheader && (
                <Typography
                  component="div"
                  variant="body2"
                  color={theme.palette.text.secondary}
                  noWrap
                >
                  {subheader}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid item marginLeft={"auto"}>
          {headerComponents}
        </Grid>
      </Grid>
    </>
  );
}
