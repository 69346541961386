import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { createFilterOptions } from "@mui/material/useAutocomplete";

const css = (theme) => ({
  dialog: {
    maxWidth: 700,
  },
  filterMenu: {
    background: theme.palette.background.paper,
  },
  dateFormControl: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  datePicker: {
    background: theme.palette.background.paper,
    width: "49%",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  typeahead: {
    backgroundColor: theme.palette.background.paper,
    "& .MuiOutlinedInput-root": {
      borderRadius: "0 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option,
  trim: true,
});

export default function TableFiltersDialog(props) {
  const theme = useTheme();
  const classes = css(theme);
  const [typeaheadValue, setTypeaheadValue] = useState("");

  const {
    filterDialog,
    filters,
    handleDialog,
    handleChangeDates,
    handleChangeDateGroup,
    handleChangeFilter,
    handleChangeTypeaheadFilter,
  } = props;

  const filterTypeaheadOptions = (options) => {
    return options.filter((option) =>
      option
        ?.toLowerCase()
        .startsWith(typeaheadValue.trimEnd().toLowerCase(), 0)
    );
  };

  const handleTypeaheadFilter = (options) => {
    if (typeaheadValue && typeaheadValue.length > 0) {
      return filterTypeaheadOptions(options);
    } else {
      return options?.filter((option) => !!option);
    }
  };
  const getEndPart = (filter) => {
    const groupName = filter.datePickerGroupName;
    return filters.find(
      (filter) => filter.datePickerGroupName === groupName && !filter.isStart
    );
  };

  return (
    <Dialog
      open={filterDialog}
      maxWidth={"md"}
      aria-labelledby={"filter-dialog"}
      disableEscapeKeyDown={false}
      fullWidth
    >
      <DialogTitle id={"filter-dialog"}>Filters</DialogTitle>
      <Divider />
      <DialogContent>
        {filters.map((filter, i) => {
          if (filter.datePicker && !filter.datePickerGroupName) {
            return (
              <FormControl
                key={i}
                sx={[
                  classes.dateFormControl,
                  filter.hidden ? { display: "none" } : {},
                ]}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    sx={classes.datePicker}
                    id="start-date"
                    emptyLabel={"Start Date"}
                    label={
                      <>
                        <FilterListIcon sx={classes.menuIcon} />
                        {filter.label}
                      </>
                    }
                    InputLabelProps={{ shrink: true }}
                    value={filter.startDate}
                    onChange={(date) => {
                      handleChangeDates("startDate-" + i, date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    orientation={"landscape"}
                  />
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    orientation="landscape"
                    sx={classes.datePicker}
                    id="end-date"
                    emptyLabel={"End Date"}
                    label={
                      <>
                        <FilterListIcon sx={classes.menuIcon} />
                        {filter.label}
                      </>
                    }
                    InputLabelProps={{ shrink: true }}
                    value={filter.endDate}
                    onChange={(date) => handleChangeDates("endDate-" + i, date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          }
          if (
            filter.datePicker &&
            filter.datePickerGroupName &&
            filter.isStart
          ) {
            const EndFilter = getEndPart(filter);

            return (
              <FormControl
                key={i}
                sx={[
                  classes.dateFormControl,
                  filter.hidden ? { display: "none" } : {},
                ]}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    sx={classes.datePicker}
                    id="start-date"
                    emptyLabel={"Start Date"}
                    label={
                      <>
                        <FilterListIcon sx={classes.menuIcon} />
                        {filter.label}
                      </>
                    }
                    InputLabelProps={{ shrink: true }}
                    value={filter.value}
                    onChange={(date) => {
                      handleChangeDateGroup(
                        filter.datePickerGroupName,
                        true,
                        date
                      );
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    orientation={"landscape"}
                  />
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    orientation="landscape"
                    sx={classes.datePicker}
                    id="end-date"
                    emptyLabel={"End Date"}
                    label={
                      <>
                        <FilterListIcon sx={classes.menuIcon} />
                        {EndFilter.label}
                      </>
                    }
                    InputLabelProps={{ shrink: true }}
                    value={EndFilter.value}
                    onChange={(date) =>
                      handleChangeDateGroup(
                        filter.datePickerGroupName,
                        false,
                        date
                      )
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            );
          }

          if (!filter.datePicker && !filter.typeahead) {
            return (
              <FormControl
                sx={[
                  classes.formControl,
                  filter.hidden ? { display: "none" } : {},
                ]}
                variant="outlined"
                key={i}
                data-telemetry-name={filter.label}
              >
                <InputLabel id={"filter-" + i} sx={classes.inputLabel}>
                  {filter.label}
                </InputLabel>
                <Select
                  labelId={"filter-" + i}
                  id={"filterInput-" + i}
                  name={"filter-" + i}
                  variant="outlined"
                  label={filter.label}
                  value={filter.value}
                  sx={classes.filterMenu}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                    </InputAdornment>
                  }
                  onChange={handleChangeFilter}
                  displayEmpty
                >
                  <MenuItem
                    value=""
                    data-telemetry-name={`${filter.label}: All`}
                  >
                    <Typography>
                      <em>All</em>
                    </Typography>
                  </MenuItem>
                  {filter.options &&
                    filter.options.map((o, q) => (
                      <MenuItem
                        value={o}
                        key={q}
                        data-telemetry-name={`${filter.label}: ${o}`}
                      >
                        <Typography>{o}</Typography>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            );
          }

          if (!filter.datePicker && filter.typeahead) {
            return (
              <FormControl
                variant="outlined"
                key={i}
                sx={[
                  classes.formControl,
                  filter.hidden ? { display: "none" } : {},
                ]}
              >
                <Autocomplete
                  id="typeahead-filter"
                  sx={classes.filterMenu}
                  multiple={filter.multiselect}
                  freeSolo={filter.multiselect}
                  ListboxProps={{
                    style: { maxHeight: "200px", overflow: "auto" },
                  }}
                  onChange={(event, newValue) => {
                    handleChangeTypeaheadFilter(newValue, i);
                    setTypeaheadValue("");
                  }}
                  value={filter.value}
                  filterOptions={filterOptions}
                  options={handleTypeaheadFilter(filter.options)}
                  fullWidth
                  onInputChange={(event, newInput) => {
                    setTypeaheadValue(newInput);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      key={option}
                      component="li"
                      sx={{
                        fontStyle: filter.customizedValue?.includes(option)
                          ? "italic"
                          : "normal",
                        padding: theme.spacing(0.8, 2),
                      }}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={filter.label}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            );
          }
        })}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={() => handleDialog(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
