import React, { useState } from "react";

import { Box, Button, MenuItem, Select } from "@mui/material";
import { useApi } from "../../utils";
import {
  AssetLifecycleResponseDto,
  AssetsLifecycleManagementClient,
  WidgetContentResponseDto_1OfAssetLifecycleManagementWidgetResponseDto,
} from "@lib/ShiOneClient";
import { Devices } from "@mui/icons-material";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { RequestConsultationCardActions } from "@widgets/framework/RequestConsultationCardActions";
import {
  asDto,
  BaseWidgetProps,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
} from "shared-ui";

const termMonthsOptions = [
  { label: "Next 3 Months", value: 3 },
  { label: "Next 6 Months", value: 6 },
  { label: "Next 12 Months", value: 12 },
];

function transformFunction(
  rawData:
    | WidgetContentResponseDto_1OfAssetLifecycleManagementWidgetResponseDto
    | undefined
): ItemBoxConfiguration[] {
  return [
    {
      amount: rawData?.data?.assetsGoingEndOfLife,
      label: "Assets Going End Of Life",
      icon: <Devices color="secondary" fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.assetsGoingEndOfSale,
      label: "Assets Going End Of Sale",
      icon: <Devices color="secondary" fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.assetsGoingEndOfSoftwareMaintenance,
      label: "Assets Going End Of Software Maintenance",
      icon: <Devices color="secondary" fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.assetsGoingEndOfSupport,
      label: "Assets Going End Of Support",
      icon: <Devices color="error" fontSize={"large"} />,
    },
  ];
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    AssetsGoingEndOfLife: 0,
    assetsGoingEndOfSale: 0,
    assetsGoingEndOfSoftwareMaintenance: 0,
    assetsGoingEndOfSupport: 0,
  },
};

function CardActions() {
  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          href={AppendIfPrefixExists("/assets/product-catalog/lifecycle")}
        >
          View Lifecycle Timeline
        </Button>
        <div>
          <RequestConsultationCardActions
            applicationContext={"Assets Expiring"}
          />
        </div>
      </Box>
    </div>
  );
}

const AssetsLifecycleManagementWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  const [termMonths, setTermMonths] = useState(3);

  const headerAction = () => {
    return (
      <Select
        sx={{ height: "40px" }}
        labelId="TermSelector"
        id="TermSelector"
        value={termMonths}
        onChange={(event) => {
          setTermMonths(
            Number(event.target.value) ? Number(event.target.value) : 0
          );
        }}
        autoWidth
        displayEmpty
      >
        {termMonthsOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const useGetLifecycleManagementData = () => {
    const api = useApi(AssetsLifecycleManagementClient);
    return useKeyInformationData<AssetLifecycleResponseDto>({
      queryKey: ["assets-lifecycle-management-widget", `${termMonths}`],
      apiFunction: (params) => {
        const term = params.queryKey[1];
        return api.getAssetsLifecycleManagementWidgetData(term);
      },
      transformFunction: transformFunction,
      placeholderData: asDto(placeholderData),
    });
  };

  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetLifecycleManagementData}
      cardActions={CardActions()}
      headerAction={headerAction()}
    />
  );
};
export default AssetsLifecycleManagementWidget;
