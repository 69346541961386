import { homeItemsToShowType } from "../../NavItemsToShowTypes";

export default function CloudMonitoringMenu(itemsToShow: homeItemsToShowType) {
  return {
    id: "monitoring",
    type: "Link",
    displayName: "Monitoring",
    path: "/cloud/monitoring",
    visible: itemsToShow.showMonitoring,
  };
}
