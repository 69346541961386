import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  closestCenter,
  CollisionDetection,
  DndContext,
  getFirstCollision,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DroppableContainer } from "./framework/DroppableContainer";
import { Widget } from "./framework/Widget";
import { PageLayoutWidgetDto } from "./models/PageLayout";
import { PageLayoutDto } from "../generated/models";

export interface IWidget {
  id: UniqueIdentifier;
  enabled: boolean;
  title: string;
  widgetGroup: string;
}

type WidgetArray = {
  // @ts-ignore
  [column1: string]: IWidget[];
  // @ts-ignore
  [column2: string]: IWidget[];
};

function useSetColumnWidget(pageConfiguration: PageLayoutDto) {
  const [widgets, setWidgets] = useState<WidgetArray>({
    column1: [],
    column2: [],
  });

  useEffect(() => {
    if (pageConfiguration) {
      setWidgets(() => {
        const column1 = pageConfiguration.columns![0].widgets!;
        const column2 = pageConfiguration.columns![1].widgets!;

        const col1Widgets: IWidget[] = [];
        const col2Widgets: IWidget[] = [];

        for (const pageLayoutWidgetDto of column1) {
          col1Widgets.push({
            id: pageLayoutWidgetDto.widgetId ?? "ERROR",
            enabled: pageLayoutWidgetDto.enabled ?? false,
            title: pageLayoutWidgetDto.title ?? "ERROR",
            widgetGroup: pageLayoutWidgetDto.widgetGroup ?? "ERROR",
          });
        }
        for (const pageLayoutWidgetDto of column2) {
          col2Widgets.push({
            id: pageLayoutWidgetDto.widgetId ?? "ERROR",
            enabled: pageLayoutWidgetDto.enabled ?? false,
            title: pageLayoutWidgetDto.title ?? "ERROR",
            widgetGroup: pageLayoutWidgetDto.widgetGroup ?? "ERROR",
          });
        }
        return { column1: col1Widgets, column2: col2Widgets };
      });
    } else {
      setWidgets({
        column1: [],
        column2: [],
      });
    }
  }, [pageConfiguration.columns]);
  return {
    widgets,
    setWidgets,
  };
}

export default function DashboardConfiguration({
  pageConfiguration,
  setPageConfiguration,
}: {
  pageConfiguration: PageLayoutDto | undefined;
  setPageConfiguration: (pageLayoutDto: PageLayoutDto) => void;
}) {
  const { widgets, setWidgets } = useSetColumnWidget(pageConfiguration);
  const [clonedWidgets, setClonedWidgets] = useState<WidgetArray | null>(null);
  const [containers] = useState(Object.keys(widgets));

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  const [activeId, setActiveId] = useState(null);

  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    if (
      pageConfiguration &&
      (widgets["column1"].length > 0 || widgets["column2"].length > 0)
    ) {
      const column1 = widgets["column1"];
      const column2 = widgets["column2"];

      const col1Widgets: PageLayoutWidgetDto[] = [];
      const col2Widgets: PageLayoutWidgetDto[] = [];

      for (const widget of column1) {
        col1Widgets.push({
          widgetId: widget.id,
          enabled: widget.enabled,
          title: widget.title,
          widgetGroup: widget.widgetGroup,
        } as unknown as PageLayoutWidgetDto);
      }

      for (const widget of column2) {
        col2Widgets.push({
          widgetId: widget.id,
          enabled: widget.enabled,
          title: widget.title,
          widgetGroup: widget.widgetGroup,
        } as unknown as PageLayoutWidgetDto);
      }

      pageConfiguration.columns[0].widgets = col1Widgets;
      pageConfiguration.columns[1].widgets = col2Widgets;

      const newPageLayout: any = {
        pageLayoutId: pageConfiguration.pageLayoutId,
        columns: pageConfiguration.columns,
        widgetGroups: pageConfiguration.widgetGroups,
      };

      if (newPageLayout !== pageConfiguration) {
        setPageConfiguration(newPageLayout);
      }
    }
  }, [widgets.column1, widgets.column2]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in widgets) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in widgets
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in widgets) {
          const containerItems = widgets[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  // @ts-ignore
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, widgets]
  );

  const getContainer = (id: UniqueIdentifier) => {
    if (id in widgets) {
      return id;
    }

    return Object.keys(widgets).find((key) =>
      widgets[key].find((widget) => widget.id === id)
    );
  };
  const handleDragEnd = ({ active, over }) => {
    const activeContainer = getContainer(active.id);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      return;
    }

    const overContainer = getContainer(overId);

    if (overContainer) {
      const activeIndex = widgets[activeContainer].findIndex(
        (widget) => widget.id === active.id
      );
      const overIndex = widgets[overContainer].findIndex(
        (widget) => widget.id === over.id
      );

      if (activeIndex !== overIndex) {
        setWidgets((widgets) => ({
          ...widgets,
          [overContainer]: arrayMove(
            widgets[overContainer],
            activeIndex,
            overIndex
          ),
        }));
      }
    }
    setActiveId(null);
  };

  const dragOverHandler = ({ active, over }) => {
    const overId = over?.id;

    if (overId == null || active.id in widgets) {
      return;
    }

    const overContainer = getContainer(overId);
    const activeContainer = getContainer(active.id);

    if (!overContainer || !activeContainer) {
      console.log("Couldn't find an over or active container");
      return;
    }

    if (activeContainer !== overContainer) {
      setWidgets((widgets) => {
        const activeItems = widgets[activeContainer];
        const overItems = widgets[overContainer];

        const overIndex = overItems.findIndex(
          (widget) => widget.id === over.id
        );
        const activeIndex = activeItems.findIndex(
          (widget) => widget.id === active.id
        );

        let newIndex: number;

        if (overId in widgets) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        recentlyMovedToNewContainer.current = true;

        return {
          ...widgets,
          [activeContainer]: widgets[activeContainer].filter(
            (widget) => widget.id !== active.id
          ),
          [overContainer]: [
            ...widgets[overContainer].slice(0, newIndex),
            widgets[activeContainer][activeIndex],
            ...widgets[overContainer].slice(
              newIndex,
              widgets[overContainer].length
            ),
          ],
        };
      });
    }
  };

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
    setClonedWidgets(widgets);
  }

  const onDragCancel = () => {
    if (clonedWidgets) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setWidgets(clonedWidgets);
    }

    setActiveId(null);
    setClonedWidgets(null);
  };
  function toggleWidget(event: React.ChangeEvent<HTMLInputElement>) {
    if (pageConfiguration === undefined) {
      return;
    }

    let widget = pageConfiguration.columns![0].widgets!.find(
      (x) => x.widgetId === event.target.id
    );
    if (!widget) {
      widget = pageConfiguration.columns![1].widgets!.find(
        (x) => x.widgetId === event.target.id
      );
    }

    if (widget) {
      widget.enabled = event.target.checked;
      const clonedLocalPageConfiguration = _.cloneDeep(pageConfiguration);
      setPageConfiguration(clonedLocalPageConfiguration);
    }
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [widgets]);

  return (
    <div style={{ display: "flex", gap: "16px", flexDirection: "row" }}>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragOver={dragOverHandler}
        onDragCancel={onDragCancel}
      >
        <div
          style={{
            display: "flex",
            gap: "16px",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          {containers.map((key) => (
            <DroppableContainer
              key={key}
              id={key}
              items={widgets[key]}
              disabled={undefined}
            >
              <SortableContext
                items={widgets[key]}
                id={key}
                key={key}
                strategy={verticalListSortingStrategy}
              >
                {widgets[key].map((widget) => (
                  <Widget
                    key={widget.id}
                    widget={widget}
                    toggleWidget={toggleWidget}
                    disabled={false}
                  />
                ))}
              </SortableContext>
            </DroppableContainer>
          ))}
        </div>
      </DndContext>
    </div>
  );
}
