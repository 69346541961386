import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React, { Fragment } from "react";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { NavLinkStyles } from "../styles/NavigationStyles";
import { NavItemType } from "./NavigationTypes";
import { NavLink as RouterLink } from "react-router-dom";

interface NavLinkProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}
export default function NavLink({
  navItem,
  tier,
  parentContext,
}: NavLinkProps) {
  const theme = useTheme();
  const styles = NavLinkStyles(theme);

  if (!navItem.visible) {
    return <Fragment />;
  }

  return (
    <ListItemButton
      sx={[
        tier === 2 ? styles.nested : {},
        tier === 3 ? styles.nested2 : {},
        {
          "&.active": {
            bgcolor: "action.selected",
          },
        },
      ]}
      component={RouterLink}
      to={AppendIfPrefixExists(navItem.path)}
      target={navItem.target}
      data-telemetry-name={`${parentContext} > ${navItem.displayName}`}
    >
      {navItem.icon && (
        <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
      )}
      <ListItemText
        primary={navItem.displayName}
        sx={[tier === 1 ? styles.listItemText : {}]}
      />
    </ListItemButton>
  );
}
