import { Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import { shiContractType } from "@features/assets-feature/utils/assetsColors";
import shiColors from "shared-ui/src/theme/shiColors";
import { useLabsLogoStore } from "@widgets/utils/Logos/useLabsLogoStore";
import { AssetContract } from "@lib/ShiOneClient";
import { DateTime } from "luxon";
import { ImageResponse } from "@widgets/utils/Logos/LabsLogoService";
import { SkeletonWrapper } from "shared-ui";
import { AppendIfPrefixExists } from "../../../../../modules/sitePaths";
import { shiOneUrl } from "@constants";
import { NavLink } from "react-router-dom";

interface Props {
  contract: AssetContract | undefined;
  containerStyle?: React.CSSProperties;
  loading: boolean;
}

const calculateDays = (endDate: string | undefined): number => {
  if (!endDate) return -1;
  const parsedDate = Date.parse(endDate);
  if (isNaN(parsedDate)) return -1;

  const contractEndDate = DateTime.fromJSDate(new Date(parsedDate));
  const now = DateTime.local();
  return Math.floor(contractEndDate.diff(now, "days").days);
};

export const getContractTypeColor = (key: string): string => {
  const defaultValue = shiColors.pink.DEFAULT;
  return shiContractType[key] || defaultValue;
};

const AssetExpiringContractItem = ({
  contract,
  containerStyle,
  loading,
}: Props) => {
  const { logoService } = useLabsLogoStore();
  const [logo, setLogo] = useState<ImageResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const daysToExpiration = useMemo(
    () => calculateDays(contract?.earliestCoveredEndDate),
    [contract?.earliestCoveredEndDate]
  );

  useEffect(() => {
    if (!contract) return;
    const fetchLogo = async () => {
      if (contract?.contractVendor) {
        const image = await logoService.getLogoByCompanyName(
          contract.contractVendor
        );
        setLogo(image);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchLogo().then();
  }, [contract]);
  if (!contract) return <></>;
  return (
    <div style={containerStyle}>
      <Grid container>
        <Grid item xs={4}>
          <SkeletonWrapper loading={isLoading || loading}>
            <div style={{ display: "flex" }}>
              {logo ? (
                <img
                  src={logo.image}
                  alt={contract.contractVendor || ""}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "50px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Typography>
                  {contract?.contractVendor
                    ? contract?.contractVendor
                    : "Unknown"}
                </Typography>
              )}
              <Link
                component={NavLink}
                style={{ marginLeft: "1rem" }}
                to={AppendIfPrefixExists(shiOneUrl.assets.vendorContracts)}
                state={{ contract: contract?.contractId }}
              >
                {contract?.contractId}
              </Link>
            </div>
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
          <SkeletonWrapper loading={loading}>
            {contract?.shiContractType?.map((contract) => (
              <ColoredChips
                key={contract}
                bgColor={getContractTypeColor(contract)}
                label={contract}
              />
            ))}
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={4}>
          <SkeletonWrapper loading={loading}>
            {daysToExpiration > -1 && (
              <Typography
                align="right"
                color={"secondary.light"}
                variant="body2"
              >{`Expires in ${daysToExpiration} ${
                daysToExpiration === 1 ? "Day" : "Days"
              }`}</Typography>
            )}
          </SkeletonWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AssetExpiringContractItem;
