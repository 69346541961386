import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { AppendIfPrefixExists } from "../../modules/sitePaths";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutComponent = () => {
  const { logout, isAuthenticated } = useAuth0();
  logout({
    logoutParams: {
      returnTo: import.meta.env.AUTH0_LOGOUT_URL,
    },
  });

  //This is a workaround for the fact that Auth0 doesn't support "logout" functionality for unauthenticated users.
  if (!isAuthenticated) {
    localStorage.clear();
    return <Navigate to={AppendIfPrefixExists("/login")} />;
  }
  return <Loading />;
};
