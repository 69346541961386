import GlobalConstants from "@constants";

const ServiceRequestSubmissionType =
  GlobalConstants.ServiceRequestSubmissionType;
const SubmissionType = GlobalConstants.SubmissionType;

export const submissionTypeDisplayNameMap = new Map([
  [SubmissionType.FullInventory, GlobalConstants.ciscoReports.fullInventory],
  [SubmissionType.LifeCycle, GlobalConstants.ciscoReports.lifecycle],
  [SubmissionType.SiteLocation, GlobalConstants.ciscoReports.siteLocation],
  [
    SubmissionType.Ticket,
    GlobalConstants.serviceRequestForm.submissionType.ticket,
  ],
]);

export const submissionTypeIntMap = new Map([
  [SubmissionType.FullInventory, 1],
  [SubmissionType.LifeCycle, 2],
  [SubmissionType.SiteLocation, 3],
  [SubmissionType.Ticket, 4],
]);

export const mapSubmissionTypeToText = (submissionType) => {
  switch (submissionType) {
    case ServiceRequestSubmissionType.Submit:
      return "Submit";
    case ServiceRequestSubmissionType.SubmitForApproval:
      return "Submit for Approval";
    default:
      return "Submit";
  }
};

export const getAllApprovedMappingsForIssueAndSubIssueType = (
  serviceRequestMappings,
  issueType,
  subIssueType
) => {
  if (serviceRequestMappings && serviceRequestMappings.length > 0) {
    const issueTypeId = Number(issueType);
    const subIssueTypeId = Number(subIssueType);
    const matchingMappings = serviceRequestMappings.filter((mapping) => {
      return (
        mapping.approved &&
        mapping.enabledSubIssues &&
        mapping.issue === issueTypeId &&
        JSON.parse(mapping.enabledSubIssues).includes(subIssueTypeId)
      );
    });

    return matchingMappings;
  }

  return [];
};

export const getMappingForIssueAndSubIssueType = (
  serviceRequestMappings,
  issueType,
  subIssueType
) => {
  const matchingMappings = getAllApprovedMappingsForIssueAndSubIssueType(
    serviceRequestMappings,
    issueType,
    subIssueType
  );

  // Take the one with the largest revision number
  if (matchingMappings.length) {
    const matchingMapping = matchingMappings.sort(
      (a, b) => (b.revision ?? 0) - (a.revision ?? 0)
    )[0];
    return matchingMapping;
  }

  return null;
};
