import LinkBehavior from "./LinkBehavior.tsx";

const MuiButton = (theme, rootElement) => ({
  defaultProps: {
    LinkComponent: LinkBehavior,
    container: rootElement,
  },
  variants: [
    {
      props: { variant: "outlined", color: "inherit" },
      style: {
        border: "1px solid" + theme.palette.border,
        color: theme.palette.text.primary,
        "& .MuiButton-startIcon": {
          color: theme.palette.text.secondary,
        },
      },
    },
  ],
});
export default MuiButton;
