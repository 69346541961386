import { PieChartDto, PieChartSliceDto } from "../generated/models";
import { Summary } from "../widgets";
import React, { CSSProperties } from "react";
import LabelLink from "../components/LabelLink";
import { AppendIfPrefixExists } from "../sitePaths";
import { RequestsSearchParamAccessors } from "../../../customer-experience/src/v2/src/features/service-requests-feature/utils/constants";
import { graphColors } from "../theme/shiColors";

const SummaryLabelLinkStyles: CSSProperties = {
  display: "inline-flex",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  fontWeight: 400,
};

function getRequestsWidgetLabelLink(
  slice: PieChartSliceDto,
  defaultView: string,
  visualKey: string,
  dateFilterOption?: string,
  defaultTab?: string
) {
  return (
    <LabelLink
      label={slice.label ?? ""}
      baseUrl={AppendIfPrefixExists("/support-center/requests")}
      queryParams={{
        [RequestsSearchParamAccessors.view]: defaultView,
        [visualKey]: slice.label ?? "",
        ...(dateFilterOption && {
          [RequestsSearchParamAccessors.createdDate]: dateFilterOption,
        }),
      }}
      hashes={defaultTab ? [defaultTab] : []}
      css={SummaryLabelLinkStyles}
    />
  );
}

export function transformPieChartDtoWithLabelLink(
  data: PieChartDto | undefined,
  name: string,
  defaultView: string,
  visualKey: string,
  dateFilterOption?: string,
  defaultTab?: string
): Summary[] {
  if (!data || !data.slices) {
    return [];
  }

  const percentageLineData = data.slices.map((slice, index) => {
    const customLabelLink = getRequestsWidgetLabelLink(
      slice,
      defaultView,
      visualKey,
      dateFilterOption,
      defaultTab
    );
    return {
      name: name,
      data: slice.quantity ?? 0,
      label: slice.label ?? "",
      color: graphColors[index],
      customLabel: customLabelLink,
    };
  });

  return percentageLineData;
}

export function transformPieChartDto(
  data: PieChartDto | undefined,
  name: string
): Summary[] {
  if (!data || !data.slices) {
    return [];
  }

  const percentageLineData = data.slices.map((slice, index) => ({
    name: name,
    data: slice.quantity ?? 0,
    label: slice.label ?? "",
    color: graphColors[index],
  }));

  return percentageLineData;
}
