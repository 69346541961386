import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import components from "./overrides";
import shiPalette from "./shiPalette";

const paletteTheme = createTheme({
  palette: shiPalette,
});

const theme = createTheme(paletteTheme, {
  typography,
  components: components(paletteTheme),
  zIndex: {
    appBar: 1100,
    drawer: 1200,
  },
});

export default theme;
