import {
  ElasticDateRangeFilter,
  ServiceRequestSearchQuery,
  GetUniqueFilterValuesRequestBase,
  ServiceRequestBaseElasticQueryRequest,
  SortOrder,
  DateFilterOption,
} from "@lib/ShiOneClient";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { SortingOrder } from "../../../../../components/mui-data-grid/constants";
import { FilterOption } from "../../../interfaces/ToolbarInterfaces";
import { RequestsSearchParamAccessors } from "@features/service-requests-feature/utils/constants";

export const mapSortOrderToEnum = (
  sortOrderValue: string
): SortOrder | undefined => {
  if (sortOrderValue in SortOrder) {
    return SortOrder[sortOrderValue as keyof typeof SortOrder];
  }
  return undefined;
};

export const getServiceRequestGridDefinitions = (gridDefinitions: any[]) => {
  const columnDefinitions = gridDefinitions;
  const pinnedColumns = { left: ["title"] };
  const hiddenColumns: string[] = ["id"];
  const sortedColumns: GridSortModel = [
    { field: "createdDate", sort: SortingOrder.Descending },
  ];
  return {
    columnDefinitions,
    pinnedColumns,
    hiddenColumns,
    sortedColumns,
  };
};

export const setTermFiltersForRequest = (
  filters: FilterOption[],
  setRequestFilters: (requestFilters: Record<string, string>) => void
) => {
  const requestFilters = filters.reduce(
    (filter: any, currentFilter: FilterOption) => {
      if (
        currentFilter.value &&
        currentFilter.accessor &&
        !currentFilter.datePicker
      ) {
        filter[currentFilter.accessor] = currentFilter.value;
      }
      return filter;
    },
    {} as Record<string, string>
  );

  setRequestFilters({ ...requestFilters });
};

export const setDateFiltersForRequest = (
  filters: FilterOption[],
  setRequestFilters: (
    requestFilters: Record<string, ElasticDateRangeFilter>
  ) => void
) => {
  const requestFilters = filters.reduce(
    (filter: any, currentFilter: FilterOption) => {
      if (
        currentFilter.accessor &&
        currentFilter.datePicker &&
        (currentFilter.startDate || currentFilter.endDate)
      ) {
        filter[currentFilter.accessor] = {
          startDate: currentFilter.startDate,
          endDate: currentFilter.endDate,
        };
      }
      return filter;
    },
    {} as Record<string, ElasticDateRangeFilter>
  );
  setRequestFilters(requestFilters);
};

export const sortOptions = (options: any[]) => {
  return options.slice().sort((a, b) => a.localeCompare(b));
};

// Creates a request to get filter options for our current data set.
// Make sure this request matches the tables initial settings (ie, sort order, sort field and other filters)
export function createUniqueFilterRequest(
  maxSize: number | undefined,
  serviceRequestQuery: ServiceRequestBaseElasticQueryRequest,
  sortBy: string,
  sortOrder: string
) {
  const uniqueFilterValuesRequest = new GetUniqueFilterValuesRequestBase();
  uniqueFilterValuesRequest.query = new ServiceRequestSearchQuery();
  uniqueFilterValuesRequest.query.resultsFrom = 0;
  uniqueFilterValuesRequest.query.pageSize = maxSize;
  uniqueFilterValuesRequest.query.sortBy = sortBy;
  uniqueFilterValuesRequest.query.sortOrder = mapSortOrderToEnum(sortOrder);
  uniqueFilterValuesRequest.serviceRequestQueryType =
    serviceRequestQuery.serviceRequestQueryType;

  return uniqueFilterValuesRequest;
}

export function getDateRangeForFilter(option: string) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  switch (option) {
    case DateFilterOption.CurrentMonth: {
      const startDate = new Date(currentYear, currentMonth, 1);
      const endDate = new Date(currentYear, currentMonth + 1, 0);
      return { startDate, endDate };
    }
    case DateFilterOption.LastMonth: {
      const startDate = new Date(currentYear, currentMonth - 1, 1);
      const endDate = new Date(currentYear, currentMonth, 0);
      return { startDate, endDate };
    }
    case DateFilterOption.CurrentQuarter: {
      const currentQuarter = Math.floor(currentMonth / 3);
      const startDate = new Date(currentYear, currentQuarter * 3, 1);
      const endDate = new Date(currentYear, (currentQuarter + 1) * 3, 0);
      return { startDate, endDate };
    }
    case DateFilterOption.LastQuarter: {
      const currentQuarter = Math.floor(currentMonth / 3);
      const startDate = new Date(currentYear, (currentQuarter - 1) * 3, 1);
      const endDate =
        currentMonth === 0
          ? new Date(currentYear - 1, 9, 0)
          : new Date(currentYear, currentQuarter * 3, 0);
      return { startDate, endDate };
    }
    case DateFilterOption.YearToDate: {
      const startDate = new Date(currentYear, 0, 1);
      const endDate = now;
      return { startDate, endDate };
    }
    case DateFilterOption.LastYear: {
      const startDate = new Date(currentYear - 1, 0, 1);
      const endDate = new Date(currentYear - 1, 11, 31);
      return { startDate, endDate };
    }
    default:
      return {
        startDate: new Date(currentYear, currentMonth, 1),
        endDate: new Date(currentYear, currentMonth + 1, 0),
      };
  }
}

export const mergeInitialFiltersWithCreatedDateRangeSearchQuery = (
  initialFilters: FilterOption[],
  urlSearchParams: Record<string, string>
): FilterOption[] => {
  return initialFilters.map((filter) => {
    if (
      filter.datePicker &&
      urlSearchParams[RequestsSearchParamAccessors.createdDate] &&
      filter.accessor === RequestsSearchParamAccessors.createdDate
    ) {
      const { startDate, endDate } = getDateRangeForFilter(
        urlSearchParams[RequestsSearchParamAccessors.createdDate]
      );

      return {
        ...filter,
        startDate: startDate,
        endDate: endDate,
      };
    }

    return {
      ...filter,
      value: urlSearchParams[filter.accessor] ?? filter.value,
    };
  });
};
