import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import GlobalConstants from "@constants";
import { ServiceRequestDTO } from "lib/ShiOneClient";
import { AppDispatch, AppThunk } from "../../../store";

import { getRestSecondaryTicketContactsById } from "../../../api/ticketStore";
import { putRestUpdateServiceRequestById } from "../../../api/serviceRequestStore";

const initialState: any = {
  secondaryContactsFetchState: GlobalConstants.fetchStatus.idle,
  secondaryContacts: [],
  currentServiceRequest: ServiceRequestDTO,
  updatingServiceRequestFetchStatus: GlobalConstants.fetchStatus.idle,
  communicationZIndex: 2,
  subjectZIndex: 2,
};

const serviceRequestViewSlice = createSlice({
  name: "serviceRequestView",
  initialState,
  reducers: {
    fetchingSecondaryContacts(state) {
      state.secondaryContactsFetchState = GlobalConstants.fetchStatus.loading;
    },
    successFetchingSecondaryContacts(state, action: PayloadAction<any>) {
      state.secondaryContactsFetchState = GlobalConstants.fetchStatus.complete;
      state.secondaryContacts = action.payload;
    },
    errorFetchingSecondaryContacts(state) {
      state.secondaryContactsFetchState = GlobalConstants.fetchStatus.error;
    },
    setCurrentServiceRequest(state, action: PayloadAction<ServiceRequestDTO>) {
      state.currentServiceRequest = action.payload;
    },
    resetState(state) {
      state = initialState;
    },
    bringCommunicationToFront(state) {
      state.communicationZIndex = 3;
      state.subjectZIndex = 2;
    },
    bringSubjectToFront(state) {
      state.communicationZIndex = 2;
      state.subjectZIndex = 3;
    },
    serviceRequestUpdateInTransit(state) {
      state.updatingServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    successUpdatingServiceRequest(state) {
      state.updatingServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    errorUpdatingServiceRequest(state) {
      state.updatingServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.error;
    },
    serviceRequestUpdateIdle(state) {
      state.updatingServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.idle;
    },
  },
});

export const getSecondaryContacts =
  (autotaskTicketId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestViewSlice.actions.fetchingSecondaryContacts());
    try {
      const secondaryContacts = await getRestSecondaryTicketContactsById(
        autotaskTicketId
      );
      dispatch(
        serviceRequestViewSlice.actions.successFetchingSecondaryContacts(
          secondaryContacts
        )
      );
    } catch (e) {
      dispatch(
        serviceRequestViewSlice.actions.errorFetchingSecondaryContacts()
      );
    }
  };

export const setCurrentServiceRequest =
  (serviceRequest: ServiceRequestDTO): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      serviceRequestViewSlice.actions.setCurrentServiceRequest(serviceRequest)
    );
  };

export const resetServiceRequestViewState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(serviceRequestViewSlice.actions.resetState);
  };

export const bringCommunicationToFront =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(serviceRequestViewSlice.actions.bringCommunicationToFront());
  };

export const bringSubjectToFront =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(serviceRequestViewSlice.actions.bringSubjectToFront());
  };

export const updateServiceRequest =
  (updatedServiceRequest: ServiceRequestDTO): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestViewSlice.actions.serviceRequestUpdateInTransit());
    try {
      let newServiceRequest = await putRestUpdateServiceRequestById(
        updatedServiceRequest
      );
      dispatch(serviceRequestViewSlice.actions.successUpdatingServiceRequest());
      if (newServiceRequest.serviceRequests) {
        dispatch(
          serviceRequestViewSlice.actions.setCurrentServiceRequest(
            newServiceRequest.serviceRequests[0]
          )
        );
      }
    } catch {
      dispatch(serviceRequestViewSlice.actions.errorUpdatingServiceRequest());
    }
    setTimeout(() => {
      dispatch(serviceRequestViewSlice.actions.serviceRequestUpdateIdle());
    }, 1500);
  };
export default serviceRequestViewSlice.reducer;
