import { Grid } from "@mui/material";
import React from "react";
import { DashboardColumn } from "./DashboardColumn";
import { WidgetRegistry } from "../../widgets/models/WidgetRegistry";
import { PageLayoutDto } from "../../generated/models";

export function getColumnWidgetsFromPage(
  page: PageLayoutDto,
  position: number
) {
  if (page.columns && page.columns.length > position) {
    return page.columns[position];
  }
  return undefined;
}

export function TwoColumnLayout({
  pageConfiguration,
  widgetRegistry,
}: {
  pageConfiguration: PageLayoutDto;
  widgetRegistry: WidgetRegistry;
}) {
  if (!pageConfiguration || pageConfiguration.widgetGroups?.length === 0) {
    return <></>;
  }

  const column1Widgets = getColumnWidgetsFromPage(pageConfiguration, 0);
  const column2Widgets = getColumnWidgetsFromPage(pageConfiguration, 1);

  return (
    <Grid container spacing={3}>
      <DashboardColumn
        column={column1Widgets}
        width={6}
        key={"foo"}
        widgetRegistry={widgetRegistry}
      />
      <DashboardColumn
        column={column2Widgets}
        width={6}
        key={"foo1"}
        widgetRegistry={widgetRegistry}
      />
    </Grid>
  );
}
