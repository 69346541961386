import { useGetUserProfile } from "../hooks/useGetUserProfile";
import {
  telemetryBeginUserWithUserProfile,
  telemetryEndUser,
} from "../../../functions/telemetry";
import { useAuth0 } from "@auth0/auth0-react";

export function UserTelemetry() {
  const { isAuthenticated } = useAuth0();

  const userProfile = useGetUserProfile().data;

  if (isAuthenticated && userProfile) {
    telemetryBeginUserWithUserProfile(userProfile);
  }

  if (!isAuthenticated) {
    telemetryEndUser();
  }

  return <></>;
}
