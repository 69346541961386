import React from "react";
import { ContractDetailDialogTitle } from "@features/projects-feature/components/ContractDetailDialogTitle";
import { ContractDetailDialogContent } from "@features/projects-feature/components/ContractDetailDialogContent";
import { ContractDetailDialogContainer } from "@features/projects-feature/components/ContractDetailDialogContainer";
import { ContractDetailsDialogProps } from "@features/projects-feature/projectTypes";
import { Button } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

export const ContractDetailsDialog = ({
  open,
  onClose,
  contract,
  picklist,
}: ContractDetailsDialogProps) => {
  if (!contract) return null;

  return (
    <ContractDetailDialogContainer open={open} onClose={onClose}>
      <ContractDetailDialogTitle onClose={onClose} />
      <ContractDetailDialogContent contract={contract} picklist={picklist} />
      <DialogActions>
        <Button onClick={onClose} size={"small"}>
          OK
        </Button>
      </DialogActions>
    </ContractDetailDialogContainer>
  );
};
