import React, { useEffect, useState } from "react";
import { TextField, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { contactInformationFieldStyles } from "./contactInformationFieldStyles";
import { IRootState } from "../../modules";

export interface ContactInformation {
  contactFirstName?: string;
  contactLastName?: string;
  email?: string;
  phoneNumber?: string;
}

export interface CurrentCustomerContactInformationFieldProperties {
  required: boolean;
  onChange: (formData: ContactInformation) => void;
  formData: ContactInformation | undefined;
}

export default function CurrentCustomerContactInformationField(
  props: CurrentCustomerContactInformationFieldProperties,
) {
  const theme = useTheme();
  const classes = contactInformationFieldStyles(theme);
  const { required, onChange, formData } = props;
  const [initialDataSet, setInitialDataSet] = useState(false);
  const currentContact = useSelector(
    (state: IRootState) =>
      state.profile.userProfileResponse.userProfile?.contact,
  );

  const [hasPhoneError, setPhoneError] = useState(false);

  useEffect(() => {
    if (!initialDataSet && currentContact) {
      const initialData = {
        contactFirstName:
          currentContact.firstName || formData?.contactFirstName || "",
        contactLastName:
          currentContact.lastName || formData?.contactLastName || "",
        email: currentContact.eMailAddress || formData?.email || "",
        phoneNumber: currentContact.phone || formData?.phoneNumber || "",
      };
      onChange(initialData);
      // Validate our initial phone number.
      initialData.phoneNumber && validatePhoneNumber(initialData.phoneNumber);
      setInitialDataSet(true);
    }
  }, [onChange, initialDataSet, currentContact, formData]);

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const {
      target: { value },
    } = event;
    const newFirstName = { ...formData, contactFirstName: value };
    onChange(newFirstName);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const {
      target: { value },
    } = event;
    const newLastName = { ...formData, contactLastName: value };
    onChange(newLastName);
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const {
      target: { value },
    } = event;
    const newEmail = { ...formData, email: value };
    onChange(newEmail);
  };

  function validatePhoneNumber(phoneNumber: string) {
    const phoneNumberRegex =
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (!phoneNumberRegex.test(phoneNumber)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }

  const handlePhoneChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const {
      target: { value },
    } = event;

    validatePhoneNumber(value);

    const newContactInfo = {
      ...formData,
      phoneNumber: value,
    };
    onChange(newContactInfo);
  };

  return (
    <>
      <div>
        <TextField
          sx={classes.root}
          label="Contact First Name"
          inputProps={{ "aria-label": "contactFirstName" }}
          margin="normal"
          fullWidth
          required={required}
          value={formData?.contactFirstName ? formData?.contactFirstName : ""}
          onChange={handleFirstNameChange}
        />
      </div>
      <div>
        <TextField
          sx={classes.root}
          label="Contact Last Name"
          name="contactLastName"
          inputProps={{ "aria-label": "contactLastName" }}
          margin="normal"
          fullWidth
          required={required}
          value={formData?.contactLastName ? formData?.contactLastName : ""}
          onChange={handleLastNameChange}
        />
      </div>
      <div>
        <TextField
          sx={classes.root}
          fullWidth
          label="Email Address"
          inputProps={{ "aria-label": "emailAddress" }}
          required={required}
          value={formData?.email ? formData?.email : ""}
          onChange={handleEmailChange}
          margin="normal"
        />
      </div>
      <div>
        <TextField
          sx={hasPhoneError ? classes.error : classes.root}
          error={hasPhoneError}
          fullWidth
          label="Phone Number"
          inputProps={{ "aria-label": "phoneNumber" }}
          required={required}
          helperText={
            hasPhoneError
              ? "required and must be a valid US phone number with area code"
              : ""
          }
          value={formData?.phoneNumber ? formData.phoneNumber : ""}
          onChange={handlePhoneChange}
          margin="normal"
        />
      </div>
    </>
  );
}
