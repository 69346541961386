import { PaletteMode } from "@mui/material";

const shiPalette = {
  mode: "light" as PaletteMode,
  primary: {
    main: "#1976D2",
    dark: "#1565C0",
    light: "#42A5F5",
    contrastText: "#FFF",
    "4p": "#1976D20A",
    "8p": "#1976D214",
    "12p": "#1976D21F",
    "30p": "#1976D24D",
    "50p": "#1976D280",
  },
  secondary: {
    main: "#FF6D15",
    dark: "#E65100",
    light: "#FF9800",
    contrastText: "#FFF",
    "4p": "#FF6D150A",
    "8p": "#FF6D1514",
    "12p": "#FF6D151F",
    "30p": "#FF6D154D",
    "50p": "#FF6D1580",
  },
  text: {
    primary: "#000000DE",
    secondary: "#0000008A",
    disabled: "#00000061",
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    light: "#EF5350",
    contrastText: "#FFF",
    "4p": "#D32F2F0A",
    "12p": "#D32F2F1F",
    "30p": "#D32F2F4D",
    "50p": "#D32F2F80",
    "160p": "#541313",
    "190p": "#fbeaea",
  },
  warning: {
    main: "#ED6C02",
    dark: "#E65100",
    light: "#FF9800",
    contrastText: "#FFF",
    "4p": "#ED6C020A",
    "12p": "#ED6C021F",
    "30p": "#ED6C024D",
    "50p": "#ED6C0280",
    "160p": "#5f2b01",
    "190p": "#fdf0e6",
  },
  info: {
    main: "#0288D1",
    dark: "#01579B",
    light: "#03A9F4",
    contrastText: "#FFF",
    "4p": "#0288D10A",
    "12p": "#0288D11F",
    "30p": "#0288D14D",
    "50p": "#0288D180",
    "160p": "#013654",
    "190p": "#e6f3fa",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
    light: "#4CAF50",
    contrastText: "#FFF",
    "4p": "#2E7D320A",
    "12p": "#2E7D321F",
    "30p": "#2E7D324D",
    "50p": "#2E7D3280",
    "160p": "#123214",
    "190p": "#eaf2ea",
  },
  background: {
    default: "#fafafa",
    dark: "#E4E9ED",
    paper: "#FFFFFF",
  },
  table: {
    border: "#D0D0D0",
    header: "#f8f9fa",
    contrastText: "#000000DE",
  },
  action: {
    active: "#0000008A",
    hover: "#0000000A",
    selected: "#00000014",
    disabled: "#00000042",
    disabledBackground: "#0000001F",
    focus: "#0000001F",
  },
  border: "#E0E0E0",
  inputLine: "#0000006B",
  ratingActive: "#FFB400",
  divider: "#0000001F",
};

export default shiPalette;
