import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import { ServiceRequestMappingState } from "./serviceRequestMappingTypes";
import { ServiceRequestMapping } from "lib/ShiOneClient";
import GlobalConstants from "@constants";
import { getRestServiceRequestMappings } from "../../../api/serviceRequestMappingStore";

const initialState: ServiceRequestMappingState = {
  serviceRequestMappingCreateStatus: GlobalConstants.fetchStatus.idle,
  serviceRequestMappingFetchStatus: GlobalConstants.fetchStatus.idle,
  serviceRequestMappingUpdateStatus: GlobalConstants.fetchStatus.idle,
  serviceRequestMappingDeleteStatus: GlobalConstants.fetchStatus.idle,
  serviceRequestMappings: [],
};

const serviceRequestMappingSlice = createSlice({
  name: "serviceRequestMapping",
  initialState,
  reducers: {
    receivingServiceRequestMappings(state) {
      state.serviceRequestMappingFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receiveServiceRequestMappings(
      state,
      action: PayloadAction<ServiceRequestMapping[]>
    ) {
      state.serviceRequestMappingFetchStatus =
        GlobalConstants.fetchStatus.complete;
      state.serviceRequestMappings = action.payload;
    },
    receiveServiceRequestMappingsError(state) {
      state.serviceRequestMappingFetchStatus =
        GlobalConstants.fetchStatus.error;
    },
    creatingServiceRequestMapping(state) {
      state.serviceRequestMappingCreateStatus =
        GlobalConstants.fetchStatus.loading;
    },
    createServiceRequestMapping(
      state,
      action: PayloadAction<ServiceRequestMapping[]>
    ) {
      state.serviceRequestMappingCreateStatus =
        GlobalConstants.fetchStatus.complete;
      state.serviceRequestMappings = action.payload;
    },
    createServiceRequestMappingError(state) {
      state.serviceRequestMappingCreateStatus =
        GlobalConstants.fetchStatus.error;
    },
    updatingServiceRequestMapping(state) {
      state.serviceRequestMappingUpdateStatus =
        GlobalConstants.fetchStatus.loading;
    },
    updateServiceRequestMapping(
      state,
      action: PayloadAction<ServiceRequestMapping[]>
    ) {
      state.serviceRequestMappingUpdateStatus =
        GlobalConstants.fetchStatus.complete;
      state.serviceRequestMappings = action.payload;
    },
    updateServiceRequestMappingError(state) {
      state.serviceRequestMappingUpdateStatus =
        GlobalConstants.fetchStatus.error;
    },
    deletingServiceRequestMapping(state) {
      state.serviceRequestMappingDeleteStatus =
        GlobalConstants.fetchStatus.loading;
    },
    deleteServiceRequestMapping(
      state,
      action: PayloadAction<ServiceRequestMapping>
    ) {
      const deleteIndex = state.serviceRequestMappings.indexOf(action.payload);
      state.serviceRequestMappings.splice(deleteIndex, 1);
      state.serviceRequestMappingDeleteStatus =
        GlobalConstants.fetchStatus.complete;
    },
    deleteServiceRequestMappingError(state) {
      state.serviceRequestMappingDeleteStatus =
        GlobalConstants.fetchStatus.error;
    },
  },
});

export const getServiceRequestMappings =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(
      serviceRequestMappingSlice.actions.receivingServiceRequestMappings()
    );
    try {
      const serviceRequestMappings = await getRestServiceRequestMappings();

      dispatch(
        serviceRequestMappingSlice.actions.receiveServiceRequestMappings(
          serviceRequestMappings
        )
      );
    } catch {
      dispatch(
        serviceRequestMappingSlice.actions.receiveServiceRequestMappingsError()
      );
    }
  };

export default serviceRequestMappingSlice.reducer;
