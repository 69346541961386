import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { FormControl, TextField, useTheme } from "@mui/material";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
});
export default function AWSAccountEmailWidget({
  formContext,
  onChange,
  disabled,
}) {
  const theme = useTheme();
  const classes = css(theme);

  const [awsAccountEmail, setAwsAccountEmail] = useState("");

  useEffect(() => {
    if (!formContext?.awsAccountDetailsDefinition) return;

    if (formContext.awsAccountDetailsDefinition.awsAccountEmail) {
      setAwsAccountEmail(
        formContext.awsAccountDetailsDefinition.awsAccountEmail
      );
      return;
    }

    setAwsAccountEmail("");
    onChange("");
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.awsAccountDetailsDefinition?.awsCustomerPayerName]);

  function updateAwsAccountEmail(value) {
    setAwsAccountEmail(value);
    onChange(value);
  }

  useEffect(() => {
    if (awsAccountEmail !== null) {
      onChange(awsAccountEmail);
    }
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsAccountEmail]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <TextField
              sx={classes.root}
              label=" AWS Account Email"
              disabled={disabled}
              name="awsAccountEmail"
              id="awsAccountEmail"
              value={awsAccountEmail}
              type="email"
              onChange={(e) => updateAwsAccountEmail(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
