import React from "react";
import { FieldProps } from "@rjsf/utils";
import { UnitedStatesSelector } from "../../components/UnitedStatesSelector";
import {
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { NameAndAbbreviation } from "../../components/ComponentData/USStatesAndTerritories";

export const StreetAddressField = "streetAddress";
export const CityField = "city";
export const StateField = "state";
export const ZipCodeField = "zipCode";
export const ZipCodeRegex = "^\\b\\d{5}\\b(?:[\\- ]{1}\\d{4})?$";
const zipFormatErrorMessage = "Must be formatted as a valid Zip Code";

const css = (theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  grow: {
    flexGrow: 1,
  },
  componentHeader: {
    marginBottom: theme.spacing(2),
  },
});

export const UnitedStatesAddressField = (props: FieldProps) => {
  const theme = useTheme();
  const classes = css(theme);

  function updateField(value: string, field: string) {
    props.onChange({
      ...props.formData,
      [field]: value,
    });
  }

  function onInvalidZipCode(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.setCustomValidity(zipFormatErrorMessage);
  }

  function resetInvalidZipCode(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.setCustomValidity("");
  }

  return (
    <>
      {props.schema?.header && (
        <>
          <Typography variant="h5">{props.schema?.header}</Typography>
          <Divider sx={classes.componentHeader} />
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            sx={classes.root}
            label="Street Address"
            value={props.formData?.streetAddress}
            required={props.required}
            fullWidth
            name={StreetAddressField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(e.target.value, StreetAddressField);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={classes.root}
            label="City"
            value={props.formData?.city}
            required={props.required}
            fullWidth
            name={CityField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(e.target.value, CityField);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UnitedStatesSelector
            sx={classes.root}
            id={"state-select"}
            labelId="state-select"
            label="State"
            value={props.formData?.state}
            onChange={(stateData: NameAndAbbreviation) => {
              updateField(stateData?.abbreviation, StateField);
            }}
            key={props.name}
            required={props.required}
            onBlur={function (): void {}}
            onFocus={function (): void {}}
            name={StateField}
            schema={props.schema}
            idSchema={props.idSchema}
            registry={props.registry}
            disabled={props.disabled}
            readonly={props.readonly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={classes.root}
            label="Zip Code"
            value={props.formData?.zipCode}
            required={props.required}
            fullWidth
            inputProps={{ pattern: ZipCodeRegex }}
            name={ZipCodeField}
            onInvalid={onInvalidZipCode}
            onInput={resetInvalidZipCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(e.target.value, ZipCodeField);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
