import { UserConfigDto, UserSettingsClient } from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";

export type ConfigKey = {
  entityId: string;
  settingsKey: string;
};

const api = new UserSettingsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function postUserConfig(userConfig: UserConfigDto): Promise<void> {
  return api.postUserConfig(userConfig);
}

export async function deleteUserConfig(
  userConfig: UserConfigDto
): Promise<void> {
  return api.deleteUserConfig(userConfig);
}

export async function getUserConfigs(): Promise<UserConfigDto[]> {
  return api.getUserConfigs();
}
