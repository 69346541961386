import { useApi } from "../../utils";
import {
  DateFilterOption,
  RequestsMetricsWidgetClient,
  WidgetContentResponseDto_1OfRequestMetricsVisualsDto,
} from "@lib/ShiOneClient";

import { SpeedOutlined } from "@mui/icons-material";
import React from "react";
import { Button, Rating } from "@mui/material";
import { DateFilterOptionHeaderAction } from "@widgets/utils/support/DateFilterOptionHeaderAction";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { isLoading } from "@widgets/utils/widgetFunctions";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import {
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
  colorSeverities,
  asDto,
  BaseWidgetProps,
} from "shared-ui";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    averageRating: 0.0,
    firstResponseMetPercentage: 2.998500749625187,
    firstResponseNotMetCount: 647,
  },
};

function getFirstResponseMetPercentage(
  firstResponseMetPercentage: number | undefined
): string {
  return firstResponseMetPercentage
    ? Math.round(firstResponseMetPercentage) + "%"
    : "0%";
}

function getFirstResponseMetColor(value: number | undefined): string {
  if (value === undefined) {
    return colorSeverities.BadCritical;
  }

  if (value >= 95) {
    return colorSeverities.GoodLow;
  }

  if (value >= 90) {
    return colorSeverities.Medium;
  }

  return colorSeverities.BadCritical;
}

function getAverageRatingValue(value: number | undefined): string {
  if (value === undefined) {
    return "--";
  }

  return value.toFixed(2);
}

function AverageRatingIcon({ value }: { value: number | undefined }) {
  return (
    <Rating
      size={"medium"}
      name="simple-controlled"
      value={value}
      readOnly
      sx={{ height: 35 }}
    />
  );
}

function getFirstResponseNotMetAmount(value: number | undefined): string {
  if (value === undefined) {
    return "--";
  }

  if (value === 0) {
    return "0 Requests";
  }

  if (value === 1) {
    return value.toString() + " Request";
  }

  return value.toString() + " Requests";
}

function getFirstResponseNotMetColor(value: boolean | undefined): string {
  if (value === undefined) {
    return "";
  }

  if (value) {
    return colorSeverities.BadCritical;
  }

  return colorSeverities.GoodLow;
}
function transformFunction(
  rawData: WidgetContentResponseDto_1OfRequestMetricsVisualsDto | undefined,
  dateFilter: DateFilterOption
): ItemBoxConfiguration[] {
  return [
    {
      amount: getAverageRatingValue(rawData?.data?.averageRating),
      label: "Average Rating",
      icon: <AverageRatingIcon value={rawData?.data?.averageRating} />,
    },
    {
      amount: getFirstResponseMetPercentage(
        rawData?.data?.firstResponseMetPercentage
      ),
      label: "First Response Met",
      icon: (
        <SpeedOutlined
          fontSize={"large"}
          sx={{
            color: getFirstResponseMetColor(
              rawData?.data?.firstResponseMetPercentage
            ),
          }}
        />
      ),
    },
    {
      amount: getFirstResponseNotMetAmount(
        rawData?.data?.firstResponseNotMetCount
      ),
      label: "First Response Not Met",
      icon: (
        <SpeedOutlined
          fontSize={"large"}
          sx={{
            color: getFirstResponseNotMetColor(
              rawData?.data?.firstResponseNotMetWarning
            ),
          }}
        />
      ),
      tileActions:
        (rawData?.data?.firstResponseNotMetCount ?? 0) > 0 ? (
          <Button
            href={AppendIfPrefixExists(
              `/support-center/requests?view=${prebuiltViewNames.allRequests}&CreatedDate=${dateFilter}&FirstResponseMet=false`
            )}
          >
            View Requests
          </Button>
        ) : (
          <></>
        ),
    },
  ];
}

const filterKey = "request-metrics-widget";

function useGetRequestMetrics(dateFilterOption: DateFilterOption) {
  const api = useApi(RequestsMetricsWidgetClient);

  return useKeyInformationData({
    queryKey: [filterKey, dateFilterOption],
    apiFunction: () => api.getRequestMetrics(dateFilterOption),
    transformFunction: (rawData) =>
      transformFunction(rawData, dateFilterOption),
    placeholderData: asDto(placeholderData),
  });
}

export function RequestMetricsWidget(props: BaseWidgetProps) {
  const initialDateFilter = DateFilterOption.CurrentMonth;

  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      props.pageLayoutWidget.widgetId!,
      filterKey,
      initialDateFilter
    );

  const responseFunc = useGetRequestMetrics(currentFilterValue);

  const isLoadingData = isLoading([
    isLoadingFilterUserConfig,
    responseFunc.isPlaceholderData,
  ]);

  return (
    <KeyInformationWidget
      headerAction={
        <DateFilterOptionHeaderAction
          filterBy={currentFilterValue}
          setFilterBy={setFilter}
          loading={isLoadingData}
          filterKey={filterKey}
        />
      }
      pageLayoutWidget={props.pageLayoutWidget}
      useQueryFunction={() => responseFunc}
    />
  );
}
