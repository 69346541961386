import React from "react";
import {
  Card,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  styled,
} from "@mui/material";
import { twoLineHeightCell } from "../v2/src/theme/mixins/lineHeight";

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#BDBDBD",
    borderTopWidth: 1.2,
  },
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  "& .MuiStepLabel-iconContainer": {
    color: theme.palette.text.disabled,
    "& .Mui-active": { color: theme.palette.secondary.light },
    "& .Mui-completed": { color: theme.palette.secondary.main },
    svg: { width: "1.8rem", height: "1.8rem" },
  },
  "& .MuiStepLabel-label": {
    fontWeight: 500,
    color: theme.palette.text.primary,
    ...twoLineHeightCell,
  },
}));

function ProgressStepper({
  activeStep,
  children,
}: {
  readonly activeStep: number;
  readonly children: React.ReactNode;
}) {
  return (
    <Card sx={{ width: "100%" }}>
      <StyledStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StyledStepConnector />}
      >
        {children}
      </StyledStepper>
    </Card>
  );
}

export default ProgressStepper;
