import { Button, Theme, Typography, Box } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const NoTableDataStyles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  fade: {
    opacity: 0.5,
    width: "100%",
    display: "flex",
    minHeight: theme.spacing(40),
  },
  grow: {
    flexGrow: 1,
  },
  fifty: {
    width: "50%",
  },
});
const NoTableData = ({
  handleTableReset,
  hideSuggestions,
  noDataMessage,
  customSuggestions,
}: {
  handleTableReset?: () => void;
  hideSuggestions?: boolean;
  noDataMessage?: string;
  customSuggestions?: React.ReactNode;
}) => {
  const theme = useTheme();
  const styles = NoTableDataStyles(theme);

  return (
    <>
      <Typography component="div" sx={[styles.root, styles.fade]}>
        <Box sx={styles.grow}>
          <Typography component="div" variant="h4" gutterBottom>
            {noDataMessage ??
              "Sorry no items matching your criteria were found in the report"}
          </Typography>
          {!hideSuggestions && !customSuggestions ? (
            <Typography component="div" variant="caption" sx={styles.fifty}>
              <Typography component="div" gutterBottom>
                Suggestions:
              </Typography>
              <Typography component="ul">
                <li>Try adjusting the filters at the top of the page.</li>
                <li>Check your spelling.</li>
                <li>Try a more general word to search.</li>
              </Typography>
            </Typography>
          ) : (
            customSuggestions
          )}
          {handleTableReset && (
            <Button
              style={{ marginTop: "1rem" }}
              variant={"contained"}
              onClick={handleTableReset}
            >
              Reset Search
            </Button>
          )}
        </Box>
      </Typography>
    </>
  );
};
export default NoTableData;
