import { ApiClientConfig } from "../auth/ApiClientConfig";
import { ArchbeeClient } from "../lib/ShiOneClient";

const api = new ArchbeeClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

async function fetchUrl(): Promise<string> {
  return api.getArchbeeLink();
}

export async function navigateToInternalArchbee() {
  const url = await fetchUrl();
  window.open(url, "docsoneshi");
}
