import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { GlobalConstants } from "../../constants";
import {
  CustomerExperienceIcon,
  CustomerSuccessIcon,
} from "../ModuleInitialsIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Link,
  Menu,
  MenuItem,
  styled,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ReactNode, useState } from "react";

interface Module {
  moduleName: string;
  moduleIcon: ReactNode;
  url: string;
}

const StyleContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  "& div": {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
  },
});
const css = (theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderBottom: "1px solid" + theme.palette.divider,
    fontSize: "1rem",
  },
  moduleNamePadding: {
    paddingTop: "2px",
    paddingLeft: "8px",
  },
  rightIcon: {
    marginLeft: "auto",
    opacity: 0.56,
  },
  currentItem: {
    padding: theme.spacing(2),
    cursor: "pointer",
  },
});
const modules: Module[] = [
  {
    moduleName: GlobalConstants.moduleName.customerExperience,
    moduleIcon: <CustomerExperienceIcon />,
    url: "/",
  },
  {
    moduleName: GlobalConstants.moduleName.customerSuccess,
    moduleIcon: <CustomerSuccessIcon />,
    url: "/success",
  },
];
const ModuleSwitcher = ({ pageName }: { pageName: string }) => {
  const theme = useTheme();
  const styles = css(theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const currentModule = modules.find(
    ({ moduleName }) => moduleName === pageName
  );
  const moduleList = modules.filter(
    ({ moduleName }) => moduleName !== currentModule?.moduleName
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModuleNameDisplay = (currentModule: Module, icon: ReactNode) => {
    return (
      <>
        {currentModule?.moduleIcon}
        <Typography sx={styles.moduleNamePadding}>
          {currentModule?.moduleName}
        </Typography>
        <div style={styles.rightIcon}>{icon}</div>
      </>
    );
  };
  if (currentModule)
    return (
      <div style={styles.root}>
        <StyleContainer style={styles.currentItem} onClick={handleClick}>
          {handleModuleNameDisplay(currentModule, <ArrowDropDownIcon />)}
        </StyleContainer>
        <Menu
          id={"module-menu"}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiMenu-paper": { left: 0 + "!important", width: "250px" },
          }}
        >
          {moduleList.map((module) => (
            <Link
              color={"textPrimary"}
              href={module.url}
              underline={"none"}
              key={module.moduleName + module.url}
            >
              <MenuItem>
                <StyleContainer>
                  {handleModuleNameDisplay(module, <ExitToAppIcon />)}
                </StyleContainer>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </div>
    );
  else return <></>;
};
export default ModuleSwitcher;
