import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  StyledEngineProvider,
  useTheme,
} from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { DateTime } from "luxon";
import { PowerBiDateRangeStyles } from "../../styles";
import { usePowerBiDateRanges } from "../../hooks";

const PowerBiDateRanges = (): React.JSX.Element => {
  const theme = useTheme();
  const classes = PowerBiDateRangeStyles(theme);

  const {
    currentInterval,
    showDateRange,
    setShowDateRange,
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    intervals,
    dateFilterViews,
    handleIntervalChange,
    handleResetDates,
    handleSetDates,
    handleMenu,
    handleClose,
    anchorEl,
  } = usePowerBiDateRanges();

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<CalendarToday />}
        onClick={handleMenu}
        style={{ marginLeft: 10 }}
      >
        {currentInterval?.value ? currentInterval.value : "DATE RANGE"}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {intervals.map((i) => (
          <MenuItem
            key={i.value}
            onClick={() =>
              i.value === "Custom Range"
                ? setShowDateRange(true)
                : handleIntervalChange(i)
            }
          >
            {i.value}
          </MenuItem>
        ))}
      </Menu>

      {showDateRange && (
        <Dialog
          open={showDateRange}
          onClose={() => setShowDateRange(false)}
          maxWidth={"sm"}
        >
          <DialogTitle>Custom Range</DialogTitle>
          <DialogContent dividers>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <StyledEngineProvider injectFirst>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <DatePicker
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                      views={dateFilterViews}
                      minDate={DateTime.utc()
                        .minus({ years: 1 })
                        .startOf("month")}
                      maxDate={DateTime.utc().startOf("month")}
                      // @ts-ignore
                      inputVariant="outlined"
                      className={classes.datePicker}
                      id="start-date"
                      label={
                        <>
                          <FilterListIcon className={classes.menuIcon} />
                          Start Date
                        </>
                      }
                      InputLabelProps={{ shrink: true }}
                      value={dateFrom}
                      onChange={(date) => {
                        if (date) {
                          setDateFrom(date.startOf("month"));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                      views={dateFilterViews}
                      minDate={DateTime.utc()
                        .minus({ years: 1 })
                        .endOf("month")}
                      maxDate={DateTime.utc().endOf("month")}
                      // @ts-ignore
                      inputVariant="outlined"
                      className={classes.datePicker}
                      id="end-date"
                      label={
                        <>
                          <FilterListIcon className={classes.menuIcon} />
                          End Date
                        </>
                      }
                      InputLabelProps={{ shrink: true }}
                      value={dateTo}
                      onChange={(date) => {
                        if (date) {
                          setDateTo(date.endOf("month"));
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledEngineProvider>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions className={classes.padding}>
            <Grid container justifyContent={"space-evenly"}>
              <Grid item>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleResetDates}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleSetDates}
                >
                  Set
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PowerBiDateRanges;
