import ServiceRequestForm from "../../components/ServiceRequestForm";
import Grid from "@mui/material/Grid";
import React, { createRef } from "react";
import RequestActions from "../Modules/MySupport/SubmitRequestComponents/RequestActions";
import {
  CityFieldName,
  CountryFieldName,
  PostalCodeFieldName,
  StateFieldName,
  StreetAddressFieldName,
} from "../Fields/AddressField";

const realFormSchema = {
  type: "object",
  properties: {
    "Employee Info": {
      type: "object",
      properties: {
        "First Name": { type: "string" },
        "Last Name": { type: "string", title: "Last Name" },
        "Preferred Name": { type: "string" },
        "Employee Title": { type: "string" },
        Department: { type: "string", title: "Department" },
        Manager: { type: "string" },
        "Start Date & Time": { type: "string", format: "datetime" },
        Timezone: { type: "string" },
        "User Contract Type": {
          type: "string",
          enum: [
            "Managed User (Full Support and Management)",
            "Managed Frontline User (Limited Support and Management)",
            "Managed Identity User (Identity Management Only)",
          ],
        },
        "Personal Email": { type: "string", format: "email" },
        "Company Email": { type: "string", format: "email" },
        "Mobile Phone": { type: "string" },
        "Office Phone": { type: "string" },
      },
      required: [
        "First Name",
        "Last Name",
        "Employee Title",
        "Department",
        "Manager",
        "Start Date & Time",
        "Timezone",
        "User Contract Type",
      ],
    },
    "Location & Shipping": {
      type: "object",
      properties: {
        "Primary Branch": { title: "Primary Branch", type: "string" },
      },
      required: ["Primary Branch"],
      dependencies: {
        "Primary Branch": {
          oneOf: [
            {
              properties: {
                "Primary Branch": { const: "Remote" },
                Address: { format: "address" },
              },
              required: ["Address"],
            },
            {
              properties: {
                "Primary Branch": { not: { const: "Remote" } },
                "Shipment Location Same as Primary Branch Location": {
                  type: "boolean",
                  default: true,
                },
              },
              dependencies: {
                "Shipment Location Same as Primary Branch Location": {
                  oneOf: [
                    {
                      properties: {
                        "Shipment Location Same as Primary Branch Location": {
                          const: true,
                        },
                      },
                    },
                    {
                      properties: {
                        "Shipment Location Same as Primary Branch Location": {
                          const: false,
                        },
                        Address: {
                          format: "address",
                        },
                      },
                      required: ["Address"],
                    },
                  ],
                },
              },
            },
          ],
        },
      },
    },
    Access: {
      type: "object",
      properties: {
        "Existing User to Compare Access to": { type: "string" },
        "Application Access": {
          type: "string",
          title: "Application Access",
          placeholder: "Desktop Applications, Cloud Applications, etc",
          outOfCharDesc:
            "The maximum number of characters in the Other Notes field is 500. If there is further information you need to include, please submit that information as an additional comment once the ticket has been created.",
        },
        "Email Groups": {
          type: "string",
          title: "Email Groups",
          outOfCharDesc:
            "The maximum number of characters in the Other Notes field is 500. If there is further information you need to include, please submit that information as an additional comment once the ticket has been created.",
        },
        "Additional Access or Security Groups": {
          type: "string",
          title: "Additional Access or Security Groups",
          outOfCharDesc:
            "The maximum number of characters in the Other Notes field is 500. If there is further information you need to include, please submit that information as an additional comment once the ticket has been created.",
        },
      },
      required: ["Application Access"],
    },
    Other: {
      type: "object",
      properties: {
        "Other Notes": {
          type: "string",
          title: "Other Notes",
          outOfCharDesc:
            "The maximum number of characters in the Other Notes field is 500. If there is further information you need to include, please submit that information as an additional comment once the ticket has been created.",
        },
      },
    },
  },
};

const realFormUISchema = {
  "Employee Info": {
    Manager: { "ui:widget": "contacts" },
    Timezone: { "ui:widget": "timezones" },
    "User Contract Type": { "ui:placeholder": "Select contract type" },
    "Mobile Phone": { "ui:options": { inputType: "tel" } },
    "Office Phone": { "ui:options": { inputType: "tel" } },
  },
  "Location & Shipping": {
    "Primary Branch": { "ui:widget": "locations" },
    Address: { "ui:field": "address" },
  },
  Access: {
    "Existing User to Compare Access to": { "ui:widget": "contacts" },
    "Application Access": { "ui:field": "limitedTextArea" },
    "Email Groups": { "ui:field": "limitedTextArea" },
    "Additional Access or Security Groups": { "ui:field": "limitedTextArea" },
  },
  Other: { "Other Notes": { "ui:field": "limitedTextArea" } },
};

const formSchema = {
  type: "object",
  properties: {
    Address: {
      type: "object",
    },
  },
  required: ["Address"],
};
const uiSchema = {
  Address: {
    "ui:field": "address",
  },
};

export function AddressFieldTestPage() {
  const submitFormButtonRef = createRef<HTMLButtonElement>();
  const submitRequest = ({ formData }: { formData: any }) => {
    console.log(formData);
  };

  return (
    <>
      <ServiceRequestForm
        schema={formSchema}
        uiSchema={uiSchema}
        submitFormButtonRef={submitFormButtonRef}
        onSubmit={submitRequest}
        onChange={(e: any) => console.log(e)}
        formData={{
          Address: {
            [CountryFieldName]: "United States",
            [StreetAddressFieldName]: "123 Main St",
            [CityFieldName]: "New York",
            [StateFieldName]: "NY",
            [PostalCodeFieldName]: "10001",
          },
        }}
      ></ServiceRequestForm>
      <Grid xs={12} item={true}>
        <RequestActions
          request={undefined}
          submittingRequest={undefined}
          shouldDisplayForm={true}
          cancelButton={undefined}
          submitFormButtonRef={submitFormButtonRef}
        />
      </Grid>
    </>
  );
}
