import React from "react";
import { hasIntegrationsByType } from "../../../../../functions/getCloudReportInformation";
import GlobalConstants from "@constants";
import { useSelector } from "react-redux";
import { CustomerExperienceReportCenter } from "../../../../../v2/src/components/CustomerExperienceReportCenter";

export function AwsGovernanceReports() {
  const integrationsCollection = useSelector(
    (state: any) => state.integrationsCollection
  );
  const hasAwsComplianceIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.awsCompliance
  );
  const hasAwsGovernanceReportsIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.awsGovernanceReports
  );

  const showSecurityAndCompliance: boolean = hasAwsComplianceIntegrations;
  const showBaseline: boolean = hasAwsGovernanceReportsIntegrations;
  const showInventory: boolean = hasAwsGovernanceReportsIntegrations;

  const reports: any[] = [];
  if (showSecurityAndCompliance) {
    reports.push({
      reportId: "CloudGovernanceFindingsReport-AWS",
      filter: "",
      reportName: "Security & Compliance",
    });
  }

  if (showBaseline) {
    reports.push({
      reportId: "CloudGovernanceBaselineReport-AWS",
      filter: "",
      reportName: "Baseline",
    });
  }

  if (showInventory) {
    reports.push({
      reportId: "CloudGovernanceInventoryReport-AWS",
      filter: "",
      reportName: "Inventory",
    });
  }

  return (
    <>
      <CustomerExperienceReportCenter
        header={["AWS", "Governance"]}
        reports={reports}
        showTimeRange={false}
      />
    </>
  );
}
