import { NavItemType } from "../../NavigationTypes";
import { cloudDatadogItemsToShowType } from "../../NavItemsToShowTypes";

export function CloudDatadogMenu(
  cloudDatadogItemsToShow: cloudDatadogItemsToShowType
): NavItemType {
  return {
    id: "cloudDatadog",
    type: "Group",
    displayName: "Datadog",
    visible: Object.entries(cloudDatadogItemsToShow).some(([_k, v]) => v),
    children: [
      {
        id: "cloudDatadogUsageReports",
        type: "Link",
        displayName: "Reports",
        path: "/cloud/datadog/reports",
        visible: cloudDatadogItemsToShow.showReports,
      },
    ],
  };
}
