import {
  ActiveUsersByServiceWidgetResponse,
  MicrosoftAdoptionWidgetsClient,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import React, { useEffect, useState } from "react";
import RankingWidget, {
  RankingColoredCell,
  RankingWidgetContainerHeight,
} from "@widgets/standard/RankingWidget";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import theme from "../../../../theme";
import { ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import {
  NoContent,
  useWidgetFilterUserConfig,
} from "../../dashboards/framework";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";

function useGetWidgetData(service: string) {
  const api = useApi(MicrosoftAdoptionWidgetsClient);

  return useQuery<ActiveUsersByServiceWidgetResponse, Error>({
    queryKey: ["feature-adoption-widget", service],
    queryFn: () => api.getFeatureAdoptionWidget(service),
    staleTime: ms("10m"),
    enabled: true,
  });
}

const features = ["M365 Apps", "Teams", "Viva Suite"];

export default function FeatureAdoptionWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const [gridData, setGridData] = useState<any>([]);
  const filterKey = "feature-adoption-widget";
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    filterKey,
    features[0]
  );

  const { data } = useGetWidgetData(currentFilterValue);

  useEffect(() => {
    if (data && data.services && data.services.length > 0) {
      setGridData(data.services);
    } else {
      setGridData([]);
    }
  }, [data]);

  const columnDefinitions = [
    {
      id: "service",
      headerName: "Feature",
      sortable: false,
      flex: 0.45,
    },
    {
      id: "activeUsers",
      headerName: "Active Users",
      sortable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.activeUsers} / {params.row.totalUsers}
          </>
        );
      },
    },
    {
      id: "adoptionRate",
      headerName: "Adoption Rate",
      sortable: true,
      flex: 0.35,
      valueGetter: (value: any, row: any) => {
        return row.activeUsers / row.totalUsers;
      },
      renderCell: (params: GridRenderCellParams) => {
        return gridData ? (
          <RankingColoredCell
            value={params.row.activeUsers}
            row={params.row}
            count={Object.keys(gridData).length}
            totalRecords={params.row.totalUsers}
            baseColor={theme.palette.green}
            showValue={false}
          />
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <RankingWidget
      pageLayoutWidget={pageLayoutWidget}
      data={gridData}
      gridDefinitions={{
        columnDefinitions,
        sortedColumns: [{ field: "adoptionRate", sort: "desc" }],
      }}
      rowExpandLookup={React.useRef({})}
      noDataElement={<NoDataElement />}
      loading={false}
      height={268}
      headerAction={
        <HeaderElement
          currentFilterValue={currentFilterValue}
          setFilter={setFilter}
        />
      }
    />
  );
}

function HeaderElement({
  currentFilterValue,
  setFilter,
}: {
  currentFilterValue: any;
  setFilter: any;
}) {
  return (
    <ToggleButtonGroup
      color="secondary"
      exclusive
      value={currentFilterValue}
      onChange={(e, value) => {
        if (value === null) return;
        setFilter(value);
      }}
    >
      {features.map((to) => {
        return (
          <ToggleButton style={{ lineHeight: 1 }} key={to} value={to}>
            {to}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

function NoDataElement() {
  return (
    <NoContent
      containerHeight={RankingWidgetContainerHeight}
      header={"No Feature Data"}
      body={"No Data Found for this specific feature."}
      actionText={""}
      actionLink={""}
      icon={""}
    />
  );
}
