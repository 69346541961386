import shiColors, { goodToBadColors } from "shared-ui/src/theme/shiColors";
import { lighten } from "@mui/material/styles";

export const assetTypesColors: { [key: string]: string } = {
  hardware: shiColors.blue.DEFAULT,
  software: shiColors.green.DEFAULT,
  subscription: shiColors.orange.DEFAULT,
  consumables: shiColors.pink.DEFAULT,
  subscriptions: shiColors.orange.light,
  data: shiColors.pink.dark,
  digital: shiColors.red.DEFAULT,
  unknown: shiColors.gray.lighter,
};

export const categoryColors: { [key: string]: string } = {
  servers: shiColors.pink.DEFAULT,
  computers: shiColors.blue.DEFAULT,
  network: shiColors.green.DEFAULT,
  peripherals: shiColors.orange.light,
  printers: shiColors.pink.dark,
  software: shiColors.purple.DEFAULT,
  other: shiColors.red.DEFAULT,
  "cloud resource": shiColors.purple.DEFAULT,
  unknown: shiColors.gray.lighter,
};

export const lifecycleStateColors: { [key: string]: string } = {
  "general availability": shiColors.blue.DEFAULT,
  "end of sale": shiColors.green.DEFAULT,
  "end of life": shiColors.pink.DEFAULT,
  "end of software maintenance": shiColors.orange.DEFAULT,
  "end of support": shiColors.pink.dark,
  close_to_next_state: lighten(shiColors.orange.DEFAULT, 0.7),
  lifecycle_state_completed: shiColors.orange.DEFAULT,
  unknown: shiColors.gray.lighter,
};

export const useStateColors: { [key: string]: string } = {
  "in use": shiColors.green.DEFAULT,
  "in transit": shiColors.pink.DEFAULT,
  "in maintenance": shiColors.orange.DEFAULT,
  retired: shiColors.pink.dark,
  "in stock": shiColors.blue.DEFAULT,
  unknown: shiColors.gray.lighter,
};

export const contractStatusColors: { [key: string]: string } = {
  "not covered": shiColors.red.DEFAULT,
  covered: shiColors.green.DEFAULT,
  "never covered": shiColors.orange.DEFAULT,
  expired: shiColors.orange.DEFAULT,
  overdue: shiColors.yellow.DEFAULT,
  unknown: shiColors.gray.lighter,
};

export const contractCoverageWidgetColors =
  Object.values(goodToBadColors).slice(1);

export const shiContractType: { [key: string]: string } = {
  Saas: shiColors.pink.DEFAULT,
  "Maintenance & Support": shiColors.green.DEFAULT,
  Licensing: shiColors.orange.light,
};
export const warehouseTypeColors: { [key: string]: string } = {
  Integration: shiColors.pink.DEFAULT,
  Transfer: shiColors.blue.DEFAULT,
  "Rack & Stack": shiColors.green.DEFAULT,
  RMA: shiColors.orange.DEFAULT,
};

export const hlmStageColors: { [key: string]: string } = {
  "asset received": shiColors.blue.DEFAULT,
  "device triage": shiColors.pink.DEFAULT,
  "staged service/quarantine": shiColors.green.DEFAULT,
  "data erasure": shiColors.orange.light,
  "service performed": shiColors.pink.dark,
  complete: shiColors.red.DEFAULT,
  other: shiColors.turquoise.DEFAULT,
};
export const warehouseStageColors: { [key: string]: string } = {
  ordered: shiColors.blue.DEFAULT,
  picked: shiColors.orange.DEFAULT,
  staging: shiColors.pink.DEFAULT,
  "rack/stack": shiColors.blue.dark,
  "cable/label": shiColors.blue.light,
  "in config": shiColors.purple.dark,
  "config/qc": shiColors.purple.dark,
  shipped: shiColors.red.DEFAULT,
  ...hlmStageColors,
};
