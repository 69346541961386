import { Grid } from "@mui/material";
import React from "react";
import { WidgetLoader } from "../../widgets";
import { WidgetRegistry } from "../../widgets/models/WidgetRegistry";
import { PageLayoutColumnDto } from "../../generated/models";

export function DashboardColumn({
  column,
  width,
  widgetRegistry,
}: {
  column: PageLayoutColumnDto | undefined;
  width?: number;
  widgetRegistry: WidgetRegistry;
}) {
  return (
    <Grid
      item
      container
      flexDirection={"column"}
      flexWrap={"nowrap"}
      xs={width}
      rowSpacing={3}
    >
      {column?.widgets
        ?.filter((w) => w.enabled)
        ?.map((widget) => {
          return (
            <Grid item key={`widget-${widget.widgetId}`}>
              <WidgetLoader
                pageLayoutWidget={widget}
                key={`widget-${widget.widgetId}`}
                widgetRegistry={widgetRegistry}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
