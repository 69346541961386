import { ConfigurationItemDTO, DeviceClient } from "../lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new DeviceClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestMyDevices(): Promise<ConfigurationItemDTO[]> {
  return api.getDevice();
}
