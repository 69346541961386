import { getManagedSecurityGroups } from "../../../../functions/securityGroupHelper";
import {
  AssetsMenu,
  CiscoMenu,
  CloudMenu,
  CustomerDashboardsMenuV2,
  CustomerSupportCenterMenuV2,
  LabsMenu,
  SettingsMenu,
} from "./SubNavGroups";
import { IntegrationsCollectorState } from "../../../../modules/rtk/integrationsCollector/integrationsCollectorTypes";
import { CloudAccount, FSACacheResponse, Team } from "@lib/ShiOneClient";
import { useClaims } from "../../../../auth/ClaimsProvider";
import { NavItemsToShow } from "./NavItemsToShow";
import { navItemsToShowType } from "./NavItemsToShowTypes";
import { CustomerServicesMenuV2 } from "./SubNavGroups/customers/CustomerServicesMenuV2";
import { ReportCenterMenu } from "./SubNavGroups/customers/ReportCenterMenu";
import { NavItemType } from "./NavigationTypes";
import { AssessmentsMenu } from "./SubNavGroups/common/AssessmentsMenu";

export default function CustomerNavigationItems({
  integrationsCollection,
  teamContracts,
  myTeams,
  knowledgebase,
  cloudContracts,
  fsas,
  backup,
  hasAzureCloudReports,
  subDomain,
}: {
  integrationsCollection: IntegrationsCollectorState;
  teamContracts: any;
  myTeams: Team[];
  knowledgebase: any;
  cloudContracts: CloudAccount[];
  fsas: FSACacheResponse;
  backup: any;
  hasAzureCloudReports: any;
  subDomain: string | undefined;
}): (NavItemType | undefined)[] {
  const { getClaimValue } = useClaims();

  const accountId = getClaimValue("accountId");
  const contactId = getClaimValue("contactId");
  const { fetchBackupStatsComplete, backupStatsError } = backup;

  let loadingBackupStats = true;
  if (fetchBackupStatsComplete || backupStatsError) {
    loadingBackupStats = false;
  }

  let fsaId: number | undefined = undefined;
  if (
    fsas &&
    fsas.flexibleServicesAgreements &&
    fsas.flexibleServicesAgreements.length > 0
  ) {
    fsaId = fsas.flexibleServicesAgreements[0].contractId;
  }

  const myManagedSecurityGroups = getManagedSecurityGroups(contactId, myTeams);

  //THIS NEEDS TO GO
  let shouldDisplayAdmin = true;
  if (subDomain === "lpl" && accountId !== "175") {
    shouldDisplayAdmin = false;
  }

  const navItemsToShow: navItemsToShowType = NavItemsToShow({
    cloudContracts,
    teamContracts,
    integrationsCollection,
    hasAzureCloudReports,
    fsaId,
    myManagedSecurityGroups,
    serviceReportsEnabled: true,
  });

  return [
    ...[CustomerDashboardsMenuV2()],
    ...[
      CustomerSupportCenterMenuV2(
        navItemsToShow.supportItemsToShow,
        knowledgebase
      ),
    ],

    Object.values(navItemsToShow.servicesItemsToShow).includes(true)
      ? CustomerServicesMenuV2(navItemsToShow.servicesItemsToShow, fsaId)
      : undefined,
    CiscoMenu(navItemsToShow.ciscoItemsToShow, true),
    CloudMenu({
      loadingBackupStats,
      showCloud: navItemsToShow.showCloud,
      navItemsToShow,
    }),
    AssetsMenu(navItemsToShow.assetItemsToShow),
    AssessmentsMenu(),
    ReportCenterMenu(),
    ...[],
    LabsMenu(),
    SettingsMenu(navItemsToShow.settingsItemsToShow, shouldDisplayAdmin),
  ];
}
