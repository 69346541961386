import React, { Fragment, useEffect, useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavItem from "./NavItem";
import { NavGroupStyles } from "../styles/NavigationStyles";
import { NavItemType } from "./NavigationTypes";
import { useLocation } from "react-router-dom";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

interface NavGroupProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}

export default function NavGroup({
  navItem,
  tier,
  parentContext,
}: NavGroupProps) {
  const theme = useTheme();
  const styles = NavGroupStyles(theme);
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (navItem.children) {
      setOpen(handleIsActive(navItem.children));
    }
  }, [location.pathname, navItem.children]);

  function handleIsActive(
    navItemChildren: (NavItemType | undefined)[],
  ): boolean {
    return navItemChildren.some((child) => {
      if (child?.path) {
        if (
          location.pathname.startsWith(child.path) ||
          location.pathname.startsWith(AppendIfPrefixExists(child.path))
        ) {
          return true;
        }
      }
      // If the child has its own children, check them recursively
      if (child?.children) {
        return handleIsActive(child.children);
      }
      return false;
    });
  }

  function handleClick() {
    setOpen(!open);
  }

  if (!navItem.visible) {
    return <Fragment />;
  }

  const telemetryContext = `${parentContext} > ${navItem.displayName}`;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        data-telemetry-name={telemetryContext}
      >
        {navItem.icon && (
          <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={navItem.displayName}
          sx={[
            tier === 1 ? styles.listItemText : {},
            tier === 2 ? styles.tier2 : {},
          ]}
          title={navItem.displayName}
        />
        {open ? (
          <ExpandLess color={"action"} />
        ) : (
          <ExpandMore color={"action"} />
        )}
      </ListItemButton>
      {navItem.children && navItem.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navItem.children.map((child, i) => {
              if (child) {
                return (
                  <NavItem
                    key={i}
                    navItem={child}
                    tier={tier + 1}
                    parentContext={telemetryContext}
                  />
                );
              }
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}
