import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  setOnboardingStatusFromLocalStorage,
  updateWelcomeLinkOptions,
} from "../modules/rtk/onboarding/onboardingSlice";
import { parseParams } from "../functions/urlHelper";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import ForwardNavLink from "../components/ForwardRefNavLink";
import { getWelcomeProgressLocalStorage } from "../auth/WelcomeHelpers";

const styles = {
  grow: {
    flexGrow: 1,
  },
  card: {
    padding: 0,
  },
  titleNoMargin: {
    fontWeight: 400,
    fontSize: "1rem",
    textTransform: "uppercase",
  },
  alert: {
    boxShadow: "none !important",
    border: "none !important",
    "& > .MuiAlert-message": {
      minWidth: "60%",
    },
    "& > .MuiAlert-message > .MuiTypography-root": {
      color: "inherit",
    },
    marginBottom: "1rem",
  },
};

const callbackResult = (params) => {
  const result = {
    success: false,
  };
  if (params.state) {
    result.welcome_guid = params.state;
  }
  if (params.admin_consent) {
    result.success = true;
  }
  if (params.error && params.error_subcode) {
    if (params.error === "access_denied" && params.error_subcode === "cancel") {
      result.error = params.error;
      result.reason =
        "Either you did not sign in with an administrator account, or you declined consent.";
    }
  }
  if (params.error && params.error_description) {
    result.error = params.error;
    result.reason = params.error_description;
  }
  return result;
};

class WelcomeCallback extends Component {
  constructor(props) {
    super(props);

    this.completeWelcomeLink = this.completeWelcomeLink.bind(this);
  }

  completeWelcomeLink = (guid) => {
    const { setWelcomeLinkOptions } = this.props;

    setWelcomeLinkOptions({
      linkId: guid,
      completed: true,
      microsoftSSOSelected: true,
      microsoftSSOSuccessful: true,
    });
  };

  render() {
    const {
      navigate,
      location,
      setOnboardingFromApplicationStorage,
      onboarding,
    } = this.props;
    const existingWelcomeStatus = getWelcomeProgressLocalStorage();
    const authResult = callbackResult(parseParams(location.search));

    if (
      authResult.welcome_guid &&
      onboarding.onboardingRequestStatus === "idle" &&
      existingWelcomeStatus.accountId !== -1
    ) {
      setOnboardingFromApplicationStorage(authResult);
    }

    if (
      authResult.welcome_guid &&
      onboarding.onboardingRequestStatus === "complete" &&
      existingWelcomeStatus.accountId !== -1
    ) {
      navigate(`/welcome/${authResult.welcome_guid}`);
    }

    if (authResult && existingWelcomeStatus.accountId === -1) {
      return (
        <Fragment>
          <Box bgcolor={"background.default"}>
            <Card className="wizard-body" sx={{ py: 3, px: 5 }}>
              <CardHeader
                title={
                  <Typography
                    variant="body1"
                    component="div"
                    color="textSecondary"
                    sx={styles.titleNoMargin}
                  >
                    SHI One Organization Registration
                  </Typography>
                }
                subheader={
                  <Typography variant="caption" component="div">
                    {authResult.success ? "Finish Registration" : "Error"}
                  </Typography>
                }
              />
              <Divider />
              <CardContent sx={styles.root}>
                <Typography variant="body1" component="div">
                  {authResult.success ? (
                    <Alert severity="success" sx={styles.alert}>
                      <AlertTitle>Success</AlertTitle>
                      <p>
                        Your tenant has been authorized for Microsoft Single
                        Sign-on.
                      </p>
                    </Alert>
                  ) : (
                    <Alert severity="error" sx={styles.alert}>
                      <p>{authResult.reason}</p>
                      <p>
                        Your tenant was not authorized for Microsoft Single
                        Sign-on due to the above reason.
                      </p>
                      <p>
                        In order to sign in to SHI One using your Microsoft
                        credentials, an admin must successfully consent to the
                        SHI One application via the consent link you were
                        provided.
                      </p>
                      <p>
                        Please try again, or contact your account executive for
                        assistance.
                      </p>
                    </Alert>
                  )}
                  {authResult.success && (
                    <p>
                      Click finish to proceed to SHI One and login with your
                      Microsoft Account.
                    </p>
                  )}
                </Typography>
              </CardContent>
              {authResult.success && (
                <Fragment>
                  <Divider />
                  <CardActions>
                    <Grid
                      container
                      sx={styles.grow}
                      spacing={3}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          component={ForwardNavLink}
                          onClick={() =>
                            this.completeWelcomeLink(authResult.welcome_guid)
                          }
                          to={AppendIfPrefixExists("/")}
                        >
                          Finish
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Fragment>
              )}
            </Card>
          </Box>
        </Fragment>
      );
    }

    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state) => {
  const { onboarding } = state;
  return {
    onboarding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnboardingFromApplicationStorage: (authResult) =>
      dispatch(setOnboardingStatusFromLocalStorage(authResult)),
    setWelcomeLinkOptions: (welcomeLink) =>
      dispatch(updateWelcomeLinkOptions(welcomeLink)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeCallback);
