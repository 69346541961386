import { View } from "../../../components/custom-toolbar-components/UserViews";
import { ActiveOrComplete } from "@features/service-requests-feature/utils/constants";

export const prebuiltViewNames = {
  allRequests: "All Requests",
  openRequests: "Open Requests",
  completedRequests: "Completed Requests",
  waitingForResponse: "Waiting for Response",
};

export function prebuiltViewsWithUrlFilters(
  urlFilters: Record<string, string>
): View[] {
  // Add any url filters that are passed in the query string as values to
  // the corresponding filter.
  return prebuiltViews.map((view) => {
    const filters = view.filters!.map((filter) => {
      if (filter.accessor in urlFilters) {
        return {
          ...filter,
          value: urlFilters[filter.accessor],
        };
      }
      return filter;
    });
    return {
      ...view,
      filters,
    };
  });
}

// These are views that the services request page will have by default
export const prebuiltViews: View[] = [
  {
    name: prebuiltViewNames.allRequests,
    filters: [],
  },
  {
    name: prebuiltViewNames.openRequests,
    filters: [
      {
        label: "Open Requests",
        accessor: "ActiveOrComplete",
        labelWidth: 98,
        options: [ActiveOrComplete.Active, ActiveOrComplete.Complete],
        value: ActiveOrComplete.Active,
        viewFilter: true,
      },
      {
        label: "Contract Name",
        accessor: "ContractName",
        labelWidth: 101,
        value: "",
        options: [],
        viewFilter: true,
      },
    ],
  },
  {
    name: prebuiltViewNames.completedRequests,
    filters: [
      {
        label: "Status",
        accessor: "ActiveOrComplete",
        labelWidth: 98,
        options: [ActiveOrComplete.Active, ActiveOrComplete.Complete],
        // Initially want active requests
        value: ActiveOrComplete.Complete,
        viewFilter: true,
      },
    ],
  },
  {
    name: prebuiltViewNames.waitingForResponse,
    filters: [
      {
        label: "Waiting for Response",
        accessor: "IsWaitingForResponse",
        labelWidth: 98,
        options: ["true", "false"],
        value: "true",
        viewFilter: true,
      },
    ],
  },
].sort((a, b) => a.name.localeCompare(b.name));
