import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useGetProjectById from "@features/projects-feature/hooks/useGetProjectById";
import { Grid, Paper } from "@mui/material";
import { useAppSelector } from "../../../../../functions/hooks/hooks";
import { IRootState } from "../../../../../modules";
import { getDuration } from "../../../../../functions/dateHelper";
import ProjectDetailsTimelineCard from "@features/projects-feature/components/ProjectDetailsTimelineCard";
import { ProjectDuration } from "@features/projects-feature/projectTypes";
import ProjectDetailsPhaseStepper from "@features/projects-feature/components/ProjectDetailsPhaseStepper";
import ProjectDetailsTasksCard from "@features/projects-feature/components/ProjectDetailsTasksCard";
import useGetContractById from "@features/projects-feature/hooks/useGetContractById";
import ProjectDetailsMetadata from "@features/projects-feature/components/ProjectDetailsMetadata";
import { ContractDetailsDialog } from "@features/projects-feature/components/ContractDetailsDialog";
import NoProjectTasksFound from "@pages/services/projects/NoProjectTasksFound";
import LoadingOrError from "shared-ui/src/global-layout/LoadingOrError";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import shiColors from "shared-ui/src/theme/shiColors";
import PageHeader from "shared-ui/src/global-layout/PageHeader";
import { getPicklistValue } from "shared-ui/src/functions/picklistValue";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";

function ProjectDetailsV2() {
  const { projectId } = useParams();
  const { state } = useLocation();

  const [timelineProgress, setTimelineProgress] = useState(0);
  const [duration, setDuration] = useState<ProjectDuration | undefined>(
    undefined
  );
  const [contractId, setContractId] = useState<number | undefined>(undefined);
  const [openContractDialog, setOpenContractDialog] = useState(false);
  const {
    data: selectedProject,
    isLoading,
    isError,
    isFetching,
  } = useGetProjectById(Number(projectId));
  const { picklist } = useAppSelector((state: IRootState) => state.picklist);
  const { data: projectContract } = useGetContractById(Number(contractId));

  useEffect(() => {
    if (
      selectedProject?.project?.startDateTime &&
      selectedProject?.project?.endDateTime
    ) {
      const duration: ProjectDuration = getDuration(
        selectedProject?.project.startDateTime,
        selectedProject?.project.endDateTime
      );

      setDuration(duration);
    }
    if (
      selectedProject?.project?.contractID &&
      selectedProject?.project?.contractID !== 0
    ) {
      setContractId(selectedProject.project.contractID);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (duration) {
      const progress = (duration.currentProgress / duration.duration) * 100;
      setTimelineProgress(progress);
    }
  }, [duration]);

  const handleOpenDialog = () => {
    setOpenContractDialog(true);
  };

  return (
    <>
      <PageHeader
        header={state ? state.name : selectedProject?.project?.projectName}
        // @ts-ignore
        subheader={
          state || selectedProject?.project?.status ? (
            <ColoredChips
              bgColor={shiColors.orange.light}
              label={
                state
                  ? state.status
                  : getPicklistValue(
                      picklist,
                      "project",
                      "status",
                      selectedProject?.project?.status
                    ).label
              }
            />
          ) : (
            ""
          )
        }
        icon={<ViewTimelineIcon fontSize={"large"} />}
        backButtonUrl={"/services/projects"}
      />
      {(isLoading || isFetching || isError || selectedProject.errorMessage) && (
        <LoadingOrError
          error={isError || !!selectedProject?.errorMessage}
          message={selectedProject?.errorMessage ?? "Project cannot be loaded"}
          loading={isLoading || isFetching}
        />
      )}

      {!!selectedProject?.project ? (
        <Grid container gap={3} direction="row-reverse">
          <Grid
            container
            item
            flexDirection="column"
            justifyContent="flex-start"
            gap={3}
            lg
          >
            <Grid item>
              <ProjectDetailsTimelineCard
                selectedProject={selectedProject}
                duration={duration}
                timelineProgress={timelineProgress}
              />
            </Grid>
            <Grid item>
              <ProjectDetailsMetadata
                selectedProject={selectedProject}
                openContractDetailDialog={handleOpenDialog}
                contract={projectContract}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={8}
            flexDirection="column"
            justifyContent="flex-start"
            gap={3}
          >
            {selectedProject?.phasesWithTasksList?.length !== 0 ? (
              <>
                <ProjectDetailsPhaseStepper
                  phasesWithTasks={selectedProject?.phasesWithTasksList ?? []}
                  activePhase={selectedProject?.activePhase ?? 0}
                />

                <ProjectDetailsTasksCard
                  phasesWithTasks={selectedProject?.phasesWithTasksList ?? []}
                  orphanTasks={selectedProject?.orphanTasks ?? []}
                  picklist={picklist}
                />
              </>
            ) : (
              <Grid item xs={12}>
                <Paper>
                  <NoProjectTasksFound />
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {projectContract && (
        <ContractDetailsDialog
          open={openContractDialog}
          onClose={() => setOpenContractDialog(false)}
          contract={projectContract}
          picklist={picklist}
        />
      )}
    </>
  );
}

export default ProjectDetailsV2;
