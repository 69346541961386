import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store";
import { DynamicTagsState } from "./dynamicTagsTypes";
import {
  deleteRestDynamicTags,
  getRestDynamicTags,
  postRestDynamicTags,
  putRestDynamicTags,
} from "../../../api/dynamicTagsStore";
import { DynamicTagRequest, DynamicTag } from "lib/ShiOneClient";
import GlobalConstants from "@constants";
import _ from "lodash";

const initialState: DynamicTagsState = {
  dynamicTagsFetchStatus: GlobalConstants.fetchStatus.idle,
  dynamicTagsUpdateStatus: GlobalConstants.fetchStatus.idle,
  dynamicTags: [],
};
const dynamicTagsSlice = createSlice({
  name: "dynamicTags",
  initialState,
  reducers: {
    receivingDynamicTags(state) {
      state.dynamicTagsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receiveDynamicTags(state, action: PayloadAction<DynamicTag[]>) {
      state.dynamicTags = action.payload;
      state.dynamicTagsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveDynamicTagsError(state) {
      state.dynamicTagsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    creatingDynamicTags(state) {
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.loading;
    },
    createDynamicTags(state, action: PayloadAction<DynamicTag[]>) {
      state.dynamicTags = state.dynamicTags.concat(action.payload);
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.complete;
    },
    createDynamicTagsError(state) {
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.error;
    },
    updatingDynamicTags(state) {
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.loading;
    },
    updateDynamicTags(state, action: PayloadAction<DynamicTag[]>) {
      const tags = state.dynamicTags.slice();

      _.forEach(action.payload, (newTag) => {
        const index = _.findIndex(tags, (tag) => tag.id === newTag.id);

        if (index !== -1) {
          tags[index] = newTag;
        }
      });

      state.dynamicTags = tags;
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.complete;
    },
    updateDynamicTagsError(state) {
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.error;
    },
    deletingDynamicTags(state) {
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.loading;
    },
    deleteDynamicTags(state, action: PayloadAction<number[]>) {
      let updatedTags = state.dynamicTags.slice();

      if (action.payload) {
        updatedTags = _.reject(updatedTags, (tag) =>
          _.includes(action.payload, tag.id)
        );
      }

      state.dynamicTags = updatedTags;
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.complete;
    },
    deleteDynamicTagsError(state) {
      state.dynamicTagsUpdateStatus = GlobalConstants.fetchStatus.error;
    },
  },
});

export const getDynamicTags = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(dynamicTagsSlice.actions.receivingDynamicTags());
  try {
    const dynamicTags = await getRestDynamicTags();

    dispatch(dynamicTagsSlice.actions.receiveDynamicTags(dynamicTags));
  } catch {
    dispatch(dynamicTagsSlice.actions.receiveDynamicTagsError());
  }
};

export const createDynamicTags =
  (request: DynamicTagRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(dynamicTagsSlice.actions.creatingDynamicTags());
    try {
      const newTags = await postRestDynamicTags(request);

      dispatch(dynamicTagsSlice.actions.createDynamicTags(newTags));
    } catch {
      dispatch(dynamicTagsSlice.actions.createDynamicTagsError());
    }
  };

export const updateDynamicTags =
  (request: DynamicTagRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(dynamicTagsSlice.actions.updatingDynamicTags());
    try {
      const updatedTags = await putRestDynamicTags(request);

      dispatch(dynamicTagsSlice.actions.updateDynamicTags(updatedTags));
    } catch {
      dispatch(dynamicTagsSlice.actions.updateDynamicTagsError());
    }
  };

export const deleteDynamicTags =
  (request: number[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(dynamicTagsSlice.actions.deletingDynamicTags());
    try {
      await deleteRestDynamicTags(request);

      dispatch(dynamicTagsSlice.actions.deleteDynamicTags(request));
    } catch {
      dispatch(dynamicTagsSlice.actions.deleteDynamicTagsError());
    }
  };

export default dynamicTagsSlice.reducer;
