import { PaletteMode } from "@mui/material";

const darkPalette = {
  mode: "dark" as PaletteMode,
  primary: {
    main: "#90CAF9",
    dark: "#42A5F5",
    light: "#E3F2FD",
    contrastText: "#000000DE",
    "8p": "#90CAF914",
    "12p": "#90CAF91F",
    "16p": "#1976D21F",
    "30p": "#90CAF94D",
    "50p": "#90CAF980",
  },
  secondary: {
    main: "#F78A44",
    dark: "#FF6D15",
    light: "#F9A773",
    contrastText: "#000000DE",
    "8p": "#F9A77314",
    "12p": "#F9A7731F",
    "16p": "#F9A77329",
    "30p": "#F9A7734D",
    "50p": "#F9A77380",
  },
  error: {
    main: "#F44336",
    dark: "#D32F2F",
    light: "#E57373",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#66BB6A",
    dark: "#388E3C",
    light: "#81C784",
    contrastText: "#000000DE",
  },
  info: {
    main: "#29B6F6",
    light: "#4FC3F7",
    dark: "#0288D1",
    contrastText: "#000000DE",
  },
  warning: {
    main: "#FFA726",
    light: "#FFB74D",
    dark: "#F57C00",
    contrastText: "#000000DE",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#e0e0e0",
    disabled: "#898989",
  },
  background: {
    default: "#313131",
    dark: "#2a2a2a",
    paper: "#121212",
  },
  table: {
    border: "rgba(255,255,255,0.73)",
    header: "#252525",
    contrastText: "#fff",
  },
  action: {
    active: "#E6E6E6",
    hover: "rgba(244,244,244,0.08)",
    selected: "rgba(255,255,255,0.12)",
    disabled: "rgba(244,244,244,0.26)",
    disabledBackground: "#0000001F",
    focus: "#0000001F",
  },
  border: "#FFFFFF3B",
  inputLine: "#0000006B",
  ratingActive: "#FFB400",
  divider: "#FFFFFF1F",
};

export default darkPalette;
