import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Cancel,
  CheckCircle,
  DeleteForever,
  Edit,
  Info,
} from "@mui/icons-material";
import { BookmarkDto, BookmarksDto, BookmarkTypes } from "../../../../../../customer-experience/src/lib/ShiOneClient";

interface PowerBiBookmarksProps {
  bookmarks: BookmarksDto|undefined;
  handleApplyBookmark: (bookmark: BookmarksDto) => void;
  handleReset: () => void;
  addBookmark: (name: string, type: BookmarkTypes) => void;
  deleteBookmark: (bookmark: BookmarksDto) => void;
  updateBookmark: (bookmark: BookmarksDto) => void;

}

export default function PowerBiBookmarks({
  bookmarks,
  handleApplyBookmark,
  handleReset,
  addBookmark,
  deleteBookmark,
  updateBookmark,
}: PowerBiBookmarksProps) {
  const theme = useTheme()


  const [changedBookmark, setChangedBookmark] = useState<BookmarkDto>(new BookmarkDto());
  const [addingAccountBookmark, setAddingAccountBookmark] = useState(false);
  const [addingContactBookmark, setAddingContactBookmark] = useState(false);
  const [contactBookmarkName, setContactBookmarkName] =
    useState("New Bookmark");
  const [accountBookmarkName, setAccountBookmarkName] =
    useState("New Bookmark");

  const handleNewBookmarkName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === BookmarkTypes.Group) {
      setAccountBookmarkName(value);
    }

    if (name === BookmarkTypes.User) {
      setContactBookmarkName(value);
    }
  };

  const handleUpdateBookmarkName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setChangedBookmark((prevState) => ({
      ...prevState,
      name: value,
    }));
  };

  const handleCancelNewBookmark = (type:BookmarkTypes) => {
    if (type === BookmarkTypes.User) {
      setAddingContactBookmark(false);
      setContactBookmarkName("New Bookmark");
    }

      if (type === BookmarkTypes.Group) {
      setAddingAccountBookmark(false);
      setAccountBookmarkName("New Bookmark");
    }
  };

  const handleCreateBookmark = (type: BookmarkTypes) => {
    if (type === BookmarkTypes.User ) {
      addBookmark(contactBookmarkName, type);

      setContactBookmarkName("New Bookmark");
      setAddingContactBookmark(false);
    }

    if (type === BookmarkTypes.Group) {
      addBookmark(accountBookmarkName, type);

      setAccountBookmarkName("New Bookmark");
      setAddingAccountBookmark(false);
    }
  };

  const handleSaveUpdatedBookmark = () => {
    updateBookmark(changedBookmark);
    setChangedBookmark( new BookmarkDto());
  };

  const handleDeleteBookmark = (bookmark: BookmarkDto) => {
    deleteBookmark(bookmark);
  };

  const handleResetView = () => {
    handleReset();
  };

  const handleBookmarkSelect = async (bookmark: BookmarkDto) => {
    handleApplyBookmark(bookmark);
  };

  const contactBookmarks = bookmarks?.userBookmarks;
  const accountBookmarks = bookmarks?.groupBookmarks;


  return (
    <>
      <Typography component={"div"}>
        <Grid container direction={"row"}>

            <>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: theme.spacing(1.5),
                    width: "100%",
                    backgroundColor: theme.palette.background.dark,
                  }}
                >
                  <span
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    Personal Views&nbsp;
                    <Tooltip title="Personal views are private to you and let you save and return to your custom view of the report">
                      <Info color="info" fontSize={"small"} />
                    </Tooltip>
                  </span>
                  <Button
                    size={"small"}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => setAddingContactBookmark(true)}
                  >
                    Add
                  </Button>
                </div>
                <List disablePadding>
                  {contactBookmarks?.map((bm) => {
                    const currentlyUpdating =
                      changedBookmark.bookmarkId === bm.bookmarkId && changedBookmark.bookmarkId;

                    return (
                      <Box key={bm.bookmarkId}>
                        <ListItem>
                          {!currentlyUpdating && (
                            <>
                              <ListItemButton
                                onClick={() => handleBookmarkSelect(bm)}
                              >
                                {bm.name}
                              </ListItemButton>
                              <ListItemSecondaryAction>
                                <Tooltip title={"Edit"}>
                                  <IconButton
                                    color="primary"
                                    onClick={() => setChangedBookmark(bm)}
                                    size="large"
                                  >
                                    <Edit fontSize={"small"} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete"}>
                                  <IconButton
                                    color="secondary"
                                    onClick={() =>
                                      handleDeleteBookmark(bm)
                                    }
                                    size="large"
                                  >
                                    <DeleteForever fontSize={"small"} />
                                  </IconButton>
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </>
                          )}
                          {currentlyUpdating && (
                            <>
                              <TextField
                                style={{
                                  width: "75%",
                                  padding: theme.spacing(1),
                                }}
                                onChange={handleUpdateBookmarkName}
                                value={changedBookmark.name}
                                variant={"standard"}
                              />
                              <ListItemSecondaryAction>
                                <Tooltip title={"Cancel"}>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => setChangedBookmark(new BookmarkDto())}
                                    size="large"
                                  >
                                    <Cancel fontSize={"small"} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={"Save"}>
                                  <IconButton
                                    color="success"
                                    onClick={() =>
                                      handleSaveUpdatedBookmark()
                                    }
                                    size="large"
                                  >
                                    <CheckCircle fontSize={"small"} />
                                  </IconButton>
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </>
                          )}
                        </ListItem>
                        <Divider />
                      </Box>
                    );
                  })}
                  {addingContactBookmark && (
                    <>
                      <ListItem>
                        <TextField
                          style={{
                            width: "75%",
                            padding: theme.spacing(1),
                          }}
                          name={BookmarkTypes.User}
                          onChange={handleNewBookmarkName}
                          value={contactBookmarkName}
                          variant={"standard"}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip title={"Cancel"}>
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                handleCancelNewBookmark(BookmarkTypes.User)
                              }
                              size="large"
                            >
                              <Cancel fontSize={"small"} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Save"}>
                            <IconButton
                              color="success"
                              onClick={() =>
                                handleCreateBookmark(BookmarkTypes.User)
                              }
                              size="large"
                            >
                              <CheckCircle fontSize={"small"} />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  )}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: theme.spacing(1.5),
                width: "100%",
                backgroundColor: theme.palette.background.dark,
              }}
            >
              <span
                style={{
                  alignSelf: "center",
                }}
              >
                Group Views&nbsp;
                <Tooltip title="Group views are public to all users on your group and let you save and share to your custom view of the report">
                  <Info color="info" fontSize={"small"} />
                </Tooltip>
              </span>
              <Button
                size={"small"}
                color={"primary"}
                variant={"contained"}
                onClick={() => setAddingAccountBookmark(true)}
              >
                Add
              </Button>
            </div>
            <List disablePadding>
              {accountBookmarks?.map((bm) => {
                const currentlyUpdating =
                  changedBookmark.bookmarkId === bm.bookmarkId && changedBookmark.bookmarkId;

                return (
                  <Box key={bm.bookmarkId}>
                    <ListItem>
                      {!currentlyUpdating && (
                        <>
                          <ListItemButton
                            onClick={() => handleBookmarkSelect(bm)}
                          >
                            {bm.name}
                          </ListItemButton>
                          <ListItemSecondaryAction>
                            <Tooltip title={"Edit"}>
                              <IconButton
                                color="primary"
                                onClick={() => setChangedBookmark(bm)}
                                size="large"
                              >
                                <Edit fontSize={"small"} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Delete"}>
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  handleDeleteBookmark(
                                    bm
                                  )
                                }
                                size="large"
                              >
                                <DeleteForever fontSize={"small"} />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </>
                      )}
                      {currentlyUpdating && (
                        <>
                          <TextField
                            style={{
                              width: "75%",
                              padding: theme.spacing(1),
                            }}
                            onChange={handleUpdateBookmarkName}
                            value={changedBookmark.name}
                            variant={"standard"}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title={"Cancel"}>
                              <IconButton
                                color="secondary"
                                onClick={() => setChangedBookmark(new BookmarkDto())}
                                size="large"
                              >
                                <Cancel fontSize={"small"} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Save"}>
                              <IconButton
                                color="success"
                                onClick={() =>
                                  handleSaveUpdatedBookmark()
                                }
                                size="large"
                              >
                                <CheckCircle fontSize={"small"} />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </>
                      )}
                    </ListItem>
                    <Divider />
                  </Box>
                );
              })}
              {addingAccountBookmark && (
                <>
                  <ListItem>
                    <TextField
                      style={{
                        width: "75%",
                        padding: theme.spacing(1),
                      }}
                      name={BookmarkTypes.Group}
                      onChange={handleNewBookmarkName}
                      value={accountBookmarkName}
                      variant={"standard"}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title={"Cancel"}>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleCancelNewBookmark(BookmarkTypes.Group)
                          }
                          size="large"
                        >
                          <Cancel fontSize={"small"} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Save"}>
                        <IconButton
                          color="success"
                          onClick={() =>
                            handleCreateBookmark(BookmarkTypes.Group)
                          }
                          size="large"
                        >
                          <CheckCircle fontSize={"small"} />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                padding: theme.spacing(1.5),
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={handleResetView}
              >
                Reset
              </Button>
            </div>
          </Grid>
        </Grid>
      </Typography>
    </>
  );
}
