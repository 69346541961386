import { GetBearerToken } from "./BearerToken";

async function AuthFetch(fetch: Function, url: string, options: any) {
  const getImpersonateHeaders = () => {
    return {
      impersonate: localStorage.getItem("impersonateUser"),
      impersonateAccount: localStorage.getItem("impersonateAccount"),
      demoData: localStorage.getItem("demoData"),
    };
  };

  const token = await GetBearerToken();
  const o = options || {};
  if (!o.headers) o.headers = {};
  const { impersonate, impersonateAccount, demoData } = getImpersonateHeaders();
  if (impersonate) o.headers.ImpersonateContact = impersonate;
  if (impersonateAccount) o.headers.ImpersonateAccount = impersonateAccount;
  if (demoData) o.headers.DemoData = demoData;
  o.headers.Authorization = `Bearer ${token}`;
  return fetch(url, o);
}

export default AuthFetch;
