import { PageLayoutDto } from "../../generated/models";

export interface IPageLayout {
  pageLayoutId: string;
  columns: IPageLayoutColumn[];
}

export interface IPageLayoutColumn {
  widgets: IPageLayoutWidget[];
}

export interface IPageLayoutWidget {
  widgetId: string;
  enabled: boolean;
}

export interface IPageLayoutWidgetDto {
  widgetId?: string;
  title?: string;
  widgetGroup?: string;
  enabled?: boolean;
}

export class PageLayoutWidgetDto implements IPageLayoutWidgetDto {
  widgetId?: string;
  title?: string;
  widgetGroup?: string;
  enabled?: boolean;

  constructor(data?: IPageLayoutWidgetDto) {
    if (data) {
      for (let property in data) {
        if (data.hasOwnProperty(property))
          (this as any)[property] = (data as any)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.widgetId = _data["widgetId"];
      this.title = _data["title"];
      this.widgetGroup = _data["widgetGroup"];
      this.enabled = _data["enabled"];
    }
  }

  static fromJS(data: any): PageLayoutWidgetDto {
    data = typeof data === "object" ? data : {};
    let result = new PageLayoutWidgetDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["widgetId"] = this.widgetId;
    data["title"] = this.title;
    data["widgetGroup"] = this.widgetGroup;
    data["enabled"] = this.enabled;
    return data;
  }
}

export interface ExtendsPageLayoutDto extends PageLayoutDto {}

export class PageLayout implements IPageLayout {
  pageLayoutId: string = "";
  columns: IPageLayoutColumn[] = [];

  constructor(pageConfiguration: ExtendsPageLayoutDto) {
    if (!pageConfiguration || !pageConfiguration.pageLayoutId) {
      return;
    }

    this.pageLayoutId = pageConfiguration.pageLayoutId;
    this.columns = !pageConfiguration.columns
      ? []
      : pageConfiguration.columns.map((column) => {
          return {
            widgets: !column.widgets
              ? []
              : column.widgets.map((widget) => {
                  return {
                    widgetId: widget.widgetId!,
                    enabled: widget.enabled!,
                  };
                }),
          };
        });
  }
}
