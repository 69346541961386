import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const internalDocsPath = "/shi-internal/docs";

function LoginComponent() {
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/dashboards/home" } };
  const query = useQuery();
  const docsRedirect = query.get("docsRedirect");
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    if (docsRedirect) {
      loginWithRedirect({
        appState: {
          returnTo: internalDocsPath,
        },
      }).then();
      return null;
    }

    loginWithRedirect({
      appState: {
        returnTo: from || "/dashboards/home",
      },
    }).then();
    return null;
  } else {
    return <Navigate to={docsRedirect ? internalDocsPath : from} />;
  }
}

export default LoginComponent;
