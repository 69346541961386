import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store";
import { ProfileState } from "modules/rtk/profile/profileTypes";
import { getRestMyUserProfile, postRestMyUserProfile } from "api/profileStore";
import _ from "lodash";
import GlobalConstants from "@constants";
import {
  CreateProfile,
  UserProfileCacheResponse,
} from "../../../lib/ShiOneClient";

const initialState: ProfileState = {
  profileFetchStatus: "idle",
  profileUpdateStatus: "idle",
  userProfileResponse: {} as UserProfileCacheResponse,
  portalRole: "user",
  userProfileError: "",
  isShiUser: false,
};

function setPortalRole(upcr: UserProfileCacheResponse) {
  const contact = upcr?.userProfile?.contact;

  if (contact && contact.userDefinedFieldsJson) {
    const { userDefinedFieldsJson } = contact;
    const udf = JSON.parse(userDefinedFieldsJson);
    const portalRoleNameValue = _.find(udf, (k) => {
      return k.Name === "Portal Role";
    });
    return portalRoleNameValue ? portalRoleNameValue.Value : "user";
  }
  return "user";
}

function setIsShiOne(upcr: UserProfileCacheResponse) {
  const contact = upcr?.userProfile?.contact;

  if (contact && contact.accountID) {
    return contact.accountID === GlobalConstants.shiAutotaskAccountId;
  }

  return false;
}

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    receivingProfile(state) {
      state.profileFetchStatus = "loading";
    },
    receiveProfile(state, action: PayloadAction<UserProfileCacheResponse>) {
      state.portalRole = setPortalRole(action.payload);
      state.userProfileResponse = action.payload;
      state.profileFetchStatus = "complete";
      state.userProfileError = "";
      state.isShiUser = setIsShiOne(action.payload);
    },
    userProfileError(state, action: PayloadAction<string>) {
      state.profileFetchStatus = "error";
      state.userProfileResponse = {} as UserProfileCacheResponse;
      state.userProfileError = action.payload;
      state.isShiUser = false;
    },
    updatingProfile(state) {
      state.profileUpdateStatus = "loading";
    },
    updatedProfile(state, action: PayloadAction<UserProfileCacheResponse>) {
      state.userProfileResponse.userProfile = action.payload.userProfile;
      state.portalRole = setPortalRole(action.payload);
      state.profileUpdateStatus = "complete";
      state.isShiUser = setIsShiOne(action.payload);
    },
  },
});

export const getMyUserProfile =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(profileSlice.actions.receivingProfile());
    try {
      const userProfile = await getRestMyUserProfile();
      dispatch(profileSlice.actions.receiveProfile(userProfile));
    } catch (error) {
      dispatch(profileSlice.actions.userProfileError(error as string));
    }
  };

export const updateMyUserProfile =
  (profile: CreateProfile): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(profileSlice.actions.updatingProfile());
    const userProfile = await postRestMyUserProfile(profile);
    dispatch(profileSlice.actions.updatedProfile(userProfile));
  };

export default profileSlice.reducer;
