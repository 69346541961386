import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FieldProps } from "@rjsf/utils";
import { USStates } from "./ComponentData/USStatesAndTerritories";

export function UnitedStatesSelector(props: FieldProps) {
return (
    <Autocomplete
      id={props.id}
      options={USStates}
      autoHighlight
      getOptionLabel={(option) => option.name}
      value={USStates.find((s) => s.abbreviation == props.value)}
      onChange={(e, value) => props.onChange(value)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/us-${option.abbreviation.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/us-${option.abbreviation.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          required={props.required}
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
