import MonitorIcon from "@mui/icons-material/Monitor";
import React from "react";
import { NavItemType } from "../../NavigationTypes";
import { assetItemsToShowType } from "../../NavItemsToShowTypes";

export function AssetsMenu(
  assetItemsToShow: assetItemsToShowType
): NavItemType {
  const showWarehouse =
    assetItemsToShow.showWarehouseStock ||
    assetItemsToShow.showWarehouseOrders ||
    assetItemsToShow.showHardwareLifecycleManagement ||
    assetItemsToShow.showIdcsReport;
  const menuIsVisible = Object.entries(assetItemsToShow).some(([_k, v]) => v);

  return {
    id: "assets",
    type: menuIsVisible ? "Dashboard" : "Group",
    displayName: "Assets",
    icon: <MonitorIcon fontSize="large" />,
    visible: menuIsVisible,
    path: "/assets-dashboard",
    children: [
      {
        id: "assetInventory",
        type: "Link",
        displayName: "Asset Inventory",
        path: "/assets/asset-inventory",
        visible: assetItemsToShow.showAssetReports,
      },
      {
        id: "managedDevices",
        type: "Group",
        displayName: "Managed Devices",
        visible: assetItemsToShow.showManagedDevices,
        children: [
          {
            id: "endUser",
            type: "Link",
            displayName: "End User",
            path: "/assets/managed-devices/end-user",
            visible: true,
          },
        ],
      },
      {
        id: "warehouse",
        type: "Group",
        displayName: "Warehouse",
        visible: showWarehouse,
        children: [
          {
            id: "warehouseInventory",
            type: "Link",
            displayName: "Stock",
            path: "/assets/warehouse/stock",
            visible: assetItemsToShow.showWarehouseStock,
          },
          {
            id: "warehouseOrders",
            type: "Link",
            displayName: "Orders",
            path: "/assets/warehouse/orders",
            visible: assetItemsToShow.showWarehouseOrders,
          },
          {
            id: "assetsIdcsReports",
            type: "Link",
            displayName: "Reports",
            path: "/assets/warehouse/reports",
            visible: assetItemsToShow.showIdcsReport,
          },
        ],
      },
      {
        id: "assetProductCatalog",
        type: "Group",
        displayName: "Product Catalog",
        visible: assetItemsToShow.showAssetReports,
        children: [
          {
            id: "assetProducts",
            type: "Link",
            displayName: "Products",
            path: "/assets/product-catalog/products",
            visible: true,
          },
          {
            id: "assetLifecycle",
            type: "Link",
            displayName: "Lifecycle",
            path: "/assets/product-catalog/lifecycle",
            visible: true,
          },
        ],
      },
      {
        id: "vendorContracts",
        type: "Link",
        displayName: "Vendor Contracts",
        path: "/assets/vendor-contracts",
        visible: assetItemsToShow.showAssetReports,
      },
      {
        id: "assetLocations",
        type: "Link",
        displayName: "Locations",
        path: "/assets/locations",
        visible: assetItemsToShow.showAssetReports,
      },
      {
        id: "ciscoOverview",
        type: "Link",
        displayName: "Cisco Overview",
        path: "/assets/cisco-overview",
        visible: assetItemsToShow.showCiscoOverview,
      },
    ],
  };
}
