import {
  Avatar,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
} from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IWidget } from "../DashboardConfiguration";

export const Widget = ({
  widget,
  toggleWidget,
  disabled,
}: {
  widget: IWidget;
  toggleWidget: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) => {
  const { id } = widget;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <Card
      style={{
        ...style,
        marginBottom: "0.5rem",
        cursor: "grab",
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <DragIndicator />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={widget.title} secondary={widget.widgetGroup} />
        <Switch
          id={id.toString()}
          checked={widget.enabled}
          onChange={toggleWidget}
        />
      </ListItem>
    </Card>
  );
};
