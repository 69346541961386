import {
  LocalHospital,
  LocalHospitalOutlined,
  AccessTimeOutlined,
  ReceiptOutlined,
} from "@mui/icons-material";
import {
  DateFilterOption,
  SupportOverviewWidgetClient,
  WidgetContentResponseDto_1OfSupportOverviewDto,
} from "@lib/ShiOneClient";

import React from "react";
import { useApi } from "../../utils";
import { usdFormatter } from "../../../../functions/currency";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { isLoading } from "@widgets/utils/widgetFunctions";
import { DateFilterOptionHeaderAction } from "@widgets/utils/support/DateFilterOptionHeaderAction";
import { Button } from "@mui/material";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import {
  asDto,
  BaseWidgetProps,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
} from "shared-ui";

const supportOverviewWidgetFilterKey = "support-overview-widget-filter";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    totalBillingHours: 0,
    totalBillingAmount: 0,
    totalOpenRequests: 0,
    totalRequests: 0,
  },
};

function transformFunction(
  rawData: WidgetContentResponseDto_1OfSupportOverviewDto | undefined,
  filter: DateFilterOption
): ItemBoxConfiguration[] {
  const tiles = [
    {
      amount: rawData?.data?.totalRequests,
      label: "Total Requests",
      icon: <LocalHospital fontSize={"large"} />,
      tileActions: (
        <Button
          href={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.allRequests}&CreatedDate=${filter}#${ServiceRequestsPageTabs.allRequests}`
          )}
        >
          View All Requests
        </Button>
      ),
    },
    {
      amount: rawData?.data?.totalOpenRequests || 0,
      label: "Open Requests",
      icon: <LocalHospitalOutlined fontSize={"large"} />,
      tileActions: (
        <Button
          href={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.openRequests}#${ServiceRequestsPageTabs.allRequests}`
          )}
        >
          View Open Requests
        </Button>
      ),
    },
    {
      amount: rawData?.data?.totalBillingHours,
      label: "Billed Hours",
      icon: <AccessTimeOutlined fontSize={"large"} />,
      tileActions: (
        <Button
          href={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.allRequests}&CreatedDate=${filter}#${ServiceRequestsPageTabs.billableRequests}`
          )}
        >
          View Billed Requests
        </Button>
      ),
    },
    {
      amount: usdFormatter.format(rawData?.data?.totalBillingAmount ?? 0),
      label: "Billed Amount",
      icon: <ReceiptOutlined fontSize={"large"} />,
      tileActions: (
        <Button
          href={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.allRequests}&CreatedDate=${filter}#${ServiceRequestsPageTabs.billableRequests}`
          )}
        >
          View Billed Requests
        </Button>
      ),
    },
  ];

  const removeOpenRequestsTileFilters = [
    DateFilterOption.LastMonth,
    DateFilterOption.LastQuarter,
    DateFilterOption.LastYear,
  ];

  if (removeOpenRequestsTileFilters.includes(filter)) {
    return tiles.filter((t) => t.label !== "Open Requests");
  }

  return tiles;
}

function useGetSupportOverviewData(dateFilterOption: DateFilterOption) {
  const api = useApi(SupportOverviewWidgetClient);

  return useKeyInformationData({
    queryKey: [`support-overview-widget-${dateFilterOption}`],
    apiFunction: () => api.getSupportRequestsOverview(dateFilterOption),
    transformFunction: (rawData) =>
      transformFunction(rawData, dateFilterOption),
    placeholderData: asDto(placeholderData),
  });
}

const SupportOverviewWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  const initialDateFilter = DateFilterOption.CurrentMonth;

  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      pageLayoutWidget.widgetId!,
      supportOverviewWidgetFilterKey,
      initialDateFilter
    );

  const responseFunc = useGetSupportOverviewData(currentFilterValue);

  const isLoadingData = isLoading([
    isLoadingFilterUserConfig,
    responseFunc.isPlaceholderData,
  ]);

  return (
    <KeyInformationWidget
      headerAction={
        <DateFilterOptionHeaderAction
          filterBy={currentFilterValue}
          setFilterBy={setFilter}
          loading={isLoadingData}
          filterKey={supportOverviewWidgetFilterKey}
        />
      }
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => responseFunc}
    />
  );
};

export default SupportOverviewWidget;
