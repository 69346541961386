import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import GlobalConstants from "@constants";
import {
  AllServiceRequestsResponse,
  ServiceRequestDTO,
  ServiceRequestResponse,
  ServiceRequestState,
  TicketDTO,
} from "lib/ShiOneClient";
import { AppDispatch, AppThunk } from "../../../store";
import {
  getRestAllServiceRequests,
  getRestMyAdditionalContactRequests,
  getRestMyServiceRequests,
  getRestServiceRequestById,
  postRestNewServiceRequest,
} from "../../../api/serviceRequestStore";
import { normalizeUserDefinedFieldsJson } from "../../../containers/Modules/SupportCenterV2/Helpers/RequestsHelpers";

export interface autoTaskTicket {
  description: string;
  priority: string;
  title: string;
  ticketAttachmentGuids: string[];
  secondaryContactIds: number[];
  category: string;
  ticketType: string;
  issueType: string;
  subIssueType: string;
  contract: string;
  configurationItemId: string;
  onBehalfAccount: string;
  onBehalfUser: string;
  serviceRequestId?: number;
  preferredContactMethod: string;
}

export interface ServiceRequestReduxState {
  allServiceRequestsFetchStatus: string;
  allServiceRequests: AllServiceRequestsResponse;
  myServiceRequestsFetchStatus: string;
  myServiceRequests: ServiceRequestDTO[];
  myAdditionalContactRequestsFetchStatus: string;
  myAdditionalContactRequests: ServiceRequestDTO[];
  currentServiceRequestFetchStatus: string;
  currentServiceRequest: ServiceRequestDTO;
  lastCreatedServiceRequest?: ServiceRequestResponse;
  createServiceRequestStatus: string;
  refreshServiceRequests: boolean;
}

const initialState: ServiceRequestReduxState = {
  allServiceRequestsFetchStatus: GlobalConstants.fetchStatus.idle,
  allServiceRequests: {} as AllServiceRequestsResponse,
  myServiceRequestsFetchStatus: GlobalConstants.fetchStatus.idle,
  myServiceRequests: [],
  myAdditionalContactRequestsFetchStatus: GlobalConstants.fetchStatus.idle,
  myAdditionalContactRequests: [],
  currentServiceRequestFetchStatus: GlobalConstants.fetchStatus.idle,
  currentServiceRequest: {} as ServiceRequestDTO,
  lastCreatedServiceRequest: undefined,
  createServiceRequestStatus: GlobalConstants.fetchStatus.idle,
  refreshServiceRequests: false,
};

const serviceRequestSlice = createSlice({
  name: "serviceRequest",
  initialState,
  reducers: {
    setRefreshServiceRequests(state, payload: PayloadAction<boolean>) {
      state.refreshServiceRequests = payload.payload;
    },
    fetchingAllServiceRequests(state) {
      state.allServiceRequestsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedAllServiceRequests(
      state,
      action: PayloadAction<AllServiceRequestsResponse>
    ) {
      state.allServiceRequests = action.payload;
      state.allServiceRequestsFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveAllServiceRequestsError(state) {
      state.allServiceRequestsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    fetchingMyServiceRequests(state) {
      state.myServiceRequestsFetchStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedMyServiceRequests(
      state,
      action: PayloadAction<ServiceRequestDTO[]>
    ) {
      state.myServiceRequests = action.payload;
      state.myServiceRequestsFetchStatus = GlobalConstants.fetchStatus.complete;
    },
    receiveMyServiceRequestsError(state) {
      state.myServiceRequestsFetchStatus = GlobalConstants.fetchStatus.error;
    },
    fetchingMyAdditionalContactRequests(state) {
      state.myAdditionalContactRequestsFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receivedMyAdditionalContactRequests(
      state,
      action: PayloadAction<ServiceRequestDTO[]>
    ) {
      state.myAdditionalContactRequests = action.payload;
      state.myAdditionalContactRequestsFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveMyAdditionalContactRequestsError(state) {
      state.myAdditionalContactRequestsFetchStatus =
        GlobalConstants.fetchStatus.error;
    },
    fetchingCurrentServiceRequest(state) {
      state.currentServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receivedCurrentServiceRequest(
      state,
      action: PayloadAction<ServiceRequestDTO>
    ) {
      state.currentServiceRequest = action.payload;
      state.currentServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveCurrentServiceRequestError(state) {
      state.currentServiceRequestFetchStatus =
        GlobalConstants.fetchStatus.error;
    },
    resetCurrentServiceRequest(state) {
      state.currentServiceRequest = initialState.currentServiceRequest;
      state.currentServiceRequestFetchStatus =
        initialState.currentServiceRequestFetchStatus;
    },
    serviceRequestInFlight(state) {
      state.createServiceRequestStatus = GlobalConstants.fetchStatus.loading;
    },
    serviceRequestCreationCompletedSuccessfully(
      state,
      action: PayloadAction<ServiceRequestResponse>
    ) {
      state.createServiceRequestStatus = GlobalConstants.fetchStatus.complete;
      state.lastCreatedServiceRequest = action.payload;
    },
    serviceRequestCreationError(state) {
      state.createServiceRequestStatus = GlobalConstants.fetchStatus.error;
    },
  },
});

export const getAllServiceRequests =
  (request: AllServiceRequestsResponse): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.fetchingAllServiceRequests());
    try {
      const allRequests = await getRestAllServiceRequests(request);
      dispatch(
        serviceRequestSlice.actions.receivedAllServiceRequests(allRequests)
      );
    } catch {
      dispatch(serviceRequestSlice.actions.receiveAllServiceRequestsError());
    }
  };

export const getMyServiceRequests =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.fetchingMyServiceRequests());
    try {
      const myRequests = await getRestMyServiceRequests();
      dispatch(
        serviceRequestSlice.actions.receivedMyServiceRequests(myRequests)
      );
    } catch {
      dispatch(serviceRequestSlice.actions.receiveMyServiceRequestsError());
    }
  };

export const getMyAdditionalContactRequests =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.fetchingMyAdditionalContactRequests());
    try {
      const myAdditionalContactRequests =
        await getRestMyAdditionalContactRequests();
      dispatch(
        serviceRequestSlice.actions.receivedMyAdditionalContactRequests(
          myAdditionalContactRequests
        )
      );
    } catch {
      dispatch(
        serviceRequestSlice.actions.receiveMyAdditionalContactRequestsError()
      );
    }
  };

// You can force the service requests page to refresh by dispatching this with a value of true
export const setRefreshServiceRequests =
  (refresh: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.setRefreshServiceRequests(refresh));
  };

export const getServiceRequestById =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.fetchingCurrentServiceRequest());
    try {
      const currentRequest = await getRestServiceRequestById(id);
      if (currentRequest.autotaskTicketData) {
        currentRequest.autotaskTicketData.userDefinedFieldsJson =
          normalizeUserDefinedFieldsJson(
            currentRequest.autotaskTicketData.userDefinedFieldsJson
          );
      }
      dispatch(
        serviceRequestSlice.actions.receivedCurrentServiceRequest(
          currentRequest
        )
      );
    } catch {
      dispatch(serviceRequestSlice.actions.receiveCurrentServiceRequestError());
    }
  };

export const resetCurrentServiceRequest =
  (): AppThunk => (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.resetCurrentServiceRequest());
  };

export const postServiceRequest =
  (updatedTicket: autoTaskTicket, formData?: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(serviceRequestSlice.actions.serviceRequestInFlight());
    try {
      const atTicketDTO = new TicketDTO();
      atTicketDTO.description = updatedTicket.description;
      atTicketDTO.priority = parseInt(updatedTicket.priority);
      atTicketDTO.title = updatedTicket.title;
      atTicketDTO.ticketAttachmentGuids = updatedTicket.ticketAttachmentGuids;
      atTicketDTO.secondaryContactIds = updatedTicket.secondaryContactIds;
      atTicketDTO.ticketCategory = parseInt(updatedTicket.category);
      atTicketDTO.ticketType = parseInt(updatedTicket.ticketType);
      atTicketDTO.issueType = parseInt(updatedTicket.issueType);
      atTicketDTO.subIssueType = parseInt(updatedTicket.subIssueType);
      atTicketDTO.contractID = parseInt(updatedTicket.contract);
      atTicketDTO.installedProductID = parseInt(
        updatedTicket.configurationItemId
      );
      atTicketDTO.accountID = parseInt(updatedTicket.onBehalfAccount);
      atTicketDTO.contactID = parseInt(updatedTicket.onBehalfUser);
      atTicketDTO.userDefinedFieldsJson = JSON.stringify([
        {
          Name: "Preferred Contact Method",
          Value: updatedTicket.preferredContactMethod,
        },
      ]);

      const serviceRequestDTO = new ServiceRequestDTO();
      serviceRequestDTO.autotaskTicketData = atTicketDTO;
      serviceRequestDTO.state = ServiceRequestState.Open;

      if (formData) {
        serviceRequestDTO.serviceRequestData = JSON.stringify(formData);
        serviceRequestDTO.serviceRequestMappingId =
          updatedTicket.serviceRequestId;
      }

      const result = await postRestNewServiceRequest(serviceRequestDTO);
      dispatch(
        serviceRequestSlice.actions.serviceRequestCreationCompletedSuccessfully(
          result
        )
      );
    } catch {
      dispatch(serviceRequestSlice.actions.serviceRequestCreationError());
    }
  };

export default serviceRequestSlice.reducer;
