import {
  GET_SITE,
  GET_SITE_ERROR,
  GET_SITE_SUCCESS,
  SET_OPEN_SIDEBAR,
  SET_SUBDOMAIN,
  SET_SUBDOMAIN_ERROR,
  SET_SUBDOMAIN_SUCCESS,
  SET_WHITELABEL,
  SET_WHITELABEL_ERROR,
  SET_WHITELABEL_SUCCESS,
} from "../actions/siteActions";
import GlobalConstants from "@constants";

const initialState = {
  default: true,
  logoUrl: GlobalConstants.storageAccountUrls.images.shiOneLogo,
  landingTitle: "Support Services Portal",
  topLeftTitle: "NAVIGATION",
  welcomeMessage: "Welcome to Your Support Services Portal",
  subDomain: undefined,
};

export function siteReducer(
  state = {
    labelInfo: initialState,
    fetchingSite: false,
    fetchSiteComplete: false,
    fetchSiteError: undefined,
    settingSubdomain: false,
    setSubdomainComplete: false,
    setSubdomainError: undefined,
    settingWhitelabel: false,
    setWhitelabelComplete: false,
    setWhitelabelError: undefined,
    openSidebar: undefined,
  },
  action
) {
  switch (action.type) {
    case SET_OPEN_SIDEBAR:
      return Object.assign({}, state, {
        openSidebar: action.payload.data,
      });
    case GET_SITE:
      return Object.assign({}, state, {
        fetchingSite: true,
        fetchSiteComplete: false,
      });
    case GET_SITE_SUCCESS:
      // console.log('GET_SITE_SUCCESS', action.payload.data)
      return Object.assign({}, state, {
        fetchingSite: false,
        fetchSiteComplete: true,
        labelInfo: action.payload.data,
      });
    case GET_SITE_ERROR:
      // console.log('GET_SITE_ERROR')
      return Object.assign({}, state, {
        fetchingSite: false,
        fetchSiteComplete: true,
        fetchSiteError: action.payload.data,
      });
    case SET_SUBDOMAIN:
      return Object.assign({}, state, {
        settingSubdomain: true,
        setSubdomainComplete: false,
      });
    case SET_SUBDOMAIN_SUCCESS:
      return Object.assign({}, state, {
        settingSubdomain: false,
        setSubdomainComplete: true,
        labelInfo: action.payload.data,
      });
    case SET_SUBDOMAIN_ERROR:
      return Object.assign({}, state, {
        settingSubdomain: false,
        setSubdomainComplete: true,
        setSubdomainError: action.payload.data,
      });
    case SET_WHITELABEL:
      return Object.assign({}, state, {
        settingWhitelabel: true,
        setWhitelabelComplete: false,
      });
    case SET_WHITELABEL_SUCCESS:
      return Object.assign({}, state, {
        settingWhitelabel: false,
        setWhitelabelComplete: true,
        labelInfo: action.payload.data,
      });
    case SET_WHITELABEL_ERROR:
      return Object.assign({}, state, {
        settingWhitelabel: false,
        setWhitelabelComplete: true,
        setWhitelabelError: action.payload.data,
      });
    default:
      return state;
  }
}
