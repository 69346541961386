import { PageLayoutClient, PageLayoutDto } from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { useApi } from "../../utils";

export function useGetDashboardConfiguration(pageId: string, cacheKey: string) {
  const api = useApi(PageLayoutClient);
  return useQuery<PageLayoutDto, Error>({
    queryKey: [cacheKey, pageId],
    queryFn: () => api.getPageLayout(pageId).then((r) => r),
    staleTime: ms("10m"),
  });
}
