import React from "react";
import { Typography, TypographyProps } from "@mui/material";

interface DenseCaptionProps extends TypographyProps {
  children: React.ReactNode;
}
const DenseCaption = (props: DenseCaptionProps) => {
  const { children, variant = "caption", ...others } = props;
  return (
    <Typography
      variant={variant}
      component={"div"}
      fontSize={"0.8em"}
      lineHeight={"0.8em"}
      {...others}
    >
      {children}
    </Typography>
  );
};

export default DenseCaption;
