import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "../../../store";
import { azureSimpleRecommendationsState } from "./azureSimpleRecommendationsTypes";
import { getRestAzureSimpleRecommendations } from "../../../api/azureSimpleRecommendationsStore";
import { ResourceRecommendation } from "lib/ShiOneClient";

const initialState: azureSimpleRecommendationsState = {
  azureSimpleRecommendationsFetchStatus: "idle",
  azureSimpleRecommendations: [],
};

const azureSimpleRecommendationsSlice = createSlice({
  name: "azureSimpleRecommendations",
  initialState,
  reducers: {
    receivingAzureSimpleRecommendations(state) {
      state.azureSimpleRecommendationsFetchStatus = "loading";
    },
    receivedAzureSimpleRecommendations(
      state,
      action: PayloadAction<ResourceRecommendation[]>
    ) {
      state.azureSimpleRecommendations = action.payload;
      state.azureSimpleRecommendationsFetchStatus = "complete";
    },
    receiveAzureSimpleRecommendationsError(state) {
      state.azureSimpleRecommendationsFetchStatus = "error";
    },
  },
});

export const getAzureSimpleRecommendations =
  (subscriptionId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      azureSimpleRecommendationsSlice.actions.receivingAzureSimpleRecommendations()
    );

    try {
      const simpleRecommendations = await getRestAzureSimpleRecommendations(
        subscriptionId
      );
      dispatch(
        azureSimpleRecommendationsSlice.actions.receivedAzureSimpleRecommendations(
          simpleRecommendations
        )
      );
    } catch {
      dispatch(
        azureSimpleRecommendationsSlice.actions.receiveAzureSimpleRecommendationsError()
      );
    }
  };

export default azureSimpleRecommendationsSlice.reducer;
