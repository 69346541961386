import Chart from "chart.js/auto";
import { useTheme } from "@mui/material";

export const useChartJsDefaults = () => {
  const theme = useTheme();
  Chart.defaults.responsive = true;
  Chart.defaults.maintainAspectRatio = false;
  Chart.defaults.scale.grid.color = theme.palette.action.disabled;
  Chart.defaults.color = theme.palette.text.secondary;
};
