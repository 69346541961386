import {
  AccountNameWithAxNumber,
  AccountClient,
  AccountSearchQuery,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";

const api = new AccountClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestAccountNameWithAxNumber(): Promise<
  AccountNameWithAxNumber[]
> {
  return api.getAccountNamesAndAxNumbers();
}

export async function getRestAllAccountNameWithAxNumber(
  searchQuery: AccountSearchQuery
): Promise<AccountNameWithAxNumber[]> {
  return api.getAllAccountNamesAndAxNumbersForOnboarding(searchQuery);
}
