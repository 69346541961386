import {
  ContractRequestRankingsWidgetClient,
  DateFilterOption,
  WidgetContentResponseDto_1OfContractRequestRankingsVisualsDto,
} from "@lib/ShiOneClient";
import { useApi } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { ContractRequestRankingsPlaceholderData } from "@widgets/utils/services/ContractRequestRankingsWidgetFunctions";

const useGetContractRequestRankings = (dateFilterOption: DateFilterOption) => {
  const api = useApi(ContractRequestRankingsWidgetClient);

  return useQuery<
    WidgetContentResponseDto_1OfContractRequestRankingsVisualsDto,
    Error
  >({
    queryKey: ["contract-request-rankings-services-widget", dateFilterOption],
    queryFn: () => api.getContractRequestRankingsWidgetData(dateFilterOption),
    placeholderData:
      ContractRequestRankingsPlaceholderData as WidgetContentResponseDto_1OfContractRequestRankingsVisualsDto,
  });
};

export default useGetContractRequestRankings;
