import { PageLayoutWidgetDto } from "@lib/ShiOneClient";
import { WidgetWrapper } from "shared-ui";
import { Typography } from "@mui/material";
import React from "react";

export default function UserEngagementWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={false}
      noPadding={true}
    >
      <Typography>Que pasa amigos de {pageLayoutWidget.title}</Typography>
    </WidgetWrapper>
  );
}
