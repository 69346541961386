import React, { useEffect, useState } from "react";
import { ServicesExpiringContractDto } from "@lib/ShiOneClient";
import { Grid, Typography } from "@mui/material";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import { lighten } from "@mui/material/styles";
import shiColors from "shared-ui/src/theme/shiColors";
import { useLabsLogoStore } from "@widgets/utils/Logos/useLabsLogoStore";
import { ImageResponse } from "@widgets/utils/Logos/LabsLogoService";
import { SkeletonWrapper } from "shared-ui";

export default function ServicesExpiringContractItem({
  contract,
  containerStyle,
}: {
  contract: ServicesExpiringContractDto | undefined;
  containerStyle?: React.CSSProperties;
}) {
  const { logoService } = useLabsLogoStore();
  const [logo, setLogo] = useState<ImageResponse>();
  const [isLoading, setIsLoading] = useState(true);

  function getContractTypeColor(contractType: string) {
    switch (contractType) {
      case "SaaS":
        return shiColors.pink.DEFAULT;
      case "Service":
        return shiColors.blue.DEFAULT;
      default:
        return shiColors.pink.DEFAULT;
    }
  }

  useEffect(() => {
    if (!contract) return;
    const fetchLogo = async () => {
      if (contract?.company) {
        const image = await logoService.getLogoByCompanyName(contract.company);
        setLogo(image);
        setIsLoading(false);
      }
    };

    fetchLogo().then();
  }, [contract, logoService]);

  return (
    <div style={containerStyle}>
      <SkeletonWrapper loading={isLoading}>
        <Grid container>
          <Grid item xs={4}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {logo ? (
                <img
                  src={logo.image}
                  alt={""}
                  style={{ maxWidth: "40px", maxHeight: "50px" }}
                />
              ) : (
                <></>
              )}
              <Typography style={{ marginLeft: "1rem" }}>
                {contract?.name}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {contract?.contractType && (
              <ColoredChips
                bgColor={getContractTypeColor(contract?.contractType)}
                label={contract?.contractType}
              />
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Typography
              align="right"
              color={lighten(shiColors.orange.DEFAULT, 0.4)}
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "0.17px",
                textAlign: "center",
              }}
              variant="body2"
            >{`Expires in ${contract?.daysUntilExpiration} ${
              contract?.daysUntilExpiration === 1 ? "Day" : "Days"
            }`}</Typography>
          </Grid>
        </Grid>
      </SkeletonWrapper>
    </div>
  );
}
