import { useEffect, useState } from "react";
import { UserProfileCacheResponse } from "@lib/ShiOneClient";
import userflow from "userflow.js";
import { useGetUserProfile } from "../hooks/useGetUserProfile";

async function initUserFlow(userProfile: UserProfileCacheResponse) {
  userflow.init(import.meta.env.USERFLOW_TOKEN);

  let userId = userProfile.userProfile?.mail!;
  if (userProfile.virtualAdmin) {
    userId = userProfile.shiProfile?.mail!;
  } else if (userProfile.shiProfile && !userProfile.virtualAdmin) {
    userId += `:${userProfile.shiProfile?.mail}`;
  }

  await userflow.identify(userId, {
    name: userProfile.userProfile?.displayName,
    email: userProfile.userProfile?.mail,
    environment: import.meta.env.DIST_ENV,
    account_id: userProfile.userProfile?.contact?.accountID,
    segment: userProfile.segment,
  });

  await userflow.group(
    userProfile.userProfile?.contact?.accountID?.toString()!
  );
}

export function UserFlow() {
  const [userFlowInitialized, setUserFlowInitialized] = useState(false);
  const userProfile = useGetUserProfile().data;
  const [dismounting, setDismounting] = useState(false);

  useEffect(() => {
    if (
      import.meta.env.USERFLOW_ENABLED &&
      import.meta.env.USERFLOW_TOKEN &&
      userProfile
    ) {
      if (!userFlowInitialized) {
        initUserFlow(userProfile)
          .then(() => {
            // If the component is unmounting, don't set the state
            if (dismounting) {
              return;
            }

            setUserFlowInitialized(true);
          })
          .catch(() => {});
      }
    }

    return () => {
      setDismounting(true);
    };
  }, [userProfile, userFlowInitialized, dismounting]);

  return <></>;
}
