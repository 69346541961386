import { styled } from "@mui/material/styles";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { projectColors } from "@features/projects-feature/utils/projectColors";

export const StyledDialogHeaderContainer = styled(Box)({
  alignItems: "center",
  gap: 1,
});

export const StyledDialogHeader = styled(Typography)({
  color: projectColors.contractDialog.header,
  fontWeight: 400,
  fontFamily: "Roboto",
  lineHeight: "21.34px",
  fontSize: "16px",
});

export const StyledDialogSubheader = styled(Typography)({
  color: projectColors.contractDialog.subheader,
  fontWeight: 400,
  fontFamily: "Roboto",
  lineHeight: "20.02px",
  fontSize: "14px",
  letterSpacing: "0.17px",
});

export const StyledDialogCloseIcon = styled(IconButton)({
  color: projectColors.contractDialog.closeIcon,
  width: "25px",
  height: "25px",
  "&:hover": {
    background: "transparent",
  },
});

export const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 24px",
});

export const StyledDialogContent = styled(DialogContent)({
  overflowY: "auto",
  height: "687px",
});
