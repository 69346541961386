import { FormControl, MenuItem, Select } from "@mui/material";
import { useApi } from "../../utils";
import {
  AssetFilterByCategoryWidgetClient,
  AssetWidgetBaseRecord,
  AssetWidgetType,
  FilterByCategory,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";

import DevicesIcon from "@mui/icons-material/Devices";
import React from "react";
import {
  assetTypesColors,
  categoryColors,
  contractCoverageWidgetColors,
  lifecycleStateColors,
} from "@features/assets-feature/utils/assetsColors";
import {
  NoContent,
  SkeletonWrapper,
  Summary,
  useSummaryData,
} from "shared-ui";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";
import LabelLink from "shared-ui/src/components/LabelLink";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { shiOneUrl } from "@constants";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    records: [
      { category: "Hardware", assetsCount: 1000 },
      { category: "Consumables", assetsCount: 10 },
      { category: "Software", assetsCount: 30 },
      { category: "Subscription", assetsCount: 4 },
    ],
  },
};

const allAssetManagementColors = {
  ...assetTypesColors,
  ...categoryColors,
  ...lifecycleStateColors,
};

const getStateObject = (
  filterName: FilterByCategory,
  value: string | undefined
) => {
  let stateObject = {};
  switch (filterName) {
    case FilterByCategory.ByType:
      stateObject = { assetType: value };
      break;
    case FilterByCategory.ByCategory:
      stateObject = { shiCategory: value };
      break;
    case FilterByCategory.ByLifecycleState:
      stateObject = { shiLifecycleState: value };
      break;
    case FilterByCategory.ByContractCoverage:
      stateObject = { contractCoverage: value };
      break;
  }
  return stateObject;
};
function transformDataIntoSummaryWidgetData(
  assets: AssetWidgetBaseRecord[] | undefined,
  type: AssetWidgetType,
  filter: FilterByCategory
) {
  if (!AssetWidgetBaseRecord.length) {
    return [] as Summary[];
  }

  const lifecycleSortOrder: { [key: string]: number } = {
    "": 0,
    "General Availability": 1,
    "End of Life": 2,
    "End of Sale": 3,
    "End of Software Maintenance": 4,
    "End of Support": 5,
    Unknown: 6,
  };
  const isAssetType = type === AssetWidgetType.Asset;
  return assets
    ?.sort(
      (a: AssetWidgetBaseRecord, b: AssetWidgetBaseRecord) =>
        lifecycleSortOrder[a.category ?? ""] -
        lifecycleSortOrder[b.category ?? ""]
    )
    ?.map((asset, index) => ({
      name: "",
      label: asset.category ?? "Unknown",
      customLabel: (
        <LabelLink
          label={asset.category ?? "Unknown"}
          baseUrl={AppendIfPrefixExists(
            isAssetType
              ? shiOneUrl.assets.assetInventory
              : shiOneUrl.assets.productCatalogProducts
          )}
          css={{ fontWeight: "normal" }}
          state={{
            ...getStateObject(filter, asset.category),
            includeChildren: isAssetType,
          }}
        />
      ),
      data: asset.assetsCount ?? 0,
      color:
        allAssetManagementColors[asset.category?.toLowerCase() ?? "unknown"] ??
        contractCoverageWidgetColors[index],
    }));
}

function HeaderAction({
  type,
  filter,
  setFilter,
  loading,
}: {
  readonly type: AssetWidgetType;
  readonly filter: string;
  readonly setFilter: (string: string) => void;
  readonly loading: boolean;
}) {
  return (
    <SkeletonWrapper loading={loading}>
      <FormControl sx={{ width: type === AssetWidgetType.Asset ? 220 : 200 }}>
        <Select
          sx={{ height: "40px", width: "100%" }}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value={FilterByCategory.ByType}>By Type</MenuItem>
          <MenuItem value={FilterByCategory.ByCategory}>By Category</MenuItem>
          <MenuItem value={FilterByCategory.ByLifecycleState}>
            By Lifecycle State
          </MenuItem>
          {type === AssetWidgetType.Asset && (
            <MenuItem value={FilterByCategory.ByContractCoverage}>
              By Contract Coverage
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </SkeletonWrapper>
  );
}

function useGetProductWidgetsData(
  filter: FilterByCategory,
  type: AssetWidgetType
) {
  const api = useApi(AssetFilterByCategoryWidgetClient);

  return useSummaryData<AssetWidgetBaseRecord[]>({
    queryKey: [`${type}-widget-data`, filter],
    apiFunction: () =>
      api
        .getFilteredAssetByCategory(type, filter)
        .then((r) => r.data?.records ?? []),
    transformFunction: (data) =>
      transformDataIntoSummaryWidgetData(data, type, filter) ?? [],
    placeholderData: placeholderData.data.records as AssetWidgetBaseRecord[],
  });
}

export default function FilteredAssetProductWidget({
  pageLayoutWidget,
  type,
}: {
  readonly pageLayoutWidget: PageLayoutWidgetDto;
  readonly type: AssetWidgetType;
}) {
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    "product-widget-filter",
    FilterByCategory.ByType
  );

  const response = useGetProductWidgetsData(currentFilterValue, type);

  return (
    <SummaryWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={() => response}
      leftLabel={
        type === AssetWidgetType.Product ? "Total Products" : "Total Assets"
      }
      headerAction={
        <HeaderAction
          type={type}
          filter={currentFilterValue}
          setFilter={setFilter}
          loading={response.isPlaceholderData}
        />
      }
      noDataElement={
        <NoContent
          header={"No Assets"}
          body={"You have no assets to view at the moment"}
          actionText={""}
          icon={<DevicesIcon />}
        />
      }
    />
  );
}
