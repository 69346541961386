import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Button, Link, TextField, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import { useDispatch, useSelector } from "react-redux";
import { updateMyUserProfile } from "../modules/rtk/profile/profileSlice";
import ShiOneLogo from "shared-ui/src/components/ShiOneLogo";
import AuthPageContainer from "./AuthPageContainer";

export default function RegisterPage(props) {
  const { user, isLoading } = useAuth0();
  const dispatch = useDispatch();

  const { profileUpdateStatus, userProfileResponse } = useSelector(
    (state) => state.profile
  );

  let defaultFirstName = "";
  let defaultLastName = "";
  let defaultPhoneNumber = "";
  let defaultExtension = "";

  if (userProfileResponse && userProfileResponse.userProfile) {
    defaultFirstName = userProfileResponse.userProfile.givenName;
    defaultLastName = userProfileResponse.userProfile.surname;
    defaultPhoneNumber = userProfileResponse.userProfile.phoneNumber;
    defaultExtension = userProfileResponse.userProfile.extension;
  }

  if (isLoading) {
    return <Loading />;
  }

  const initialInputs = {
    email: userProfileResponse.userProfile.mail ?? user.email,
    firstName: defaultFirstName ?? user.given_name,
    lastName: defaultLastName ?? user.family_name,
    phoneNumber: defaultPhoneNumber ?? user.phone_number,
    extension: defaultExtension,
  };
  const handleSubmit = (values) => {
    dispatch(updateMyUserProfile(values));
  };

  return (
    <AuthPageContainer>
      <ShiOneLogo />
      <Typography variant="h6">
        {profileUpdateStatus !== "complete"
          ? "Profile Registration"
          : "Registration Complete!"}
      </Typography>
      {profileUpdateStatus !== "complete" ? (
        <Formik
          initialValues={initialInputs}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required("Required"),
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            phoneNumber: Yup.string().required("Phone number is required"),
            extension: Yup.string(),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { values, touched, errors, handleChange, handleBlur } = props;
            return (
              <Form>
                <TextField
                  label="Email"
                  id="email"
                  type="email"
                  name="email"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    <span>
                      If this is incorrect, please&nbsp;
                      <Link style={{ textDecoration: "none" }} href="/logout">
                        log out
                      </Link>
                      &nbsp;and log back in with the correct email address.
                    </span>
                  }
                  required
                  disabled
                />
                <TextField
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={<ErrorMessage name="firstName" />}
                  error={errors.firstName && touched.firstName}
                  required
                  disabled={profileUpdateStatus === "loading"}
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  id="lastName"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={<ErrorMessage name="lastName" />}
                  error={errors.lastName && touched.lastName}
                  required
                  disabled={profileUpdateStatus === "loading"}
                />
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={<ErrorMessage name="phoneNumber" />}
                  error={errors.phoneNumber && touched.phoneNumber}
                  required
                  disabled={profileUpdateStatus === "loading"}
                />
                <TextField
                  label="Phone Extension"
                  name="extension"
                  id="extension"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={values.extension}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.extension && touched.extension}
                  disabled={profileUpdateStatus === "loading"}
                />
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={profileUpdateStatus === "loading"}
                  sx={{ mt: 3 }}
                >
                  {profileUpdateStatus === "loading"
                    ? "Submitting..."
                    : "Finish Registration"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>
          <NavLink
            to={AppendIfPrefixExists("/logout")}
            className="landing-btn"
            activeClassName="active"
          >
            Return to Login
          </NavLink>
        </div>
      )}
    </AuthPageContainer>
  );
}
