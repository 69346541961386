import { Button, Stack, Typography, useTheme } from "@mui/material";
import { LocalHospitalRounded } from "@mui/icons-material";
import React, { ReactNode } from "react";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

export function NoContent({
  containerHeight,
  header,
  body,
  actionText,
  actionLink,
  icon,
}: {
  containerHeight?: number;
  header?: string;
  body?: string;
  actionText?: string;
  actionLink?: string;
  icon?: ReactNode;
}) {
  const theme = useTheme();
  const greyColor = theme.palette.text.secondary;

  return (
    <div
      style={{
        height: containerHeight || "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        gap={1}
        height={"100%"}
        sx={{
          padding: 1,
          "& .MuiSvgIcon-root": {
            fontSize: "48px",
            color: greyColor,
            opacity: 0.5,
          },
        }}
      >
        {icon ?? <LocalHospitalRounded />}
        <Typography color={greyColor} variant={"h6"}>
          {header ?? "No data found"}
        </Typography>
        <Typography color={greyColor} variant={"body2"}>
          {body ??
            "Create a new request to report an incident or submit a service request"}
        </Typography>
        <Button
          size="small"
          href={
            actionLink ?? AppendIfPrefixExists("/support-center/new-request")
          }
        >
          {actionText ?? "get support"}
        </Button>
      </Stack>
    </div>
  );
}
