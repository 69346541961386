import { Theme } from "@mui/material";

const MuiTab = (theme: Theme, rootElement: HTMLElement) => ({
  defaultProps: {
    container: rootElement,
  },
  styleOverrides: {
    root: {
      color: theme.palette.text.secondary,
      "&.Mui-selected": {
        color: theme.palette.text.secondary,
      },
    },
  },
});
export default MuiTab;
