import axios from "axios";
import GlobalConstants from "@constants";
const isProductionOrStaging =
  import.meta.env.DIST_ENV === "prod" ||
  import.meta.env.DIST_ENV === "staging";

function getManifestUrlByEnv(): string {
  if (isProductionOrStaging) {
    return GlobalConstants.storageAccountUrls.blueprints.production;
  }
  return GlobalConstants.storageAccountUrls.blueprints.integration;
}

export async function getRestBlueprints(): Promise<any[]> {
  try {
    const manifestUrl = getManifestUrlByEnv();
    const response = await axios.get<any[]>(manifestUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}
