import { NavItemType } from "../../NavigationTypes";
import { cloudOffice365ItemsToShowType } from "../../NavItemsToShowTypes";

export function CloudOffice365MenuV2(
  cloudOffice365ItemsToShow: cloudOffice365ItemsToShowType
): NavItemType {
  return {
    id: "cloudOffice365",
    type: "Group",
    displayName: "Office 365",
    visible: Object.entries(cloudOffice365ItemsToShow).some(([_k, v]) => v),
    children: [
      {
        id: "cloudOffice365Backup",
        type: "Link",
        displayName: "Backup",
        path: "/cloud/office365/backup",
        visible: cloudOffice365ItemsToShow.showBackup,
      },
      {
        id: "cloudOffice365Subscriptions",
        type: "Link",
        displayName: "Subscriptions",
        path: "/cloud/office365/subscriptions",
        visible: cloudOffice365ItemsToShow.showSubscriptions,
      },
    ],
  };
}
