import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GlobalConstants from "@constants";
import { getAccountBillingAddressData } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import { debounce } from "lodash";
import { ServiceRequestCloudTypes } from "lib/ShiOneClient";
import AlertWidget from "../Widgets/AlertWidget";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  alertMargin: {
    marginTop: theme.spacing(2),
  },
});

export default function BillingAddressField(props) {
  const { onChange, required, schema, disabled, formContext } = props;
  const theme = useTheme();
  const classes = css(theme);
  const dispatch = useDispatch();
  const { cloudType } = schema;
  const { accountBillingAddress, accountBillingAddressFetchStatus } =
    useSelector((state) => state.serviceRequestTemplate);

  const isLoading =
    accountBillingAddressFetchStatus === GlobalConstants.fetchStatus.loading;
  const isComplete =
    accountBillingAddressFetchStatus === GlobalConstants.fetchStatus.complete;

  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    if (formContext?.billingDetailsDefinition?.accountBillingAddress) {
      setSelectedAddress(
        formContext.billingDetailsDefinition.accountBillingAddress
      );
    }
  }, []);

  useEffect(() => {
    if (formContext.shiAccountDetails?.axAccountNumber) {
      switch (cloudType.toLowerCase()) {
        case "aws": {
          debouncedBillingAddressSearch(
            formContext.shiAccountDetails?.axAccountNumber,
            ServiceRequestCloudTypes.AWS
          );
          break;
        }
        case "gcp": {
          debouncedBillingAddressSearch(
            formContext.shiAccountDetails?.axAccountNumber,
            ServiceRequestCloudTypes.GCP
          );
          break;
        }
        default: {
          console.log("Invalid cloud type provided");
          break;
        }
      }
    } else {
      setSelectedAddress(null);
      setAvailableAddresses([]);
      onChange(null);
    }
  }, [cloudType, formContext.shiAccountDetails?.axAccountNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountBillingAddress?.addresses?.length > 0 && isComplete) {
      setAvailableAddresses(accountBillingAddress.addresses);
    } else if (
      formContext &&
      isComplete &&
      accountBillingAddress.addresses &&
      accountBillingAddress.addresses.length === 0
    ) {
      setSelectedAddress(null);
      setAvailableAddresses(accountBillingAddress.addresses);
      onChange(null);
    }
  }, [accountBillingAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedBillingAddressSearch = debounce((axAccount, cloudType) => {
    dispatch(getAccountBillingAddressData(axAccount, cloudType));
    if (!formContext?.billingDetailsDefinition?.accountBillingAddress) {
      setSelectedAddress(null);
      onChange(null);
    }
  }, 1000);

  const alertLabel =
    "If addresses do not appear in the drop-down menu, please add an Invoice or Business address in AX to your primary account and then click REFRESH.";

  const handleAddressUpdate = (value) => {
    setSelectedAddress(value);
  };

  return (
    <>
      <Autocomplete
        disabled={disabled}
        sx={classes.root}
        onChange={(event, value) => {
          handleAddressUpdate(value);
          onChange(value);
        }}
        value={selectedAddress}
        options={availableAddresses}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search addresses..."
            InputLabelProps={{ shrink: true }}
            label={"Billing Address"}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
      <div style={classes.alertMargin}>
        <AlertWidget label={alertLabel} />
      </div>
    </>
  );
}
