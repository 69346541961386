const assetsAccessor = {
  displayName: "displayName",
  product: "product",
  productDescription: "productDescription",
  manufacturer: "manufacturer",
  itemQuantity: "itemQuantity",
  serialNumber: "serialNumber",
  assetType: "assetType",
  shiCategory: "shiCategory",
  subCategory: "subCategory",
  microCategory: "microCategory",
  shiUseState: "shiUseState",
  discoveredOnNet: "discoveredOnNet",
  networkId: "networkId",
  networkAddress: "networkAddress",
  shiLifecycleState: "shiLifecycleState",
  contract: "contract",
  contractId: "contractId",
  serviceLevelAgreement: "serviceLevelAgreement",
  coveredStatus: "coveredStatus",
  coveredStartDate: "coveredStartDate",
  coveredEndDate: "coveredEndDate",
  renewalDate: "renewalDate",
  endOfLife: "endOfLife",
  endOfSaleDate: "endOfSaleDate",
  endOfSoftwareMaintenanceDate: "endOfSoftwareMaintenanceDate",
  lastDateOfSupport: "lastDateOfSupport",
  shipDate: "shipDate",
  managingPartner: "managingPartner",
  displayLocationName: "displayLocationName",
  children: "children",
  tags: "tags",
};

export default assetsAccessor;
