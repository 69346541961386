export enum ActiveOrComplete {
  Active = "Active",
  Complete = "Complete",
}

export const RequestsSearchParamAccessors = {
  dateRange: "dateRange",
  view: "view",
  contractName: "ContractName",
  createdDate: "CreatedDate",
  activeOrComplete: "ActiveOrComplete",
  firstResponseMet: "FirstResponseMet",
  serviceRequestType: "ServiceRequestType",
  priority: "Priority",
  serviceRequestCategory: "ServiceRequestCategory",
  createdBy: "CreatedBy",
  issueType: "IssueType",
  subIssueType: "SubIssueType",
};
