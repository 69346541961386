import { useDispatch, useSelector } from "react-redux";
import GlobalConstants from "@constants";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { getAxBillingContacts } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import React, { Fragment, useEffect, useState } from "react";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import AlertWidget from "../Widgets/AlertWidget";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  alertMargin: {
    marginTop: theme.spacing(2),
  },
});

export default function BillingContactsField(props) {
  const theme = useTheme();
  const classes = css(theme);
  const dispatch = useDispatch();

  const { required, onChange, formData, disabled, formContext } = props;
  const { billingContacts, billingContactsFetchStatus } = useSelector(
    (state) => state.serviceRequestTemplate
  );

  const baseState = {
    name: null,
    phoneNumbers: [],
    emails: [],
  };

  const [selectedPerson, setSelectedPerson] = useState(baseState);

  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [currentBillingAccounts, setCurrentBillingAccounts] = useState(null);

  const emailPlaceHolder = "Select a Email";
  const phonePlaceHolder = "Select a Phone";

  const isLoading =
    billingContactsFetchStatus === GlobalConstants.fetchStatus.loading;
  const isComplete =
    billingContactsFetchStatus === GlobalConstants.fetchStatus.complete;

  const [currentAnimation, setCurrentAnimation] = React.useState({});

  const getBillingContactByName = (nameOfContact) => {
    return currentBillingAccounts.find(
      (contact) => contact.name === nameOfContact
    );
  };

  const handlePersonUpdate = (value) => {
    setPhone([]);
    setEmail([]);
    if (value === null) {
      setSelectedPerson(baseState);
    } else {
      const billingContact = getBillingContactByName(value);
      setSelectedPerson({
        name: billingContact.name,
        phoneNumbers: billingContact.phoneNumbers,
        emails: billingContact.emails,
      });
    }
  };

  useEffect(() => {
    if (isLoading) {
      setCurrentAnimation({
        animation: `spin .5s linear`,
        "@keyframes spin": {
          "0%": {
            transform: "rotate(360deg)",
          },
          "100%": {
            transform: "rotate(0deg)",
          },
        },
      });
    }

    if (isComplete) {
      setCurrentAnimation({});
      setCurrentBillingAccounts(billingContacts);
    }
  }, [billingContactsFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentBillingAccounts) {
      if (
        formData.billingName &&
        currentBillingAccounts.some((c) => c.name === formData.billingName)
      ) {
        handlePersonUpdate(formData.billingName);
        if (formData.billingEmail) {
          setEmail(formData.billingEmail);
        }
        if (formData.billingPhone) {
          setPhone(formData.billingPhone);
        }
      } else {
        setSelectedPerson(baseState);
        setPhone([]);
        setEmail([]);
        onChange({});
      }
    }
    // FIXME: Review this logic to resolve the eslint ignore (Missing UseEffect Dependency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBillingAccounts]);

  useEffect(() => {
    if (formContext.shiAccountDetails?.axAccountNumber) {
      handleBillingContactUpdate();
    }
    if (!formContext.shiAccountDetails?.axAccountNumber) {
      setSelectedPerson(baseState);
      setPhone([]);
      setEmail([]);
      setCurrentBillingAccounts([]);
      onChange({});
    }
  }, [formContext.shiAccountDetails?.axAccountNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBillingContactUpdate = () => {
    dispatch(
      getAxBillingContacts(formContext.shiAccountDetails?.axAccountNumber)
    );
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };
  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const alertLabel =
    "If billing contact is not available in drop down, please add the contact in AX and then return to this form.";

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          disabled={disabled}
          onClick={() => {
            handleBillingContactUpdate();
          }}
          endIcon={
            <CachedOutlinedIcon sx={currentAnimation}></CachedOutlinedIcon>
          }
        >
          Refresh
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disabled={disabled}
          sx={classes.root}
          label={"Billing Contact Name"}
          value={selectedPerson.name}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(event, value) => {
            handlePersonUpdate(value);
            onChange({ ...formData, billingName: value });
          }}
          options={
            isComplete && currentBillingAccounts
              ? [
                  ...new Set(
                    currentBillingAccounts.map((contact) => `${contact.name}`)
                  ),
                ]
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search billing contacts"
              InputLabelProps={{ shrink: true }}
              label={"Billing Contact Name"}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
        <div style={classes.alertMargin}>
          <AlertWidget label={alertLabel} />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          disabled={disabled}
          sx={classes.root}
          value={email}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => {
            if (option.length === 0) {
              return "";
            } else {
              return option;
            }
          }}
          onChange={(event, value) => {
            handleEmailChange(value);
            onChange({ ...formData, billingEmail: value });
          }}
          options={selectedPerson.emails}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={emailPlaceHolder}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label={"Billing Contact Email"}
            ></TextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          disabled={disabled}
          sx={classes.root}
          value={phone}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => {
            if (option.length === 0) {
              return "";
            } else {
              return option;
            }
          }}
          onChange={(event, value) => {
            handlePhoneChange(value);
            onChange({ ...formData, billingPhone: value });
          }}
          options={selectedPerson.phoneNumbers}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={phonePlaceHolder}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label={"Billing Contact Phone"}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
