export const GlobalConstants = {
  dateDisplayOptions: {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  dateFormat: "MM/dd/yyyy",
  moduleName: {
    customerSuccess: "Customer Success",
    customerExperience: "Customer Experience",
  },
};
