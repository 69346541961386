import { ActiveReportStore } from "../stores";
import { useContext } from "react";
import { PbiContext } from "../components/PbiContext";

let webWorker: any;
if (typeof Worker !== "undefined") {
  webWorker = new Worker(
    new URL("../workers/checkPowerBiAccessToken.ts", import.meta.url),
    { type: "module" }
  );
}

export const useEmbeddedPowerBiWebWorker = () => {
  const [ActiveReportParams, setActiveEmbedConfig] = ActiveReportStore((s) => [
    s.activeReportParams,
    s.setActiveEmbedConfig,
  ]);
  const { webWorkerProps } = useContext(PbiContext);

  if (!webWorkerProps?.webWorkerGetNewAccessToken) {
    return null;
  }

  webWorker.onmessage = async (message: any, transfer: Transferable[]) => {
    const { result } = message.data;

    if (result && ActiveReportParams?.reportId) {
      const newToken = await webWorkerProps?.webWorkerGetNewAccessToken(
        ActiveReportParams?.reportId,
        ActiveReportParams?.filter
      );
      if (newToken) {
        webWorkerProps?.telemetryFunc(
          `New PowerBI Token for report ${ActiveReportParams?.reportId}`,
          {
            tokenExpiration: newToken?.expiration,
          }
        );
        webWorker.postMessage(
          {
            currentTokenExpDate: newToken?.expiration,
          },
          transfer
        );
        // @ts-ignore
        setActiveEmbedConfig((prevState) => {
          return { ...prevState, accessToken: newToken.token };
        });
      }
    }
  };
  webWorker.onerror = (e: any) => {
    webWorkerProps?.telemetryFunc("Error on web worker", { error: e });
  };
};
