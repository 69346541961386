import React, { useEffect, useState } from "react";
import { AdvancedFilter, RelativeDateFilter } from "powerbi-models";
import { DateView } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { getAdvancedDateFilter, getIntervals } from "../helpers";
import { ActiveReportStore, EmbeddedPowerBiStore } from "../stores";

export type IntervalType = {
  value: string;
  filter: AdvancedFilter | RelativeDateFilter | undefined;
};

export const usePowerBiDateRanges = () => {
  const [showDateRange, setShowDateRange] = useState(false);
  const [availableIntervals, defaultInterval, setDefaultInterval] =
    EmbeddedPowerBiStore((s) => [
      s.availableIntervals,
      s.defaultInterval,
      s.setDefaultInterval,
    ]);

  const setActiveReportFilterDate = ActiveReportStore(
    (s) => s.setActiveReportFilterDate
  );
  const [dateTo, setDateTo] = useState<DateTime | null>(null);
  const [dateFrom, setDateFrom] = useState<DateTime | null>(null);
  const [intervals, setIntervals] = useState<IntervalType[]>([]);

  const [dateFilterViews, setDateFilterViews] = useState<DateView[]>([
    "year",
    "month",
  ]);

  const [currentInterval, setCurrentInterval] = useState<
    IntervalType | undefined
  >(undefined);

  const [filterDate, setFilterDate] = useState<
    AdvancedFilter | RelativeDateFilter | undefined
  >(undefined);

  const handleIntervalChange = (newValue: IntervalType) => {
    const value = newValue.value;
    if (!value) {
      return;
    }

    setCurrentInterval(newValue);
    if (value === "Custom Range") {
      setShowDateRange(true);

      const advFilter = getAdvancedDateFilter(
        dateFrom?.toISO(),
        dateTo?.toISO()
      );

      setFilterDate(advFilter);
      setActiveReportFilterDate(advFilter);
    } else {
      setShowDateRange(false);
      const filter = intervals.find(
        (i: IntervalType) => i.value === value
      )?.filter;
      if (filter) {
        setFilterDate(filter);
        setActiveReportFilterDate(filter);
      }
    }
    setAnchorEl(null);
  };

  const handleResetDates = () => {
    setDateTo(DateTime.utc().endOf("month"));
    setDateFrom(DateTime.utc().minus({ years: 1 }).startOf("month"));
  };

  const handleSetDates = () => {
    setShowDateRange(false);
    const advFilter = getAdvancedDateFilter(
      dateFrom?.toISO({ includeOffset: false }),
      dateTo?.toISO({ includeOffset: false })
    );
    setCurrentInterval({ value: "Custom Range", filter: advFilter });
    setFilterDate(advFilter);
    setActiveReportFilterDate(advFilter);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (availableIntervals.length !== 0) {
      const intervals = getIntervals(availableIntervals, setDateFilterViews);
      setIntervals(intervals);

      // Set default interval
      const defaultInterval =
        intervals.find((x) => x.value === "Last 6 months") ?? intervals[0];
      setDefaultInterval(defaultInterval);
      setActiveReportFilterDate(defaultInterval.filter);
      setCurrentInterval(defaultInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableIntervals]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = () => {
    setAnchorEl(null);
  };

  return {
    showDateRange,
    setShowDateRange,
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    intervals,
    dateFilterViews,
    currentInterval,
    handleIntervalChange,
    handleResetDates,
    handleSetDates,
    handleMenu,
    handleClose,
    handleMenuItemClick,
    anchorEl,
    filterDate,
    defaultInterval,
  };
};
