import {
  getRestAllPendingAccounts,
  postRestCreateAccount,
  postRestInviteAccount,
  postRestCancelAccountAction,
  postRestConfirmExpiration,
  postRestAccountStatus,
} from "../../../api/awsAccountsStore";
import {
  CancelPost,
  CreatePost,
  InvitePost,
  ConfirmExpirationPost,
  StatusPost,
  AwsAccountsState,
  AllStatusesResponse,
  LinkedAccountResponse,
} from "./awsAccountTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";

const initialState: AwsAccountsState = {
  createAccountRequestStatus: "idle",
  inviteAccountRequestStatus: "idle",
  cancelAccountRequestStatus: "idle",
  confirmExpirationRequestStatus: "idle",
  accountStatusRequestStatus: "idle",
  allAccountStatusesRequestStatus: "idle",
  currentAccountStatusState: {
    state: "",
    operationId: "",
  },
  allAccountStatuses: {
    responseStatus: 0,
    errorMessage: "",
    statusInformation: [],
  },
  awsLinkedAccountResponse: {
    accountInviteResponse: {
      parties: [],
      operationId: "",
      actionType: "",
      expirationTimestamp: "",
      state: "",
      exceptionMessage: "",
      exceptionOccurred: false,
    },
    accountCreateResponse: {
      newAccountId: "",
      accountName: "",
      requestFailed: false,
      failureReason: "",
      state: "",
      operationId: "",
      exceptionMessage: "",
      message: "",
      exceptionOccurred: false,
    },
    accountStatusResponse: {
      expirationTimestamp: "",
      newAccountId: "",
      autotaskAccountId: 0,
      autotaskContractId: 0,
      accountName: "",
      requestFailed: false,
      requestType: "",
      payerAccountId: "",
      requestedAccountEmail: "",
      requestedAccountId: "",
      failureReason: "",
      state: "",
      operationId: "",
      message: "",
      exceptionMessage: "",
      exceptionOccurred: false,
    },
    accountCancelResponse: {
      actionType: "",
      expirationTimestamp: "",
      operationId: "",
      state: "",
      parties: [],
    },
    accountCompleteCancelResponse: {
      state: "",
      operationId: "",
      exceptionOccurred: false,
    },
    responseStatus: 0,
    errorMessage: "",
  },
  allAccountStatusesRequestError: "",
  awsLinkedAccountResponseError: "",
};

const awsAccountsSlice = createSlice({
  name: "awsAccounts",
  initialState,
  reducers: {
    requestingCreateAccount(state) {
      state.createAccountRequestStatus = "loading";
    },
    requestedCreateAccount(
      state,
      action: PayloadAction<LinkedAccountResponse>
    ) {
      state.inviteAccountRequestStatus = "";
      state.createAccountRequestStatus = "complete";
      state.awsLinkedAccountResponse = action.payload;
    },
    requestedCreateAccountError(state, action: PayloadAction<string>) {
      state.inviteAccountRequestStatus = "";
      state.createAccountRequestStatus = "error";
      state.awsLinkedAccountResponseError = action.payload;
    },
    requestingInviteAccount(state) {
      state.inviteAccountRequestStatus = "loading";
    },
    requestedInviteAccount(
      state,
      action: PayloadAction<LinkedAccountResponse>
    ) {
      state.createAccountRequestStatus = "";
      state.inviteAccountRequestStatus = "complete";
      state.awsLinkedAccountResponse = action.payload;
    },
    requestedInviteAccountError(state, action: PayloadAction<string>) {
      state.createAccountRequestStatus = "";
      state.inviteAccountRequestStatus = "error";
      state.awsLinkedAccountResponseError = action.payload;
    },
    requestingCancelAccountAction(state) {
      state.cancelAccountRequestStatus = "loading";
    },
    requestedCancelAccountAction(
      state,
      action: PayloadAction<LinkedAccountResponse>
    ) {
      state.cancelAccountRequestStatus = "complete";
      state.awsLinkedAccountResponse = action.payload;
    },
    requestingExpirationConfirmation(state) {
      state.confirmExpirationRequestStatus = "loading";
    },
    requestedExpirationConfirmation(
      state,
      action: PayloadAction<LinkedAccountResponse>
    ) {
      state.confirmExpirationRequestStatus = "complete";
      state.awsLinkedAccountResponse = action.payload;
    },
    requestingPendingAccountStatus(state) {
      state.accountStatusRequestStatus = "loading";
    },
    requestedPendingAccountStatus(
      state,
      action: PayloadAction<LinkedAccountResponse>
    ) {
      state.accountStatusRequestStatus = "complete";
      state.awsLinkedAccountResponse = action.payload;
    },
    receivingAllAccountStatuses(state) {
      state.allAccountStatusesRequestStatus = "loading";
    },
    receivedAllAccountStatuses(
      state,
      action: PayloadAction<AllStatusesResponse>
    ) {
      state.allAccountStatusesRequestStatus = "complete";
      state.allAccountStatuses = action.payload;
    },
    allAccountStatusesError(state, action: PayloadAction<string>) {
      state.allAccountStatusesRequestStatus = "error";
      state.allAccountStatusesRequestError = action.payload;
    },
  },
});

export const requestCreateAccount =
  (createRequest: CreatePost): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(awsAccountsSlice.actions.requestingCreateAccount());
    try {
      const createAccountResponse = await postRestCreateAccount(createRequest);
      const allPendingAccountStatuses = await getRestAllPendingAccounts();
      dispatch(
        awsAccountsSlice.actions.requestedCreateAccount(createAccountResponse)
      );
      dispatch(
        awsAccountsSlice.actions.receivedAllAccountStatuses(
          allPendingAccountStatuses
        )
      );
    } catch (error) {
      dispatch(
        awsAccountsSlice.actions.requestedCreateAccountError(error as string)
      );
      const allPendingAccountStatuses = await getRestAllPendingAccounts();
      dispatch(
        awsAccountsSlice.actions.receivedAllAccountStatuses(
          allPendingAccountStatuses
        )
      );
    }
  };

export const requestInviteAccount =
  (inviteRequest: InvitePost): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(awsAccountsSlice.actions.requestingInviteAccount());
    try {
      const inviteAccountResponse = await postRestInviteAccount(inviteRequest);
      const allPendingAccountStatuses = await getRestAllPendingAccounts();
      dispatch(
        awsAccountsSlice.actions.requestedInviteAccount(inviteAccountResponse)
      );
      dispatch(
        awsAccountsSlice.actions.receivedAllAccountStatuses(
          allPendingAccountStatuses
        )
      );
    } catch (error) {
      dispatch(
        awsAccountsSlice.actions.requestedInviteAccountError(error as string)
      );
      const allPendingAccountStatuses = await getRestAllPendingAccounts();
      dispatch(
        awsAccountsSlice.actions.receivedAllAccountStatuses(
          allPendingAccountStatuses
        )
      );
    }
  };

export const requestPendingAccountStatus =
  (statusRequest: StatusPost): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(awsAccountsSlice.actions.requestingPendingAccountStatus());
    const accountStatusResponse = await postRestAccountStatus(statusRequest);
    const allPendingAccountStatuses = await getRestAllPendingAccounts();
    dispatch(
      awsAccountsSlice.actions.requestedPendingAccountStatus(
        accountStatusResponse
      )
    );
    dispatch(
      awsAccountsSlice.actions.receivedAllAccountStatuses(
        allPendingAccountStatuses
      )
    );
  };

export const requestCancelAccountAction =
  (cancelRequest: CancelPost): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(awsAccountsSlice.actions.requestingCancelAccountAction());
    const cancelActionResponse = await postRestCancelAccountAction(
      cancelRequest
    );
    const allPendingAccountStatuses = await getRestAllPendingAccounts();
    dispatch(
      awsAccountsSlice.actions.requestedCancelAccountAction(
        cancelActionResponse
      )
    );
    dispatch(
      awsAccountsSlice.actions.receivedAllAccountStatuses(
        allPendingAccountStatuses
      )
    );
  };

export const requestExpirationConfirmation =
  (confirmationRequest: ConfirmExpirationPost): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(awsAccountsSlice.actions.requestingExpirationConfirmation());
    const expirationConfirmationResponse = await postRestConfirmExpiration(
      confirmationRequest
    );
    const allPendingAccountStatuses = await getRestAllPendingAccounts();
    dispatch(
      awsAccountsSlice.actions.requestedExpirationConfirmation(
        expirationConfirmationResponse
      )
    );
    dispatch(
      awsAccountsSlice.actions.receivedAllAccountStatuses(
        allPendingAccountStatuses
      )
    );
  };

export const getAllPendingAccountStatuses =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(awsAccountsSlice.actions.receivingAllAccountStatuses());
    try {
      const allPendingAccountStatuses = await getRestAllPendingAccounts();
      dispatch(
        awsAccountsSlice.actions.receivedAllAccountStatuses(
          allPendingAccountStatuses
        )
      );
    } catch (error) {
      dispatch(
        awsAccountsSlice.actions.allAccountStatusesError(error as string)
      );
    }
  };

export default awsAccountsSlice.reducer;
