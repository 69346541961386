import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "../../../store";
import { AzureBackupsState } from "modules/rtk/azureBackup/azureBackupsTypes";
import { getRestAzureBackups } from "../../../api/azureBackupsStore";
import { SimpleProtectedItemResource } from "lib/ShiOneClient";

const initialState: AzureBackupsState = {
  azureBackupsFetchStatus: "idle",
  azureBackups: [],
};

const azureBackupsSlice = createSlice({
  name: "azureBackups",
  initialState,
  reducers: {
    receivingAzureBackups(state) {
      state.azureBackupsFetchStatus = "loading";
    },
    receivedAzureBackups(
      state,
      action: PayloadAction<SimpleProtectedItemResource[]>
    ) {
      state.azureBackups = action.payload;
      state.azureBackupsFetchStatus = "complete";
    },
    receiveAzureBackupsError(state) {
      state.azureBackupsFetchStatus = "error";
    },
  },
});

export const getAzureBackups =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(azureBackupsSlice.actions.receivingAzureBackups());
    try {
      const azureBackups = await getRestAzureBackups();
      dispatch(azureBackupsSlice.actions.receivedAzureBackups(azureBackups));
    } catch {
      dispatch(azureBackupsSlice.actions.receiveAzureBackupsError());
    }
  };

export default azureBackupsSlice.reducer;
