const MuiListItem = (theme, rootElement) => ({
  defaultProps: {
    container: rootElement,
  },
  button: {
    "&:hover": {
      backgroundColor: theme.palette.primary["4p"],
    },
    "&:focus": {
      backgroundColor: theme.palette.primary["12p"],
    },
  },
});

export default MuiListItem;
