import { ContractInvoiceClient, Invoice } from "@lib/ShiOneClient";
import { useApi } from "../../../utils";
import { useQuery } from "@tanstack/react-query";

export const useGetContractInvoices = (contractId: number) => {
  const api = useApi(ContractInvoiceClient);

  const getContractInvoices = async () => {
    return await api.getContractInvoices(contractId);
  };

  return useQuery<Invoice[], Error>({
    queryKey: ["contract-invoices", contractId],
    queryFn: getContractInvoices,
  });
};
