import React from "react";
import { Card, Box } from "@mui/material";

const AuthPageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      bgcolor="background.default"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <Card>
        <Box
          display="flex"
          width={{ xs: "100%", md: "40rem" }}
          px={4}
          py={5}
          gap={2}
          flexDirection="column"
          textAlign="center"
          bgcolor={"background.paper"}
        >
          {children}
        </Box>
      </Card>
    </Box>
  );
};

export default AuthPageContainer;
