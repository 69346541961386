const typography = {
  allVariants: {
    fontFamily: "Roboto",
  },
  h1: {
    fontWeight: 300,
    fontSize: "6rem",
    letterSpacing: "-0.00833em",
    lineHeight: "1",
    marginBottom: "16px",
  },
  h2: {
    fontWeight: 300,
    fontSize: "3.75rem",
    letterSpacing: "-0.0.00735em",
    lineHeight: "1.25",
  },
  h3: {
    fontWeight: 400,
    fontSize: "3rem",
    letterSpacing: "0em",
    lineHeight: "1.33",
  },
  h4: {
    fontWeight: 400,
    fontSize: "2.125rem",
    letterSpacing: "0.25px",
    lineHeight: "2.5rem",
  },
  h5: {
    fontWeight: 400,
    fontSize: "1.5rem",
    letterSpacing: "0px",
    lineHeight: "1.2rem",
  },
  h6: {
    fontWeight: 500,
    fontSize: "1.25rem",
    letterSpacing: 0.15,
    lineHeight: "1.75rem",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "1rem",
    letterSpacing: "0em",
    lineHeight: "1.33rem",
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "0.875rem",
    letterSpacing: "0em",
    lineHeight: "1.33rem",
  },
  body1: {
    fontWeight: 400,
    fontSize: "1rem",
    letterSpacing: "0.01071em",
    lineHeight: "1.5rem",
  },
  body2: {
    fontWeight: 400,
    fontSize: "0.875rem",
    letterSpacing: "0.01071em",
    lineHeight: "1.5",
  },
  button: {
    fontSize: "14px",
  },
  caption: {
    fontSize: "0.75rem",
    letterSpacing: "0.17px",
    lineHeight: "20.02px",
  },
};

export default typography;
