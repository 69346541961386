import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { impersonateUser } from "shared-ui/src/utils/clientmanagement";
import PageHeader from "../../PageHeader";
import GlobalConstants from "@constants";

const css = (theme) => ({
  card: {
    height: "100%",
    paddingBottom: 8,
    position: "relative",
  },
  cardBody: {
    textAlign: "center",
  },
  cardActions: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
  },

  panelContent: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flex: "2 0px !important",
    },
  },

  title: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textTransform: "uppercase",
  },

  warning: {
    color: theme.palette.warning.main,
  },
  cardMedia: {
    objectPosition: "right top",
  },
});

const demoUserIds = {
  everythingDemo: 30691967,
  multiCloudDemo: 30691968,
  assetManagementDemo: 30692540,
  ciscoDemo: 30702555,
};

export default function DemoAccounts() {
  const theme = useTheme();
  const classes = css(theme);

  const demos = [
    {
      name: "Everything",
      description:
        "Showcasing all features that give the organization that single pane of glass view into their SHI services",
      img: GlobalConstants.storageAccountUrls.demoImages.everything,
      contactId: demoUserIds.everythingDemo,
      id: "everythingDemo",
    },
    {
      name: "Cloud",
      description:
        "Allowing organizations to easily manage and report on multiple cloud platform accounts, achieve improved cloud governance and accelerate cloud adoption",
      img: GlobalConstants.storageAccountUrls.demoImages.multiCloud,
      contactId: demoUserIds.multiCloudDemo,
      id: "multiCloudDemo",
    },
    {
      name: "Asset Management",
      description:
        "Highlights features for organizations to manage and track procurement and related services from SHI warehouses",
      img: GlobalConstants.storageAccountUrls.demoImages.assetManagement,
      contactId: demoUserIds.assetManagementDemo,
      id: "assetDemo",
    },
  ];

  return (
    <>
      <PageHeader header={"SHI One Demos"} />
      <Typography component="div" sx={classes.panelContent}>
        <Grid container spacing={5}>
          {demos.map((account, k) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={k}>
                <Card sx={classes.card}>
                  <CardMedia
                    component="img"
                    alt={account.name}
                    height="200"
                    image={account.img}
                    title={account.name}
                    sx={classes.cardMedia}
                  />
                  <CardContent sx={classes.cardBody}>
                    <Typography variant="body1">{account.name}</Typography>
                    <Typography variant="body2">
                      {account.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={classes.cardActions}>
                    <Grid
                      container
                      sx={classes.grow}
                      spacing={3}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Tooltip title="Launch Demo">
                          <Button
                            id={account.id}
                            onClick={() =>
                              impersonateUser(account.contactId, true)
                            }
                            size="small"
                            aria-label="Launch Demo"
                          >
                            Launch
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Typography>
    </>
  );
}
