export abstract class DemoAccountIdentifiers {
  static readonly demoAxNumbers: string[] = ["0000000", "9999997", "9999998"];
  static readonly demoAccountIds: number[] = [31018, 32071, 32072];
  static readonly censoredWarehouseAccountIds: number[] = [31018, 32071];
  static readonly censoredCiscoAccountIds: number[] = [31018];
}

export function isDemoAccountByAccountId(accountId: number): boolean {
  return DemoAccountIdentifiers.demoAccountIds.includes(accountId);
}
