import { useApi } from "../../utils";
import {
  BarChartDatasetDto,
  BarChartDto,
  TotalRequestsCompletedWidgetClient,
} from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { barChartColors } from "../../../../theme/siteColors";
import { Skeleton, ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React from "react";
import ms from "ms";

import {
  timeOptionKeys,
  timeOptions,
  timeOptionTypes,
} from "@widgets/utils/DateRanges";
import SingleDataWithLabels from "../../components/SingleDataWithLabels";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";
import { BaseWidgetProps, NoContent, WidgetWrapper, asDto } from "shared-ui";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { useChartJsDefaults } from "shared-ui/src/theme/useChartJsDefaults";

Chart.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

const TotalRequestsCompletedContainerSize = 350;

const placeholderData: BarChartDto = new BarChartDto({
  labels: ["Jan", "Feb", "Mar"],
  datasets: [
    new BarChartDatasetDto({
      label: "Created",
      data: [0, 0, 0],
      stackId: "stackId1",
    }),
    new BarChartDatasetDto({
      label: "Completed",
      data: [0, 0, 0],
      stackId: "stackId1",
    }),
  ],
});

function getChartData(
  data: BarChartDto,
  dateRangeType: string
): ChartData<"bar", number[], string | string[]> {
  if (!data || !data.datasets) {
    return {
      labels: [],
      datasets: [],
    };
  }

  return {
    labels:
      dateRangeType === timeOptionKeys.oneMonth
        ? data.labels?.map((label) => {
            return ["Week of", label];
          })
        : data.labels,
    datasets: [
      {
        label: data.datasets[0].label,
        data: data.datasets[0].data || [],
        backgroundColor: barChartColors.created,
        borderColor: barChartColors.created,
        borderWidth: 1,
        stack: data.datasets[0].stackId,
        hoverBackgroundColor: barChartColors.created,
        hoverBorderColor: barChartColors.created,
      },
      {
        label: data.datasets[1].label,
        data: data.datasets[1].data || [],
        backgroundColor: barChartColors.complete,
        borderColor: barChartColors.complete,
        borderWidth: 1,
        stack: data.datasets[1].stackId,
        hoverBackgroundColor: barChartColors.complete,
        hoverBorderColor: barChartColors.complete,
      },
    ],
  };
}

function Content({
  totalCreated,
  totalCompleted,
  loading,
  data,
  dateRangeType,
}: {
  totalCreated: number;
  totalCompleted: number;
  loading: boolean;
  data: BarChartDto;
  dateRangeType: string;
}) {
  useChartJsDefaults();
  const barChartOptions = {
    scales: {},
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context: any) {
            return context[0].label.replace(",", " ");
          },
        },
      },
      legend: {
        position: "bottom" as const,
      },
    },
  };

  if (loading || !data?.datasets) {
    return <Skeleton variant={"rectangular"} width={"100%"} height={300} />;
  }

  const percentage =
    totalCreated > 0 ? Math.round((totalCompleted / totalCreated) * 100) : 0;

  if (totalCreated === 0 && totalCompleted === 0) {
    return (
      <NoContent
        containerHeight={TotalRequestsCompletedContainerSize}
        header={"No Requests Completed"}
        body={"You have no requests that were completed for this time period"}
        actionText={"View All Requests"}
        actionLink={AppendIfPrefixExists(
          `/support-center/requests?view=${prebuiltViewNames.allRequests}#${ServiceRequestsPageTabs.allRequests}`
        )}
      />
    );
  }

  return (
    <>
      <SingleDataWithLabels
        data={`${numberFormatter.format(
          totalCompleted
        )} / ${numberFormatter.format(totalCreated)} (${percentage}%)`}
        label={"Requests Completed"}
      />
      <div>
        <Bar
          data={getChartData(data, dateRangeType)}
          height={TotalRequestsCompletedContainerSize}
          options={barChartOptions}
        />
      </div>
    </>
  );
}

function useGetTotalRequestsCompletedData(currentFilterValue: timeOptionTypes) {
  const api = useApi(TotalRequestsCompletedWidgetClient);

  //Need this because different filterKeys existed before
  let dateFrom = timeOptions[timeOptionKeys.oneMonth].dateFrom;
  let dateTo = timeOptions[timeOptionKeys.oneMonth].dateTo;

  if (timeOptions[currentFilterValue]) {
    dateFrom = timeOptions[currentFilterValue].dateFrom;
    dateTo = timeOptions[currentFilterValue].dateTo;
  }

  return useQuery(
    [`total-requests-completed-widget`, dateFrom, dateTo],
    () => api.getTotalRequestsCompletedData(dateFrom, dateTo),
    {
      placeholderData: asDto(placeholderData),
      staleTime: ms("1m"),
    }
  );
}

export default function TotalRequestsCompletedWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const filterKey = "total-requests-completed-widget-filter";
  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      pageLayoutWidget.widgetId!,
      filterKey,
      timeOptionKeys.oneMonth
    );

  let timeOptionsFiltered = Object.keys(timeOptions).filter((to) => {
    return (
      to === timeOptionKeys.oneMonth ||
      to === timeOptionKeys.threeMonth ||
      to === timeOptionKeys.sixMonth
    );
  });

  const { data, isPlaceholderData, isError } =
    useGetTotalRequestsCompletedData(currentFilterValue);
  let totalCompleted = 0;
  let totalCreated = 0;

  if (data) {
    totalCreated = data?.data?.datasets[0].data.reduce(
      (a: number, n: number) => a + n,
      0
    );
    totalCompleted = data?.data?.datasets[1].data.reduce(
      (a: number, n: number) => a + n,
      0
    );
  }

  const loading = isPlaceholderData || isError || isLoadingFilterUserConfig;

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      headerAction={
        <ToggleButtonGroup
          size="small"
          color="secondary"
          exclusive
          value={currentFilterValue}
          onChange={(e, value) => {
            if (value === null) return;
            setFilter(value);
          }}
        >
          {timeOptionsFiltered.map((k, i) => {
            return (
              <ToggleButton value={k} key={i}>
                {k}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      }
      isEmpty={totalCreated === 0 && totalCompleted === 0}
    >
      <Content
        totalCompleted={totalCompleted}
        totalCreated={totalCreated}
        loading={loading}
        data={data?.data}
        dateRangeType={currentFilterValue}
      />
    </WidgetWrapper>
  );
}
