import {WidgetRegistry} from "../models/WidgetRegistry";

export function getWidgetRegistryEntry(
  widgetId: string | undefined,
  widgetRegistry: WidgetRegistry
): any | undefined {
  if (!widgetId) {
    return undefined;
  }

  const widget = widgetRegistry.widgets.find((w) => w.id === widgetId);

  return widget;
}

export function asDto(data: any) {
  return {
    ...data,
    init: (_data?: any) => {},
    toJSON: (_data?: any) => {
      return _data;
    },
  };
}
