import { TextField } from "@mui/material";

const pad = (n) => n.toString().padStart(2, "0");

function fromHumanReadable(v) {
  if (v) {
    const ts = Date.parse(v);
    if (!isNaN(ts)) {
      const d = new Date(ts);
      // Format: YYYY-MM-DDTHH:mm
      const s = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(
        d.getDate()
      )}T${pad(d.getHours())}:${pad(d.getMinutes())}`;
      return s;
    }
  }

  return v;
}

function toHumanReadable(v) {
  if (v) {
    const ts = Date.parse(v);
    if (!isNaN(ts)) {
      const d = new Date(ts);
      // Format: M/D/YYYY, H:mm tt
      const s = d.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      });
      return s;
    }
  }

  return v;
}

export default function LocalDateTimeWidget(props) {
  const { id, value, onChange, required } = props;

  return (
    <TextField
      style={{ display: "flex" }}
      variant="outlined"
      id={id}
      type="datetime-local"
      required={required}
      defaultValue={fromHumanReadable(value)}
      onChange={(event) => onChange(toHumanReadable(event.target.value))}
    />
  );
}
