import { appInsightsTelemetry } from "./telemetryAppInsights";
import { datadogTelemetry } from "./telemetryDatadog";
import { googleTagManagerTelemetry } from "./telemetryGoogleTagManager";
import {
  BotInfo,
  BrowserInfo,
  NodeInfo,
  ReactNativeInfo,
  SearchBotDeviceInfo,
} from "detect-browser";
import {
  IUserProfileCacheResponse,
  UserProfileCacheResponse,
} from "@lib/ShiOneClient";
import { FeatureDefinitions } from "shared-ui/src/utils/FeatureDefinitions.js";

let currentUserEmail: string | undefined = undefined;
let currentPath = "";
type TelemetryProvider = {
  init: () => void;
  trackException: (
    uuid: any,
    errorMessage: string,
    browserType:
      | BrowserInfo
      | SearchBotDeviceInfo
      | BotInfo
      | NodeInfo
      | ReactNativeInfo
      | null,
    userProfileResponse: IUserProfileCacheResponse,
    router: any
  ) => void;
  endUser: () => void;
  beginUser: (userProfileResponse: UserProfileCacheResponse) => void;
  beginRoute: (location: Location, featureRoute: any) => void;
  trackEvent: (name: string, context: any) => void;
};

let telemetry: TelemetryProvider[] = [];

if (appInsightsTelemetry) telemetry.push(appInsightsTelemetry);

if (datadogTelemetry) telemetry.push(datadogTelemetry);

if (googleTagManagerTelemetry) telemetry.push(googleTagManagerTelemetry);

export const telemetryInit = () => {
  try {
    currentUserEmail = undefined;
    telemetry.forEach((t) => t.init());
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};

export const trackException = (
  uuid: any,
  errorMessage: string,
  browserType:
    | BrowserInfo
    | SearchBotDeviceInfo
    | BotInfo
    | NodeInfo
    | ReactNativeInfo
    | null,
  userProfileResponse: IUserProfileCacheResponse,
  router: any
) => {
  try {
    telemetry.forEach((t) =>
      t.trackException(
        uuid,
        errorMessage,
        browserType,
        userProfileResponse,
        router
      )
    );
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};

export const telemetryEndUser = () => {
  try {
    if (currentUserEmail) {
      currentUserEmail = undefined;
      telemetry.forEach((t) => t.endUser());
    }
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};

export const telemetryBeginUserWithUserProfile = (
  userProfileResponse: UserProfileCacheResponse
) => {
  try {
    if (!userProfileResponse || !userProfileResponse.userProfile) return;

    if (
      currentUserEmail &&
      currentUserEmail === userProfileResponse.userProfile.mail
    )
      return;

    currentUserEmail = userProfileResponse.userProfile.mail;
    telemetry.forEach((t) => t.beginUser(userProfileResponse));
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};

export const telemetryBeginRoute = (featureRoute: any) => {
  try {
    if (window.location.pathname === currentPath) {
      return;
    }

    currentPath = window.location.pathname;
    telemetry.forEach((t) => t.beginRoute(window.location, featureRoute));
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};

export const telemetryTrackEvent = (name: string, context: any | undefined) => {
  try {
    telemetry.forEach((t) => t.trackEvent(name, context));
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};

export const usageTrackEvent = (
  name: string,
  context: {
    relatedFeature: typeof FeatureDefinitions[keyof typeof FeatureDefinitions];
    isMetricsTracking?: boolean;
    metadata?: any;
  }
) => {
  try {
    context.isMetricsTracking = context.isMetricsTracking ?? true;
    telemetry.forEach((t) => t.trackEvent(name, context));
  } catch (e) {
    // Don't allow telemetry errors to impact the application
  }
};
