import React, { forwardRef } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, "to"> & { href: NavLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;

  return <NavLink ref={ref} to={href} {...other} />;
});

export default LinkBehavior;
