import React from "react";
import { TwoColumnLayout } from "./framework";
import { DashboardConfigurationDialog } from "./framework/DashboardConfigurationDialog";
import LoadingOrError from "../global-layout/LoadingOrError";
import { WidgetRegistry } from "../widgets/models/WidgetRegistry";
import { ExtendsPageLayoutDto } from "./models/PageLayout";
import { PageLayoutDto } from "../generated/models";
import PageHeader from "../global-layout/PageHeader";

export interface IDashboard {
  data: PageLayoutDto | undefined;
  isLoading: boolean;
  isError: boolean;
  updatePageConfiguration: (pageConfiguration: ExtendsPageLayoutDto) => void;
}

export default function TwoColumnDashboard({
  widgetRegistry,
  dashboardProps,
  header,
  showDashboardHeader,
}: {
  dashboardProps: IDashboard;
  widgetRegistry: WidgetRegistry;
  header: string;
  showDashboardHeader: boolean;
}) {
  const { data, isLoading, isError, updatePageConfiguration } = dashboardProps;

  if (isLoading || isError) {
    return (
      <LoadingOrError
        header={header}
        loading={isLoading}
        error={isError}
        message="There's a problem loading the dashboard"
        suggestions="Please try again later."
      />
    );
  }

  return (
    <>
      {showDashboardHeader && <PageHeader header={header} />}
      <TwoColumnLayout
        pageConfiguration={data}
        widgetRegistry={widgetRegistry}
      />
      <DashboardConfigurationDialog
        pageConfiguration={data}
        setPageConfiguration={updatePageConfiguration}
      />
    </>
  );
}
