export const EXPAND_TOGGLE = "navState/EXPAND_TOGGLE";

const initialState = {
  expanded: window.innerWidth > 992,
};

const navState = (state = initialState, action) => {
  switch (action.type) {
    case EXPAND_TOGGLE:
      return {
        ...state,
        expanded: !state.expanded,
      };

    default:
      return state;
  }
};

export default navState;

export const expandToggle = () => {
  return (dispatch) => {
    dispatch({
      type: EXPAND_TOGGLE,
    });
  };
};
