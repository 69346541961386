import React from "react";
import LoadingCubeSvg from "../assets/loading-cubes.svg?react";
import { SvgIcon } from "@mui/material";

function LoadingCube() {
  return (
    <SvgIcon
      component={LoadingCubeSvg}
      inheritViewBox
      sx={{ fontSize: "50px" }}
    />
  );
}

export default LoadingCube;
