import {
  ADD_CLIENT_ACCOUNT_TEAM_MEMBER,
  ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
  ADD_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
  GET_CLIENT_ACCOUNT_TEAM,
  GET_CLIENT_ACCOUNT_TEAM_ERROR,
  GET_CLIENT_ACCOUNT_TEAM_SUCCESS,
  GET_CLIENT_ACCOUNTS,
  GET_CLIENT_ACCOUNTS_ERROR,
  GET_CLIENT_ACCOUNTS_SUCCESS,
  GET_CLIENT_AX_NUMBERS,
  GET_CLIENT_AX_NUMBERS_ERROR,
  GET_CLIENT_AX_NUMBERS_SUCCESS,
  GET_CLIENT_USERS,
  GET_CLIENT_USERS_ERROR,
  GET_CLIENT_USERS_SUCCESS,
  REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER,
  REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
  REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
  RESET_CLIENT_ACCOUNT_STORE,
  UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER,
  UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
  UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
  UPDATE_CLIENT_AX_NUMBERS,
  UPDATE_CLIENT_AX_NUMBERS_ERROR,
  UPDATE_CLIENT_AX_NUMBERS_SUCCESS,
} from "../actions/clientManagementActions";

import _ from "lodash";

export function clientManagementReducer(
  state = {
    clientAccounts: {},
    userType: "",
    fetchingClientAccounts: false,
    fetchClientAccountsComplete: false,
    fetchClientAccountsError: undefined,
    clientAccountUsers: {},
    fetchingClientAccountUsers: false,
    fetchClientAccountUsersComplete: false,
    fetchClientAccountUsersError: undefined,
    accountTeamMembers: [],
    fetchingClientAccountTeam: false,
    fetchClientAccountTeamComplete: false,
    fetchClientAccountTeamError: undefined,
    addingClientAccountTeamMember: false,
    addingClientAccountMemberComplete: false,
    addingClientAccountMemberError: undefined,
    removingClientAccountTeamMember: false,
    removingClientAccountMemberComplete: false,
    removingClientAccountMemberError: undefined,
    updatedTeamMembers: [],
    updatingClientAccountTeamMember: false,
    updatingClientAccountMemberComplete: false,
    updatingClientAccountMemberError: undefined,
    clientAxNumbers: [],
    fetchingClientAxNumbers: false,
    fetchClientAxNumbersComplete: false,
    fetchClientAxNumbersError: undefined,
    currentAccountId: -1,
    updatingClientAxNumbers: false,
    updatingClientAxNumbersComplete: false,
    updatingClientAxNumbersError: undefined,
  },
  action
) {
  switch (action.type) {
    case GET_CLIENT_AX_NUMBERS: {
      return Object.assign({}, state, {
        currentAccountId: action.payload.data,
        fetchingClientAxNumbers: true,
        fetchClientAxNumbersComplete: false,
      });
    }
    case GET_CLIENT_AX_NUMBERS_SUCCESS: {
      return Object.assign({}, state, {
        clientAxNumbers: action.payload.data,
        fetchingClientAxNumbers: false,
        fetchClientAxNumbersComplete: true,
      });
    }
    case GET_CLIENT_AX_NUMBERS_ERROR: {
      return Object.assign({}, state, {
        fetchClientAxNumbersError: action.payload.data,
        fetchingClientAxNumbers: false,
        fetchClientAxNumbersComplete: false,
      });
    }
    case UPDATE_CLIENT_AX_NUMBERS: {
      return Object.assign({}, state, {
        currentAccountId: action.payload.data.accountId,
        updatingClientAxNumbers: true,
        updatingClientAxNumbersComplete: false,
      });
    }
    case UPDATE_CLIENT_AX_NUMBERS_SUCCESS: {
      return Object.assign({}, state, {
        clientAxNumbers: action.payload.data,
        updatingClientAxNumbers: false,
        updatingClientAxNumbersComplete: true,
      });
    }
    case UPDATE_CLIENT_AX_NUMBERS_ERROR: {
      return Object.assign({}, state, {
        updatingClientAxNumbersError: action.payload.data,
        updatingClientAxNumbers: false,
        updatingClientAxNumbersComplete: false,
      });
    }
    case GET_CLIENT_USERS:
      return Object.assign({}, state, {
        fetchingClientAccountUsers: true,
        fetchClientAccountUsersComplete: false,
      });
    case GET_CLIENT_USERS_ERROR:
      // console.log('GET_ACCOUNT_USERS_ERROR', action)
      return Object.assign({}, state, {
        fetchingClientAccountUsers: false,
        fetchClientAccountUsersComplete: true,
        fetchClientAccountUsersError: action.payload.data,
      });
    case GET_CLIENT_USERS_SUCCESS:
      // console.log('GET_ACCOUNT_USERS_SUCCESS', action)
      return Object.assign({}, state, {
        fetchingClientAccountUsers: false,
        fetchClientAccountUsersComplete: true,
        clientAccountUsers: action.payload.data,
      });
    case GET_CLIENT_ACCOUNT_TEAM:
      return Object.assign({}, state, {
        fetchingClientAccountTeam: true,
        fetchClientAccountTeamComplete: false,
      });
    case GET_CLIENT_ACCOUNT_TEAM_ERROR:
      // console.log('GET_ACCOUNT_USERS_ERROR', action)
      return Object.assign({}, state, {
        accountTeamMembers: [],
        fetchingClientAccountTeam: false,
        fetchClientAccountTeamComplete: true,
        fetchClientAccountTeamError: action.payload.data,
      });
    case GET_CLIENT_ACCOUNT_TEAM_SUCCESS:
      return Object.assign({}, state, {
        fetchingClientAccountTeam: false,
        fetchClientAccountTeamComplete: true,
        accountTeamMembers: action.payload.data,
      });
    case ADD_CLIENT_ACCOUNT_TEAM_MEMBER:
      return Object.assign({}, state, {
        addingClientAccountTeamMember: true,
        addingClientAccountTeamMemberComplete: false,
      });
    case ADD_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS:
      return Object.assign({}, state, {
        accountTeamMembers: [...state.accountTeamMembers, action.payload.data],
        addingClientAccountTeamMember: false,
        addingClientAccountMemberComplete: true,
      });
    case ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR:
      return Object.assign({}, state, {
        addingClientAccountTeamMember: false,
        addingClientAccountTeamMemberComplete: true,
        addingClientAccountMemberError: action.payload.data,
      });
    case REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER:
      return Object.assign({}, state, {
        removingClientAccountTeamMember: true,
        removingClientAccountTeamMemberComplete: false,
      });
    case REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS:
      const newTeam = _.filter(state.accountTeamMembers, (contact) => {
        return contact.id !== action.payload.data.id;
      });
      return Object.assign({}, state, {
        accountTeamMembers: newTeam,
        removingClientAccountTeamMember: false,
        removingClientAccountMemberComplete: true,
      });
    case REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR:
      return Object.assign({}, state, {
        removingClientAccountTeamMember: false,
        removingClientAccountTeamMemberComplete: true,
        removingClientAccountMemberError: action.payload.data,
      });
    case UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER:
      return Object.assign({}, state, {
        updatingClientAccountTeamMember: true,
        updatingClientAccountTeamMemberComplete: false,
      });
    case UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS:
      const updatedTeam = _.map(state.accountTeamMembers, (contact) => {
        return contact.id === action.payload.data.id
          ? action.payload.data
          : contact;
      });
      return Object.assign({}, state, {
        accountTeamMembers: updatedTeam,
        updatedTeamMembers: [...state.updatedTeamMembers, action.payload.data],
        updatingClientAccountTeamMember: false,
        updatingClientAccountMemberComplete: true,
      });
    case UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR:
      return Object.assign({}, state, {
        updatingClientAccountTeamMember: false,
        updatingClientAccountTeamMemberComplete: true,
        updatingClientAccountMemberError: action.payload.data,
      });
    case GET_CLIENT_ACCOUNTS:
      return Object.assign({}, state, {
        fetchingClientAccounts: true,
        fetchClientAccountsComplete: false,
      });
    case GET_CLIENT_ACCOUNTS_ERROR:
      return Object.assign({}, state, {
        fetchingClientAccounts: false,
        fetchClientAccountsComplete: true,
        fetchClientAccountsError: action.payload.data,
      });
    case GET_CLIENT_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        fetchingClientAccounts: false,
        fetchClientAccountsComplete: true,
        clientAccounts: action.payload.data,
        userType: action.payload.userType,
      });
    case RESET_CLIENT_ACCOUNT_STORE:
      return Object.assign({}, state, {
        clientAccounts: {},
        userType: "",
        fetchingClientAccounts: false,
        fetchClientAccountsComplete: false,
        fetchClientAccountsError: undefined,
        clientAccountUsers: {},
        fetchingClientAccountUsers: false,
        fetchClientAccountUsersComplete: false,
        fetchClientAccountUsersError: undefined,
        accountTeamMembers: [],
        fetchingClientAccountTeam: false,
        fetchClientAccountTeamComplete: false,
        fetchClientAccountTeamError: undefined,
        addingClientAccountTeamMember: false,
        addingClientAccountMemberComplete: false,
        addingClientAccountMemberError: undefined,
        removingClientAccountTeamMember: false,
        removingClientAccountMemberComplete: false,
        removingClientAccountMemberError: undefined,
        updatedTeamMembers: [],
        updatingClientAccountTeamMember: false,
        updatingClientAccountMemberComplete: false,
        updatingClientAccountMemberError: undefined,
        clientAxNumbers: [],
        fetchingClientAxNumbers: false,
        fetchClientAxNumbersComplete: false,
        fetchClientAxNumbersError: undefined,
        currentAccountId: -1,
        updatingClientAxNumbers: false,
        updatingClientAxNumbersComplete: false,
        updatingClientAxNumbersError: undefined,
      });
    default:
      return state;
  }
}
