import React, { Fragment } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getContactsWidgetData } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import GlobalConstants from "@constants";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
});

export default function ContactsWidget({ id, label, onChange, required }) {
  const theme = useTheme();
  const classes = css(theme);
  const dispatch = useDispatch();

  const accountId = useSelector(
    (state) => state.profile.userProfileResponse.userProfile.contact.accountID
  );
  const { accountContacts, accountContactsFetchStatus } = useSelector(
    (state) => state.serviceRequestTemplate
  );

  const isLoading =
    accountContactsFetchStatus === GlobalConstants.fetchStatus.loading;
  const isComplete =
    accountContactsFetchStatus === GlobalConstants.fetchStatus.complete;

  return (
    <Autocomplete
      sx={classes.root}
      onOpen={() => dispatch(getContactsWidgetData(accountId))}
      id={id}
      name={label}
      onChange={(event, value) => onChange(value)}
      options={
        isComplete
          ? [
              ...new Set(
                accountContacts.map(
                  (contact) => `${contact.firstName} ${contact.lastName}`
                )
              ),
            ]
          : []
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search account contacts"
          InputLabelProps={{ shrink: true }}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
