import React, { useMemo } from "react";
import { useApi } from "../../utils";
import {
  AssetsByProductOverviewWidgetClient,
  AssetsByProductOverviewWidgetResponse,
  AssetWidgetBaseRecord,
} from "@lib/ShiOneClient";

import { useQuery } from "@tanstack/react-query";
import PercentageLine from "../../components/PercentageLine";
import { Summary } from "../../interfaces/WidgetInterfaces";
import SingleDataWithLabels from "../../components/SingleDataWithLabels";
import { Button, Divider } from "@mui/material";
import {
  assetTypesColors,
  categoryColors,
  lifecycleStateColors,
} from "@features/assets-feature/utils/assetsColors";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { BaseWidgetProps, graphColors, WidgetWrapper } from "shared-ui";
import LabelLink from "shared-ui/src/components/LabelLink";
import { shiOneUrl } from "@constants";

const useGetAssetProductOverview = () => {
  const api = useApi(AssetsByProductOverviewWidgetClient);

  return useQuery<AssetsByProductOverviewWidgetResponse>({
    queryKey: ["assets-product-overview-widget"],
    queryFn: () => api.getAssetsProductOverviewWidgetData(),
  });
};

function CardActions() {
  return (
    <Button
      href={AppendIfPrefixExists(shiOneUrl.assets.productCatalogProducts)}
    >
      View Products
    </Button>
  );
}

export default function AssetsProductOverviewWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  const containerStyle = { padding: "16px" };
  const { data, isLoading } = useGetAssetProductOverview();

  const totalCount: number = useMemo(() => {
    return data?.totalProducts ?? 0;
  }, [data?.productTypes]);

  // @ts-ignore
  const formattedProductTypes: Summary[] = useMemo(() => {
    return data?.productTypes?.map(
      (item: AssetWidgetBaseRecord, index: number) => ({
        name: "ProductTypes",
        data: item.assetsCount ?? 0,
        label: item.category ? item.category : "Unknown",
        customLabel: (
          <LabelLink
            label={item.category ? item.category : "Unknown"}
            baseUrl={AppendIfPrefixExists(
              shiOneUrl.assets.productCatalogProducts
            )}
            css={{ fontWeight: "normal" }}
            state={{ assetType: item.category }}
          />
        ),
        color:
          assetTypesColors[
            item.category ? item.category?.toLowerCase() : "unknown"
          ] ?? graphColors[index + 1],
      })
    );
  }, [data?.productTypes]);

  // @ts-ignore
  const formattedProductCategories: Summary[] = useMemo(() => {
    return (
      data?.productCategories?.map((item: AssetWidgetBaseRecord) => ({
        name: "ProductCategories",
        data: item.assetsCount ?? 0,
        label: item.category ? item.category : "Unknown",
        customLabel: (
          <LabelLink
            label={item.category ? item.category : "Unknown"}
            baseUrl={AppendIfPrefixExists(
              shiOneUrl.assets.productCatalogProducts
            )}
            css={{ fontWeight: "normal" }}
            state={{ shiCategory: item.category }}
          />
        ),
        color:
          categoryColors[
            item.category ? item.category?.toLowerCase() : "unknown"
          ],
      })) ?? ([] as Summary[])
    );
  }, [data?.productCategories]);

  // @ts-ignore
  const formattedLifecycle: Summary[] = useMemo(() => {
    const sortOrder: { [key: string]: number } = {
      "General Availability": 1,
      "End of Life": 2,
      "End of Sale": 3,
      "End of Software Maintenance": 4,
      "End of Support": 5,
      Unknown: 6,
    };

    return data?.lifecycleStates
      ?.sort(
        (a: AssetWidgetBaseRecord, b: AssetWidgetBaseRecord) =>
          sortOrder[a.category ?? ""] - sortOrder[b.category ?? ""]
      )
      .map((item: AssetWidgetBaseRecord) => ({
        name: "lifecycleStates",
        data: item.assetsCount ?? 0,
        label: item.category ? item.category : "Unknown",
        customLabel: (
          <LabelLink
            label={item.category ? item.category : "Unknown"}
            baseUrl={AppendIfPrefixExists(
              shiOneUrl.assets.productCatalogProducts
            )}
            css={{ fontWeight: "normal" }}
            state={{ shiLifecycleState: item.category }}
          />
        ),
        color:
          lifecycleStateColors[
            item.category ? item.category?.toLowerCase() : "unknown"
          ],
      }));
  }, [data?.lifecycleStates]);
  const numberFormatter = new Intl.NumberFormat("en-US");

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      cardHeaderDivider
      noPadding={true}
      cardActions={<CardActions />}
    >
      <>
        <SingleDataWithLabels
          data={numberFormatter.format(totalCount)}
          label={"Total Products"}
          loading={isLoading}
          containerStyle={containerStyle}
        />
        <Divider />
        <PercentageLine
          visualParts={formattedProductTypes ?? []}
          loading={isLoading}
          leftLabel={"Types"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
        <PercentageLine
          visualParts={formattedProductCategories ?? []}
          loading={isLoading}
          leftLabel={"Categories"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
        <PercentageLine
          visualParts={formattedLifecycle ?? []}
          loading={isLoading}
          leftLabel={"Lifecycle States"}
          containerStyle={containerStyle}
          showSummaryValue={false}
        />
        <Divider />
      </>
    </WidgetWrapper>
  );
}
