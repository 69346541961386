import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MenuItem, TextField } from "@mui/material";

const styles = {
  formControl: {
    minWidth: 150,
  },
};

export default function DeviceMakeModelField(props) {
  const [deviceMakeModels, setDeviceMakeModels] = useState([]);
  const { required, formData, onChange } = props;

  const integrations = useSelector(
    (state) => state.integrationsCollection.integrations,
  );

  useEffect(() => {
    const deviceMakeModels = integrations
      .filter((integration) => integration?.metadata?.deviceMakeModel)
      .map((integration) => integration.metadata.deviceMakeModel);

    setDeviceMakeModels(deviceMakeModels);
  }, [integrations]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newDevice = { ...formData, deviceMakeModels: value };
    onChange(newDevice);
  };

  return (
    <>
      <TextField
        select
        sx={styles.formControl}
        label="Device Make/Model"
        variant="outlined"
        value={formData?.deviceMakeModels}
        onChange={handleChange}
        fullWidth
        required={required}
      >
        {deviceMakeModels.map((dmm) => (
          <MenuItem value={dmm} key={dmm}>
            {dmm}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
