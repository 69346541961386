import {
  GET_ACCOUNT_DEVICES,
  GET_ACCOUNT_DEVICES_SUCCESS,
  GET_ACCOUNT_DEVICES_ERROR,
  GET_ACCOUNT_USERS,
  GET_ACCOUNT_USERS_SUCCESS,
  GET_ACCOUNT_USERS_ERROR,
  ADD_TICKET_NOTE_TEMP,
  ACTIVATE_ACCOUNT_USERS,
  ACTIVATE_ACCOUNT_USERS_SUCCESS,
  ACTIVATE_ACCOUNT_USERS_ERROR,
  ADMIN_UPDATE_USER,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_ERROR,
  ADMIN_MANUAL_EMAIL_APPROVAL,
  ADMIN_MANUAL_EMAIL_APPROVAL_ERROR,
  ADMIN_MANUAL_EMAIL_APPROVAL_SUCCESS,
  GET_OTHER_ACCOUNTS,
  GET_OTHER_ACCOUNTS_ERROR,
  GET_OTHER_ACCOUNTS_SUCCESS,
  GET_APPREG_STATUS,
  GET_APPREG_STATUS_ERROR,
  GET_APPREG_STATUS_SUCCESS,
  ADMIN_CREATE_USER,
  ADMIN_CREATE_USER_ERROR,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_RESET_USER_STATUS,
} from "../actions/accountAdminActions";

import _ from "lodash";
import {DateTime} from "luxon";

function hasJsonStructure(str) {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
}

const updateMappedUserProfile = (
  mappedUserProfiles,
  updatedUserProfiles,
  updatedContacts
) => {
  return _.map(mappedUserProfiles, function (el) {
    if (el.id === updatedUserProfiles[0].id) {
      updatedUserProfiles[0].contact = updatedContacts[0];
      return updatedUserProfiles[0];
    }
    return el;
  });
};

const updateContacts = (contacts, updatedContacts) => {
  return _.map(contacts, function (el) {
    if (el.id === updatedContacts[0].id) {
      return updatedContacts[0];
    }
    return el;
  });
};

const addOrUpdateClientAccount = (existingAccounts, account, accountId) => {
  return {
    ...existingAccounts,
    [accountId]: account,
  };
};

const appRegistrationsWithOrgJson = (appRegistrations) => {
  return _.map(appRegistrations, (ap) => {
    return {
      ...ap,
      organizationData:
        ap.organizationResponseJson &&
        hasJsonStructure(ap.organizationResponseJson)
          ? JSON.parse(ap.organizationResponseJson).value[0]
          : false,
    };
  });
};

const updateWithCreatedUsers = (existingState, payloadData) => {
  const { mappedUserProfiles, unmappedUserProfiles, contacts, approvedUsers } =
    existingState;
  const { updatedContacts, updatedUserProfiles } = payloadData;
  // console.log(existingState, payloadData)

  // step 1: remove createdUserProfile from approvedUsers and unmappedUserProfiles
  const filteredApprovedUserProfiles = _.filter(approvedUsers, (au) => {
    const index = updatedUserProfiles.findIndex((e) => e.id === au.id);
    return index === -1;
  });
  // console.log('filteredApprovedUserProfiles', filteredApprovedUserProfiles)

  const filteredUnmappedUserProfiles = _.filter(unmappedUserProfiles, (uup) => {
    const index = updatedUserProfiles.findIndex((e) => e.id === uup.id);
    return index === -1;
  });
  // console.log('filteredUnmappedUserProfiles', filteredUnmappedUserProfiles)

  // step 2: add or replace contacts with new contacts
  // step 2a: remove duplicates
  const filteredContacts = _.filter(contacts, (c) => {
    const index = updatedContacts.findIndex((e) => e.id === c.id);
    return index === -1;
  });
  // console.log('filteredContacts', filteredContacts)

  // step 3a: re-add new contacts
  const mergedContacts = [...filteredContacts, ...updatedContacts];
  // console.log('mergedContacts', mergedContacts)

  // step 4: add new user profiles to mappedUserProfiles
  const combinedUserProfiles = [...mappedUserProfiles, ...updatedUserProfiles];
  // console.log('combinedUserProfiles', combinedUserProfiles)

  // step 3: add contact objects to userProfiles
  const combinedUserProfilesWithContacts = _.map(
    combinedUserProfiles,
    (cup) => {
      const contactIndex = cup.contact
        ? mergedContacts.findIndex((e) => e.id === cup.contact.id)
        : mergedContacts.findIndex((e) => e.id === cup.contactId);
      return {
        ...cup,
        contact: mergedContacts[contactIndex],
      };
    }
  );

  const result = {
    contacts: mergedContacts,
    mappedUserProfiles: combinedUserProfilesWithContacts,
    approvedUsers: filteredApprovedUserProfiles,
    unmappedUserProfiles: filteredUnmappedUserProfiles,
  };

  // console.log(result)

  return result;
};

export function accountAdminReducer(
  state = {
    localTicketNotes: [],
    fetchingAccountReports: false,
    fetchAccountReportsComplete: false,
    fetchAccountReportsError: undefined,
    accountDevices: {
      resultContacts: [],
      resultDevices: [],
      resultInfo: {},
      resultResources: [],
    },
    fetchingAccountDevices: false,
    fetchAccountDevicesComplete: false,
    fetchAccountDevicesError: undefined,
    accountUsers: {},
    fetchingAccountUsers: false,
    fetchAccountUsersComplete: false,
    fetchAccountUsersError: undefined,
    updatingAccountUser: false,
    updatingAccountUserComplete: false,
    updatingAccountUserError: undefined,
    updatingAccountUsers: false,
    updatingAccountUsersComplete: false,
    updatingAccountUsersError: undefined,
    activatedUsers: [],
    updatingManualEmail: false,
    updatingManualEmailComplete: false,
    updatingManualEmailError: undefined,
    clientAccountReports: {},
    fetchingClientAccountReport: {},
    fetchClientAccountReportComplete: {},
    fetchClientAccountReportError: {},
    clientAccounts: {},
    fetchingClientAccounts: {},
    fetchClientAccountsComplete: {},
    fetchClientAccountsError: {},
    appRegStatus: [],
    fetchingAppReg: false,
    fetchAppRegComplete: false,
    fetchAppRegError: undefined,
    creatingAccountUser: false,
    creatingAccountUserComplete: false,
    creatingAccountUserError: undefined,
  },
  action
) {
  switch (action.type) {
    case GET_APPREG_STATUS:
      return Object.assign({}, state, {
        fetchingAppReg: true,
        fetchAppRegComplete: false,
        fetchAppRegError: undefined,
      });
    case GET_APPREG_STATUS_SUCCESS:
      return Object.assign({}, state, {
        appRegStatus: appRegistrationsWithOrgJson(action.payload.data),
        fetchingAppReg: false,
        fetchAppRegComplete: true,
        fetchAppRegError: undefined,
      });
    case GET_APPREG_STATUS_ERROR:
      return Object.assign({}, state, {
        fetchingAppReg: false,
        fetchAppRegComplete: true,
        fetchAppRegError: action.payload.data,
      });
    case ADMIN_MANUAL_EMAIL_APPROVAL:
      return Object.assign({}, state, {
        updatingManualEmail: true,
        updatingManualEmailComplete: false,
      });
    case ADMIN_MANUAL_EMAIL_APPROVAL_ERROR:
      return Object.assign({}, state, {
        updatingManualEmail: false,
        updatingManualEmailComplete: true,
        updatingManualEmailError: action.payload.data,
      });
    case ADMIN_MANUAL_EMAIL_APPROVAL_SUCCESS:
      return Object.assign({}, state, {
        updatingManualEmail: false,
        updatingManualEmailComplete: true,
        accounts: action.payload.data,
      });
    case ADD_TICKET_NOTE_TEMP: {
      return Object.assign({}, state, {
        localTicketNotes: [
          ...state.localTicketNotes,
          Object.assign({}, action.payload.data, {
            timestamp: DateTime.local().toMillis(),
          }),
        ],
      });
    }
    case ACTIVATE_ACCOUNT_USERS:
      return Object.assign({}, state, {
        updatingAccountUsers: true,
        updatingAccountUsersComplete: false,
      });
    case ACTIVATE_ACCOUNT_USERS_ERROR:
      return Object.assign({}, state, {
        updatingAccountUsers: false,
        updatingAccountUsersComplete: true,
        updatingAccountUsersError: action.payload.data,
      });
    case ACTIVATE_ACCOUNT_USERS_SUCCESS:
      return Object.assign({}, state, {
        updatingAccountUsers: false,
        updatingAccountUsersComplete: true,
        accountUsers: Object.assign({}, state.accountUsers, {
          ...state.accountUsers,
          approvedUsers: [
            ...state.accountUsers.approvedUsers,
            ...action.payload.data.updatedUserProfiles,
          ],
        }),
      });
    case ADMIN_UPDATE_USER:
      return Object.assign({}, state, {
        updatingAccountUser: true,
        updatingAccountUserComplete: false,
      });
    case ADMIN_UPDATE_USER_ERROR:
      return Object.assign({}, state, {
        updatingAccountUser: false,
        updatingAccountUserComplete: true,
        updatingAccountUserError: action.payload.data,
      });
    case ADMIN_UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        updatingAccountUser: false,
        updatingAccountUserComplete: true,
        accountUsers: Object.assign({}, state.accountUsers, {
          ...state.accountUsers,
          mappedUserProfiles: updateMappedUserProfile(
            state.accountUsers.mappedUserProfiles,
            action.payload.data.updatedUserProfiles,
            action.payload.data.updatedContacts
          ),
          contacts: updateContacts(
            state.accountUsers.contacts,
            action.payload.data.updatedContacts
          ),
        }),
      });
    case ADMIN_CREATE_USER:
      return Object.assign({}, state, {
        creatingAccountUser: true,
        creatingAccountUserComplete: false,
      });
    case ADMIN_CREATE_USER_ERROR:
      return Object.assign({}, state, {
        creatingAccountUser: false,
        creatingAccountUserComplete: true,
        creatingAccountUserError: action.payload.data,
      });
    case ADMIN_CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        creatingAccountUser: false,
        creatingAccountUserComplete: true,
        accountUsers: updateWithCreatedUsers(
          state.accountUsers,
          action.payload.data
        ),
        lastCreatedUser: action.payload.data,
      });
    case ADMIN_RESET_USER_STATUS:
      return Object.assign({}, state, {
        creatingAccountUser: false,
        creatingAccountUserComplete: false,
        creatingAccountUserError: undefined,
        lastCreatedUser: undefined,
      });
    case GET_ACCOUNT_USERS:
      return Object.assign({}, state, {
        fetchingAccountUsers: true,
        fetchAccountUsersComplete: false,
      });
    case GET_ACCOUNT_USERS_ERROR:
      return Object.assign({}, state, {
        fetchingAccountUsers: false,
        fetchAccountUsersComplete: true,
        fetchAccountUsersError: action.payload.data,
      });
    case GET_ACCOUNT_USERS_SUCCESS:
      return Object.assign({}, state, {
        fetchingAccountUsers: false,
        fetchAccountUsersComplete: true,
        accountUsers: action.payload.data,
      });
    case GET_ACCOUNT_DEVICES:
      return Object.assign({}, state, {
        fetchingAccountDevices: true,
        fetchAccountDevicesComplete: false,
      });
    case GET_ACCOUNT_DEVICES_ERROR:
      return Object.assign({}, state, {
        fetchingAccountDevices: false,
        fetchAccountDevicesComplete: true,
        fetchAccountDevicesError: action.payload.data,
      });
    case GET_ACCOUNT_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        fetchingAccountDevices: false,
        fetchAccountDevicesComplete: true,
        accountDevices: action.payload.data,
      });
    case GET_OTHER_ACCOUNTS:
      return Object.assign({}, state, {
        fetchingClientAccounts: {
          ...state.fetchingClientAccounts,
          [action.payload.accountId]: true,
        },
        fetchClientAccountsComplete: {
          ...state.fetchClientAccountsComplete,
          [action.payload.accountId]: false,
        },
        fetchClientAccountsError: {
          ...state.fetchClientAccountsError,
          [action.payload.accountId]: undefined,
        },
      });
    case GET_OTHER_ACCOUNTS_ERROR:
      return Object.assign({}, state, {
        fetchingClientAccounts: {
          ...state.fetchingClientAccounts,
          [action.payload.accountId]: false,
        },
        fetchClientAccountsComplete: {
          ...state.fetchClientAccountsComplete,
          [action.payload.accountId]: true,
        },
        fetchClientAccountsError: {
          ...state.fetchClientAccountsError,
          [action.payload.accountId]: action.payload.data,
        },
      });
    case GET_OTHER_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        fetchingClientAccounts: {
          ...state.fetchingClientAccounts,
          [action.payload.accountId]: false,
        },
        fetchClientAccountsComplete: {
          ...state.fetchClientAccountsComplete,
          [action.payload.accountId]: true,
        },
        fetchClientAccountsError: {
          ...state.fetchClientAccountsError,
          [action.payload.accountId]: undefined,
        },
        clientAccounts: addOrUpdateClientAccount(
          state.clientAccounts,
          action.payload.data,
          action.payload.accountId
        ),
      });
    default:
      return state;
  }
}
