import {
  MarketplaceMappingClient,
  SubscriptionMapping,
  TenantSubscriptions,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const marketplaceMappingApi = new MarketplaceMappingClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export async function getRestCustomerTenantSubscriptions(
  unmappedOnly: boolean
): Promise<TenantSubscriptions[]> {
  return marketplaceMappingApi.getCustomerTenantSubscriptions(unmappedOnly);
}

export async function postRestSubscriptionMapping(
  postRequest: SubscriptionMapping
): Promise<void> {
  return marketplaceMappingApi.submitSubscriptionMapping(postRequest);
}
