import React from "react";
import GlobalConstants from "@constants";
import { CustomerExperienceReportCenter } from "../../../../v2/src/components/CustomerExperienceReportCenter";

const Title = "SHI";

export function ServiceReport() {
  return (
    <>
      <CustomerExperienceReportCenter
        key={"RequestDashboard"}
        title={Title}
        header="Service Report"
        reports={[
          {
            reportId: "RequestDashboardCustomer",
            filter: "",
            reportName: "Service Report",
            hideIntervals: false,
            intervals: [
              GlobalConstants.reportIntervals.lastMonth,
              GlobalConstants.reportIntervals.lastQuarter,
              GlobalConstants.reportIntervals.customRangeWithDays,
            ],
          },
        ]}
      />
    </>
  );
}
