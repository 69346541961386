import { combineReducers } from "@reduxjs/toolkit";
import navState from "./navState";
import { ticketReducer } from "./ticket/reducers/ticketReducer";
import { picklistReducer } from "./picklist/reducers/picklistReducer";
import { siteReducer } from "./site/reducers/siteReducer";
import { accountAdminReducer } from "./accountAdmin/reducers/accountAdminReducer";
import { clientManagementReducer } from "./clientManagement/reducers/clientManagementReducer";
import { teamReducer } from "./team/reducers/teamReducer";
import { knowledgebaseReducer } from "./knowledgebase/reducers/knowledgebaseReducer";
import { backupReducer } from "./backup/reducers/backupReducer";
import { contractMappingReducer } from "./mapping/reducers/contractMappingReducer";
import projects from "modules/rtk/project/projectSlice";
import device from "modules/rtk/device/deviceSlice";
import onboarding from "modules/rtk/onboarding/onboardingSlice";
import alert from "modules/rtk/alert/alertSlice";
import teamRtk from "modules/rtk/team/teamSlice";
import clientManagementRtk from "modules/rtk/clientManagement/clientManagementSlice";
import awsAccounts from "modules/rtk/awsAccounts/awsAccountSlice";
import profile from "modules/rtk/profile/profileSlice";
import accountAdminRtk from "modules/rtk/accountAdmin/accountAdminSlice";
import azureCompliance from "modules/rtk/azureCompliance/azureComplianceSlice";
import integrationsCollection from "modules/rtk/integrationsCollector/integrationsCollectorSlice";
import invoice from "modules/rtk/invoice/invoiceSlice";
import awsSimpleRecommendations from "modules/rtk/awsSimpleRecommendations/awsSimpleRecommendationsSlice";
import azureSimpleRecommendations from "modules/rtk/azureSimpleRecommendations/azureSimpleRecommendationsSlice";
import costAnalytics from "modules/rtk/costAnalytics/costAnalyticsSlice";
import azureBackups from "modules/rtk/azureBackup/azureBackupsSlice";
import azureSubscription from "modules/rtk/azureSubscription/azureSubscriptionSlice";
import marketplaceMapping from "modules/rtk/marketplaceMapping/marketplaceMappingSlice";
import contract from "modules/rtk/contract/contractSlice";
import bookmark from "modules/rtk/bookmark/bookmarkSlice";
import blueprints from "modules/rtk/blueprints/blueprintsSlice";
import account from "modules/rtk/account/accountSlice";
import dynamicTags from "modules/rtk/dynamicTags/dynamicTagsSlice";
import serviceRequestMapping from "modules/rtk/serviceRequestMapping/serviceRequestMappingSlice";
import costCenters from "modules/rtk/costCenters/costCentersSlice";
import serviceRequestTemplate from "modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import serviceRequest, {
  ServiceRequestReduxState,
} from "modules/rtk/serviceRequest/serviceRequestSlice";
import serviceRequestView from "modules/rtk/serviceRequest/serviceRequestViewSlice";
import { ProfileState } from "./rtk/profile/profileTypes";
import { ServiceRequestMappingState } from "./rtk/serviceRequestMapping/serviceRequestMappingTypes";
import { ClientManagementState } from "./rtk/clientManagement/clientManagementTypes";
import { contractState } from "./rtk/contract/contractTypes";
import { IntegrationsCollectorState } from "./rtk/integrationsCollector/integrationsCollectorTypes";
import { DeviceState } from "./rtk/device/deviceTypes";
import { accountState } from "./rtk/account/accountTypes";
import { TeamState } from "./rtk/team/teamTypes";
import { AccountAdminState } from "./rtk/accountAdmin/accountAdminTypes";

const rootReducer = () =>
  combineReducers({
    site: siteReducer,
    navState,
    account,
    accountAdmin: accountAdminReducer,
    accountAdminRtk,
    alert,
    awsAccounts,
    awsSimpleRecommendations,
    azureCompliance,
    azureBackups,
    azureSimpleRecommendations,
    azureSubscription,
    backup: backupReducer,
    bookmark,
    clientManagement: clientManagementReducer,
    clientManagementRtk,
    contract,
    contractSla: contractMappingReducer,
    costAnalytics,
    costCenters,
    device,
    dynamicTags,
    blueprints,
    integrationsCollection,
    invoice,
    knowledgebase: knowledgebaseReducer,
    marketplaceMapping,
    onboarding,
    picklist: picklistReducer,
    profile,
    projects,
    serviceRequestTemplate,
    serviceRequestMapping,
    serviceRequest,
    serviceRequestView,
    team: teamReducer,
    teamRtk,
    ticket: ticketReducer,
  });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export interface IRootState {
  clientManagement: ReturnType<typeof clientManagementReducer>;
  picklist: any;
  profile: ProfileState;
  serviceRequestMapping: ServiceRequestMappingState;
  serviceRequest: ServiceRequestReduxState;
  clientManagementRtk: ClientManagementState;
  contract: contractState;
  site: ReturnType<typeof siteReducer>;
  integrationsCollection: IntegrationsCollectorState;
  backup: ReturnType<typeof backupReducer>;
  device: DeviceState;
  knowledgebase: ReturnType<typeof knowledgebaseReducer>;
  account: accountState;
  team: ReturnType<typeof teamReducer>;
  teamRtk: TeamState;
  accountAdminRtk: AccountAdminState;
}
