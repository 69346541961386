import {
  GET_CLIENT_ACCOUNTS,
  GET_CLIENT_ACCOUNTS_SUCCESS,
  GET_CLIENT_ACCOUNTS_ERROR,
  GET_CLIENT_USERS,
  GET_CLIENT_USERS_SUCCESS,
  GET_CLIENT_USERS_ERROR,
  GET_CLIENT_ACCOUNT_TEAM,
  GET_CLIENT_ACCOUNT_TEAM_ERROR,
  GET_CLIENT_ACCOUNT_TEAM_SUCCESS,
  ADD_CLIENT_ACCOUNT_TEAM_MEMBER,
  ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
  ADD_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
  REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER,
  REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
  REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
  UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER,
  UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
  UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
  GET_CLIENT_AX_NUMBERS,
  GET_CLIENT_AX_NUMBERS_ERROR,
  GET_CLIENT_AX_NUMBERS_SUCCESS,
  UPDATE_CLIENT_AX_NUMBERS,
  UPDATE_CLIENT_AX_NUMBERS_SUCCESS,
  UPDATE_CLIENT_AX_NUMBERS_ERROR,
} from "../modules/clientManagement/actions/clientManagementActions";
import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${import.meta.env.API_ENDPOINT}/api/clientmanagement`;

const clientManagementApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_CLIENT_AX_NUMBERS: {
      AuthFetch(
        fetch,
        `${apiEndpoint}/client/axReferences/${action.payload.data}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: GET_CLIENT_AX_NUMBERS_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: GET_CLIENT_AX_NUMBERS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_CLIENT_AX_NUMBERS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case UPDATE_CLIENT_AX_NUMBERS: {
      AuthFetch(fetch, `${apiEndpoint}/client/addAxNumbers`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: UPDATE_CLIENT_AX_NUMBERS_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: UPDATE_CLIENT_AX_NUMBERS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: UPDATE_CLIENT_AX_NUMBERS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_CLIENT_USERS: {
      AuthFetch(fetch, `${apiEndpoint}/users/${action.payload.data}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: GET_CLIENT_USERS_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: GET_CLIENT_USERS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_CLIENT_USERS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_CLIENT_ACCOUNT_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/accountTeam/${action.payload.data}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: GET_CLIENT_ACCOUNT_TEAM_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: GET_CLIENT_ACCOUNT_TEAM_SUCCESS,
            payload: {
              data: data.accountTeamMembers,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_CLIENT_USERS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ADD_CLIENT_ACCOUNT_TEAM_MEMBER: {
      AuthFetch(fetch, `${apiEndpoint}/accountTeam/add`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: ADD_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
            payload: {
              data: data.accountTeamMember,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER: {
      AuthFetch(fetch, `${apiEndpoint}/accountTeam/remove`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
            payload: {
              data: action.payload.data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: REMOVE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER: {
      AuthFetch(fetch, `${apiEndpoint}/accountTeam/update`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
              payload: {
                data: data.errorMessage,
              },
            });
          }
          return next({
            type: UPDATE_CLIENT_ACCOUNT_TEAM_MEMBER_SUCCESS,
            payload: {
              data: data.accountTeamMember,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADD_CLIENT_ACCOUNT_TEAM_MEMBER_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_CLIENT_ACCOUNTS: {
      AuthFetch(fetch, `${apiEndpoint}/clients`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (!data || !data.responseStatus || data.responseStatus !== 1) {
            // console.log('GET_ACCOUNTS_ERROR fail', data)
            return next({
              type: GET_CLIENT_ACCOUNTS_ERROR,
              payload: {
                data:
                  data && data.errorMessage
                    ? data.errorMessage
                    : "No Result Found",
              },
            });
          }
          return next({
            type: GET_CLIENT_ACCOUNTS_SUCCESS,
            payload: {
              data: data.clientAccounts,
              userType: data.userType,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_CLIENT_ACCOUNTS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default clientManagementApi;
