import { AdvancedFilter, RelativeDateFilter, RelativeDateFilterTimeUnit, RelativeDateOperators } from "powerbi-models";
import { DateTime } from "luxon";
import { IntervalType } from "../hooks";
import { DateView } from "@mui/x-date-pickers-pro";

export enum ReportIntervals {
  lastMonth = "LastMonth",
  lastThreeMonths = "LastThreeMonths",
  lastQuarter = "LastQuarter",
  lastSixMonths = "LastSixMonths",
  yearToDate = "YearToDate",
  yearOverYear = "YearOverYear",
  customRange = "CustomRange",
  customRangeWithDays = "CustomRangeWithDays",
}

export const defaultAvailableIntervals = [
  ReportIntervals.lastThreeMonths,
  ReportIntervals.lastSixMonths,
  ReportIntervals.yearToDate,
  ReportIntervals.yearOverYear,
  ReportIntervals.customRange,
];

export const getRelativeDateFilter = (
  unitCount = 6,
  unit = RelativeDateFilterTimeUnit.Months,
  operator = RelativeDateOperators.InLast,
  target = {
    table: "Dates",
    hierarchy: "Dates Hierarchy",
    hierarchyLevel: "Date",
  }
): RelativeDateFilter => {
  const f = new RelativeDateFilter(target, operator, unitCount, unit, true);
  f.displaySettings = { isLockedInViewMode: true };
  return f;
};

export const getAdvancedDateFilter = (
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  target = {
    table: "Dates",
    hierarchy: "Dates Hierarchy",
    hierarchyLevel: "Date",
  }
): AdvancedFilter | undefined => {
  if (!startDate || !endDate) return undefined;
  const f = new AdvancedFilter(
    target,
    "And",
    {
      operator: "GreaterThanOrEqual",
      value: startDate,
    },
    { operator: "LessThanOrEqual", value: endDate }
  );
  f.displaySettings = { isLockedInViewMode: true };
  return f;
};

export const getIntervals = (
  sourceIntervals: string[],
  setDateFilterViews: (views: DateView[]) => void
) => {
  const intervals: IntervalType[] = [];
  if (sourceIntervals.includes(ReportIntervals.lastMonth)) {
    intervals.push({
      value: "Last Month",
      filter: getAdvancedDateFilter(
        DateTime.utc()
          .startOf("month")
          .minus({ months: 1 })
          .toISO({ includeOffset: false }),
        DateTime.utc()
          .startOf("month")
          .minus({ days: 1 })
          .toISO({ includeOffset: false })
      ),
    });
  }
  if (sourceIntervals.includes(ReportIntervals.lastThreeMonths)) {
    intervals.push({
      value: "Last 3 months",
      filter: getAdvancedDateFilter(
        DateTime.utc()
          .minus({ months: 2 })
          .startOf("month")
          .toISO({ includeOffset: false }),
        DateTime.utc().toISO()
      ),
    });
  }

  if (sourceIntervals.includes(ReportIntervals.lastQuarter)) {
    intervals.push({
      value: "Last Quarter",
      filter: getAdvancedDateFilter(
        DateTime.utc()
          .startOf("quarter")
          .minus({ quarters: 1 })
          .toISO({ includeOffset: false }),
        DateTime.utc().endOf("quarter").minus({ quarters: 1 }).toISO()
      ),
    });
  }

  if (sourceIntervals.includes(ReportIntervals.lastSixMonths)) {
    intervals.push({
      value: "Last 6 months",
      filter: getAdvancedDateFilter(
        DateTime.utc()
          .minus({ months: 5 })
          .startOf("month")
          .toISO({ includeOffset: false }),
        DateTime.utc().toISO()
      ),
    });
  }

  if (sourceIntervals.includes(ReportIntervals.yearToDate)) {
    intervals.push({
      value: "Year to Date",
      filter: getRelativeDateFilter(
        1,
        RelativeDateFilterTimeUnit.Years,
        RelativeDateOperators.InThis
      ),
    });
  }

  if (sourceIntervals.includes(ReportIntervals.yearOverYear)) {
    intervals.push({
      value: "Year over Year",
      filter: getRelativeDateFilter(1, RelativeDateFilterTimeUnit.Years),
    });
  }

  if (sourceIntervals.includes(ReportIntervals.customRange)) {
    intervals.push({
      value: "Custom Range",
      filter: undefined,
    });
    setDateFilterViews(["year", "month"]);
  }

  if (sourceIntervals.includes(ReportIntervals.customRangeWithDays)) {
    intervals.push({
      value: "Custom Range",
      filter: undefined,
    });
    setDateFilterViews(["year", "month", "day"]);
  }
  return intervals;
};
