import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import { accountState } from "./accountTypes";
import {
  getRestAccountNameWithAxNumber,
  getRestAllAccountNameWithAxNumber,
} from "../../../api/accountStore";
import { AccountNameWithAxNumber, AccountSearchQuery } from "lib/ShiOneClient";
import GlobalConstants from "@constants";

const initialState: accountState = {
  accountNameWithAxNumberStatus: "idle",
  accountNamesWithAxNumbers: [] as AccountNameWithAxNumber[],
  allAccountNamesWithAxNumberStatus: "idle",
  allAccountNamesWithAxNumbers: [] as AccountNameWithAxNumber[],
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    receivingAccountNamesWithAxNumbers(state) {
      state.accountNameWithAxNumberStatus = GlobalConstants.fetchStatus.loading;
    },
    receivedAccountNamesWithAxNumbers(
      state,
      action: PayloadAction<AccountNameWithAxNumber[]>
    ) {
      state.accountNamesWithAxNumbers = action.payload;
      state.accountNameWithAxNumberStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveAccountNamesWithAxNumbersError(state) {
      state.accountNameWithAxNumberStatus = GlobalConstants.fetchStatus.error;
    },
    receivingAllAccountNamesWithAxNumbers(state) {
      state.allAccountNamesWithAxNumberStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receivedAllAccountNamesWithAxNumbers(
      state,
      action: PayloadAction<AccountNameWithAxNumber[]>
    ) {
      state.allAccountNamesWithAxNumbers = action.payload;
      state.allAccountNamesWithAxNumberStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receiveAllAccountNamesWithAxNumbersError(state) {
      state.allAccountNamesWithAxNumberStatus =
        GlobalConstants.fetchStatus.error;
    },
  },
});

export const getAccountNamesAndAxNumbers =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(accountSlice.actions.receivingAccountNamesWithAxNumbers());
    try {
      const accountNamesWithAxNumbers = await getRestAccountNameWithAxNumber();
      dispatch(
        accountSlice.actions.receivedAccountNamesWithAxNumbers(
          accountNamesWithAxNumbers
        )
      );
    } catch (err) {
      dispatch(accountSlice.actions.receiveAccountNamesWithAxNumbersError());
    }
  };

export const getAllAccountNamesAndAxNumbers =
  (searchQuery: AccountSearchQuery): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountSlice.actions.receivingAllAccountNamesWithAxNumbers());
    try {
      const accountNamesWithAxNumbers = await getRestAllAccountNameWithAxNumber(
        searchQuery
      );
      dispatch(
        accountSlice.actions.receivedAllAccountNamesWithAxNumbers(
          accountNamesWithAxNumbers
        )
      );
    } catch (err) {
      dispatch(accountSlice.actions.receiveAllAccountNamesWithAxNumbersError());
    }
  };

export default accountSlice.reducer;
