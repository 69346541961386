import { useQuery } from "@tanstack/react-query";
import { ProjectListResponse } from "@lib/ShiOneClient";
import ms from "ms";
import { getProjects } from "@api/projectStore";

export function useGetProjects() {
  const fetchValues = async () => {
    return await getProjects();
  };

  return useQuery<ProjectListResponse, Error>({
    queryKey: ["projects"],
    queryFn: fetchValues,
    staleTime: ms("15m"),
  });
}
