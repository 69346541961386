import { useCallback, useEffect, useRef, useState } from "react";

export function useObserveElementSize() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const resizeObserver = useRef<any>();

  const onResize = useCallback((entries) => {
    const { width, height } = entries[0].contentRect;
    setHeight(height);
    setWidth(width);
  }, []);

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        if (resizeObserver?.current) {
          resizeObserver.current.disconnect();
        }
        resizeObserver.current = new ResizeObserver(onResize);
        resizeObserver.current.observe(node);
      }
    },
    [onResize]
  );

  useEffect(
    () => () => {
      if (resizeObserver?.current) {
        resizeObserver.current.disconnect();
      }
    },
    []
  );

  return {
    measuredRef,
    width,
    height,
  };
}
