import { OnboardingAdmin, OnboardingStatus } from "../lib/ShiOneClient";

export const getWelcomeProgressLocalStorage = (): OnboardingStatus => {
  const onboardingStatusString = localStorage.getItem("onboardingStatus");
  if (onboardingStatusString != null) {
    const onboardingStatusAny: any = JSON.parse(onboardingStatusString);
    return onboardingStatusAny as OnboardingStatus;
  }
  return new OnboardingStatus({
    accountId: -1,
    accountName: "",
    contactCreationSuccessful: false,
    welcomeLinkGuid: "",
    connections: [],
    domains: [],
    existingAdmins: [],
    firstAdmin: {} as OnboardingAdmin,
  });
};

export const setWelcomeProgressLocalStorage = (
  welcomeProgress: OnboardingStatus
) => {
  localStorage.setItem("onboardingStatus", JSON.stringify(welcomeProgress));
};
