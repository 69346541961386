import React, { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import { useTheme } from "@mui/material";
import shiOneLight from "../assets/shi-one-light.svg";
import shiOneDark from "../assets/shi-one-dark.svg";

const ShiOneLogo = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  const { palette } = useTheme();
  const {
    src = palette.mode === "light" ? shiOneLight : shiOneDark,
    alt = "shi-one-logo",
    height = "36px",
    width = "auto",
    ...otherProps
  } = props;
  if (palette.mode === "light") {
    return (
      <img src={src} alt={alt} height={height} width={width} {...otherProps} />
    );
  } else
    return (
      <img src={src} alt={alt} height={height} width={width} {...otherProps} />
    );
};
export default ShiOneLogo;
