import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store";
import { BlueprintsState } from "./blueprintsTypes";
import { getRestBlueprints } from "../../../api/blueprintsStore";

const initialState: BlueprintsState = {
  blueprintsFetchStatus: "idle",
  blueprints: {} as any,
};

const blueprintsSlice = createSlice({
  name: "blueprints",
  initialState,
  reducers: {
    receivingBlueprints(state) {
      state.blueprintsFetchStatus = "loading";
    },
    receivedBlueprints(state, action: PayloadAction<any>) {
      state.blueprints = action.payload;
      state.blueprintsFetchStatus = "complete";
    },
    receiveBlueprintsError(state) {
      state.blueprintsFetchStatus = "error";
    },
  },
});

export const getBlueprints = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(blueprintsSlice.actions.receivingBlueprints());
  try {
    const data = await getRestBlueprints();
    dispatch(blueprintsSlice.actions.receivedBlueprints(data));
  } catch {
    dispatch(blueprintsSlice.actions.receiveBlueprintsError());
  }
};

export default blueprintsSlice.reducer;
