import TwoColumnDashboard from "shared-ui/src/dashboards/TwoColumnDashboard";
import { UserConfigDto } from "@lib/ShiOneClient";
import { widgetRegistry } from "@widgets/widgetRegistry";
import { useQueryClient } from "@tanstack/react-query";
import { useGetDashboardConfiguration } from "./framework/useGetDashboardConfiguration";
import { useUpsertUserConfig } from "../../../modules/hooks/useUserConfig";
import {
  ExtendsPageLayoutDto,
  IPageLayout,
  PageLayout,
} from "shared-ui/src/dashboards/models/PageLayout";

export default function CustomerExperienceTwoColumnDashboard(props: {
  dashboardId: string;
  header?: string;
}) {
  const { dashboardId, header = "" } = props;
  const cacheKey = `pageLayout/${dashboardId}`;
  const { data, isLoading, isError } = useGetDashboardConfiguration(
    dashboardId,
    cacheKey
  );
  const upsertUserConfigs = useUpsertUserConfig();

  function updatePageConfiguration(pageConfiguration: ExtendsPageLayoutDto) {
    const pageLayout: IPageLayout = new PageLayout(pageConfiguration);

    const settings = new UserConfigDto();
    settings.settingsKey = `PageLayout:${pageConfiguration.pageLayoutId}`;
    settings.settingsValue = JSON.stringify(pageLayout);
    upsertUserConfigs.mutate(settings);
    queryClient.setQueryData([cacheKey, dashboardId], pageConfiguration);
  }

  const queryClient = useQueryClient();

  let showDashboardHeader = false;

  if (header) {
    showDashboardHeader = true;
  }

  return (
    <TwoColumnDashboard
      dashboardProps={{ data, isLoading, isError, updatePageConfiguration }}
      header={header}
      showDashboardHeader={showDashboardHeader}
      widgetRegistry={widgetRegistry}
    />
  );
}
