import GlobalConstants from "@constants";

import { DateTime } from "luxon";

export const expiredContract = (contract) => {
  const today = DateTime.now().setZone("utc");
  return today >= contract.endDate;
};

export const determineContractType = (contract) => {
  switch (true) {
    case contract.contractCategory ===
      GlobalConstants.contractCategory.flexibleServiceAgreement:
      return GlobalConstants.contractDetailsType.fsa;
    case contract.serviceLevelAgreementID ===
      GlobalConstants.serviceLevelAgreement.agileCloudEngineering:
      return GlobalConstants.contractDetailsType.ace;
    case contract.contractType ===
      GlobalConstants.contractType.recurringService &&
      contract.contractCategory ===
        GlobalConstants.contractCategory.managedService:
      return GlobalConstants.contractDetailsType.managedRecurringService;
    case contract.contractType ===
      GlobalConstants.contractType.recurringService:
      return GlobalConstants.contractDetailsType.recurringService;
    default:
      return GlobalConstants.contractDetailsType.generic;
  }
};

export const hasAzureCloudContracts = (cloudContracts) => {
  return (
    cloudContracts &&
    cloudContracts.filter(
      (c) =>
        (c.cloud === "Azure" || c.cloud === "Azure CSP") &&
        c.userDefinedFieldsJson &&
        c.userDefinedFieldsJson !== "[]"
    ).length > 0
  );
};

export const hasAwsCloudContracts = (cloudContracts) => {
  return (
    cloudContracts &&
    cloudContracts.filter(
      (c) =>
        c.cloud === "AWS" &&
        c.userDefinedFieldsJson &&
        c.userDefinedFieldsJson !== "[]"
    ).length > 0
  );
};

export const hasOffice365CloudContracts = (cloudContracts) => {
  return (
    cloudContracts &&
    cloudContracts.filter(
      (c) =>
        (c.cloud === "Office 365 CSP" || c.cloud === "Office 365") &&
        c.userDefinedFieldsJson &&
        c.userDefinedFieldsJson !== "[]"
    ).length > 0
  );
};

export const hasGCPCloudContracts = (teamContracts, cloudContracts) => {
  const hasGcpCloudContracts =
    cloudContracts &&
    cloudContracts.filter(
      (c) =>
        (c.cloud === "GCP" || c.cloud === "GCP Project") &&
        c.userDefinedFieldsJson &&
        c.userDefinedFieldsJson !== "[]"
    ).length > 0;
  const hasGcpTeamCloudContracts =
    teamContracts &&
    teamContracts.cloudContracts &&
    teamContracts.cloudContracts.length > 0 &&
    teamContracts.cloudContracts.filter(
      (tc) =>
        tc.contractCategory === GlobalConstants.contractCategory.gcp &&
        tc.serviceLevelAgreementID === GlobalConstants.serviceLevelAgreement.gcp
    ).length > 0;

  return !!(hasGcpCloudContracts || hasGcpTeamCloudContracts);
};

export const shouldContractHaveDetails = (contract) => {
  return (
    determineContractType(contract) !==
    GlobalConstants.contractDetailsType.generic
  );
};
