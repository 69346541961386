import {
  FETCH_CONTRACTSLAS,
  FETCH_CONTRACTSLAS_SUCCESS,
  FETCH_CONTRACTSLAS_ERROR,
  CREATE_CONTRACTSLAS,
  CREATE_CONTRACTSLAS_SUCCESS,
  CREATE_CONTRACTSLAS_ERROR,
  UPDATE_CONTRACTSLAS,
  UPDATE_CONTRACTSLAS_SUCCESS,
  UPDATE_CONTRACTSLAS_ERROR,
} from "../../mapping/actions/contractMappingActions";

import _ from "lodash";

const updateSlaMappings = (updatedSlaMapping, existingSlaMappings) => {
  return {
    ...existingSlaMappings,
    mappedContractSLAs: existingSlaMappings.mappedContractSLAs.map((esm) => {
      if (esm.id === updatedSlaMapping.update.id) {
        return updatedSlaMapping.update;
      }
      return esm;
    }),
  };
};

const createSlaMappings = (createdSlaMapping, existingSlaMappings) => {
  // console.log(createdSlaMapping, existingSlaMappings)
  return {
    mappedContractSLAs: [
      ...existingSlaMappings.mappedContractSLAs,
      createdSlaMapping.create,
    ],
    unmappedContractSLAs: _.filter(
      existingSlaMappings.unmappedContractSLAs,
      (m) => {
        return (
          Number(m.contractCategory) !==
            Number(createdSlaMapping.create.contractCategory) &&
          Number(m.serviceLevelAgreementId) !==
            Number(createdSlaMapping.create.serviceLevelAgreementId)
        );
      }
    ),
  };
};

export function contractMappingReducer(
  state = {
    contractSlaMappings: undefined,
    createdContractSlaMapping: undefined,
    updatedContractSlaMapping: undefined,
    fetchingContractSlas: false,
    fetchingContractSlasComplete: false,
    fetchingContractSlasError: undefined,
    creatingContractSla: false,
    creatingContractSlaComplete: false,
    creatingContractSlaError: undefined,
    updatingContractSla: false,
    updatingContractSlaComplete: false,
    updatingContractSlaError: undefined,
  },
  action
) {
  switch (action.type) {
    case FETCH_CONTRACTSLAS:
      return Object.assign({}, state, {
        fetchingContractSlas: true,
        fetchingContractSlasError: undefined,
        fetchingContractSlasComplete: false,
        contractSlaMappings: undefined,
      });
    case FETCH_CONTRACTSLAS_SUCCESS:
      return Object.assign({}, state, {
        fetchingContractSlas: false,
        fetchingContractSlasError: undefined,
        fetchingContractSlasComplete: true,
        contractSlaMappings: action.payload.data,
      });
    case FETCH_CONTRACTSLAS_ERROR:
      return Object.assign({}, state, {
        fetchingContractSlas: false,
        fetchingContractSlasComplete: false,
        fetchingContractSlasError: action.payload.data,
        contractSlaMappings: undefined,
      });
    case CREATE_CONTRACTSLAS:
      return Object.assign({}, state, {
        creatingContractSla: true,
        creatingContractSlaComplete: false,
        creatingContractSlaError: undefined,
        createdContractSlaMapping: undefined,
      });
    case CREATE_CONTRACTSLAS_SUCCESS:
      return Object.assign({}, state, {
        creatingContractSla: false,
        creatingContractSlaError: undefined,
        creatingContractSlaComplete: true,
        createdContractSlaMapping: action.payload.data,
        contractSlaMappings: createSlaMappings(
          action.payload.data,
          state.contractSlaMappings
        ),
      });
    case CREATE_CONTRACTSLAS_ERROR:
      return Object.assign({}, state, {
        creatingContractSla: false,
        creatingContractSlaComplete: false,
        creatingContractSlaError: action.payload.data,
        createdContractSlaMapping: undefined,
      });
    case UPDATE_CONTRACTSLAS:
      return Object.assign({}, state, {
        updatingContractSla: true,
        updatingContractSlaError: undefined,
        updatingContractSlaComplete: false,
        updatedContractSlaMapping: undefined,
      });
    case UPDATE_CONTRACTSLAS_SUCCESS:
      return Object.assign({}, state, {
        updatingContractSla: false,
        updatingContractSlaError: undefined,
        updatingContractSlaComplete: true,
        updatedContractSlaMapping: action.payload.data,
        contractSlaMappings: updateSlaMappings(
          action.payload.data,
          state.contractSlaMappings
        ),
      });
    case UPDATE_CONTRACTSLAS_ERROR:
      return Object.assign({}, state, {
        updatingContractSla: false,
        updatingContractSlaComplete: false,
        updatingContractSlaError: action.payload.data,
        updatedContractSlaMapping: undefined,
      });
    default:
      return state;
  }
}
