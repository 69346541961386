import { DateTime } from "luxon";

export const parseDateToDate = (date: any): DateTime | null => {
  const isoDate = date.replace(' ', 'T');
  let dateTime: DateTime | null = null;

  if (typeof isoDate === "string") {
    dateTime = DateTime.fromISO(isoDate);
  } else if (isoDate instanceof Date) {
    dateTime = DateTime.fromJSDate(isoDate);
  }

  if (!dateTime || !dateTime.isValid) {
    return null;
  }
  return dateTime;
};

export const parseDateToString = (date: any) => {
  if (typeof date === "string") {
    return DateTime.fromISO(date).toJSDate().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  } else if (date instanceof Date) {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  } else {
    return null;
  }
};