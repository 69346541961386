import { useGetWarehouseStock } from "@features/assets-feature/hooks/warehouse/useGetWarehouseStock";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IWarehouseStockItemDto } from "@lib/ShiOneClient";
import {
  Autocomplete,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiDataGrid from "../../components/mui-data-grid/MuiDataGrid";
import { GridEditInputCell, useGridApiRef } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import CustomToolbar from "../../v2/src/components/CustomToolbar";
import { clsx } from "clsx";
import { FieldProps } from "@rjsf/utils";
import LoadingOrError from "../LoadingOrError";
import { DeleteSweep, ShoppingCartCheckout } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledBox = styled("div")<{ showSortVisible: boolean }>(
  ({ theme, showSortVisible }) => ({
    "& .Mui-error": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      ...theme.applyStyles("dark", {
        backgroundColor: "rgb(126,10,15, 0)",
      }),
    },
    "&  .MuiDataGrid-iconButtonContainer": {
      visibility: (showSortVisible ? "visible" : "hidden") + "!important",
    },
  })
);

interface WarehouseStock extends IWarehouseStockItemDto {
  quantity: number;
}

interface OrderRow {
  mfgPartNumber: string;
  shiItemNumber: string;
  description: string;
  quantity: number;
  projectId: string;
}

export default function WarehousePicker(props: FieldProps) {
  const { state: assetsUseLocationState } = useLocation();
  const { data, isError } = useGetWarehouseStock(assetsUseLocationState);

  const { formData, onChange } = props;

  const [updatedItems, setUpdatedItems] = useState<WarehouseStock[]>();
  const [projectNames, setProjectNames] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [localSearch, setLocalSearch] = useState("");

  const apiRef = useGridApiRef();

  function generateItem(item: WarehouseStock) {
    return `SHI Item Id: ${item.itemNumber}  | Project(variant): ${item.projectID}  | Mfr Part: ${item.mfgPartNum}  | Description: ${item.productName}  | Qty: ${item.quantity}`;
  }

  useEffect(() => {
    // @ts-ignore
    const updatedFormData: OrderRow[] =
      itemsWithQuantity()?.map((item) => {
        return generateItem(item);
      }) ?? [];

    onChange({
      ...formData,
      orderItems: [...updatedFormData],
    });
  }, [updatedItems]);

  useEffect(() => {
    const rawData = data?.warehouseStock?.map((item, index) => {
      if (
        (item?.variant?.includes("D-") || item?.variant?.includes("X-")) &&
        // @ts-ignore
        item?.physInv > 0
      ) {
        return { ...item, quantity: 0 };
      }
    });

    const projectIds = rawData?.map((i) => {
      if (i?.projectID) {
        return i.projectID;
      }
    });
    // @ts-ignore
    setProjectNames([...new Set(projectIds)].filter((i) => i !== undefined));
    // @ts-ignore
    setUpdatedItems(rawData);
  }, [data]);

  const filteredItems = updatedItems?.filter((item) => {
    return (
      // @ts-ignore
      selectedProjects.includes(item?.projectID) &&
      (item?.productName?.toLowerCase().includes(localSearch.toLowerCase()) ||
        item?.mfgPartNum?.toLowerCase().includes(localSearch.toLowerCase()) ||
        item?.productLine?.toLowerCase().includes(localSearch.toLowerCase()) ||
        item?.projectID?.toLowerCase().includes(localSearch.toLowerCase()))
    );
  });

  const handleInputChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setLocalSearch(value.toLowerCase());
  };

  const customToolbar = () => {
    return (
      <CustomToolbar
        searchValue={localSearch}
        handleSearch={handleInputChange}
      />
    );
  };

  const handleProcessRowUpdate = (newRow: WarehouseStock) => {
    setUpdatedItems((prevRows) =>
      prevRows?.map((row) =>
        row?.uniqueId === newRow?.uniqueId ? newRow : row
      )
    );
    return newRow;
  };

  const itemsWithQuantity = () => {
    return updatedItems?.filter((item) => item?.quantity > 0);
  };

  const dataGridClass = selectedProjects?.length > 0 ? "" : "invisible h-0";

  if (isError) {
    return (
      <LoadingOrError
        error
        message={"Failed to fetch warehouse stock please try again later"}
      />
    );
  }

  return (
    <>
      <FormControl className={"mt-4"}>
        <Autocomplete
          fullWidth
          multiple
          id={"projectIds"}
          value={selectedProjects}
          // @ts-ignore
          getOptionLabel={(option) => option}
          onChange={(_event, newValue) => {
            const itemsWithQuantityRest = updatedItems?.map((item) => {
              // @ts-ignore
              if (!newValue.includes(item?.projectID)) {
                return { ...item, quantity: 0 };
              }
              return item;
            });
            // @ts-ignore
            const updatedFormData: OrderRow[] = itemsWithQuantityRest?.map(
              (item) => {
                if (item?.quantity > 0) {
                  return generateItem(item);
                }
              }
            );
            onChange({
              ...formData,
              orderItems: [...updatedFormData],
            });
            setUpdatedItems(itemsWithQuantityRest);
            // @ts-ignore
            setSelectedProjects(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Project (Variant)"
              placeholder="Select a Project"
            />
          )}
          options={projectNames}
        />
      </FormControl>

      <div className={"flex mt-4 gap-2 items-center"}>
        {selectedProjects.map((i: string, index) => {
          return (
            <>
              {index !== 0 && (
                <Divider
                  className={"mb-0 mr-2"}
                  orientation={"vertical"}
                  flexItem
                />
              )}
              <Typography>{i} Items</Typography>
              <div>
                <IconButton
                  color={"primary"}
                  onClick={() => {
                    const api = apiRef.current;
                    const rowsToUpdate = Array.from(api.getRowModels()).filter(
                      (item) => item?.[1].projectID === i
                    );
                    const newRows = rowsToUpdate.map((item) => {
                      const i = item?.[1];
                      return {
                        ...i,
                        quantity: i.physInv,
                      };
                    });

                    newRows.forEach((row) => {
                      api.updateRows([row]);
                      handleProcessRowUpdate(row);
                    });
                  }}
                >
                  <Tooltip title={`Add all ${i} items to Order Summary`}>
                    <ShoppingCartCheckout />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color={"error"}
                  onClick={() => {
                    const api = apiRef.current;
                    const rowsToUpdate = Array.from(api.getRowModels()).filter(
                      (item) => item?.[1].projectID === i
                    );
                    const newRows = rowsToUpdate.map((item) => {
                      const i = item?.[1];
                      return {
                        ...i,
                        quantity: 0,
                      };
                    });

                    newRows.forEach((row) => {
                      api.updateRows([row]);
                      handleProcessRowUpdate(row);
                    });
                  }}
                >
                  <Tooltip title={`Delete all ${i} items from Order Summary`}>
                    <DeleteSweep />
                  </Tooltip>
                </IconButton>
              </div>
            </>
          );
        })}
      </div>
      <div className={clsx("flex gap-4 mt-4", dataGridClass)}>
        <StyledBox
          className={"grow min-w-[57.5vw]"}
          showSortVisible={selectedProjects?.length > 0}
        >
          <MuiDataGrid
            apiRef={apiRef}
            processRowUpdate={handleProcessRowUpdate}
            customToolbar={customToolbar}
            adjustRowHeight={false}
            getRowId={(rowData) => rowData?.uniqueId}
            rowCount={filteredItems?.length}
            gridDefinitions={{
              columnDefinitions: [
                {
                  headerName: "Manufacturer Part#",
                  field: "mfgPartNum",
                  flex: 0.5,
                },
                {
                  headerName: "Product Description",
                  field: "productName",
                  flex: 1,
                },
                { headerName: "Manufacturer", field: "productLine" },
                { headerName: "In Warehouse", field: "physInv" },
                {
                  headerName: "Quantity",
                  field: "quantity",
                  cellClassName: dataGridClass,
                  headerClassName: dataGridClass,
                  editable: true,
                  type: "number",
                  preProcessEditCellProps: (params) => {
                    const hasError =
                      !Number.isInteger(params.props.value) ||
                      params.props.value > params.row.physInv ||
                      params.props.value < 0;
                    return { ...params.props, error: hasError };
                  },
                  renderEditCell: (params) => (
                    <GridEditInputCell
                      {...params}
                      inputProps={{
                        min: 0,
                      }}
                    />
                  ),
                },
                {
                  field: "action",
                  headerName: "",
                  sortable: false,
                  flex: 0.4,
                  renderCell: (params) => {
                    const api = apiRef.current;
                    const currentRow = params.row;

                    if (params.row.quantity > 0) {
                      return (
                        <Tooltip
                          title={`Remove item quantity from Order Summary`}
                        >
                          <IconButton>
                            <DeleteIcon
                              color={"error"}
                              onClick={() => {
                                const newRow = { ...currentRow, quantity: 0 };
                                api.updateRows([newRow]);
                                handleProcessRowUpdate(newRow);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          title={`Add all item quantity to Order Summary`}
                        >
                          <IconButton>
                            <ShoppingCartCheckout
                              color={"primary"}
                              onClick={() => {
                                const newRow = {
                                  ...currentRow,
                                  quantity: params.row.physInv,
                                };
                                api.updateRows([newRow]);
                                handleProcessRowUpdate(newRow);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                  },
                },
              ],
              sortedColumns: [{ field: "quantity", sort: "desc" }],
            }}
            gridData={filteredItems ?? []}
          />
        </StyledBox>

        <div>
          <div
            className={
              "bg-background-default justify-center w-[23rem] border border-solid border-border"
            }
          >
            <Typography
              variant={"subtitle1"}
              className={
                "text-center py-4 text-nowrap  border border-solid px-0 border-border border-x-0 border-t-0 "
              }
            >
              Order Summary
            </Typography>

            <List>
              {
                // @ts-ignore
                itemsWithQuantity()?.length === 0 && (
                  <ListItem className={"text-center"}>
                    <ListItemText secondary={"No Items Added"} />
                  </ListItem>
                )
              }

              {updatedItems
                ?.sort((a, b) => b.quantity - a.quantity)
                ?.map((item) => {
                  if (item?.quantity > 0) {
                    return (
                      <ListItem
                        className={"flex text-nowrap grow"}
                        key={item?.uniqueId}
                      >
                        <ListItemText
                          secondaryTypographyProps={{
                            sx: {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          }}
                          primary={item?.mfgPartNum}
                          secondary={item?.productName}
                        />
                        <ListItemText
                          inset
                          primary={item?.quantity}
                          className={"text-right"}
                        />
                      </ListItem>
                    );
                  }
                })}
              {/*// @ts-ignore*/}
              {itemsWithQuantity()?.length > 0 && (
                <ListItem
                  className={
                    "border border-solid border-border border-x-0 border-b-0 pt-4"
                  }
                >
                  <ListItemText primary={"Total Items"} />
                  <ListItemText
                    className={"text-right"}
                    // @ts-ignore
                    primary={itemsWithQuantity().reduce(
                      (accum, currentValue) => accum + currentValue.quantity,
                      0
                    )}
                  />
                </ListItem>
              )}
            </List>
          </div>
        </div>
      </div>
    </>
  );
}
