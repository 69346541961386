import {
  ContractDto,
  PhasesWithTasks,
  ProjectIndividualResponse,
  ProjectTaskDto,
} from "@lib/ShiOneClient";
import React, { ReactNode } from "react";
import { OutlinedInputProps } from "@mui/material";

export enum DetailFieldType {
  Text,
  Number,
  Date,
}

export interface DetailField<T> {
  fieldKey: keyof T;
  label: string;
  type: DetailFieldType;
  columnKey: number;
  sortPriority: number;
  inputProps?: OutlinedInputProps;
  isPicklist?: boolean;
  picklistEntityKey?: string;
  picklistFieldKey?: string;
  disabled?: boolean;
}

export interface RecordsDetail {
  title: string;
  count: number;
  linkTo: string;
  contractName: string;
}

export interface ContractDetailField extends DetailField<ContractDto> {}

export type ProjectDuration = {
  duration: number;
  currentProgress: number;
};

export interface ProjectDetailsPhaseProps {
  phasesWithTasks: PhasesWithTasks[];
  orphanTasks: ProjectTaskDto[];
  picklist: any;
}

export interface ProjectDetailChildPhaseProps {
  childPhases: PhasesWithTasks;
  displayTasks: (tasks: ProjectTaskDto[], style?: React.CSSProperties) => any;
  picklist: any;
  style: React.CSSProperties;
}

export interface ProjectDetailsPageHeaderProps {
  children?: React.ReactNode;
  picklist: any;
  selectedProject: ProjectIndividualResponse;
}

export interface ProjectDetailsPhaseStepperProps {
  phasesWithTasks: PhasesWithTasks[];
  activePhase: number;
}

export interface ProjectDetailsTimelineCardProps {
  selectedProject: ProjectIndividualResponse;
  duration: ProjectDuration | undefined;
  timelineProgress: number;
}

export interface ProjectDetailsMetadataProps {
  selectedProject: ProjectIndividualResponse;
  openContractDetailDialog: () => void;
  contract: ContractDto | undefined;
}

export interface ContractDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  contract: ContractDto;
  picklist: any;
}

export interface ContractDetailsDialogContainerProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export interface ContractDetailsDialogCloseOnlyProps {
  onClose: () => void;
}

export interface ServiceContractDetailsContainerProps {
  contract: ContractDto;
  picklist: any;
}

export interface ContractDetailTextFieldProps {
  field: ContractDetailField;
  contract: ContractDto;
  picklist: any;
}

export interface RecordsDetailContainerProps {
  recordsDetail: RecordsDetail[];
  loading: boolean;
}
