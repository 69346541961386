import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { ProjectDetailsTimelineCardProps } from "../projectTypes";
import { formatDateTime } from "@features/projects-feature/projectHelpers";

const ProjectDetailsTimelineCard: React.FC<ProjectDetailsTimelineCardProps> = ({
  selectedProject,
  duration,
  timelineProgress,
}) => {
  const clampedTimelineProgress = Math.min(Math.max(timelineProgress, 0), 100);
  return (
    <Card>
      <CardHeader title={<Typography variant="body1">TIMELINE</Typography>} />
      <CardContent
        sx={{
          paddingTop: 1,
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        {duration && (
          <Grid container direction="column" gap={1}>
            <Grid item container justifyContent="space-between">
              <Grid item xs={6}>
                {selectedProject.project?.startDateTime && (
                  <Typography noWrap variant="body2">
                    {"Start Date: " +
                      formatDateTime(selectedProject.project.startDateTime)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                {selectedProject.project?.endDateTime && (
                  <Typography
                    component="div"
                    noWrap
                    variant="body2"
                    textAlign="right"
                  >
                    {"End Date: " +
                      formatDateTime(selectedProject.project?.endDateTime)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                sx={{ height: 11 }}
                value={clampedTimelineProgress}
                variant={"determinate"}
              />
            </Grid>
            <Grid item xs={12}>
              {selectedProject.project?.status === 5 ? (
                <Typography variant="body2" textAlign="right">
                  Complete
                </Typography>
              ) : (
                <Typography component="div" variant="body2" textAlign="right">
                  {duration?.currentProgress.toLocaleString() + " / "}
                  {duration?.duration.toLocaleString() + " days "}
                  <Typography
                    component="span"
                    color={"textSecondary"}
                    variant={"body2"}
                  >
                    ({+Math.floor(timelineProgress).toLocaleString()}
                    %)
                  </Typography>
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectDetailsTimelineCard;
