import axios from "axios";
import { GetAxiosHeaders } from "../auth/GetAxiosHeaders";
import { CreateProfile, UserProfileCacheResponse } from "../lib/ShiOneClient";

const baseUrl = `${import.meta.env.API_ENDPOINT}/api/profile`;

export async function getRestMyUserProfile(): Promise<UserProfileCacheResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<UserProfileCacheResponse>(
    `${baseUrl}/currentUser`,
    { headers: axiosHeaders }
  );

  return response.data;
}

export async function postRestMyUserProfile(
  profile: CreateProfile
): Promise<UserProfileCacheResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<UserProfileCacheResponse>(
    baseUrl + "/updateprofile",
    profile,
    { headers: axiosHeaders }
  );

  return response.data;
}
