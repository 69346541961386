import palette from "./palette";
import "../css/app.css";

const typography = {
  h1: {
    fontFamily: "Lora",
    fontWeight: "300",
    fontSize: "3rem",
    letterSpacing: "-0.00833em",
    lineHeight: "1",
    marginBottom: "16px",
  },
  h2: {
    fontFamily: "Lora",
    fontWeight: "400",
    fontSize: "2rem",
    letterSpacing: "-0.0.00735em",
    lineHeight: "1.25",
  },
  h3: {
    fontFamily: "Lora",
    fontWeight: "400",
    fontSize: "1.33rem",
    letterSpacing: "0em",
    lineHeight: "1.33",
  },
  h4: {
    fontFamily: "Lora",
    color: palette.text.secondary,
    fontWeight: "300",
    fontSize: "20px",
    letterSpacing: "-0.06px",
    lineHeight: "24px",
  },
  h5: {
    color: palette.text.secondary,
    fontWeight: "300",
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
  },
  h6: {
    fontWeight: "400",
    fontSize: "1rem",
    letterSpacing: "0.00938em",
    lineHeight: "1.75",
  },
  subtitle1: {
    fontWeight: "400",
    fontSize: "1.33rem",
    letterSpacing: "0em",
    lineHeight: "1.33",
    paddingBottom: "40px",
  },
  subtitle2: {
    fontWeight: "400",
    fontSize: "1.33rem",
    letterSpacing: "0em",
    lineHeight: "1.33",
  },
  body1: {
    color: palette.text.primary,
    fontWeight: "400",
    fontSize: "1rem",
    letterSpacing: "0.01071em",
    lineHeight: "1.5",
  },
  body2: {
    color: palette.text.secondary,
    fontWeight: "400",
    fontSize: "0.875rem",
    letterSpacing: "0.01071em",
    lineHeight: "1.5",
  },
  button: {
    fontSize: "14px",
  },
  caption: {
    color: palette.text.hint,
    fontSize: "0.875rem",
    letterSpacing: "0.01071em",
    lineHeight: "1.5",
  },
};

export default typography;
