import { ServiceRequestVisualsTypes } from "@lib/ShiOneClient";
import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { SkeletonWrapper } from "shared-ui";

const SupportRequestsWidgetPieChartVisuals = [
  ServiceRequestVisualsTypes.ServiceRequestCategory,
  ServiceRequestVisualsTypes.Priority,
  ServiceRequestVisualsTypes.ServiceRequestType,
];

const getDisplayTitleByServiceRequestVisualsTypesKey = (
  key: ServiceRequestVisualsTypes
): string => {
  switch (key) {
    case ServiceRequestVisualsTypes.ServiceRequestType:
      return "By Request Type";
    case ServiceRequestVisualsTypes.Priority:
      return "By Severity";
    case ServiceRequestVisualsTypes.ServiceRequestCategory:
      return "By Category";
    default:
      return "";
  }
};

export function SupportRequestsWidgetHeaderAction({
  filterBy,
  setFilterBy,
  loading,
  filterKey,
}: {
  filterBy: ServiceRequestVisualsTypes;
  setFilterBy: (visualType: ServiceRequestVisualsTypes) => void;
  loading: boolean;
  filterKey: string;
}) {
  return (
    <SkeletonWrapper loading={loading}>
      <FormControl sx={{ m: 0.5, width: 180 }}>
        <Select
          sx={{ height: "40px", width: "100%" }}
          labelId={filterKey}
          id={filterKey}
          value={filterBy}
          onChange={(e) => {
            setFilterBy(e.target.value as ServiceRequestVisualsTypes);
          }}
          displayEmpty
        >
          {SupportRequestsWidgetPieChartVisuals.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {getDisplayTitleByServiceRequestVisualsTypesKey(value)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </SkeletonWrapper>
  );
}
