import { GetBearerToken } from "./BearerToken";

export const GetAxiosHeaders = async () => {
  const token = await GetBearerToken();
  const getImpersonateHeaders = () => {
    return {
      impersonate: localStorage.getItem("impersonateUser"),
      impersonateAccount: localStorage.getItem("impersonateAccount"),
      demoData: localStorage.getItem("demoData"),
    };
  };

  const { impersonate, impersonateAccount, demoData } = getImpersonateHeaders();

  if (token === null) {
    console.error("Axios Token is null");
  }

  return {
    ...(impersonate && { ImpersonateContact: impersonate }),
    ...(impersonateAccount && { ImpersonateAccount: impersonateAccount }),
    ...(demoData && { DemoData: demoData }),
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  };
};
