// MS Welcome Helpers
import {v4 as uuidv4} from 'uuid';
import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

function generateNonce() {
  return uuidv4().toString("hex");
}

const fullUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");

export const adalConfig = {
  tenant: import.meta.env.ADAL_CONFIG_TENANT,
  clientId: import.meta.env.ADAL_CONFIG_CLIENT_ID,
  endpoints: {
    api: import.meta.env.ADAL_CONFIG_ENDPOINTS_API,
  },
  postLogoutRedirectUri: window.location.origin,
  redirectUri: fullUrl + import.meta.env.ADAL_CONFIG_REDIRECT_URI,
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);

export const getAdminConsentUrl = () => {
  return getAppRegConsentUrl(adalConfig.clientId, true);
};

export const getAppRegConsentUrl = (clientId, idToken) => {
  const nonce = generateNonce();
  let url = "https://login.microsoftonline.com/common/oauth2/authorize?";
  url = idToken ? url + "response_type=id_token" : url + "response_type=code";
  url = url + `&client_id=${clientId}`;
  url = url + `&redirect_uri=${window.location}`;
  url = url + `&nonce=${nonce}`;
  url = url + "&prompt=admin_consent";
  return url;
};

export const getAppRegAdminConsentUrl = (
  clientId,
  common,
  roles,
  redirectUri,
  returnAfterRedirectUri
) => {
  let url = `https://login.microsoftonline.com/${common}/v2.0/adminconsent?`;
  url = url + `client_id=${clientId}`;
  url = url + `&state=${returnAfterRedirectUri}+${clientId}`;
  url = url + `&redirect_uri=${redirectUri}`;
  let scopes = "";
  roles = roles.split(",");
  roles.forEach((scope, index) => {
    scopes =
      index !== roles.length - 1
        ? (scopes += `https://graph.microsoft.com/${scope}+`)
        : (scopes += `https://graph.microsoft.com/${scope}`);
  });
  encodeURIComponent(scopes);
  url = url + `&scope=${scopes}`;
  return url;
};

export const getWelcomeAppRegAdminConsentUrl = (state) => {
  const nonce = generateNonce();
  let url = "https://login.microsoftonline.com/common/oauth2/authorize?";
  url = url + "response_type=code";
  url = url + `&client_id=${adalConfig.clientId}`;
  url = url + `&state=${state}`;
  url = url + `&redirect_uri=${window.location.origin}/welcomeCallback`;
  url = url + `&nonce=${nonce}`;
  url = url + "&prompt=admin_consent";
  return url;
};
