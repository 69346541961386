import { create } from "zustand";
import { EmbeddedPowerBIParams, ReportCenterParams } from "../entities";
import { models } from "powerbi-client";
import { defaultAvailableIntervals } from "../helpers";
import { IntervalType } from "../hooks";

interface IEmbeddedPowerBiStore {
  reportCenterParams: ReportCenterParams;
  reportEmbeddedPowerBiParams?: EmbeddedPowerBIParams[];
  availableIntervals: string[];
  defaultInterval?: IntervalType;
  defaultBookmark?: any;
  showIntervals: boolean;
  defaultFilter: models.Filter[];
  setReportCenterParams: (reportCenterParams: ReportCenterParams) => void;
  setAvailableIntervals: (intervals: string[]) => void;
  setDefaultInterval: (interval: IntervalType) => void;
  setDefaultBookmark: (bookmark: any | undefined) => void;
  resetState: () => void;
}

export const EmbeddedPowerBiStore = create<IEmbeddedPowerBiStore>((set) => ({
  reportCenterParams: {
    reports: [],
    showTimeRange: false,
    showHeader: false,
    expandFilterPane: false,
  },
  showIntervals: false,
  defaultFilter: [],
  availableIntervals: defaultAvailableIntervals,

  setReportCenterParams: (reportCenterParams: ReportCenterParams) => {
    set({
      reportCenterParams,
      showIntervals: reportCenterParams?.showTimeRange ?? false,
      reportEmbeddedPowerBiParams: reportCenterParams?.reports ?? [],
    });
  },
  setAvailableIntervals: (interval: string[]) => {
    set({
      availableIntervals: interval,
    });
  },
  setDefaultInterval: (interval: IntervalType) => {
    set({
      defaultInterval: interval,
    });
  },
  setDefaultBookmark: (bookmark: any | undefined) => {
    set({
      defaultBookmark: bookmark,
    });
  },
  resetState: () => {
    set({
      reportCenterParams: undefined,
      reportEmbeddedPowerBiParams: [],
      availableIntervals: [],
      defaultInterval: undefined,
      defaultBookmark: undefined,
      showIntervals: false,
      defaultFilter: [],
    });
  },
}));
