import shiPalette from "../shiPalette";

const rootElement = document.getElementById("root");

const MuiFilledInput = {
  defaultProps: {
    container: rootElement,
  },
  styleOverrides: {
    root: {
      "&:after": {
        color: shiPalette.text.primary,
      },
    },
    underline: {
      "&:after": {
        borderBottom: [[2, "solid", shiPalette.text.secondary]],
      },
    },
  },
};

export default MuiFilledInput;
