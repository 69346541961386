import React from "react";
import { CustomerExperienceReportCenter } from "../../../../v2/src/components/CustomerExperienceReportCenter";

export function AzureGovernanceReports() {
  return (
    <>
      <CustomerExperienceReportCenter
        header={["Azure", "Governance"]}
        reports={[
          {
            reportId: "CloudGovernanceReport-Azure",
            filter: "",
            reportName: "Governance",
          },
        ]}
        showTimeRange={false}
      />
    </>
  );
}
