import ScienceIcon from "@mui/icons-material/Science";
import React from "react";
import { NavItemType } from "../../NavigationTypes";

export function LabsMenu(): NavItemType {
  return {
    id: "shiLabs",
    type: "Link",
    displayName: "Labs",
    path: "/labs",
    icon: <ScienceIcon />,
    visible: true,
  };
}
