import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "../../../store";
import { azureSubscriptionState } from "./azureSubscriptionTypes";
import { putRestAzureSubscription } from "../../../api/azureSubscriptionStore";
import {
  AzureCreateSubscriptionRequest,
  AzureSubscriptionResponse,
} from "lib/ShiOneClient";

const initialState: azureSubscriptionState = {
  azureSubscriptionSubmitStatus: "idle",
  azureSubscriptionResponse: {} as AzureSubscriptionResponse,
};

const azureSubscriptionSlice = createSlice({
  name: "azureSubscription",
  initialState,
  reducers: {
    submittingAzureSubscription(state) {
      state.azureSubscriptionSubmitStatus = "loading";
    },
    submittedAzureSubscription(
      state,
      action: PayloadAction<AzureSubscriptionResponse>
    ) {
      state.azureSubscriptionResponse = action.payload;
      state.azureSubscriptionSubmitStatus = "complete";
    },
    submitAzureSubscriptionError(state) {
      state.azureSubscriptionSubmitStatus = "error";
    },
    resetState(state) {
      state.azureSubscriptionSubmitStatus = "idle";
      state.azureSubscriptionResponse = {} as AzureSubscriptionResponse;
    },
  },
});

export const submitNewAzureSubscription =
  (request: AzureCreateSubscriptionRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(azureSubscriptionSlice.actions.submittingAzureSubscription());
    try {
      const azureSubscriptionResponse = await putRestAzureSubscription(request);
      dispatch(
        azureSubscriptionSlice.actions.submittedAzureSubscription(
          azureSubscriptionResponse
        )
      );
    } catch {
      dispatch(azureSubscriptionSlice.actions.submitAzureSubscriptionError());
    }
  };

export const resetAzureSubscriptionState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(azureSubscriptionSlice.actions.resetState());
  };

export default azureSubscriptionSlice.reducer;
