import React from "react";
import { useApi } from "../../utils";
import { PieChartDto, SampleWidgetCClient } from "@lib/ShiOneClient";
import {
  asDto,
  BaseWidgetProps,
  graphColors,
  Summary,
  useSummaryData,
} from "shared-ui";
import SummaryWidget from "shared-ui/src/widgets/standard/SummaryWidget";

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    totalLabel: "Total Population",
    totalCount: 331223695,
    slices: [
      {
        label: "California",
        quantity: 39237836,
        percentage: 12,
      },
      {
        label: "Texas",
        quantity: 29527941,
        percentage: 9,
      },
      {
        label: "Florida",
        quantity: 21781128,
        percentage: 7,
      },
      {
        label: "New York",
        quantity: 19835913,
        percentage: 6,
      },
      {
        label: "Pennsylvania",
        quantity: 12964056,
        percentage: 4,
      },
      {
        label: "Illinois",
        quantity: 12671469,
        percentage: 4,
      },
      {
        label: "Ohio",
        quantity: 11780017,
        percentage: 4,
      },
      {
        label: "Georgia",
        quantity: 10799566,
        percentage: 3,
      },
      {
        label: "North Carolina",
        quantity: 10551162,
        percentage: 3,
      },
      {
        label: "Other",
        quantity: 162074607,
        percentage: 49,
      },
    ],
  },
};

function useGetSampleWidgetCData() {
  const api = useApi(SampleWidgetCClient);

  return useSummaryData({
    queryKey: [`sample-widget-c`],
    apiFunction: () => api.getSampleWidgetC(),
    transformFunction: (rawData) => transform(rawData?.data),
    placeholderData: asDto(placeholderData),
  });
}

function transform(data: PieChartDto | undefined): Summary[] {
  if (!data || !data.slices) {
    return [];
  }

  const percentageLineData = data.slices.map((slice, index) => ({
    // This is how the label for the chart gets set. It pulls it from the first item in the array.
    name: "States",
    data: slice.quantity ?? 0,
    label: slice.label ?? "",
    color: graphColors[index],
  }));

  return percentageLineData;
}

export default function SampleWidgetC({ pageLayoutWidget }: BaseWidgetProps) {
  return (
    <SummaryWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetSampleWidgetCData}
      leftLabel={"Population"}
    ></SummaryWidget>
  );
}
