import { NavItemType } from "../../NavigationTypes";
import AssessmentIcon from "@mui/icons-material/Assessment";

export function ReportCenterMenu(): NavItemType {
  return {
    id: "reportCenter",
    type: "Dashboard",
    displayName: "Report Center",
    path: "/report-center",
    icon: <AssessmentIcon />,
    children: [],
    visible: true,
  };
}
