import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { usageTrackEvent } from "../functions/telemetry";
import { FeatureDefinitions } from "shared-ui/src/utils/FeatureDefinitions";
import LoadingCube from "shared-ui/src/components/LoadingCube";

export default function Loading() {
  const { isLoading } = useAuth0();

  useEffect(() => {
    const timerCallBack = () => {
      if (!isLoading) return;
      usageTrackEvent("Auth0 still loading after 5 seconds", {
        relatedFeature: FeatureDefinitions.Auth_Auth,
        isMetricsTracking: false,
      });
    };

    const timeout = setTimeout(timerCallBack, 5000);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingCube />
    </div>
  );
}
