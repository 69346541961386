import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import GlobalConstants from "@constants";
import React from "react";
import { KnowledgeBaseMenu } from "../common";
import { NavItemType } from "../../NavigationTypes";
import { supportItemsToShowType } from "../../NavItemsToShowTypes";

export function CustomerSupportCenterMenuV2(
  supportItemsToShow: supportItemsToShowType,
  knowledgebase: any
): NavItemType {
  return {
    id: "support",
    type: "Dashboard",
    displayName: "Support",
    icon: (
      <LocalHospitalIcon
        sx={{ fontSize: "large" }}
        className={"fas fa-medkit"}
      />
    ),
    visible: true,
    path: "/support",
    children: [
      {
        id: "newrequest",
        type: "Link",
        displayName: "New Request",
        path: GlobalConstants.knownPaths.supportCenter_NewRequest,
        visible: true,
      },
      {
        id: "requests",
        type: "Link",
        displayName: "Requests",
        path: "/support-center/requests",
        visible: true,
      },
      {
        id: "serviceReports",
        type: "Link",
        displayName: "Reports",
        path: "/support-center/service-report",
        visible: supportItemsToShow.showServiceReport,
      },
      {
        id: "myDevices",
        type: "Link",
        displayName: "My Devices",
        path: "/support-center/mydevices",
        visible: supportItemsToShow.showMyDevices,
      },
      KnowledgeBaseMenu({
        knowledgebase,
        v2UIEnabled: supportItemsToShow.showKnowledgeBase,
      }),
    ],
  };
}
