import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useApi } from "../../utils";
import {
  AwsPotentialSavingsWidgetClient,
  SeverityColor,
  WidgetContentResponseDto_1OfAwsPotentialSavingsWidgetDto,
} from "@lib/ShiOneClient";
import { Add } from "@mui/icons-material";
import {
  asDto,
  BaseWidgetProps,
  colorSeverities,
  ItemBoxConfiguration,
  KeyInformationWidget,
  useKeyInformationData,
} from "shared-ui";
import { usdFormatter } from "../../../../functions/currency";

function getAmountStyle(severity: SeverityColor | undefined): any {
  if (severity === undefined) {
    return {};
  }

  return { color: colorSeverities[`${severity}`] };
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    optimizationSavingsAmount: 0,
    rightsizingSavingsAmount: 0,
    withSavingsPlansAmount: 0,
    reservationsSavingsAmount: 0,
  },
};

function CardActions() {
  return (
    <Container disableGutters={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button href={"/cloud/aws/recommendations"}>
          View Recommendations
        </Button>
        <Button href={"/support-center/new-request"}>
          <Add sx={{ fontSize: "16px", marginRight: "8px" }} />
          Request Consultation
        </Button>
      </Box>
    </Container>
  );
}

function getFormattedAmount(
  amount: number | undefined,
  severity: SeverityColor | undefined
) {
  return (
    <>
      <Typography sx={getAmountStyle(severity)}>
        {usdFormatter.format(amount ?? 0)}
      </Typography>
      <Typography variant={"caption"} sx={getAmountStyle(severity)}>
        per month
      </Typography>
    </>
  );
}

function transformFunction(
  rawData: WidgetContentResponseDto_1OfAwsPotentialSavingsWidgetDto | undefined
): ItemBoxConfiguration[] {
  const itemBoxConfigurations: ItemBoxConfiguration[] = [];

  if (rawData?.data?.optimizationSavingsAmount !== undefined) {
    itemBoxConfigurations.push({
      amount: getFormattedAmount(
        rawData?.data?.optimizationSavingsAmount,
        rawData?.data?.optimizationSavingsSeverityColor
      ),
      label: "Optimization Savings",
      icon: <></>,
    });
  }

  itemBoxConfigurations.push(
    {
      amount: getFormattedAmount(
        rawData?.data?.rightsizingSavingsAmount,
        rawData?.data?.rightsizingSavingsSeverityColor
      ),
      label: "Rightsizing Savings",
      icon: <></>,
    },
    {
      amount: getFormattedAmount(
        rawData?.data?.withSavingsPlansAmount,
        rawData?.data?.withSavingsPlansSeverityColor
      ),
      label: "With Savings Plans",
      icon: <></>,
    },
    {
      amount: getFormattedAmount(
        rawData?.data?.reservationsSavingsAmount,
        rawData?.data?.reservationsSavingsSeverityColor
      ),
      label: "Reservations Savings",
      icon: <></>,
    }
  );

  return itemBoxConfigurations;
}

function useGetAwsPotentialSavingsWidgetData() {
  const api = useApi(AwsPotentialSavingsWidgetClient);

  return useKeyInformationData({
    queryKey: ["aws-potential-savings-widget"],
    apiFunction: () => api.getAwsPotentialSavingsWidget(),
    transformFunction: transformFunction,
    placeholderData: asDto(placeholderData),
  });
}

export default function AwsPotentialSavingsWidget({
  pageLayoutWidget,
}: BaseWidgetProps) {
  return (
    <KeyInformationWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetAwsPotentialSavingsWidgetData}
      cardActions={<CardActions />}
    />
  );
}
