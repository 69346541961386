import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GlobalConstants from "@constants";
import { getAccountDetailsData } from "../../modules/rtk/serviceRequestTemplate/serviceRequestTemplateSlice";
import { debounce } from "lodash";
import { getAllAccountNamesAndAxNumbers } from "../../modules/rtk/account/accountSlice";
import ErrorIcon from "@mui/icons-material/Error";

const css = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  formControl: {
    display: "block",
    variant: "outlined",
  },
  grow: {
    flexGrow: 1,
  },
  componentHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
});

const ErrorMessage = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <FormHelperText style={{ color: "red" }}>
      No account information for the provided AX Account
    </FormHelperText>
  );
};

export default function AllAccountsDetailsField(props) {
  const { id, label, onChange, required, formData, disabled } = props;
  const theme = useTheme();
  const classes = css(theme);
  const dispatch = useDispatch();

  const { accountDetails, accountDetailsFetchStatus } = useSelector(
    (state) => state.serviceRequestTemplate
  );

  const { allAccountNamesWithAxNumbers, allAccountNamesWithAxNumberStatus } =
    useSelector((state) => state.account);

  const defaultAccountDetailsState = {
    axAccountNumber: formData?.axAccountNumber ?? "",
    companyName: formData?.companyName ?? "",
    paymentTerms: formData?.paymentTerms ?? "",
  };

  const [currentAccountDetails, setCurrentAccountDetails] = useState(
    defaultAccountDetailsState
  );

  const [filteredAccountList, setFilteredAccountList] = useState([]);

  useEffect(() => {
    if (accountDetailsFetchStatus === GlobalConstants.fetchStatus.complete) {
      setCurrentAccountDetails(accountDetails);
    }

    if (accountDetailsFetchStatus === GlobalConstants.fetchStatus.error) {
      setCurrentAccountDetails(defaultAccountDetailsState);
    }
  }, [accountDetailsFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      allAccountNamesWithAxNumberStatus === GlobalConstants.fetchStatus.complete
    ) {
      let axAccountString = allAccountNamesWithAxNumbers.map(
        (ac) => `${ac.axNumber} - ${ac.accountName}`
      );

      setFilteredAccountList([...new Set(axAccountString)]);
    }

    // eslint-disable-next-line
  }, [allAccountNamesWithAxNumberStatus]);

  useEffect(() => {
    const newData = {
      ...formData?.shiAccountDetails,
      axAccountNumber: currentAccountDetails.axAccountNumber,
      companyName: currentAccountDetails.companyName,
      paymentTerms: currentAccountDetails.paymentTerms,
    };

    onChange(newData);
  }, [currentAccountDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedAccountDetailsSearch = debounce((value) => {
    let axNumber = value.substring(0, value.indexOf(" "));
    dispatch(getAccountDetailsData(axNumber));
  }, 1000);

  const handleOnChange = (value) => {
    if (value) {
      debouncedAccountDetailsSearch(value);
    } else {
      setCurrentAccountDetails(defaultAccountDetailsState);
    }
  };

  let debouncedAllAccountsAxSearch = debounce((e) => {
    let searchTerm = e.target.value ?? "";
    const pageNumber = 0;
    const pageSize = GlobalConstants.pageSize.fifty;
    const searchQuery = {
      searchString: searchTerm,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    if (searchQuery.searchString.length >= 3) {
      dispatch(getAllAccountNamesAndAxNumbers(searchQuery));
    }
  }, 500);

  const handleInputChange = (e) => {
    if (e) {
      e.preventDefault();
      debouncedAllAccountsAxSearch(e);
    }
  };

  return (
    <Box sx={classes.root}>
      <Grid container sx={classes.grow} spacing={3}>
        <Grid item xs={12}>
          <Box sx={classes.componentHeader}>
            <Typography variant="h5">SHI Account Details</Typography>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={classes.grow} spacing={3}>
        <Grid item xs={12}>
          <FormControl sx={classes.formControl}>
            <Autocomplete
              sx={classes.root}
              value={currentAccountDetails.axAccountNumber}
              disabled={disabled}
              noOptionsText={
                allAccountNamesWithAxNumberStatus ===
                  GlobalConstants.fetchStatus.loading ||
                filteredAccountList.length > 0
                  ? "Loading..."
                  : "No Options"
              }
              id={id}
              name={label}
              onChange={(e, value) => handleOnChange(value)}
              onInputChange={handleInputChange}
              options={
                filteredAccountList && filteredAccountList.length > 0
                  ? filteredAccountList
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="axAccountNumbers"
                  name="axAccountNumbers"
                  variant="outlined"
                  placeholder="Search AX accounts"
                  label="AX Accounts"
                  aria-label="AX Accounts"
                  required={required}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {accountDetailsFetchStatus ===
                          GlobalConstants.fetchStatus.loading ||
                        allAccountNamesWithAxNumberStatus ===
                          GlobalConstants.fetchStatus.loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : [
                            accountDetailsFetchStatus,
                            allAccountNamesWithAxNumberStatus,
                          ].indexOf(GlobalConstants.fetchStatus.error) > -1 ? (
                          <Tooltip
                            title={
                              "Error fetching accounts, verify your permissions"
                            }
                          >
                            <ErrorIcon sx={classes.error} />
                          </Tooltip>
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {accountDetailsFetchStatus === GlobalConstants.fetchStatus.error && (
        <ErrorMessage />
      )}
      <Grid container sx={classes.grow} spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }} variant="filled">
            <InputLabel shrink htmlFor="companyName">
              Company Name
            </InputLabel>
            <FilledInput
              readOnly={true}
              disableUnderline
              id="companyName"
              name="companyName"
              value={currentAccountDetails.companyName}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={classes.grow} spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="filled">
            <InputLabel shrink htmlFor="paymentTerms">
              Payment Terms
            </InputLabel>
            <FilledInput
              readOnly={true}
              disableUnderline
              name="paymentTerms"
              id="paymentTerms"
              value={currentAccountDetails.paymentTerms}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
