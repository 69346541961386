import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      cacheLocation={"localstorage"}
      useRefreshTokens={true}
      domain={import.meta.env.AUTH0_DOMAIN}
      clientId={import.meta.env.AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: import.meta.env.AUTH0_CALLBACK_URL,
        audience: import.meta.env.AUTH0_AUDIENCE,
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
