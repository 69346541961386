import { useQuery } from "@tanstack/react-query";
import { EmbedParams } from "@lib/ShiOneClient";
import { getEmbedParams } from "../../../../../api/reportStore";
import ms from "ms";
import { EmbeddedPowerBIParams } from "../entities";

const cacheKey = "PowerBiBookmarks";

export const useGetEmbedParams = ({
  reportId,
  filter,
}: EmbeddedPowerBIParams) => {
  return useQuery<EmbedParams, Error>({
    queryKey: [cacheKey, reportId, filter],
    queryFn: async () => await getEmbedParams(reportId, filter),
    enabled: !!reportId,
    refetchOnWindowFocus: false,
    staleTime: ms("10m"),
  });
};
