export const widgetTableTheme = () => ({
  borderRadius: 0,
  borderLeft: "none",
  borderRight: "none",
  "& .MuiDataGrid-main": {
    overflow: "auto",
  },
  "& .MuiDataGrid-cell:has(+ :not(.MuiDataGrid-cell))": {
    justifyContent: "flex-end",
    "& div": {
      textAlign: "right",
      justifyContent: "flex-end",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    overflowX: "hidden",
  },
});

export const rankingTableCell = {
  "& .MuiDataGrid-cell": {
    padding: 0,
    "& a": {
      fontWeight: 600,
    },
  },
  "& .MuiDataGrid-cell:last-child": {
    "& div": {
      paddingRight: ".5rem",
    },
  },
  "& .MuiDataGrid-cell:has(+ .MuiDataGrid-cell:not(.MuiDataGrid-cell--textLeft):not(.MuiDataGrid-cell--textRight):empty)":
    {
      "& div": {
        paddingRight: ".5rem",
      },
    },
};
