import { ProjectDetailsPhaseProps } from "@features/projects-feature/projectTypes";
import React, { Fragment } from "react";
import {
  StyledListSubheader,
  StyledTasksCardContent,
  StyledTasksList,
} from "@features/projects-feature/utils/components/StyledProjectDetailsTasksComponents";
import { Card, CardHeader, Divider, Typography } from "@mui/material";
import { PhasesWithTasks, ProjectTaskDto } from "@lib/ShiOneClient";
import ProjectTaskGridItem from "@features/projects-feature/components/ProjectTaskGridItem";
import GlobalConstants from "@constants";
import ProjectDetailsChildPhaseTasks from "@features/projects-feature/components/ProjectDetailsChildPhaseTasks";

const ProjectDetailsTasksCard: React.FC<ProjectDetailsPhaseProps> = ({
  phasesWithTasks,
  orphanTasks,
  picklist,
}) => {
  const displayTasks = (
    tasks: ProjectTaskDto[],
    style?: React.CSSProperties
  ) => {
    return (
      tasks?.length !== 0 &&
      tasks?.map((task: ProjectTaskDto, index: number) => {
        const key = `${task.id}` + index;
        if (task.isVisibleInClientPortal) {
          return (
            <div key={key} style={style}>
              <ProjectTaskGridItem
                title={task.title ?? ""}
                checked={task.completedByResourceID !== 0}
                status={task.status ?? GlobalConstants.projectStatus.new}
                picklist={picklist}
              />
            </div>
          );
        }
      })
    );
  };

  return (
    <Card>
      <CardHeader title={<Typography variant="body1">TASKS</Typography>} />
      <Divider />
      <StyledTasksCardContent>
        <StyledTasksList>
          {phasesWithTasks.map((phase: PhasesWithTasks, k: number) => {
            const key = `${phase.phase?.id ?? ""}` + k;
            if (phase.tasks?.length !== 0)
              return (
                <Fragment key={key}>
                  <StyledListSubheader disableSticky>
                    {phase.phase?.title}
                  </StyledListSubheader>
                  {displayTasks(phase.tasks ?? [])}
                  {phase.childPhases?.length !== 0 &&
                    phase.childPhases?.map((child, k) => {
                      const key =
                        child.phase?.id ?? "" + child.phase?.title ?? "" + k;
                      return (
                        <ProjectDetailsChildPhaseTasks
                          key={key}
                          childPhases={child}
                          displayTasks={displayTasks}
                          picklist={picklist}
                          style={{ paddingLeft: "32px" }}
                        />
                      );
                    })}
                </Fragment>
              );
          })}
          {orphanTasks.length > 0 && (
            <>
              <StyledListSubheader disableSticky>
                Uncategorized
              </StyledListSubheader>
              {orphanTasks.map((task) => (
                <ProjectTaskGridItem
                  key={task.id}
                  title={task.title ?? ""}
                  checked={task.completedByResourceID !== 0}
                  status={task.status ?? 1}
                  picklist={picklist}
                />
              ))}
            </>
          )}
        </StyledTasksList>
      </StyledTasksCardContent>
    </Card>
  );
};

export default ProjectDetailsTasksCard;
