import { Dialog } from "@mui/material";
import { ContractDetailsDialogContainerProps } from "@features/projects-feature/projectTypes";
const dialogStyles = () => ({
  paper: {
    width: "861px",
    height: "814px",
    maxWidth: "none",
    borderRadius: "4px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
});

export const ContractDetailDialogContainer = ({
  open,
  onClose,
  children,
}: ContractDetailsDialogContainerProps) => {
  const css = dialogStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { ...css.paper },
      }}
    >
      {children}
    </Dialog>
  );
};
