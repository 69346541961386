import React, { Component } from "react";
import { authContext } from "../auth/MSAdal";
import ShiOneLogo from "../../../shared-ui/src/components/ShiOneLogo";
import { Link, Typography } from "@mui/material";
import AuthPageContainer from "./AuthPageContainer";

class InvalidUrlPage extends Component {
  render() {
    const { _user } = authContext;
    const accountString = _user
      ? `Your current account: ${_user.userName}`
      : "";

    return (
      <AuthPageContainer>
        <ShiOneLogo />
        <Typography variant="h6">
          You don&#39;t have access to this site!
        </Typography>
        <Typography>{accountString}</Typography>
        <div>
          <Typography>
            If you (or a site administrator) has recently changed your site URL,
            this message may appear for 5 minutes while the change is processed.
            Please try to access the site in 5 minutes. If the error still
            exists then, please contact an administrator or email us at{" "}
            <Link href="mailto:SupportServices@shi.com">
              SupportServices@shi.com
            </Link>
          </Typography>
          <p>
            If you think you may have logged in with the wrong account, you can{" "}
            <Link href="/logout">log out</Link> and log back in with another
            account.
          </p>
          <p>
            If you should have access to this portal and are still seeing this
            message, please contact your administrator.
          </p>
        </div>
      </AuthPageContainer>
    );
  }
}

export default InvalidUrlPage;
