import { NavItemType } from "../../NavigationTypes";
import { cloudGcpItemsToShowType } from "../../NavItemsToShowTypes";

export function CloudGcpMenuV2(
  cloudGcpItemsToShow: cloudGcpItemsToShowType
): NavItemType {
  return {
    id: "cloudGcp",
    type: "Group",
    displayName: "GCP",
    visible: Object.entries(cloudGcpItemsToShow).some(([_k, v]) => v),
    children: [
      {
        id: "cloudGcpProjects",
        type: "Link",
        displayName: "Projects",
        path: "/cloud/gcp/projects",
        visible: cloudGcpItemsToShow.showProjects,
      },
      {
        id: "cloudGcpReports",
        type: "Link",
        displayName: "Reports",
        path: "/cloud/gcp/reports",
        visible: cloudGcpItemsToShow.showReports,
      },
    ],
  };
}
