import {
  ADD_CONTRACTS_TO_TEAM,
  ADD_CONTRACTS_TO_TEAM_ERROR,
  ADD_CONTRACTS_TO_TEAM_SUCCESS,
  ADD_TEAM_CONFIG_ITEMS,
  ADD_TEAM_CONFIG_ITEMS_ERROR,
  ADD_TEAM_CONFIG_ITEMS_SUCCESS,
  ADD_USERS_TO_TEAM,
  ADD_USERS_TO_TEAM_ERROR,
  ADD_USERS_TO_TEAM_SUCCESS,
  CREATE_TEAM,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_SUCCESS,
  GET_ACCOUNT_TEAMS,
  GET_ACCOUNT_TEAMS_ERROR,
  GET_ACCOUNT_TEAMS_SUCCESS,
  GET_TEAM_CONTRACTS,
  GET_TEAM_CONTRACTS_ERROR,
  GET_TEAM_CONTRACTS_SUCCESS,
  MODIFY_CONTRACT_MAPPING,
  MODIFY_CONTRACT_MAPPING_ERROR,
  MODIFY_CONTRACT_MAPPING_SUCCESS,
  REMOVE_CONTRACTS_FROM_TEAM,
  REMOVE_CONTRACTS_FROM_TEAM_ERROR,
  REMOVE_CONTRACTS_FROM_TEAM_SUCCESS,
  REMOVE_TEAM,
  REMOVE_TEAM_CONFIG_ITEMS,
  REMOVE_TEAM_CONFIG_ITEMS_ERROR,
  REMOVE_TEAM_CONFIG_ITEMS_SUCCESS,
  REMOVE_TEAM_ERROR,
  REMOVE_TEAM_SUCCESS,
  REMOVE_USERS_FROM_TEAM,
  REMOVE_USERS_FROM_TEAM_ERROR,
  REMOVE_USERS_FROM_TEAM_SUCCESS,
  RENAME_TEAM,
  RENAME_TEAM_ERROR,
  RENAME_TEAM_SUCCESS,
  SET_CONTACT_TEAM_ROLE,
  SET_CONTACT_TEAM_ROLE_ERROR,
  SET_CONTACT_TEAM_ROLE_SUCCESS,
} from "../actions/teamActions";

import _ from "lodash";

export function teamReducer(
  state = {
    teams: [],
    fetchingTeams: false,
    fetchTeamsComplete: false,
    fetchTeamsError: undefined,
    teamSubmitted: false,
    submittingTeam: false,
    teamSubmissionError: undefined,
    addingContracts: false,
    addContractsComplete: false,
    addContractsError: undefined,
    removingContracts: false,
    removeContractsComplete: false,
    removeContractsError: undefined,
    modifyingContractMapping: false,
    modifyContractMappingComplete: false,
    modifyContractMappingError: undefined,
    addingUsers: false,
    addUsersToTeamComplete: false,
    addUsersToTeamError: undefined,
    removingUsers: false,
    removeUsersFromTeamComplete: false,
    removeUsersFromTeamError: undefined,
    teamContracts: {},
    fetchingTeamContracts: false,
    fetchTeamContractsComplete: false,
    fetchTeamContractsError: undefined,
    addingConfigItems: false,
    addConfigItemsComplete: false,
    addConfigItemsError: undefined,
    removingConfigItems: false,
    removeConfigItemsComplete: false,
    removeConfigItemsError: undefined,
    removingTeam: false,
    removeTeamComplete: false,
    removeTeamError: undefined,
    renamingTeam: false,
    renameTeamComplete: false,
    renameTeamError: false,
    updatingTeamRole: false,
    updatingTeamRoleComplete: false,
    updatingTeamRoleError: false,
    updatedTeamContacts: [],
  },
  action
) {
  switch (action.type) {
    case ADD_TEAM_CONFIG_ITEMS: {
      return Object.assign({}, state, {
        addConfigItemsComplete: false,
        addingConfigItems: true,
      });
    }
    case ADD_TEAM_CONFIG_ITEMS_SUCCESS: {
      // console.log(action.payload)
      const newTeamsAddConfig = _.map(state.teams, (t) => {
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      return Object.assign({}, state, {
        addConfigItemsComplete: true,
        addingConfigItems: false,
        teams: newTeamsAddConfig,
      });
    }
    case ADD_TEAM_CONFIG_ITEMS_ERROR: {
      return Object.assign({}, state, {
        addConfigItemsComplete: false,
        addingConfigItems: false,
        addConfigItemsError: action.payload.data,
      });
    }
    case REMOVE_TEAM_CONFIG_ITEMS: {
      return Object.assign({}, state, {
        removeConfigItemsComplete: false,
        removingConfigItems: true,
      });
    }
    case REMOVE_TEAM_CONFIG_ITEMS_SUCCESS: {
      const newTeamsRemoveConfig = _.map(state.teams, (t) => {
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      return Object.assign({}, state, {
        removeConfigItemsComplete: true,
        removingConfigItems: false,
        teams: newTeamsRemoveConfig,
      });
    }
    case REMOVE_TEAM_CONFIG_ITEMS_ERROR: {
      return Object.assign({}, state, {
        removeConfigItemsComplete: false,
        removingConfigItems: false,
        removeConfigItemsError: action.payload.data,
      });
    }
    case GET_TEAM_CONTRACTS:
      return Object.assign({}, state, {
        fetchingTeamContracts: true,
        fetchTeamContractsComplete: false,
      });
    case GET_TEAM_CONTRACTS_SUCCESS:
      return Object.assign({}, state, {
        fetchingTeamContracts: false,
        fetchTeamContractsComplete: true,
        teamContracts: action.payload.data,
      });
    case GET_TEAM_CONTRACTS_ERROR:
      return Object.assign({}, state, {
        fetchingTeamContracts: false,
        fetchTeamContractsComplete: true,
        fetchTeamContractsError: action.payload.data,
      });
    case GET_ACCOUNT_TEAMS:
      return Object.assign({}, state, {
        fetchingTeams: true,
        fetchTeamsComplete: false,
      });
    case GET_ACCOUNT_TEAMS_SUCCESS:
      return Object.assign({}, state, {
        fetchingTeams: false,
        fetchTeamsComplete: true,
        teams: action.payload.data,
      });
    case GET_ACCOUNT_TEAMS_ERROR:
      return Object.assign({}, state, {
        fetchingTeams: false,
        fetchTeamsComplete: true,
        fetchTeamsError: action.payload.data,
      });
    case CREATE_TEAM: {
      return Object.assign({}, state, {
        teamSubmitted: false,
        submittingTeam: true,
      });
    }
    case CREATE_TEAM_SUCCESS: {
      return Object.assign({}, state, {
        teamSubmitted: true,
        submittingTeam: false,
        teams: [...state.teams, action.payload.data],
      });
    }
    case CREATE_TEAM_ERROR: {
      return Object.assign({}, state, {
        teamSubmitted: false,
        submittingTeam: false,
        teamSubmissionError: action.payload.data,
      });
    }
    case REMOVE_TEAM: {
      return Object.assign({}, state, {
        removingTeam: true,
        removeTeamComplete: false,
        removeTeamError: false,
      });
    }
    case REMOVE_TEAM_SUCCESS: {
      const filteredTeams = _.filter(state.teams, (t) => {
        return t.id !== action.payload.data;
      });
      return Object.assign({}, state, {
        removingTeam: false,
        removeTeamComplete: true,
        teams: filteredTeams,
      });
    }
    case REMOVE_TEAM_ERROR: {
      return Object.assign({}, state, {
        removingTeam: false,
        removeTeamComplete: false,
        removeTeamError: true,
      });
    }
    case RENAME_TEAM: {
      return Object.assign({}, state, {
        renamingTeam: true,
        renameTeamComplete: false,
        renameTeamError: false,
      });
    }
    case RENAME_TEAM_SUCCESS: {
      const modifiedTeams = _.map(state.teams, (t) => {
        if (t.id === action.payload.teamId) {
          return Object.assign({}, t, {
            teamName: action.payload.data,
          });
        }
        return t;
      });
      return Object.assign({}, state, {
        renamingTeam: false,
        renameTeamComplete: true,
        renameTeamError: false,
        teams: modifiedTeams,
      });
    }
    case RENAME_TEAM_ERROR: {
      return Object.assign({}, state, {
        renamingTeam: false,
        renameTeamComplete: false,
        renameTeamError: true,
      });
    }
    case ADD_CONTRACTS_TO_TEAM: {
      return Object.assign({}, state, {
        addContractsComplete: false,
        addingContracts: true,
      });
    }
    case ADD_CONTRACTS_TO_TEAM_SUCCESS: {
      const newTeams = _.map(state.teams, (t) => {
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      return Object.assign({}, state, {
        addContractsComplete: true,
        addingContracts: false,
        teams: newTeams,
      });
    }
    case ADD_CONTRACTS_TO_TEAM_ERROR: {
      return Object.assign({}, state, {
        addContractsComplete: false,
        addingContracts: false,
        addContractsError: action.payload.data,
      });
    }
    case REMOVE_CONTRACTS_FROM_TEAM: {
      return Object.assign({}, state, {
        removeContractsComplete: false,
        removingContracts: true,
      });
    }
    case REMOVE_CONTRACTS_FROM_TEAM_SUCCESS: {
      const newTeamsRemoveContract = _.map(state.teams, (t) => {
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      return Object.assign({}, state, {
        removeContractsComplete: true,
        removingContracts: false,
        teams: newTeamsRemoveContract,
      });
    }
    case REMOVE_CONTRACTS_FROM_TEAM_ERROR: {
      return Object.assign({}, state, {
        removeContractsComplete: false,
        removingContracts: false,
        removeContractsError: action.payload.data,
      });
    }
    case MODIFY_CONTRACT_MAPPING: {
      return Object.assign({}, state, {
        modifyContractMappingComplete: false,
        modifyingContractMapping: true,
      });
    }
    case MODIFY_CONTRACT_MAPPING_SUCCESS: {
      // console.log(state.teams, action.payload.data)
      const newTeamsModifyMapping = _.map(state.teams, (t) => {
        // console.log(t.id, 'vs', action.payload.data.id)
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      // console.log('newTeams', newTeams)
      return Object.assign({}, state, {
        modifyContractMappingComplete: true,
        modifyingContractMapping: false,
        teams: newTeamsModifyMapping,
      });
    }
    case MODIFY_CONTRACT_MAPPING_ERROR: {
      return Object.assign({}, state, {
        modifyContractMappingComplete: false,
        modifyingContractMapping: false,
        modifyContractMappingError: action.payload.data,
      });
    }
    case ADD_USERS_TO_TEAM: {
      // return state
      return Object.assign({}, state, {
        addUsersToTeamComplete: false,
        addingUsers: true,
      });
    }
    case ADD_USERS_TO_TEAM_SUCCESS: {
      // console.log(state.teams, action.payload.data)
      const newTeamsContacts = _.map(state.teams, (t) => {
        // console.log(t.id, 'vs', action.payload.data.id)
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      return Object.assign({}, state, {
        addUsersToTeamComplete: true,
        addingUsers: false,
        teams: newTeamsContacts,
      });
    }
    case ADD_USERS_TO_TEAM_ERROR: {
      return Object.assign({}, state, {
        addUsersToTeamComplete: false,
        addingUsers: false,
        addUsersToTeamError: action.payload.data,
      });
    }
    case REMOVE_USERS_FROM_TEAM: {
      return Object.assign({}, state, {
        removeUsersFromTeamComplete: false,
        removingUsers: true,
      });
    }
    case REMOVE_USERS_FROM_TEAM_SUCCESS: {
      // console.log(state.teams, action.payload.data)
      const newTeamsRemoveContact = _.map(state.teams, (t) => {
        // console.log(t.id, 'vs', action.payload.data.id)
        if (t.id === action.payload.data.id) {
          return action.payload.data;
        }
        return t;
      });
      // console.log('newTeams', newTeams)
      return Object.assign({}, state, {
        removeUsersFromTeamComplete: true,
        removingUsers: false,
        teams: newTeamsRemoveContact,
      });
    }
    case REMOVE_USERS_FROM_TEAM_ERROR: {
      return Object.assign({}, state, {
        removeUsersFromTeamComplete: false,
        removingUsers: false,
        removeUsersFromTeamError: action.payload.data,
      });
    }
    case SET_CONTACT_TEAM_ROLE: {
      return Object.assign({}, state, {
        updatingTeamRoleComplete: false,
        updatingTeamRole: true,
      });
    }
    case SET_CONTACT_TEAM_ROLE_SUCCESS: {
      return Object.assign({}, state, {
        updatingTeamRoleComplete: true,
        updatedTeamContacts: [
          ...state.updatedTeamContacts,
          action.payload.data,
        ],
        updatingTeamRole: false,
      });
    }
    case SET_CONTACT_TEAM_ROLE_ERROR: {
      return Object.assign({}, state, {
        updatingTeamRoleComplete: false,
        updatingTeamRole: false,
        updatingTeamRoleError: action.payload.data,
      });
    }
    default:
      return state;
  }
}
