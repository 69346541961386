import {
  GET_PICKLIST,
  GET_PICKLIST_SUCCESS,
  GET_PICKLIST_ERROR,
} from "../modules/picklist/actions/picklistActions";
import fetch from "isomorphic-fetch";

const url = import.meta.env.AUTOTASK_PICKLIST;

const picklistApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_PICKLIST: {
      fetch(url, { method: "get" })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return next({
              type: GET_PICKLIST_ERROR,
              payload: {
                data: response.status + " " + response.statusText,
              },
            });
          }
        })
        .then((data) => {
          return next({
            type: GET_PICKLIST_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_PICKLIST_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default picklistApi;
