import { getWidgetRegistryEntry } from "./widgetHelpers";
import { WidgetWrapper } from "./WidgetWrapper";
import {WidgetRegistry} from "../models/WidgetRegistry";
import {WidgetRegistryEntry} from "../models/WidgetRegistryEntry";
import { Typography } from "@mui/material";

export function WidgetLoader({
  pageLayoutWidget,
  widgetRegistry,
}: {
  pageLayoutWidget: any;
  widgetRegistry: WidgetRegistry;
}) {
  if (!pageLayoutWidget.enabled) {
    return <></>;
  }

  const widgetRegistryEntry: WidgetRegistryEntry = getWidgetRegistryEntry(
    pageLayoutWidget.widgetId,
    widgetRegistry
  );

  if (!widgetRegistryEntry) {
    return (
      <WidgetWrapper pageLayoutWidget={pageLayoutWidget}>
        <Typography>Widget {pageLayoutWidget.widgetId} not found</Typography>
      </WidgetWrapper>
    );
  }

  return <>{widgetRegistryEntry.widget({
    pageLayoutWidget
  })}</>;
}
