import { Theme } from "@mui/material";
import {
  threeLineHeightCell,
  twoLineHeightCell,
} from "../../theme/mixins/lineHeight";

export const widgetStyles = (theme: Theme) => ({
  progressWrapper: {
    margin: [[theme.spacing(10), "auto", 0, "auto"]],
    display: "block",
  },
  cardHeader: {
    "& .MuiCardHeader-title": {
      color: theme.palette.text.primary,
      textTransform: "uppercase",
      fontSize: "16px",
    },
    "& .MuiCardHeader-subheader": {
      fontSize: "14px",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    "& .MuiCardHeader-action": {
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      "& .MuiToggleButton-root": {
        padding: theme.spacing(1, 2),
      },
    },
  },
  card: {
    border: "1px solid" + theme.palette.border,
    boxShadow: "0px 2px 1.5px 0px rgba(0,0,0,0.08)",
    "& .MuiCardContent-root": {
      padding: 0 + "!important",
      "& canvas": {
        height: "300px",
      },
    },
    "& .MuiCardActions-root": {
      "& a": {
        fontSize: "0.825rem",
        padding: "4px 8px",
      },
      "& button": {
        fontSize: "0.825rem",
        padding: "4px 8px",
      },
    },
  },

  twoLineHeightCell: twoLineHeightCell,
  threeLineHeightCell: threeLineHeightCell,
});

export type WidgetStylesType = ReturnType<typeof widgetStyles>;
